import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const grnApi = {
  ddl(params) {
    return Api.get('grn/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('grn/list', params);
  },
  detail(params) {
    return Api.get('grn', params);
  },
  create(data) {
    return Api.post('grn/create', data);
  },
  update(data) {
    return Api.post('grn/update', data);
  },
  delete(data) {
    return Api.post('grn/delete', data);
  },
};

export const grnLabelApi = {
  ddl(params) {
    return Api.get('grn/label/picklist', params);
  },
  list(params) {
    return Api.get('grn/label/list', params);
  },
  printList(params) {
    return Api.get('grn/label/print', params);
  },
  fifoList(params) {
    return Api.get('grn/label/fifo', params);
  },
  detail(params) {
    return Api.get('grn/label', params);
  },
  create(data) {
    return Api.post('grn/label/create', data);
  },
  update(data) {
    return Api.post('grn/label/update', data);
  },
  delete(data) {
    return Api.post('grn/label/delete', data);
  },
  purge(data) {
    return Api.post('grn/label/purge', data);
  },
};

export const grnItemApi = {
  ddl(params) {
    return Api.get('grn/item/picklist', params);
  },
  list(params) {
    return Api.get('grn/item/list', params);
  },
  detail(params) {
    return Api.get('grn/item', params);
  },
  create(data) {
    return Api.post('grn/item/create', data);
  },
  update(data) {
    return Api.post('grn/item/update', data);
  },
  delete(data) {
    return Api.post('grn/item/delete', data);
  },
  preupload(data) {
    return Api.post('grn/item/image/preupload', data);
  },
};
