<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import Details from './Details';
export default {
  name: 'FGoodsLabel',
  components: {
    Details,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'FGoodsLabel':
          return 'Details';
        default:
          return 'Details';
      }
    },
  },
};
</script>
