<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title>
          <TextField v-model="searchVal" class="mr-2" :hide-details="true" :placeholder="$t('lbl.search')" />
          <AppBtn color="primary" @click="createModalShow = true">{{ $t('supp.act.addSupp') }}</AppBtn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
        <v-data-table
          disable-sort
          :headers="headers"
          :items="data"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.pageSize"
          :footer-props="{
            itemsPerPageOptions: pageSizeOpts,
          }"
          :server-items-length="pagination.total"
          @update:page="pageChg"
          @update:items-per-page="pageSizeChg"
        >
          <!-- <template v-slot:item.type="{ item }">
            {{ $t('map.outletType')[item.type] }}
          </template>
          <template v-slot:item.isDef="{ item }">
            <v-icon small :color="item.isDef ? 'green' : 'red'">{{ item.isDef ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
          </template> -->
          <template v-slot:item.mobile="{ item }">
            {{ item.dialCode + item.mobile }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ $t('map.status')[item.status] }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-end">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click.stop="showConfirmModal(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </app-card>
    </v-col>
    <CreateModal
      v-if="createModalShow"
      :id="id"
      :show-modal.sync="createModalShow"
      @close="closeModal"
      @getList="getListNTotalRec"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import CreateModal from './components/CreateModal';
import { supplierApi } from '@/api/supplier';
import { mapState } from 'vuex';
export default {
  name: 'SupplierList',
  components: {
    CreateModal,
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('supp.title.deleteSupplier'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        total: 0,
      },
      searchVal: '',
      createModalShow: false,
      headers: [
        { text: '#', width: '40', value: 'no' },
        { text: this.$t('supp.lbl.code'), width: '120', value: 'shortCode' },
        { text: this.$t('supp.lbl.name'), width: '250', value: 'name' },
        { text: this.$t('supp.lbl.shortName'), width: '180', value: 'shortName' },
        { text: this.$t('supp.lbl.mobileNo'), width: '150', value: 'mobile' },
        { text: this.$t('supp.lbl.contactPerson'), width: '150', value: 'contact' },
        //{ text: this.$t('supp.lbl.email'), width: '200', value: 'email' },
        { text: this.$t('lbl.status'), width: '100', value: 'status' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      data: [],
      id: null,
      timeout: null,
      initialLoad: null,
      initialRecObj: {
        total: 0,
        page: 1,
        pageSize: 10,
        data: [],
      }
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
  },
  watch: {
    searchVal(val) {
      clearTimeout(this.timeout);
      if (val.length >= 3) {
        this.timeout = setTimeout(() => {
          this.pagination.page = 1;
          this.getListNTotalRec();
        }, 500);
      } else if (this.isUndefinedNullOrEmpty(val)) {
        this.pagination.page = this.initialRecObj.page;
        this.pagination.total = this.initialRecObj.total;
        if (this.pagination.pageSize == this.initialRecObj.pageSize) {
          this.data = this.initialRecObj.data;
        } else {
          this.getListNTotalRec();
        }
      }
    }
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.initialLoad = true;
    this.getListNTotalRec();
  },
  methods: {
    closeModal() {
      this.createModalShow = false;
      this.id = null;
    },
    editItem(item) {
      this.id = item.id;
      this.createModalShow = true;
    },
    delete(id) {
      this.confirmActionLoading = true;
      return supplierApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        this.getList();
      });
    },
    getTotalRec() {
      const params = {
        name: this.searchVal,
        page: 0,
      };
      return supplierApi.list(params).then(res => {
        this.pagination.total = res.data.total;
        if (this.initialLoad) {
          this.initialRecObj.total = this.pagination.total;
        }
      });
    },
    getList() {
      const params = {
        name: this.searchVal,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return supplierApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
        if (this.initialLoad) {
          this.initialRecObj.page = this.pagination.page;
          this.initialRecObj.pageSize = this.pagination.pageSize;
          this.initialRecObj.data = this.data;
          this.initialLoad = false;
        }
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
