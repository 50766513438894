import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const warehouseApi = {
  ddl(params) {
    return Api.get('storage/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('storage/list', params);
  },
  detail(params) {
    return Api.get('storage', params);
  },
  create(data) {
    return Api.post('storage/create', data);
  },
  update(data) {
    return Api.post('storage/update', data);
  },
  delete(data) {
    return Api.post('storage/delete', data);
  },
};
