var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('app-card',[_c('v-card-title',[_c('TextField',{staticClass:"mr-2",attrs:{"hide-details":true,"placeholder":_vm.$t('lbl.search')},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('AppBtn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.createModalShow = true}}},[_vm._v(_vm._s(_vm.$t('warehouse.act.addWarehouse')))])],1),_c('v-data-table',{staticClass:"cursor-pointer",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.data,"page":_vm.pagination.page,"items-per-page":_vm.pagination.pageSize,"footer-props":{
          itemsPerPageOptions: _vm.pageSizeOpts,
        },"server-items-length":_vm.pagination.total},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.pageChg],"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},_vm.pageSizeChg],"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('map.productType')[item.type])+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('map.status')[item.status])+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmModal(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)],1),(_vm.createModalShow)?_c('CreateModal',{attrs:{"id":_vm.id,"show-modal":_vm.createModalShow},on:{"update:showModal":function($event){_vm.createModalShow=$event},"update:show-modal":function($event){_vm.createModalShow=$event},"close":_vm.closeModal,"getList":_vm.getListNTotalRec}}):_vm._e(),_c('ConfirmModal',{attrs:{"confirm-action-loading":_vm.confirmActionLoading,"header-title":_vm.confirmModalTitle,"modal-size":'modal-xs',"confirm-msg":_vm.confirmMsg,"show-modal":_vm.confirmModalShow},on:{"update:showModal":function($event){_vm.confirmModalShow=$event},"update:show-modal":function($event){_vm.confirmModalShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }