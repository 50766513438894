<template>
  <vue-qrcode :value="value" :options="{ width, margin }"></vue-qrcode>
</template>
<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
export default {
  name: 'QrCode',
  components: {
    VueQrcode,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    width: {
      type: [String, Number],
      default: 200,
    },
    margin: {
      type: [String, Number],
      default: 0,
    }
  },
}
</script>