<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <!-- <v-col v-if="isUpdate" class="form-row-col" cols="12" sm="12">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.itemDesc') }}
            </label>
            <TextField
              v-model="form.product"
              :label="$t('item.lbl.itemDesc')"
              disabled
            />
          </v-col> -->
          <v-col class="form-row-col" cols="12" sm="12">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.rawMaterialList') }}
              <span class="font-red">{{ rules.selectedId.required ? '*' : '' }}</span>
            </label>
            <Autocomplete
              v-model="selectedId"
              class="font-weight-bold"
              :label="$t('item.lbl.rawMaterialList')"
              :outlined="true"
              :solo="false"
              :options="itemDdl"
              :placeholder="$t('item.lbl.rawMaterialList')"
              :rules="rules.selectedId"
              @change="itemChg"
              :disabled="isUpdate"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.quantity') }}
              <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.qty"
              :type="'number'"
              :min="1"
              :step="decimalStep"
              :rules="rules.qty"
              :label="$t('item.lbl.quantity')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.uom') }}
              <span class="font-red">{{ rules.uom.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.uom" :rules="rules.uom" :label="$t('uom.lbl.uom')" disabled />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.unitPrice') }}
              <span class="font-red">{{ rules.cost.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.cost"
              :type="'number'"
              :min="1"
              :step="decimalStep"
              :rules="rules.cost"
              :label="$t('item.lbl.unitPrice')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.taxCode') }}
              <span class="font-red">{{ rules.taxCode.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.taxCode" :rules="rules.taxCode" :options="$t('taxCodeOpt')" :label="$t('item.lbl.taxCode')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.salesTaxPercent') }}
              <span class="font-red">{{ rules.tax.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.tax" :type="'number'" :min="0" :rules="rules.tax" :label="$t('item.lbl.salesTaxPercent')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label">{{ $t('item.lbl.subtotal') }} </label>
            <TextField
              v-model="computedSubtotal"
              :type="'number'"
              :min="1"
              :step="decimalStep"
              :label="$t('item.lbl.subtotal')"
              disabled
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label">{{ $t('item.lbl.salesTax') }} </label>
            <TextField
              v-model="computedTaxRm"
              :type="'number'"
              :min="0"
              :step="decimalStep"
              :label="$t('item.lbl.salesTax')"
              disabled
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label">{{ $t('item.lbl.subtotalInclTax') }} </label>
            <TextField
              v-model="computedSubtotalInclTax"
              :type="'number'"
              :min="0"
              :step="decimalStep"
              :label="$t('item.lbl.subtotalInclTax')"
              disabled
            />
          </v-col>
        </v-row>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { purchaseOrderItemApi } from '@/api/purchase-order';
import { isUndefinedNullOrEmpty, roundToDecimal, extractJsonKey } from '@/util/tools';

export default {
  name: 'ProductItemDetails',
  props: {
    poId: {
      type: Number,
      default: null,
    },
    poItemId: {
      type: Number,
      default: null,
    },
    itemDdl: {
      type: Array,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.poItemId) ? this.$t('item.title.updateItem') : this.$t('item.title.addItem'),
      taxRm: 0,
      form: {
        orderId: null,
        productId: null,
        qty: 1,
        uom: '',
        taxCode: 'ZR',
        cost: null,
        tax: null,
        price: 0,
        notes: null,
      },
      selectedId: null,
      decimalStep: '0.01',
      rules: {
        orderId: { required: true },
        productId: { required: true },
        qty: { required: true },
        uom: { required: true },
        taxCode: { required: true },
        cost: { required: true },
        tax: { required: true },
        price: { required: true },
        notes: { required: false },
        selectedId: { required: true },
      },
      productUomList: [],
      supplierList: [],
      loading: false,
    };
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.poItemId) ? true : false;
    },
    computedSubtotal() {
      return roundToDecimal(this.form.cost * this.form.qty, 2);
    },
    computedTaxRm() {
      return roundToDecimal((this.computedSubtotal * this.form.tax) / 100, 2);
    },
    computedSubtotalInclTax() {
      return roundToDecimal(this.computedSubtotal + this.computedTaxRm, 2);
    },
  },
  created() {
    if (!isUndefinedNullOrEmpty(this.poItemId)) {
      this.detail(this.poItemId);
    }
  },
  methods: {
    itemChg(id) {
      let selectedItem = this.itemDdl.find(item => {
        return (item.id == id);
      });
      this.form.qty = selectedItem.qty;
      this.form.uom = selectedItem.uom;
      this.form.cost = selectedItem.cost;
      this.form.price = selectedItem.price;
      this.form.taxCode = selectedItem.taxCode;
      this.form.tax = selectedItem.tax;
      this.form.productId = selectedItem.productId;
      this.selectedId = selectedItem.id; // price id
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
        qty: Number(this.form.qty),
        cost: Number(this.form.cost),
        tax: Number(this.form.tax),
        price: Number((this.form.cost * (1 + this.form.tax / 100)).toFixed(2)),
        orderId: this.poId,
        priceId: this.selectedId,
      };

      if (this.isUpdate) {
        data.id = this.poItemId;
        const keys = ['id', 'orderId', 'productId', 'qty', 'uom', 'taxCode', 'cost', 'tax', 'price', 'notes'];
        const finalData = extractJsonKey(keys, data);
        this.update(finalData);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return purchaseOrderItemApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return purchaseOrderItemApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return purchaseOrderItemApi.detail({ id }).then(res => {
        this.form = res.data;
        this.selectedId = res.data.priceId;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
.v-icon {
  font-size: 18px;
}
</style>
