<template>
  <ValidationObserver ref="observer">
    <div>
      <!-- <v-col cols="6" sm="3"></v-col> -->
      <CheckboxGroup v-model="selectedCategory" :options="categoryList" :label="$t('recipe.lbl.category')" :rules="rules.selectedCategory" />
    </div>
    <div class="d-flex justify-end">
      <AppBtn class="btn-cancel mr-2" @click="back">{{ $t('act.back') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ $t('act.save') }}</AppBtn>
    </div>
  </ValidationObserver>
</template>
<script>
import { recipeCategoryApi } from '@/api/recipe';
export default {
  name: 'RecipeCategories',
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      categoryList: [],
      initialSelectedCategory: [],
      selectedCategory: [],
      rules: {
        selectedCategory: { required: true },
      }
    }
  },
  watch: {
    data(value) {
      this.populateData(value);
    },
  },
  computed: {
    // isUpdate() {
    //   return !this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'RecipeUpdate';
    // },
    categoryId() {
      return this.$route.params.categoryId || null;
    },
  },
  created() {
    this.getCategoryList();
    if (!this.isUndefinedNullOrEmpty(this.data)) {
      this.populateData(this.data);
    }
  },
  methods: {
    getCategoryList() {
      const params = {
        page: 1,
        pageSize: -1,
      }
      return recipeCategoryApi.list(params).then (res => {
        this.categoryList = res.data.map(item => {
          return {
            text: item.name,
            value: item.id,
          }
        });
      });
    },
    back() {
      if (this.isUndefinedNullOrEmpty(this.categoryId) || this.categoryId == 0) {
        this.navigatePush('RecipeListByCategory');
      } else {
        this.navigatePush('RecipeList', { categoryId: this.categoryId });
      }
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      // console.log('this.initialSelectedCategory', this.initialSelectedCategory);
      // console.log('this.selectedCategory', this.selectedCategory);
      let newSelectedCategory = this.selectedCategory.filter(selected => {
        return this.initialSelectedCategory.find(item => {
          return selected != item.grpId;
        })
      });
      // console.log('newSelectedCategory', newSelectedCategory);

      // const keys = ['name', 'qty', 'uom', 'avgQty', 'dimension', 'batch', 'notes', 'approved', 'published'];
      // let data = extractJsonKey(keys, this.form);

      // if (this.isUpdate) {
      //   data.id = this.form.id;
      //   this.update(data);
      // } else {
      //   data.subGrps = [
      //     {
      //       recipeId: 0,
      //       grpId: this.categoryId,
      //       flag: 0,
      //     },
      //   ]
      //   // this.create(data);
      // }
    },
    // create(data) {
    //   console.log(JSON.stringify(data, '', 2));
    //   this.loading = true;
    //   return recipeApi
    //     .create(data)
    //     .then(() => {
    //       this.loading = false;
    //       this.showSuccess(this.$t('lbl.createSuccess'));
    //       this.navigatePush('RecipeList', { categoryId: this.categoryId });
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    populateData(data) {
      this.form = { ...this.form, ...data };
      let initialSelectedCategory = [];
      let selectedCategory = [];
      for (let item of data.subGrps) {
        initialSelectedCategory.push({
          id: item.id,
          grpId: item.grpId,
        });
        selectedCategory.push(item.grpId);
      }

      this.initialSelectedCategory = initialSelectedCategory;
      this.selectedCategory = selectedCategory;
    },
  }
}
</script>