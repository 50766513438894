import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const rawMatInventoryBalApi = {
  ddl(params) {
    params = sanitizeParams(params);
    return Api.get('stock/product/bal/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('stock/product/bal/list', params);
  },
};

export const rawMatInventoryTxApi = {
  ddl(params) {
    return Api.get('stock/product/tx/picklist', params);
  },
  list(params) {
    return Api.get('stock/product/tx/list', params);
  },
  stockIn(data) {
    return Api.post('stock/product/tx/in', data);
  },
  stockOut(data) {
    return Api.post('stock/product/tx/out', data);
  },
};

export const semiGoodsInventoryBalApi = {
  ddl(params) {
    params = sanitizeParams(params);
    return Api.get('stock/semi-goods/bal/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('stock/semi-goods/bal/list', params);
  },
};

export const semiGoodsInventoryTxApi = {
  ddl(params) {
    return Api.get('stock/semi-goods/tx/picklist', params);
  },
  list(params) {
    return Api.get('stock/semi-goods/tx/list', params);
  },
  stockIn(data) {
    return Api.post('stock/semi-goods/tx/in', data);
  },
  stockOut(data) {
    return Api.post('stock/semi-goods/tx/out', data);
  },
};

export const fGoodsInventoryBalApi = {
  ddl(params) {
    params = sanitizeParams(params);
    return Api.get('stock/goods/bal/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('stock/goods/bal/list', params);
  },
};

export const fGoodsInventoryTxApi = {
  ddl(params) {
    return Api.get('stock/goods/tx/picklist', params);
  },
  list(params) {
    return Api.get('stock/goods/tx/list', params);
  },
  stockIn(data) {
    return Api.post('stock/goods/tx/in', data);
  },
  stockOut(data) {
    return Api.post('stock/goods/tx/out', data);
  },
};