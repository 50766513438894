const data = [
  {
    path: 'auth/user/login',
    values: ['NotInitialized'],
  },
  {
    path: '/first-time/login',
    values: [1131],
  },
  {
    path: '/verify/scrt/answer',
    values: [99],
  },
  {
    path: 'store/stock/order/label/import',
    values: ['InvalidRequest'],
  },
  {
    path: 'sales/order/label/import',
    values: ['InvalidRequest'],
  },
];

export default data;
