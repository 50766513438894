<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.supplier') }}
              <span class="font-red">{{ rules.supplierId.required ? '*' : '' }}</span>
            </label>
            <Select
              v-model="form.supplierId"
              :label="$t('productPrice.lbl.supplier')"
              :options="supplierList"
              :rules="rules.supplierId"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productCert.lbl.certType') }}
              <span class="font-red">{{ rules.type.required ? '*' : '' }}</span>
            </label>
            <Select
              v-model="form.type"
              :rules="rules.type"
              :options="productCertTypeList"
              :label="$t('productCert.lbl.certType')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="12">
            <label class="form-row-col__label"
              >{{ $t('productCert.lbl.refNo') }}
              <span class="font-red">{{ rules.refNo.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.refNo" :rules="rules.refNo" :label="$t('productCert.lbl.refNo')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productCert.lbl.expiry') }}
              <span class="font-red">{{ rules.expiry.required ? '*' : '' }}</span>
            </label>
            <DatePicker
              v-model="form.expiry"
              :rules="rules.expiry"
              :label="$t('productCert.lbl.expiry')"
              :disabled="!enableEdit"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productCert.lbl.certificate') }}
              <span class="font-red">{{ rules.cert.required ? '*' : '' }}</span>
            </label>
            <FileUpload v-model="form.cert[0]" :mode="fileUploadMode" @onFile="onFile" @onClear="onClear" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="12">
            <label class="form-row-col__label"
              >{{ $t('lbl.remarks') }}
              <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
          </v-col>
        </v-row>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Countries from '@/util/countries';
import { productCertApi } from '@/api/product';
import { supplierApi } from '@/api/supplier';
import { isUndefinedNullOrEmpty, extractJsonKey } from '@/util/tools';
import { FILE_UPLOAD_MODE } from '@/common/enum';
import { upload } from '@/util/upload';

export default {
  name: 'ProductCertDetails',
  props: {
    id: {
      type: Number,
      default: null,
    },
    productId: {
      type: Number,
      default: null,
    },
    uom: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.id)
        ? this.$t('productCert.title.updateCert')
        : this.$t('productCert.title.addCert'),
      taxRm: 0,
      form: {
        supplierId: null,
        type: null,
        expiry: null,
        refNo: null,
        notes: null,
        cert: [],
      },
      filesToUpload: [],
      rules: {
        supplierId: { required: true },
        type: { required: true },
        expiry: { required: true },
        cert: { required: false },
        refNo: { required: false },
        notes: { required: false },
      },
      supplierList: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      productCertTypeList: state => state.lookup.productCertTypeList,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.id) ? true : false;
    },
    enableEdit() {
      return true;
    },
    fileUploadMode() {
      return this.isUpdate && this.form?.cert[0]?.url ? FILE_UPLOAD_MODE.VIEW : FILE_UPLOAD_MODE.ADD;
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.productCertTypeList)) {
      this.fetchProductCertTypeList();
    }
    if (!isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    } else {
      this.form.uom = this.uom.orderUom;
    }
    this.getSupplierList();
  },
  methods: {
    ...mapActions(['fetchProductCertTypeList']),
    async onFile(data) {
      this.$set(this.form.cert, 0, {
        file: data[0],
        name: data[0].name,
        url: URL.createObjectURL(data[0]),
      });

      this.filesToUpload.push(this.form.cert[0]);
    },
    onClear() {
      this.form.cert = [];
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
      };
      if (this.isUpdate) {
        data.id = this.id;
        const keys = ['id', 'type', 'refNo', 'notes', 'expiry', 'status'];
        const finalData = extractJsonKey(keys, data);
        this.update(finalData);
      } else {
        data.productId = this.productId;
        data.status = 1;
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return productCertApi
        .create(data)
        .then(async res => {
          await this.processFilesUpload(res.data.id);
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update(data) {
      this.loading = true;
      await this.processFilesUpload(this.id);
      return productCertApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return productCertApi.detail({ id }).then(res => {
        this.form = res.data;
        this.form.expiry = isUndefinedNullOrEmpty(this.form.expiry) ? null : this.dateOnly(this.form.expiry);
      });
    },
    certPreupload(id, index) {
      return productCertApi.preupload({ id, index }).then(res => {
        return res;
      });
    },
    async processFilesUpload(id) {
      let promises = [];
      for (let [index, fileObj] of Object.entries(this.filesToUpload)) {
        promises.push(await this.processFileUpload(id, index, fileObj));
      }
      if (promises.length > 0) {
        Promise.all(promises).then(res => {
          return res;
        });
      }
      return;
    },
    async processFileUpload(id, index, fileObj) {
      const {
        data: { filename, hostUrl, token },
      } = await this.certPreupload(id, index);

      let file = new File([fileObj.file], `${filename}${fileObj.name}`, { type: fileObj.file.type });
      await upload(token, hostUrl, { file });
    },
    getSupplierList() {
      const params = {
        orgId: null,
        orderBy: null,
      };
      return supplierApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.supplierList = list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
.v-icon {
  font-size: 18px;
}
</style>
