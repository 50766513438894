<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="4">
                  <div>
                    <label class="font-1dot25r">{{ $t('salesOrder.subSec.soDetails') }}</label>
                  </div>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('salesOrder.lbl.soNo') }}
                      <span class="font-red">{{ rules.soNo.required ? '*' : '' }}</span>
                    </label>
                    <TextField v-model="form.soNo" :rules="rules.soNo" :label="$t('salesOrder.lbl.soNo')" :disabled="disableEdit" />
                  </div>
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('salesOrder.lbl.soDate') }}
                      <span class="font-red">{{ rules.soDate.required ? '*' : '' }}</span>
                    </label>
                    <DatePicker v-model="form.soDate" :rules="rules.soDate" :label="$t('salesOrder.lbl.soDate')" :disabled="disableAfterCompleted" />
                  </div>
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('lbl.status') }}
                      <span class="font-red">{{ rules.status.required ? '*' : '' }}</span>
                    </label>
                    <TextField v-if="viewOnlyStatus" :value="$t('map.salesOrderSts')[form.status]" :label="$t('lbl.status')" disabled />
                    <Select
                      v-else
                      v-model="form.status"
                      :label="$t('lbl.status')"
                      :options="$t('salesOrderSts')"
                      :rules="rules.status"
                      :disabled="disableAfterCompleted"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('salesOrder.lbl.deliveryDate') }}
                      <span class="font-red">{{ rules.doDate.required ? '*' : '' }}</span>
                    </label>
                    <DatePicker v-model="form.doDate" :rules="rules.doDate" :label="$t('salesOrder.lbl.deliveryDate')" :disabled="disableAfterCompleted" />
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('salesOrder.lbl.department') }}
                      <span class="font-red">{{ rules.deptId.required ? '*' : '' }}</span>
                    </label>
                    <Select
                      v-model="form.deptId"
                      :label="$t('salesOrder.lbl.department')"
                      :options="deptList"
                      :rules="rules.deptId"
                    />
                  </div> -->
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('lbl.remarks') }}
                      <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                    </label>
                    <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" :disabled="disableAfterCompleted" />
                  </div>
                </v-col>

                <v-col class="form-row-col" cols="12" sm="4">
                  <div class="d-flex align-center">
                    <label class="font-1dot25r mr-2">{{ $t('salesOrder.subSec.customer') }}</label>
                    <!-- <v-icon small @click="supplierModalShow = true">
                      mdi-pencil
                    </v-icon>
                    <ValidationProvider
                      :name="$t('purchaseOrder.lbl.supplier')"
                      :rules="rules.customerId"
                      v-slot="{ errors }"
                    >
                      <input v-show="false" v-model="form.customerId" type="text" />
                      <span class="font-12 font-red ml-2">{{ errors[0] }}</span>
                    </ValidationProvider> -->
                    <!-- <AppBtn :height="'30'" @click="supplierModalShow = true">{{ $t('purchaseOrder.act.selectSupplier') }}</AppBtn> -->
                  </div>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('salesOrder.act.selectCustomer') }}
                      <span class="font-red">{{ rules.customer.id.required ? '*' : '' }}</span>
                    </label>
                    <Select
                      v-model="form.customerId"
                      :label="$t('salesOrder.act.selectCustomer')"
                      :options="customerList"
                      :clearable="false"
                      @change="selectCustomer"
                      :disabled="disableEdit"
                    />
                  </div>
                  <template v-if="form.customerId">
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('salesOrder.lbl.customerName') }}
                        <span class="font-red">{{ rules.customer.name.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.customer.name"
                        :disabled="customerFieldsDisabled"
                        :rules="rules.customer.name"
                        :label="$t('salesOrder.lbl.customerName')"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.addr1') }}
                        <span class="font-red">{{ rules.customer.address.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.customer.address"
                        :disabled="customerFieldsDisabled"
                        :rules="rules.customer.address"
                        :label="$t('lbl.addr.addr1')"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.addr2') }}
                        <span class="font-red">{{ rules.customer.address2.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.customer.address2"
                        :disabled="customerFieldsDisabled"
                        :rules="rules.customer.address2"
                        :label="$t('lbl.addr.addr2')"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.addr3') }}
                        <span class="font-red">{{ rules.customer.address3.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.customer.address3"
                        :disabled="customerFieldsDisabled"
                        :rules="rules.customer.address3"
                        :label="$t('lbl.addr.addr3')"
                      />
                    </div>
                    <v-row class="mb-0 mb-md-3 my-0">
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('lbl.addr.state') }}
                          <span class="font-red">{{ rules.customer.province.required ? '*' : '' }}</span>
                        </label>
                        <Select
                          v-model="form.info.customer.province"
                          :disabled="customerFieldsDisabled"
                          :label="$t('lbl.addr.state')"
                          :options="provinceList"
                          :rules="rules.customer.province"
                        />
                      </v-col>
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('lbl.addr.postalCode') }}
                          <span class="font-red">{{ rules.customer.postcode.required ? '*' : '' }}</span>
                        </label>
                        <TextField
                          v-model="form.info.customer.postcode"
                          :disabled="customerFieldsDisabled"
                          :rules="rules.customer.postcode"
                          :label="$t('lbl.addr.postalCode')"
                        />
                      </v-col>
                    </v-row>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.country') }}
                        <span class="font-red">{{ rules.customer.country.required ? '*' : '' }}</span>
                      </label>
                      <Select
                        v-model="form.info.customer.country"
                        :disabled="customerFieldsDisabled"
                        :label="$t('lbl.addr.country')"
                        :options="countries"
                        :rules="rules.customer.country"
                      />
                    </div>
                    <v-row class="mb-0 mb-md-3 my-0">
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('salesOrder.lbl.tel') }}
                          <span class="font-red">{{ rules.customer.tel.required ? '*' : '' }}</span>
                        </label>
                        <TextField
                          v-model="form.info.customer.tel"
                          :disabled="customerFieldsDisabled"
                          :rules="rules.customer.tel"
                          :label="$t('salesOrder.lbl.tel')"
                        />
                      </v-col>
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('salesOrder.lbl.fax') }}
                          <span class="font-red">{{ rules.customer.fax.required ? '*' : '' }}</span>
                        </label>
                        <TextField
                          v-model="form.info.customer.fax"
                          :disabled="customerFieldsDisabled"
                          :rules="rules.customer.fax"
                          :label="$t('salesOrder.lbl.fax')"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>

                <v-col class="form-row-col" cols="12" sm="4">
                  <div class="d-flex align-center">
                    <label class="font-1dot25r mr-2">{{ $t('salesOrder.subSec.from') }}</label>
                    <!-- <v-icon small @click="outletModalShow = true">
                      mdi-pencil
                    </v-icon>
                    <ValidationProvider :name="$t('purchaseOrder.subSec.shipTo')" :rules="rules.customerId" v-slot="{ errors }">
                      <input v-show="false" v-model="form.outletId" type="text" />
                      <span class="font-12 font-red ml-2">{{ errors[0] }}</span>
                    </ValidationProvider> -->
                  </div>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <div class="mb-3">
                    <label class="form-row-col__label"
                      >{{ $t('salesOrder.act.selectBranch') }}
                      <span class="font-red">{{ rules.from.outletId.required ? '*' : '' }}</span>
                    </label>
                    <Select
                      v-model="form.outletId"
                      :label="$t('salesOrder.act.selectBranch')"
                      :options="outletList"
                      :clearable="false"
                      @change="selectOutlet"
                      :disabled="disableEdit"
                    />
                  </div>
                  <template v-if="form.outletId">
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('salesOrder.lbl.contactPerson') }}
                        <span class="font-red">{{ rules.from.contact.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.from.contact"
                        :disabled="fromOutletFieldsDisabled"
                        :rules="rules.from.contact"
                        :label="$t('salesOrder.lbl.contactPerson')"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.addr1') }}
                        <span class="font-red">{{ rules.from.address.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.from.address"
                        :disabled="fromOutletFieldsDisabled"
                        :rules="rules.from.address"
                        :label="$t('lbl.addr.addr1')"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.addr2') }}
                        <span class="font-red">{{ rules.from.address2.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.from.address2"
                        :disabled="fromOutletFieldsDisabled"
                        :rules="rules.from.address2"
                        :label="$t('lbl.addr.addr2')"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.addr3') }}
                        <span class="font-red">{{ rules.from.address3.required ? '*' : '' }}</span>
                      </label>
                      <TextField
                        v-model="form.info.from.address3"
                        :disabled="fromOutletFieldsDisabled"
                        :rules="rules.from.address3"
                        :label="$t('lbl.addr.addr3')"
                      />
                    </div>
                    <v-row class="mb-0 mb-md-3 my-0">
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('lbl.addr.state') }}
                          <span class="font-red">{{ rules.from.province.required ? '*' : '' }}</span>
                        </label>
                        <Select
                          v-model="form.info.from.province"
                          :disabled="fromOutletFieldsDisabled"
                          :label="$t('lbl.addr.state')"
                          :options="provinceList"
                          :rules="rules.from.province"
                        />
                      </v-col>
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('lbl.addr.postalCode') }}
                          <span class="font-red">{{ rules.from.postcode.required ? '*' : '' }}</span>
                        </label>
                        <TextField
                          v-model="form.info.from.postcode"
                          :disabled="fromOutletFieldsDisabled"
                          :rules="rules.from.postcode"
                          :label="$t('lbl.addr.postalCode')"
                        />
                      </v-col>
                    </v-row>
                    <div class="mb-3">
                      <label class="form-row-col__label"
                        >{{ $t('lbl.addr.country') }}
                        <span class="font-red">{{ rules.from.country.required ? '*' : '' }}</span>
                      </label>
                      <Select
                        v-model="form.info.from.country"
                        :disabled="fromOutletFieldsDisabled"
                        :label="$t('lbl.addr.country')"
                        :options="countries"
                        :rules="rules.from.country"
                      />
                    </div>
                    <v-row class="mb-0 mb-md-3 my-0">
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('salesOrder.lbl.tel') }}
                          <span class="font-red">{{ rules.from.tel.required ? '*' : '' }}</span>
                        </label>
                        <TextField
                          v-model="form.info.from.tel"
                          :disabled="fromOutletFieldsDisabled"
                          :rules="rules.from.tel"
                          :label="$t('salesOrder.lbl.tel')"
                        />
                      </v-col>
                      <v-col class="py-0 mb-3 mb-md-0" cols="12" md="6">
                        <label class="form-row-col__label"
                          >{{ $t('salesOrder.lbl.fax') }}
                          <span class="font-red">{{ rules.from.fax.required ? '*' : '' }}</span>
                        </label>
                        <TextField
                          v-model="form.info.from.fax"
                          :disabled="fromOutletFieldsDisabled"
                          :rules="rules.from.fax"
                          :label="$t('salesOrder.lbl.fax')"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <div v-if="pageReady" class="d-flex flex-wrap justify-end mt-5">
                <AppBtn class="btn-grey mr-2" @click="navigateReplace('SalesOrderList')">{{ $t('act.back') }}</AppBtn>
                <AppBtn v-if="allowCancel" class="btn-orange mr-2" @click="showConfirmModal(soId, actEnum.CANCEL)">{{ $t('act.cancel') }}</AppBtn>
                <AppBtn v-if="allowDelete" class="btn-red mr-2" @click="showConfirmModal(soId, actEnum.DELETE)">{{ $t('act.delete') }}</AppBtn>
                <AppBtn v-if="allowApprove" class="btn-green mr-2" :loading="approveLoading" @click="approve(soId)">{{ $t('act.approve') }}</AppBtn>
                <AppBtn v-if="showCreateUpdate" :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
              </div>
            </div>
            <div v-if="tab.id === 1" class="tab-content-wrap px-2 py-3 px-md-4">
              <ItemList :so-id="soId" :customer-id="form.customerId" :disable-edit="disableEdit" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </v-col>
    <SelectModal
      v-if="outletModalShow"
      :id="form.outletId"
      :field-lbl="$t('salesOrder.act.selectBranch')"
      :header-title="$t('salesOrder.act.selectBranch')"
      :list="outletList"
      :show-modal.sync="outletModalShow"
      @close="outletModalShow = false"
      @confirm="selectOutlet"
    />
    <SelectModal
      v-if="supplierModalShow"
      :id="form.customerId"
      :field-lbl="$t('salesOrder.act.selectCustomer')"
      :header-title="$t('salesOrder.act.selectCustomer')"
      :list="customerList"
      :show-modal.sync="supplierModalShow"
      @close="supplierModalShow = false"
      @confirm="selectCustomer"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import { salesOrderApi } from '@/api/sales-order';
import { deptApi } from '@/api/department';
import { outletApi } from '@/api/outlet';
import { customerApi } from '@/api/customer';
import { mapState, mapActions } from 'vuex';
import { extractJsonKey, getCurrentDate } from '@/util/tools';
import { SALES_ORDER_STATUS } from '@/common/enum';
import Countries from '@/util/countries';
import SelectModal from './components/SelectModal';
import ItemList from '@/views/SalesOrderItemList';

export default {
  name: 'SalesOrderDetails',
  components: {
    ItemList,
    SelectModal,
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: '',
      confirmMsg: '',

      actEnum: {
        CANCEL: 1,
        DELETE: 2,
      },
      selectedTab: 0,
      tabs: this.$t('salesOrderTabs'),
      outletModalShow: false,
      supplierModalShow: false,
      deptList: [],
      outletList: [],
      customerList: [],
      fromOutletFieldsDisabled: true,
      customerFieldsDisabled: true,
      loading: false,
      approveLoading: false,
      pageReady: false,
      currentStatus: null, // Current status, use this because user can toggle status from select field manually
      form: {
        customerId: null,
        outletId: null,
        soNo: '',
        status: SALES_ORDER_STATUS.CONFIRMED,
        soDate: null,
        doDate: null,
        reqId: null,
        // deptId: null,
        info: {
          from: {
            contact: '',
            address: '',
            address2: '',
            address3: '',
            province: '',
            postcode: '',
            country: '',
            tel: '',
            fax: '',
          },
          customer: {
            name: '',
            address: '',
            address2: '',
            address3: '',
            province: '',
            postcode: '',
            country: '',
            tel: '',
            fax: '',
          },
        },
        curr: null,
        amt: null,
        notes: null,
        notify: null,
        type: null,
        flag: null,
        items: [
          // {
          //   orderId: 0,
          //   productId: 1,
          //   qty: 1,
          //   uom: 'PKT',
          //   taxCode: null,
          //   cost: null,
          //   tax: null,
          //   price: 20,
          //   notes: null,
          //   flag: null,
          // },
        ],
      },
      rules: {
        soNo: {
          required: false,
        },
        soDate: {
          required: true,
        },
        customerId: {
          required: true,
        },
        outletId: {
          required: true,
        },
        status: {
          required: true,
        },
        doDate: {
          required: false,
        },
        // deptId: {
        //   required: false,
        // },
        notes: {
          required: false,
        },
        from: {
          outletId: { required: true },
          contact: { required: true },
          address: { required: false },
          address2: { required: false },
          address3: { required: false },
          province: { required: false },
          postcode: { required: false },
          country: { required: false },
          tel: { required: false },
          fax: { required: false },
        },
        customer: {
          id: { required: true },
          name: { required: true },
          address: { required: false },
          address2: { required: false },
          address3: { required: false },
          province: { required: false },
          postcode: { required: false },
          country: { required: false },
          tel: { required: false },
          fax: { required: false },
        },
      },
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      provinceList: state => state.lookup.provinceList,
    }),
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'SalesOrderUpdate';
    },
    soId() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) ? Number(this.$route.params.id) : null;
    },
    disableEdit() {
      return this.isUpdate && this.currentStatus != SALES_ORDER_STATUS.NEW && this.currentStatus != SALES_ORDER_STATUS.CONFIRMED;
    },
    disableAfterCompleted() {
      return this.isUpdate && (this.currentStatus == SALES_ORDER_STATUS.COMPLETED || this.currentStatus == SALES_ORDER_STATUS.DELETED || this.currentStatus == SALES_ORDER_STATUS.CANCELLED);
    },
    allowCancel() {
      return this.isUpdate && (this.currentStatus == SALES_ORDER_STATUS.NEW || this.currentStatus == SALES_ORDER_STATUS.CONFIRMED || this.currentStatus == SALES_ORDER_STATUS.APPROVED);
    },
    allowDelete() {
      return this.isUpdate && (this.currentStatus == SALES_ORDER_STATUS.NEW || this.currentStatus == SALES_ORDER_STATUS.CONFIRMED);
    },
    allowApprove() {
      return this.isUpdate && (this.currentStatus == SALES_ORDER_STATUS.CONFIRMED);
    },
    showCreateUpdate() {
      return !this.isUpdate || (this.currentStatus == SALES_ORDER_STATUS.NEW || this.currentStatus == SALES_ORDER_STATUS.CONFIRMED || this.currentStatus == SALES_ORDER_STATUS.APPROVED);
    },
    viewOnlyStatus() {
      return this.isUpdate && (this.currentStatus == SALES_ORDER_STATUS.DELETED || this.currentStatus == SALES_ORDER_STATUS.CANCELLED);
    },
  },
  async created() {
    this.getDeptList();
    this.fetchProvinceList({ country: 'MY' }); // Future: get from ip country

    if (this.isUpdate) {
      this.getOutletList();
      this.getCustomerList();
      this.detail(this.soId);
    } else {
      this.pageReady = true;
      await this.getOutletList();
      await this.getCustomerList();
      this.form.soDate = getCurrentDate();
      if (this.outletList.length == 1) this.selectOutlet(this.outletList[0].value);
      if (this.customerList.length == 1) this.selectCustomer(this.customerList[0].value);
    }
  },
  methods: {
    ...mapActions(['fetchProvinceList']),
    showConfirmModal(soId, act) {
      if (act == this.actEnum.CANCEL) {
        this.confirmModalTitle = this.$t('lbl.cancelOrder');
        this.confirmMsg = this.$t('lbl.confirm.cancel.order');
      } else {
        this.confirmModalTitle = this.$t('lbl.deleteOrder');
        this.confirmMsg = this.$t('lbl.confirm.delete.order');
      }
      const event = {
        id: soId,
        onSuccess: act == this.actEnum.CANCEL ? this.cancelOrder : this.deleteOrder,
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    selectOutlet(id) {
      if (this.isUndefinedNullOrEmpty(id)) return;
      return this.getOutletDetail(id).then(res => {
        if (!this.isUndefinedNullOrEmpty(res.data)) {
          this.form.outletId = id;
          let keys = [
            'contact',
            'address',
            'address2',
            'address3',
            'province',
            'postcode',
            'country',
            'dialCode',
            'mobile',
            'fax',
          ];
          let data = extractJsonKey(keys, res.data);
          data.tel = data.dialCode + data.mobile;

          delete data.dialCode;
          delete data.mobile;

          if (!this.isUndefinedNullOrEmpty(data.country)) {
            this.fetchProvinceList({ country: data.country });
          }
          this.form.info.from = { ...data };
        }
      });
    },
    selectCustomer(id) {
      if (this.isUndefinedNullOrEmpty(id)) return;
      return this.getCustomerDetail(id).then(res => {
        if (!this.isUndefinedNullOrEmpty(res.data)) {
          this.form.customerId = id;
          let keys = ['name', 'address', 'address2', 'address3', 'province', 'postcode', 'country', 'dialCode', 'mobile', 'fax'];
          let data = extractJsonKey(keys, res.data);
          data.tel = data.dialCode + data.mobile;

          delete data.dialCode;
          delete data.mobile;

          if (!this.isUndefinedNullOrEmpty(data.country)) {
            this.fetchProvinceList({ country: data.country });
          }
          this.form.info.customer = { ...data };
        }
      });
    },
    getDeptList() {
      return deptApi.ddl({}).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.deptList = list;
      });
    },
    getOutletList() {
      return outletApi.ddl({}).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.outletList = list;
        return list;
      });
    },
    getOutletDetail(id) {
      return outletApi.detail({ id }).then(res => {
        return res;
      });
    },
    getCustomerList() {
      return customerApi.ddl({}).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.customerList = list;
        return list;
      });
    },
    getCustomerDetail(id) {
      return customerApi.detail({ id }).then(res => {
        return res;
      });
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
      };
      data.info = JSON.stringify(data.info);
      if (this.isUpdate) {
        data.id = this.soId;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return salesOrderApi
        .create(data)
        .then(res => {
          this.loading = false;
          this.currentStatus = data.status;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.navigatePush('SalesOrderUpdate', { id: res.data.id });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    approve(id) {
      this.approveLoading = true;
      return salesOrderApi
        .approve({ id })
        .then(() => {
          this.approveLoading = false;
          this.form.status = SALES_ORDER_STATUS.APPROVED;
          this.currentStatus = SALES_ORDER_STATUS.APPROVED;
          this.showSuccess(this.$t('salesOrder.success.approve'));
        })
        .catch(() => {
          this.approveLoading = false;
        });
    },
    update(data) {
      this.loading = true;
      return salesOrderApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.currentStatus = data.status;
          this.showSuccess(this.$t('lbl.updateSuccess'));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancelOrder(id) {
      this.confirmActionLoading = true;
      return salesOrderApi.cancel({ id }).then(res => {
        this.confirmActionLoading = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('lbl.cancelSuccess'));
          this.confirmModalShow = false;
          this.navigateReplace('SalesOrderList');
        }
      }).catch(() => {
        this.confirmActionLoading = false;
      });
    },
    deleteOrder(id) {
      this.confirmActionLoading = true;
      return salesOrderApi.delete({ id }).then(res => {
        this.confirmActionLoading = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('lbl.deleteSuccess'));
          this.confirmModalShow = false;
          this.navigateReplace('SalesOrderList');
        }
      }).catch(() => {
        this.confirmActionLoading = false;
      });
    },
    detail(id) {
      return salesOrderApi.detail({ id }).then(res => {
        let infoTemplate = this.form.info; // For supplier & ship to branch
        this.form = res.data;
        this.currentStatus = this.form.status;
        this.form.info = !this.isUndefinedNullOrEmpty(this.form.info) ? this.form.info : infoTemplate;
        this.form.doDate = !this.isUndefinedNullOrEmpty(this.form.doDate)
          ? this.setTimeZone(this.form.doDate, this.dateFormat)
          : null;
        this.form.soDate = !this.isUndefinedNullOrEmpty(this.form.soDate)
          ? this.setTimeZone(this.form.soDate, this.dateFormat)
          : null;
        this.pageReady = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
