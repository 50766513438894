<template>
  <div class="wrapper">
    <ValidationProvider ref="validator" v-slot="{ errors }" :name="label" :rules="rules" :mode="vMode">
      <vue-tel-input
        :key="countryCode"
        v-model="displayPhone"
        class="tel-input"
        :class="{ 'tel-input-error': errors[0] || phoneValid == false }"
        :default-country="countryCode"
        :disabled="disabled"
        :placeholder="placeholder"
        :mode="mode"
        :disabled-fetching-country="disableFetching"
        @input="onInputPhone"
      />
      <!-- @blur="setShowError" -->
      <div v-if="errors[0] || phoneValid == false" class="tel-error-text px-3 mt-1 mb-0">
        <span>{{ errors[0] || $t('err.lbl.invalidPhoneFormat') }}</span>
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
import Vue from 'vue';
import { VueTelInput } from 'vue-tel-input';
// import { isUndefinedNullOrEmpty } from '@/util/tools';
// import AppStorage from '@/plugins/storage/index.js';

Vue.use(VueTelInput);

export default {
  name: 'MobileNo',
  components: {
    VueTelInput,
  },
  props: {
    countryCode: {
      type: String,
      default: null,
    },
    dialCode: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      // for validation error
      type: String,
      default: '',
    },
    vMode: {
      type: String,
      default: 'passive',
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'auto',
      // auto, international, national
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      // 'required|email'
      default: () => {
        return {};
      },
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      // displayPhone: '',
      phoneValid: true,
      // mounted: false,
      showErr: true,
      disableFetching: false,
    };
  },
  computed: {
    displayPhone: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    // this.displayPhone = this.value;
    // this.mounted = true;
    // this.getCountryFromSession();
    // if (this.value.indexOf('+') !== -1) {
    //   this.displayPhone = this.value.replace('+', '');
    // } else {
    //   this.displayPhone = this.value;
    // }
  },
  methods: {
    async validate() {
      this.phoneValid = true;
      await this.$refs.validator.validate();
    },
    // async setShowError() {
    //   if (!this.phoneValid) {
    //     await this.$refs.validator.validate();
    //   } else {
    //     if (!isUndefinedNullOrEmpty(this.$refs.validator.errors)) {
    //       this.$refs.validator.reset();
    //     }
    //   }
    //   this.showErr = true;
    // },
    onInputPhone(number, phoneObj) {
      // console.log('number', number);
      // console.log('phoneObj', phoneObj);
      this.phoneValid = phoneObj.valid;
      // console.log('phoneValid', this.phoneValid);
      if (this.phoneValid) {
        this.$emit('update:country-code', phoneObj.country.iso2);
        this.$emit('update:dial-code', phoneObj.country.dialCode);
        this.$emit('getPhoneFormat', phoneObj.nationalNumber);
      }
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss" scoped>
.vue-tel-input {
  // &.tel-input::v-deep {
  //   margin-bottom: 2px;
  //   min-height: 38px;
  //   &:focus-within {
  //     border: 1px solid #bbb;
  //     box-shadow: none;
  //   }

  //   input {
  //     font-size: 0.875rem;
  //   }
  // }
  &.tel-input-error {
    border: 1px solid var(--red) !important;
  }
}
.tel-error-text {
  color: var(--red);
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  margin-bottom: 8px;
}
</style>
