<template>
  <ValidationObserver ref="observer">
    <v-row>
      <v-col cols="12" sm="4">
        <v-img :src="require('@/assets/recipe.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="mb-3">
          <div class="form-row-col__label"
            >{{ $t('recipe.lbl.recipeName') }}
            <span class="font-red">{{ rules.name.required ? '*' : '' }}</span>
          </div>
          <TextField v-model="form.name" :rules="rules.name" :label="$t('recipe.lbl.recipeName')"></TextField>
        </div>
        <div class="mb-3">
          <div class="form-row-col__label"
            >{{ $t('recipe.lbl.expectedQty') }}
            <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
          </div>
          <v-row class="my-0">
            <v-col class="py-0" cols="6" sm="6">
              <TextField
                v-model="form.qty"
                :type="'number'"
                :min="0"
                :step="decimalStep"
                :rules="rules.qty"
                :label="$t('recipe.lbl.expectedQty')"
              />
            </v-col>
            <v-col class="py-0" cols="6" sm="6">
              <Select
                v-model="form.uom"
                :label="$t('uom.lbl.uom')"
                :options="uomList"
                :rules="rules.uom"
              />
            </v-col>
          </v-row>
        </div>
        <div class="mb-3">
          <div class="form-row-col__label"
            >{{ $t('recipe.lbl.avgActualQty') }}
            <span class="font-red">{{ rules.avgQty.required ? '*' : '' }}</span>
          </div>
          <v-row class="my-0">
            <v-col class="py-0" cols="6" sm="6">
              <TextField
                v-model="form.avgQty"
                :type="'number'"
                :min="0"
                :step="decimalStep"
                :rules="rules.avgQty"
                :label="$t('recipe.lbl.avgActualQty')"
              />
            </v-col>
            <v-col class="py-0" cols="6" sm="6">
              <Select
                v-model="form.uom"
                :label="$t('uom.lbl.uom')"
                :options="uomList"
                :rules="rules.uom"
              />
            </v-col>
          </v-row>
        </div>
        <div class="mb-3">
          <div class="form-row-col__label"
            >{{ $t('recipe.lbl.dimension') }}
            <span class="font-red">{{ rules.dimension.required ? '*' : '' }}</span>
          </div>
          <TextField v-model="form.dimension" :rules="rules.dimension" :label="$t('recipe.lbl.dimension')"></TextField>
        </div>
        <div class="mb-3">
          <div class="form-row-col__label"
            >{{ $t('lbl.remarks') }}
            <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
          </div>
          <TextArea v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')"></TextArea>
        </div>
        <div class="mb-3">
          <v-row class="my-0">
            <v-col class="py-0" cols="12" sm="6">
              <div class="form-row-col__label"
                >{{ $t('recipe.lbl.isApproved') }}
                <span class="font-red">{{ rules.approved.required ? '*' : '' }}</span>
              </div>
              <SwitchToggle v-model="form.approved" :label="$t('map.yesNoBool')[form.approved]" :rules="rules.approved" />
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <div class="form-row-col__label"
                >{{ $t('recipe.lbl.isPublished') }}
                <span class="font-red">{{ rules.published.required ? '*' : '' }}</span>
              </div>
              <SwitchToggle v-model="form.published" :label="$t('map.yesNoBool')[form.published]" :rules="rules.published" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <AppBtn class="btn-cancel mr-2" @click="back">{{ $t('act.back') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </div>
  </ValidationObserver>
</template>
<script>
import { extractJsonKey } from '@/util/tools';
import { recipeApi } from '@/api/recipe';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'RecipeGeneral',
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      decimalStep: '0.01',
      loading: false,
      form: {
        name: '',
        qty: null,
        uom: null,
        avgQty: null,
        dimension: '',
        batch: 1,
        notes: '',
        approved: false,
        published: false,
      },
      rules: {
        name: { required: true },
        qty: { required: true },
        uom: { required: true },
        avgQty: { required: false },
        dimension: { required: false },
        notes: { required: false },
        approved: { required: true },
        published: { required: true },
      }
    }
  },
  watch: {
    data(value) {
      this.populateData(value);
    },
  },
  computed: {
    ...mapState({
      uomList: state => state.lookup.uomList,
    }),
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'RecipeUpdate';
    },
    categoryId() {
      return this.$route.params.categoryId || null;
    },
  },
  created() {
    if (this.isUpdate && !this.isUndefinedNullOrEmpty(this.data)) {
      this.populateData(this.data);
    }
    if (this.isUndefinedNullOrEmpty(this.uomList)) {
      this.fetchUomList();
    }
  },
  methods: {
    ...mapActions(['fetchUomList']),
    detail(id) {
      return recipeApi.detail({ id }).then(res => {
        this.form = res.data;
      });
    },
    back() {
      if (this.isUndefinedNullOrEmpty(this.categoryId) || this.categoryId == 0) {
        this.navigatePush('RecipeListByCategory');
      } else {
        this.navigatePush('RecipeList', { categoryId: this.categoryId });
      }
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      const keys = ['name', 'qty', 'uom', 'avgQty', 'dimension', 'batch', 'notes', 'approved', 'published'];
      let data = extractJsonKey(keys, this.form);

      if (this.isUpdate) {
        data.id = this.form.id;
        this.update(data);
      } else {
        data.subGrps = [
          {
            recipeId: 0,
            grpId: this.categoryId,
            flag: 0,
          },
        ]
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return recipeApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.navigatePush('RecipeList', { categoryId: this.categoryId });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return recipeApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          if (this.isUndefinedNullOrEmpty(this.categoryId) || this.categoryId == 0) {
            this.navigatePush('RecipeListByCategory');
          } else {
            this.navigatePush('RecipeList', { categoryId: this.categoryId });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    populateData(data) {
      this.form = { ...this.form, ...data };
    },
  }
}
</script>