const MENU_TYPE = {
  FIRST_LAYER: 0,
  SUB_SECTION: 1,
  LOWEST_LAYER: 2,
};

const PRODUCT_TYPE = {
  RAW: 1,
  FGOODS: 2,
  SEMIFGOODS: 3,
};

const INGREDIENT_TYPE = {
  RAW_MAT: 1,
  SEMI_F_GOODS: 2,
  LINKED_RECIPE: 3,
  MANUAL: 4,
};

const PO_STATUS = {
  NEW: 0,
  CONFIRMED: 1,
  APPROVED: 2,
  DELETED: 3,
  CANCELLED: 4,
  COMPLETED: 9,
  OPEN: 7,
  ALL: 8,
};

const GRN_ITEM_ACT = {
  ACCEPT: 1,
  REJECT: 2,
};

const STOCK_IN_OUT_MODE = {
  QR: 1,
  MANUAL: 2,
};

const JOB_STATUS = {
  NEW: 0,
  CONFIRMED: 1,
  IN_PROGRESS: 2,
  DELETED: 3,
  CANCELLED: 4,
  COMPLETED: 5,
  COMPLETED_VARIANCE: 6,
};

const SEMI_GOODS_LABEL_STATUS = {
  PENDING: 0,
  PRINTED: 1,
  VOIDED: 4,
  PURGED: 5,
  WRITE_OFF: 8,
  USED: 9,
};

const GOODS_LABEL_STATUS = {
  PENDING: 0,
  PRINTED: 1,
  VOIDED: 4,
  PURGED: 5,
  WRITE_OFF: 8,
  USED: 9,
};

const RAW_MAT_GRP = {
  RAW_MAT: 0,
  TRADE_ITEM: 1,
};

const GRN_LABEL_STATUS = {
  PENDING: 0,
  PRINTED: 1,
  VOIDED: 4,
  PURGED: 5,
  WRITE_OFF: 8,
  USED: 9,
};

const SALES_ORDER_STATUS = {
  NEW: 0,
  CONFIRMED: 1,
  APPROVED: 2,
  DELETED: 3,
  CANCELLED: 4,
  COMPLETED: 9,
};

const COMMON_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  SUSPENDED: 2,
  DELETED: 3,
};

const FILE_UPLOAD_MODE = {
  ADD: 'ADD',
  VIEW: 'VIEW',
};

export {
  MENU_TYPE,
  PRODUCT_TYPE,
  INGREDIENT_TYPE,
  PO_STATUS,
  GRN_ITEM_ACT,
  STOCK_IN_OUT_MODE,
  JOB_STATUS,
  SEMI_GOODS_LABEL_STATUS,
  GOODS_LABEL_STATUS,
  RAW_MAT_GRP,
  GRN_LABEL_STATUS,
  SALES_ORDER_STATUS,
  COMMON_STATUS,
  FILE_UPLOAD_MODE,
};
