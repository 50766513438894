const Layout = () => import('@/layouts/default/Index');

const Login = () => import('@/views/Login');

const Branch = () => import('@/views/Branch');
const Dashboard = () => import('@/views/Dashboard/index.vue');
const FGoods = () => import('@/views/FGoods');
const FGoodsInventory = () => import('@/views/Inventory/FGoodsInventory');
const FGoodsLabel = () => import('@/views/FGoodsLabel');
const Grn = () => import('@/views/Grn');
const Inventory = () => import('@/views/Inventory');
const Maintenance = () => import('@/views/Maintenance');
const Production = () => import('@/views/Production');
const ProductionStockOut = () => import('@/views/Production/StockOut');
const PurchaseOrder = () => import('@/views/PurchaseOrder');
const SalesOrder = () => import('@/views/SalesOrder');
const RawMat = () => import('@/views/RawMat');
const RawMatInventory = () => import('@/views/Inventory/RawMatInventory');
const RawMatLabel = () => import('@/views/RawMatLabel');
const Recipe = () => import('@/views/RecipeModule/Recipe');
const RecipeCategory = () => import('@/views/RecipeModule/Category');
const RecipeModule = () => import('@/views/RecipeModule');
// const RegularTables = () => import('@/views/RegularTables');
const Replenish = () => import('@/views/Replenish');
const SemiFGoods = () => import('@/views/SemiFGoods');
const SemiFGoodsInventory = () => import('@/views/Inventory/SemiFGoodsInventory');
const SemiFGoodsLabel = () => import('@/views/SemiFGoodsLabel');
const StockOrder = () => import('@/views/StockOrder');
const Supplier = () => import('@/views/Supplier');
const Warehouse = () => import('@/views/Warehouse');
const QC = () => import('@/views/QC');
// const QrCodeReader = () => import('@/views/QrCodeReader');
// const QrBarcodeReader = () => import('@/views/QrBarcodeReader');
// const QrBarcodeImg = () => import('@/views/QrBarcodeImg');

const Notifications = () => import('@/views/Notifications');
const Icons = () => import('@/views/Icons');
const UserProfile = () => import('@/views/UserProfile');
// const GoogleMaps = () => import('@/views/GoogleMaps');

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '',
    component: Layout,
    redirect: { name: 'Dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { requireAuth: true },
        component: Dashboard,
      },
      // {
      //   path: 'login',
      //   name: 'Login',
      //   component: Login,
      // },
      {
        path: 'components/profile',
        name: 'UserProfile',
        meta: { requireAuth: true },
        component: UserProfile,
      },
      {
        path: 'components/notifications',
        name: 'Notifications',
        meta: { requireAuth: true },
        component: Notifications,
      },
      {
        path: 'components/icons',
        name: 'Icons',
        meta: { requireAuth: true },
        component: Icons,
      },
      // {
      //   path: 'qr-code-reader',
      //   name: 'QrCodeReader',
      //   component: QrCodeReader,
      // },
      // {
      //   path: 'qr-barcode-reader',
      //   name: 'QrBarcodeReader',
      //   component: QrBarcodeReader,
      // },
      // {
      //   path: 'qr-barcode-image',
      //   name: 'QrBarcodeImg',
      //   component: QrBarcodeImg,
      // },
      {
        path: 'purchase-order',
        name: 'PurchaseOrder',
        component: PurchaseOrder,
        children: [
          {
            path: 'list',
            name: 'PurchaseOrderList',
            meta: { requireAuth: true },
            component: PurchaseOrder,
          },
          {
            path: 'create',
            name: 'PurchaseOrderCreate',
            meta: { requireAuth: true },
            component: PurchaseOrder,
          },
          {
            path: 'update/:id',
            name: 'PurchaseOrderUpdate',
            meta: { requireAuth: true },
            component: PurchaseOrder,
          },
        ],
      },
      {
        path: 'sales-order',
        name: 'SalesOrder',
        component: SalesOrder,
        children: [
          {
            path: 'list',
            name: 'SalesOrderList',
            meta: { requireAuth: true },
            component: SalesOrder,
          },
          {
            path: 'create',
            name: 'SalesOrderCreate',
            meta: { requireAuth: true },
            component: SalesOrder,
          },
          {
            path: 'update/:id',
            name: 'SalesOrderUpdate',
            meta: { requireAuth: true },
            component: SalesOrder,
          },
        ],
      },
      {
        path: 'job',
        name: 'Production',
        component: Production,
        meta: { requireAuth: true },
        children: [
          {
            path: 'dashboard',
            name: 'ProductionDashboard',
            meta: { requireAuth: true },
            component: Production,
          },
          {
            path: 'list',
            name: 'ProductionList',
            meta: { requireAuth: true },
            component: Production,
          },
          {
            path: 'create',
            name: 'ProductionCreate',
            meta: { requireAuth: true },
            component: Production,
          },
          {
            path: 'update/:id',
            name: 'ProductionUpdate',
            meta: { requireAuth: true },
            component: Production,
          },
          {
            path: 'stock-out/:jobNo/:recipeId?',
            name: 'ProductionStockOut',
            meta: { requireAuth: true },
            component: Production,
          },
        ],
      },
      {
        path: 'grn',
        name: 'Grn',
        component: Grn,
        children: [
          {
            path: 'list',
            name: 'GrnList',
            meta: { requireAuth: true },
            component: Grn,
          },
          {
            path: 'create/:id?/:poId?',
            name: 'GrnCreate',
            meta: { requireAuth: true },
            component: Grn,
          },
          {
            path: 'update/:id/:poId',
            name: 'GrnUpdate',
            meta: { requireAuth: true },
            component: Grn,
          },
        ],
      },
      {
        path: 'recipes',
        name: 'Recipes',
        component: RecipeModule,
        children: [
          {
            path: 'categories',
            name: 'RecipeCategory',
            component: RecipeCategory,
            children: [
              {
                path: 'list',
                name: 'RecipeCategoryList',
                meta: { requireAuth: true },
                component: RecipeCategory,
              },
              {
                path: 'create',
                name: 'RecipeCategoryCreate',
                meta: { requireAuth: true },
                component: RecipeCategory,
              },
              {
                path: 'update/:id',
                name: 'RecipeCategoryUpdate',
                meta: { requireAuth: true },
                component: RecipeCategory,
              },
            ],
          },
          {
            path: '',
            name: 'Recipe',
            component: Recipe,
            children: [
              {
                path: 'category-list',
                name: 'RecipeListByCategory',
                meta: { requireAuth: true },
                component: Recipe,
              },
              {
                path: 'list/:categoryId',
                name: 'RecipeList',
                meta: { requireAuth: true },
                component: Recipe,
              },
              {
                path: 'create/:categoryId',
                name: 'RecipeCreate',
                meta: { requireAuth: true },
                component: Recipe,
              },
              {
                path: 'update/:categoryId/:id',
                name: 'RecipeUpdate',
                meta: { requireAuth: true },
                component: Recipe,
              },
            ],
          },
          // {
          //   path: 'create/:id?',
          //   name: 'GrnCreate',
          //   component: Grn,
          // },
          // {
          //   path: 'update/:id',
          //   name: 'GrnUpdate',
          //   component: Grn,
          // },
        ],
      },

      {
        path: 'inventory',
        name: 'Inventory',
        component: Inventory,
        meta: { requireAuth: true },
        children: [
          {
            path: 'raw-materials',
            name: 'RawMatInventory',
            component: RawMatInventory,
            children: [
              {
                path: 'list',
                name: 'RawMatInventoryList',
                meta: { requireAuth: true },
                component: RawMatInventory,
              },
              {
                path: 'stock-in/:grnNo?',
                name: 'RawMatStockIn',
                meta: { requireAuth: true },
                component: RawMatInventory,
              },
              {
                path: 'stock-out',
                name: 'RawMatStockOut',
                meta: { requireAuth: true },
                component: RawMatInventory,
              },
            ],
          },
          {
            path: 'semi-fgs',
            name: 'SemiFGoodsInventory',
            component: SemiFGoodsInventory,
            children: [
              {
                path: 'list',
                name: 'SemiFGoodsInventoryList',
                meta: { requireAuth: true },
                component: SemiFGoodsInventory,
              },
              {
                path: 'stock-in',
                name: 'SemiFGoodsStockIn',
                meta: { requireAuth: true },
                component: SemiFGoodsInventory,
              },
              {
                path: 'stock-out',
                name: 'SemiFGoodsStockOut',
                meta: { requireAuth: true },
                component: SemiFGoodsInventory,
              },
            ],
          },
          {
            path: 'goods',
            name: 'FGoodsInventory',
            component: FGoodsInventory,
            children: [
              {
                path: 'list',
                name: 'FGoodsInventoryList',
                meta: { requireAuth: true },
                component: FGoodsInventory,
              },
              {
                path: 'stock-in',
                name: 'FGoodsStockIn',
                meta: { requireAuth: true },
                component: FGoodsInventory,
              },
              // {
              //   path: 'stock-out',
              //   name: 'FGoodsStockOut',
              //   component: FGoodsInventory,
              // }
            ],
          },
        ],
      },
      {
        path: 'raw-materials/labels/print',
        name: 'RawMatLabel',
        meta: { requireAuth: true },
        component: RawMatLabel,
        props: true,
      },
      {
        path: 'semi-fgs/labels/print',
        name: 'SemiFGoodsLabel',
        meta: { requireAuth: true },
        component: SemiFGoodsLabel,
        props: true,
      },
      {
        path: 'goods/labels/print',
        name: 'FGoodsLabel',
        meta: { requireAuth: true },
        component: FGoodsLabel,
        props: true,
      },
      {
        path: 'maintenance',
        name: 'Maintenance',
        component: Maintenance,
        children: [
          {
            path: 'suppliers',
            name: 'Supplier',
            component: Supplier,
            children: [
              {
                path: 'list',
                name: 'SupplierList',
                meta: { requireAuth: true },
                component: Supplier,
              },
            ],
          },
          {
            path: 'raw-materials',
            name: 'RawMat',
            component: RawMat,
            children: [
              {
                path: 'list',
                name: 'RawMatList',
                meta: { requireAuth: true },
                component: RawMat,
              },
            ],
          },
          {
            path: 'semi-fgs',
            name: 'SemiFGoods',
            component: SemiFGoods,
            children: [
              {
                path: 'list',
                name: 'SemiFGoodsList',
                meta: { requireAuth: true },
                component: SemiFGoods,
              },
            ],
          },
          {
            path: 'goods',
            name: 'FGoods',
            component: FGoods,
            children: [
              {
                path: 'list',
                name: 'FGoodsList',
                meta: { requireAuth: true },
                component: FGoods,
              },
            ],
          },
          {
            path: 'warehouses',
            name: 'Warehouse',
            component: Warehouse,
            children: [
              {
                path: 'list',
                name: 'WarehouseList',
                meta: { requireAuth: true },
                component: Warehouse,
              },
            ],
          },
          {
            path: 'branches',
            name: 'Branch',
            component: Branch,
            children: [
              {
                path: 'list',
                name: 'BranchList',
                meta: { requireAuth: true },
                component: Branch,
              },
            ],
          },
        ],
      },
      {
        path: 'qc',
        name: 'QC',
        component: QC,
        meta: { requireAuth: true },
        children: [
          {
            path: 'list',
            name: 'QCList',
            meta: { requireAuth: true },
            component: QC,
          },
          {
            path: 'create',
            name: 'QCCreate',
            meta: { requireAuth: true },
            component: QC,
          },
        ],
      },
      {
        path: 'replenish',
        name: 'Replenish',
        meta: { requireAuth: true },
        component: Replenish,
      },
      {
        path: 'stock-order/:id?',
        name: 'StockOrder',
        meta: { requireAuth: true },
        component: StockOrder,
      },
      // {
      //   path: 'tables/regular',
      //   name: 'RegularTables',
      //   component: RegularTables,
      // },
      // {
      //   path: 'maps/google',
      //   name: 'GoogleMaps',
      //   component: GoogleMaps,
      // },
    ],
  },
];
