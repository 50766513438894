<template>
  <CommonModal :show-modal.sync="modalState" :header-title="itemName" :modal-size="'modal-md'" with-footer>
    <template v-slot:content>
      <DataTable
        :class="'cursor-pointer'"
        :headers="headers"
        :items="data"
        :mobile-breakpoint="0"
        :hide-default-header="hideHeader"
        :pagination-config.sync="pagination"
        @pageChg="pageChg"
        @pageSizeChg="pageSizeChg"
      >
        <template #item-column-1-xs="{ item }">
          <div>
            <div class="font-weight-medium font-15">{{ !isUndefinedNullOrEmpty(item.cdate) ? setTimeZone(item.cdate) : '-' }}</div>
            <div>{{ $t('inventory.lbl.type') }}: <span class="font-weight-regular">{{ stockTxTypeMap[item.type] }}</span></div>
            <div>{{ $t('inventory.lbl.storage') }}: <span class="font-weight-regular">{{ item.storage }}</span></div>
            <div>{{ $t('inventory.lbl.qty') }}:
              <span class="font-weight-regular" :class="item.dr ? 'font-red' : 'font-green'">{{ item.dr ? '-' : '+' }} {{ `${item.qty} ${item.uom}` }}</span>
            </div>
          </div>
        </template>

        <template #item-cdate="{ item }">
          {{ !isUndefinedNullOrEmpty(item.cdate) ? setTimeZone(item.cdate) : '-' }}
        </template>
        <template #item-type="{ item }">
          {{ stockTxTypeMap[item.type] }}
        </template>
        <template #item-qty="{ item }">
          <span class="font-weight-bold" :class="item.dr ? 'font-red' : 'font-green'">{{ item.dr ? '-' : '+' }} {{ `${item.qty} ${item.uom}` }}</span>
        </template>
      </DataTable>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.close') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { fGoodsInventoryTxApi } from '@/api/inventory';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FGoodsMovement',
  props: {
    itemId: {
      type: Number,
      default: null,
    },
    itemName: {
      type: String,
      default: null,
    },
    storageId: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pagination: {
        total: 0,
      },
      data: [],
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
      stockTxTypeMap: state => state.lookup.stockTxTypeMap,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '100%', value: 'column-1-xs' },
        ];
      } else {
        return [
          { text: '#', width: '30', value: 'no' },
          { text: this.$t('lbl.date'), width: '150', value: 'cdate' },
          { text: this.$t('inventory.lbl.type'), width: '180', value: 'type' },
          { text: this.$t('inventory.lbl.storage'), width: '200', value: 'storage' },
          { text: this.$t('inventory.lbl.serialNo'), width: '140', value: 'sn' },
          { text: this.$t('inventory.lbl.qty'), width: '80', value: 'qty' },
          { text: this.$t('lbl.cby'), width: '150', value: 'cby' },
        ];
      }
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.stockTxTypeMap)) {
      this.fetchStockTxType();
    }
    if (!this.isUndefinedNullOrEmpty(this.itemId) && !this.isUndefinedNullOrEmpty(this.storageId)) {
      this.pagination = { ...this.paginationOpts, ...this.pagination };
      this.getListNTotalRec();
    }
  },
  methods: {
    ...mapActions(['fetchStockTxType']),
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        if (this.pagination.total > 0) {
          this.getList();
        }
      });
    },
    getTotalRec() {
      const params = {
        page: 0,
        itemId: this.itemId,
        storageId: this.storageId,
      };
      return fGoodsInventoryTxApi.list(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
        itemId: this.itemId,
        storageId: this.storageId,
      };
      return fGoodsInventoryTxApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          };
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
