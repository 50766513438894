<template>
  <v-container id="dashboard-view" fluid tag="section">
    <!-- <h2>Welcome {{ getUsername }}</h2> -->
    <app-card class="mb-3">
      <v-card-title class="px-2 py-4 px-sm-4">
        <div class="font-1dot25r font-weight-bold">{{ $t('noti.lbl.notification') }}</div>
      </v-card-title>
      <div class="list-wrap">
        <NotiList :list="recentList" />
      </div>
    </app-card>
    <app-card class="mb-3">
      <div class="list-wrap">
        <SalesList />
      </div>
    </app-card>
  </v-container>
</template>

<script>
import { notiApi } from '@/api/notification';
import NotiList from '@/views/Notification/List';
import SalesList from '@/views/StoreSales/List';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DashboardView',
  components: {
    NotiList,
    SalesList,
  },
  data() {
    return {
      recentList: [],
    };
  },
  computed: {
    ...mapGetters(['getNotiBadgeLastId', 'getNotiBadgeShow']),
  },
  created() {
    this.listRecent();
  },
  methods: {
    ...mapMutations(['SET_NOTI_BADGE_LAST_ID', 'SET_NOTI_BADGE_SHOW']),
    listRecent() {
      const params = {
        page: 1,
        pageSize: 30,
      };
      return notiApi.listRecent(params).then(res => {
        this.recentList = res.data;
        const listLength = this.recentList.length;
        if (listLength > 0) {
          if (this.isUndefinedNullOrEmpty(this.getNotiBadgeShow) || this.getNotiBadgeShow == false) {
            const lastId = this.recentList[0].id;
            if (this.isUndefinedNullOrEmpty(this.getNotiBadgeLastId)) {
              this.SET_NOTI_BADGE_SHOW(true);
            } else if (lastId > this.getNotiBadgeLastId) {
              this.SET_NOTI_BADGE_SHOW(true);
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-wrap {
  margin: 0 auto;
  max-width: 800px;
}
</style>