<template>
  <div>
    <div>
      <div class="text-right mb-2">
        <AppBtn @click="showStockOutModal">{{ $t('stockOrder.act.stockOut') }}</AppBtn>
      </div>

        <DataTable
          :class="'cursor-pointer'"
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :pagination-config.sync="pagination"
          @click:row="detail"
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-15">{{ !isUndefinedNullOrEmpty(item.id) ? `#${item.id}` : '-' }}</div>
              <div>{{ $t('stockOrder.lbl.store') }}: <span class="font-weight-regular">{{ item.store }}</span></div>
              <div>{{ $t('stockOrder.lbl.type') }}: 
                <span class="font-weight-regular">{{ $t('map.storeStockOrderType')[item.type] }}</span>, 
                <span>{{ $t('stockOrder.lbl.totQty') }}: <span class="font-weight-regular">{{ item.totQty }}</span></span>
              </div>
              <div>{{ $t('lbl.cdate') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.cdate) ? setTimeZone(item.cdate) : '-' }}</span></div>
              <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.storeStockOrderStatus')[item.status] }}</span></div>
            </div>
          </template>
          <template #item-column-act-xs="{ item }">
            <div class="d-flex justify-end">
              <AppBtn v-if="item.status == STORE_STOCK_ORDER_STATUS.CONFIRMED" class="btn-green" :loading="item.loading" @click.stop="approveStockOrder(item)">{{ $t('act.approve') }}</AppBtn>
            </div>
          </template>

          <!-- { text: this.$t('stockOrder.lbl.orderId'), width: '80', value: 'id' },
          { text: this.$t('stockOrder.lbl.store'), width: '250', value: 'store' },
          // { text: this.$t('stockOrder.lbl.refNo'), width: '130', value: 'refNo' },
          { text: this.$t('stockOrder.lbl.type'), width: '90', value: 'type' },
          { text: this.$t('stockOrder.lbl.totQty'), width: '90', value: 'totQty' },
          { text: this.$t('lbl.cdate'), width: '150', value: 'cdate' },
          { text: this.$t('lbl.status'), width: '120', value: 'status' },
          // { width: '150', value: 'done', align: 'right' },
          // { text: this.$t('lbl.cby'), width: '120', value: 'cby' },
          { width: '80', value: 'actions' }, -->

          <template #item-type="{ item }">
            {{ $t('map.storeStockOrderType')[item.type] }}
          </template>
          <template #item-cdate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.cdate) ? setTimeZone(item.cdate) : '-' }}
          </template>
          <template #item-status="{ item }">
            {{ $t('map.storeStockOrderStatus')[item.status] }}
          </template>
          <template #item-actions="{ item }">
            <div class="d-flex justify-end">
              <AppBtn v-if="item.status != STORE_STOCK_ORDER_STATUS.APPROVED" class="btn-green" :loading="item.loading" @click.stop="approveStockOrder(item)">{{ $t('act.approve') }}</AppBtn>
            </div>
          </template>
        </DataTable>
    </div>
    <CommonModal v-if="orderItemModalShow" :show-modal.sync="orderItemModalShow" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
      <template v-slot:content>
        <div class="text-label mb-2">{{ $t('stockOrder.lbl.store') }}: <b>{{ modalData.store }}</b></div>
        <div class="d-flex justify-center mb-3">
          <div>
            <div class="text-center">
              <QrCode class="mb-2" :value="`${orderPrefix}${modalData.id}`" />
              <div class="text-label mb-3">{{ $t('stockOrder.lbl.order') }} <b>#{{ modalData.id }}</b></div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-center mb-3">
          <div>
            <div class="text-label mb-1">Store: <b>{{ modalData.store }}</b></div>
            <div class="text-label mb-3">Order <b>#{{ modalData.id }}</b></div>
            <div class="text-center">
              <QrCode :value="modalData.id" />
            </div>
          </div>
        </div> -->

        <DataTable
          :class="'cursor-pointer'"
          :headers="orderItemHeaders"
          :items="orderItems"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :hide-default-footer="true"
          :pagination-config.sync="pagination"
          disable-pagination
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
        </DataTable>
      </template>
      <template v-slot:footer>
        <AppBtn class="btn-cancel" @click="orderItemModalShow = false">{{ $t('act.close') }}</AppBtn>
      </template>
    </CommonModal>
    <CommonModal v-if="stockOutModalShow" :show-modal.sync="stockOutModalShow" :header-title="stockOutHeaderTitle" :modal-size="'modal-md'" with-footer @close="resetFields">
      <template v-slot:content>
        <ValidationObserver ref="observer">
          <TextArea ref="textArea" v-model="form.stockOutData" :rules="rules.stockOutData" :rows="18" :label="$t('stockOrder.lbl.stockOutItems')" :placeholder="$t('stockOrder.lbl.stockOutItems')"></TextArea>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <AppBtn :loading="loading" @click="stockOut">{{ $t('act.confirm') }}</AppBtn>
      </template>
    </CommonModal>
    <CommonModal v-if="stockOutErrModalShow" :show-modal.sync="stockOutErrModalShow" :header-title="stockOutErrHeaderTitle" :modal-size="'modal-sm'" with-footer @close="resetErrFields">
      <template v-slot:content>
        <ValidationObserver ref="observer">
          <div v-for="(item, index) in stockOutErrData" :key="index" class="mb-3">
            <div class="font-weight-bold font-black">{{ `Order #${item.orderId}` }}</div>
            <ul>
              <li v-for="(cause, causeIndex) in item.cause" :key="causeIndex" class="font-weight-bold font-red">
                {{ cause.notes }} <span v-if="cause.sku" class="font-black font-weight-medium">{{ `(${$t('lbl.sku')}: ${cause.sku})` }}</span>
              </li>
            </ul>
          </div>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <AppBtn @click="resetErrFields">{{ $t('act.close') }}</AppBtn>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { storeStockOrderApi, storeStockOrderItemApi, storeStockOrderLabelApi } from '@/api/store';
import { STORE_STOCK_ORDER_STATUS } from '@/common/storeEnum';
export default {
  data() {
    return {
      data: [],
      orderItems: [],
      pagination: {
        total: 0,
      },
      form: {
        stockOutData: '',
      },
      orderItemModalShow: false,
      stockOutModalShow: false,
      stockOutErrModalShow: false,
      modalData: {},
      headerTitle: this.$t('stockOrder.lbl.orderItemList'),
      stockOutHeaderTitle: this.$t('stockOrder.act.stockOut'),
      stockOutErrHeaderTitle: this.$t('stockOrder.lbl.stockOutFailedForItems'),
      stockOutErrData: [],
      rules: {
        stockOutData: { required: true },
      },
      orderPrefix: 'OD:',
      loading: false,
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          { text: this.$t('stockOrder.lbl.orderId'), width: '80', value: 'id' },
          { text: this.$t('stockOrder.lbl.store'), width: '250', value: 'store' },
          // { text: this.$t('stockOrder.lbl.refNo'), width: '130', value: 'refNo' },
          { text: this.$t('stockOrder.lbl.type'), width: '90', value: 'type' },
          { text: this.$t('stockOrder.lbl.totQty'), width: '90', value: 'totQty' },
          { text: this.$t('lbl.cdate'), width: '150', value: 'cdate' },
          { text: this.$t('lbl.status'), width: '120', value: 'status' },
          // { width: '150', value: 'done', align: 'right' },
          // { text: this.$t('lbl.cby'), width: '120', value: 'cby' },
          { width: '80', value: 'actions' },
        ];
      }
    },
    orderItemHeaders() {
      return [
        { text: this.$t('lbl.no'), width: '50', value: 'no' },
        { text: this.$t('stockOrder.lbl.item'), width: '350', value: 'shortName' },
        { text: this.$t('stockOrder.lbl.orderQty'), width: '100', value: 'qty' },
        { text: this.$t('stockOrder.lbl.doQty'), width: '100', value: 'doQty' },
        { text: this.$t('uom.lbl.uom'), width: '100', value: 'uom' },
      ];
    },
    inputWidth() {
      if (this.isMobileView) {
        return '100%';
      } else {
        return '250px';
      }
    },
    STORE_STOCK_ORDER_STATUS() {
      return STORE_STOCK_ORDER_STATUS;
    },
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getListNTotalRec();
  },
  methods: {
    showStockOutModal() {
      this.stockOutModalShow = true;
      setTimeout(() => {
        this.$refs.textArea.focus();
      }, 300);
    },
    markDone(item) {
      item.done = 1;
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        if (this.pagination.total > 0) {
          this.getList();
        }
      });
    },
    getTotalRec() {
      const params = {
        page: 0
      };
      return storeStockOrderApi.openList(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return storeStockOrderApi.openList(params).then(res => {
        this.data = res.data.map(item => {
          return {
            ...item,
            loading: false,
          }
        });
      });
    },
    async detail(item) {
      await this.getOrderItemList(item.id);
      this.modalData.store = item.store;
      this.modalData.id = item.id;
      this.orderItemModalShow = true;
    },
    getOrderItemList(orderId) {
      const data = {
        page: 1,
        pageSize: 100,
        orderId
      }
      return storeStockOrderItemApi.list(data).then(res => {
        this.orderItems = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    approveStockOrder(item) {
      const data = {
        id: item.id,
        status: STORE_STOCK_ORDER_STATUS.APPROVED,
      }
      item.loading = true;
      return storeStockOrderApi.storeStockOrderUpdate(data).then(res => {
        item.loading = false;
        if (res.ret == '0') {
          item.status = STORE_STOCK_ORDER_STATUS.APPROVED;
          this.showSuccess(this.$t('stockOrder.success.approve'));
        }
      }).catch(() => {
        item.loading = false;
      });
    },
    async stockOut() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.loading = true;
      const obj = {
        data: this.form.stockOutData,
      }
      return storeStockOrderLabelApi.stockOut(obj).then(res => {
        setTimeout(() => {
          this.loading = false;
          if (res.ret == '0') {
            this.showSuccess(this.$t('stockOrder.success.stockOut'));
            this.stockOutModalShow = false;
            this.form.stockOutData = '';
            this.getListNTotalRec();
          } else {
            if (!this.isUndefinedNullOrEmpty(res.data)) {
              this.stockOutModalShow = false;
              this.form.stockOutData = '';
              if (res.data.length > 0) {
                console.log(res.data);
                setTimeout(() => {
                  this.stockOutErrModalShow = true;
                  this.stockOutErrData = res.data;
                }, 150);
              }
            }
          }
        }, 500);
      }).catch((error) => {
        this.loading = false;
      });
    },
    resetFields() {
      this.form.stockOutData = '';
    },
    resetErrFields() {
      this.stockOutErrModalShow = false;
      this.stockOutErrData = [];
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.text-label {
  color: #000;
  font-size: 1rem;
}
</style>