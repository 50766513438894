<template>
  <!-- Dialog will be full width if not specified. Please specify only either modalSize OR modalCustWidth in parent component -->
  <v-dialog
    v-model="showModal"
    persistent
    :fullscreen="fullscreen"
    :transition="fullscreen ? fullscreenTransition : ''"
    :content-class="'form-modal ' + modalSize"
    :width="modalCustWidth"
    :style="{ display: !showModal ? 'none' : 'block' }"
  >
    <v-card>
      <v-card-title class="modal-title" :style="{ padding: headerPadding }">
        <div>
          <span class="modal-title__headline">{{ headerTitle }}</span>
        </div>
        <v-btn v-if="close" class="modal-title__close-btn" text icon @click="hideModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div v-if="headerDesc" class="modal-title__desc">{{ headerDesc }}</div>
      <v-card-text class="modal-content" :style="{ margin: bodyMargin }">
        <!-- Data fields will be from parent component -->
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions v-if="withFooter" class="modal-footer">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommonModal',
  props: {
    bodyMargin: {
      type: String,
      default: '',
    },
    fullscreenTransition: {
      type: String,
      default: 'dialog-bottom-transition',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerDesc: {
      type: String,
      default: '',
    },
    headerPadding: {
      type: String,
      default: '',
    },
    modalCustWidth: {
      type: String,
      default: '',
    },
    modalSize: {
      // Options: modal-lg, modal-md, modal-sm, modal-xs
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    withFooter: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    hideModal() {
      this.$emit('close');
      this.$emit('update:showModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .form-modal {
    .form-container {
      padding: 0;
    }

    .modal-title {
      padding: 10px 24px 5px;
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 2;
      justify-content: space-between;

      &__close-btn {
        margin-right: -10px;
      }

      &__headline {
        font-size: 1.25rem;
        font-weight: 500;
      }

      &__desc {
        color: var(--black);
        font-size: 12px;
        font-weight: 500;
        padding: 0px 24px;
      }
    }

    .modal-content {
      padding: 0 24px 14px;
    }

    // .v-card__text.modal-content {
    //   padding: 0 calc(1.8 * var(--gap-full)) calc(1.2 * var(--gap-full));
    // }
    .modal-footer {
      justify-content: flex-end;
      padding: 8px 24px;
    }

    &.modal-lg {
      max-width: 1200px;
    }

    &.modal-md {
      max-width: 1000px;
    }

    &.modal-md-2 {
      max-width: 800px;
    }

    &.modal-sm {
      max-width: 600px;
    }

    &.modal-sm-2 {
      max-width: 500px;
    }

    &.modal-xs {
      max-width: 400px;
    }
  }
}
@media screen and (max-width: 599px) {
  .v-dialog__content::v-deep {
    .form-modal {
      .modal-title {
        padding-left: 15px;
        padding-right: 15px;
      }
      .v-card__text.modal-content {
        padding: 0 15px 10px;
      }
    }
  }
}
</style>
