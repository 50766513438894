<template>
  <div>
    <div>
      <v-data-table
        class="cursor-pointer"
        :headers="headers"
        :items="data"
        hide-default-footer
        disable-pagination
        disable-sort
        @update:page="pageChg"
        @update:items-per-page="pageSizeChg"
        @click:row="detail"
      >
        <template v-slot:item.type="{ item }">
          {{ $t('map.storeStockOrderType')[item.type] }}
        </template>
        <template v-slot:item.cdate="{ item }">
          {{ !isUndefinedNullOrEmpty(item.cdate) ? setTimeZone(item.cdate) : '-' }}
        </template>
        <!-- <template v-slot:item.action="{ item }">
          <v-icon class="cursor-pointer" @click="detail(item)">
            mdi-eye
          </v-icon>
        </template> -->
      </v-data-table>
    </div>
    <CommonModal v-if="orderItemModalShow" :show-modal.sync="orderItemModalShow" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
      <template v-slot:content>
        <div class="text-label mb-1">Store: <b>{{ modalData.store }}</b></div>
        <div class="d-flex justify-center mb-3">
          <div>
            <div class="text-center">
              <QrCode class="mb-2" :value="`${orderPrefix}${modalData.id}`" />
              <div class="text-label mb-3">Order <b>#{{ modalData.id }}</b></div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-center mb-3">
          <div>
            <div class="text-label mb-1">Store: <b>{{ modalData.store }}</b></div>
            <div class="text-label mb-3">Order <b>#{{ modalData.id }}</b></div>
            <div class="text-center">
              <QrCode :value="modalData.id" />
            </div>
          </div>
        </div> -->
        <v-data-table
          :headers="orderItemHeaders"
          :items="orderItems"
          hide-default-footer
          disable-pagination
          disable-sort
          @update:page="pageChg"
          @update:items-per-page="pageSizeChg"
        >
        </v-data-table>
      </template>
      <template v-slot:footer>
        <AppBtn class="btn-cancel" @click="orderItemModalShow = false">{{ $t('act.close') }}</AppBtn>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { storeStockOrderApi, storeStockOrderItemApi, storeStockOrderLabelApi } from '@/api/store';
import { STORE_STOCK_ORDER_STATUS } from '@/common/storeEnum';
export default {
  data() {
    return {
      data: [],
      orderItems: [],
      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
      orderItemModalShow: false,
      modalData: {},
      headerTitle: 'Order Item List',
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    headers() {
      return [
        { text: this.$t('stockOrder.lbl.orderId'), width: '80', value: 'id' },
        { text: this.$t('stockOrder.lbl.store'), width: '250', value: 'store' },
        // { text: this.$t('stockOrder.lbl.refNo'), width: '130', value: 'refNo' },
        { text: this.$t('stockOrder.lbl.type'), width: '90', value: 'type' },
        { text: this.$t('stockOrder.lbl.totQty'), width: '90', value: 'totQty' },
        { text: this.$t('lbl.cdate'), width: '150', value: 'cdate' },
        // { text: this.$t('lbl.cby'), width: '120', value: 'cby' },
        // { width: '80', value: 'action' },
      ];
    },
    orderItemHeaders() {
      return [
        { text: this.$t('lbl.no'), width: '50', value: 'no' },
        { text: this.$t('stockOrder.lbl.item'), width: '350', value: 'shortName' },
        { text: this.$t('stockOrder.lbl.orderQty'), width: '100', value: 'qty' },
        { text: this.$t('stockOrder.lbl.doQty'), width: '100', value: 'doQty' },
        { text: this.$t('uom.lbl.uom'), width: '100', value: 'uom' },
      ];
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
        status: STORE_STOCK_ORDER_STATUS.COMPLETED,
      };
      return storeStockOrderApi.list(params).then(res => {
        this.data = res.data;
      });
    },
    async detail(item) {
      await this.getOrderItemList(item.id);
      this.modalData.store = item.store;
      this.modalData.id = item.id;
      this.orderItemModalShow = true;
    },
    getOrderItemList(orderId) {
      const data = {
        page: 1,
        pageSize: 100,
        orderId
      }
      return storeStockOrderItemApi.list(data).then(res => {
        this.orderItems = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.text-label {
  color: #000;
  font-size: 1rem;
}
</style>