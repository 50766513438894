import Vue from 'vue';
import Toastr from '@/components/Toastr';
// import AppStorage from '../plugins/storage';
// import i18n from '@/translations';

export default {
  data() {
    return {};
  },
  components: {},
  filters: {
    // orgStatusColor(status) {
    //   const map = {
    //     0: 'status-light-grey',
    //     1: 'status-green',
    //     2: 'status-orange',
    //     3: 'status-red',
    //   };
    //   return map[status];
    // },
  },
  computed: {
    isMobileView() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    // storeLoginCredential(data) {
    //   this.SET_LASTUPDATETIME(new Date().getTime() / 1000);
    //   this.SET_EXPIRETIME(data.expireTime);
    //   this.SET_TOKEN(data.token);
    //   this.SET_USERINFO(data);
    //   if (this.rememberMe == '1') {
    //     AppStorage.local.set('email', this.form.username);
    //   } else {
    //     AppStorage.local.remove('email');
    //   }
    // },
    // processLoginSuccess(data) {
    //   this.storeLoginCredential(data);
    //   // let willJump = this.$route.query.redirect;

    //   // if (willJump !== undefined) {
    //   // 预跳转页
    //   // this.$router.replace(willJump).catch();
    //   // } else {
    //   // 跳转首页
    //   this.$router.replace({ name: 'home' }).catch();
    //   // }
    //   // 获取用户详情信息
    //   // this.GET_USERINFO_ACTION();
    // },
    // logoutAction(type = 0) {
    //   this.LOGOUT_ACTION()
    //     .then(() => {
    //       this.$router.replace({ name: 'login' }).catch();
    //       window.location.reload();
    //     })
    //     .catch(() => {});
    //   if (type == 1) this.showError(i18n.t('labels.maxAttempReach'));
    // },
    showInfo(msg) {
      var ComponentClass = Vue.extend(Toastr);
      var instance = new ComponentClass({
        propsData: { title: 'Info', message: msg, type: 'info-purple', icon: 'mdi-info' },
      });
      instance.$mount();
      var snackbarTag = document.getElementById('snackbar');
      snackbarTag.appendChild(instance.$el);
    },
    showSuccess(msg) {
      var ComponentClass = Vue.extend(Toastr);
      var instance = new ComponentClass({
        propsData: { title: 'Success', message: msg, type: 'success' },
      });
      instance.$mount();
      var snackbarTag = document.getElementById('snackbar');
      snackbarTag.appendChild(instance.$el);
    },
    showError(msg) {
      var ComponentClass = Vue.extend(Toastr);
      var instance = new ComponentClass({
        propsData: { title: 'Error', message: msg, type: 'error' },
      });
      instance.$mount();
      var snackbarTag = document.getElementById('snackbar');
      snackbarTag.appendChild(instance.$el);
    },
    showWarning(msg) {
      var ComponentClass = Vue.extend(Toastr);
      var instance = new ComponentClass({
        propsData: { title: 'Warning', message: msg, type: 'warning' },
      });
      instance.$mount();
      var snackbarTag = document.getElementById('snackbar');
      snackbarTag.appendChild(instance.$el);
    },
    _copySuccess() {
      this.showSuccess(this.$t('success.copy'));
    },
    navigatePush(routeName, params) {
      this.$router.push({ name: routeName, params: { ...params } }).catch(() => {});
    },
    navigateReplace(routeName, params) {
      this.$router.replace({ name: routeName, params: { ...params } }).catch(() => {});
    },
    setPageLoading(value) {
      this.SET_PAGE_LOADING(value);
    },
  },
};

export function showInfo(msg, delay) {
  var ComponentClass = Vue.extend(Toastr);
  var instance = new ComponentClass({
    propsData: { title: 'Info', message: msg, type: 'info-purple', icon: 'mdi-info', delay: delay },
  });
  instance.$mount();
  var snackbarTag = document.getElementById('snackbar');
  snackbarTag.appendChild(instance.$el);
}

export function showSuccess(msg) {
  var ComponentClass = Vue.extend(Toastr);
  var instance = new ComponentClass({
    propsData: { title: 'Success', message: msg, type: 'success' },
  });
  instance.$mount();
  var snackbarTag = document.getElementById('snackbar');
  snackbarTag.appendChild(instance.$el);
}

export function showError(msg) {
  var ComponentClass = Vue.extend(Toastr);
  var instance = new ComponentClass({
    propsData: { title: 'Error', message: msg, type: 'error' },
  });
  instance.$mount();
  var snackbarTag = document.getElementById('snackbar');
  snackbarTag.appendChild(instance.$el);
}

export function showWarning(msg) {
  var ComponentClass = Vue.extend(Toastr);
  var instance = new ComponentClass({
    propsData: { title: 'Warning', message: msg, type: 'warning' },
  });
  instance.$mount();
  var snackbarTag = document.getElementById('snackbar');
  snackbarTag.appendChild(instance.$el);
}
