import Api from '../api';

export const storeApi = {
  ddl(params) {
    return Api.get('/store/picklist', params);
  },
  ddlQcRequired(params) {
    return Api.get('/store/list/qc-required', params);
  },
};

export const storeSalesApi = {
  daily(params) {
    return Api.get('/store/sales?listType=2', params);
  },
};

export const storeStockApi = {
  balList(params) {
    return Api.get('store/stock/bal/list', params);
  },
  refillListByStore(params) {
    return Api.get('store/stock/bal/list/refill-by-store', params);
  },
  refillListByStockItem(params) {
    return Api.get('store/stock/bal/list/refill-by-stock-item', params);
  },
};

export const storeStockOrderApi = {
  openList(params) {
    return Api.get('store/stock/order/list/open', params);
  },
  list(params) {
    return Api.get('store/stock/order/list', params);
  },
  storeStockOrderCreate(data) {
    return Api.post('store/stock/order/create', data);
  },
  storeStockOrderUpdate(data) {
    return Api.post('store/stock/order/update', data);
  },
};

export const storeStockOrderItemApi = {
  list(params) {
    return Api.get('store/stock/order/item/list', params);
  },
};

export const storeStockOrderLabelApi = {
  stockOut(params) {
    return Api.post('store/stock/order/label/import', params);
  },
};

export const storeQcItemApi = {
  list(params) {
    return Api.get('store/qc/item/list', params);
  },
};

export const storeShiftQcApi = {
  list(params) {
    return Api.get('store/shift/qc/list', params);
  },
  create(data) {
    return Api.post('store/shift/qc/create', data);
  },
};