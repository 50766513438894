import Api from '../api';

export const lookupApi = {
  uom(params) {
    return Api.get('lookup/uom', params);
  },
  timezone(params) {
    return Api.get('lookup/timezone', params);
  },
  industry(params) {
    return Api.get('lookup/industry', params);
  },
  province(params) {
    return Api.get('lookup/province', params);
  },
  certType(params) {
    return Api.get('lookup?category=CertType', params);
  },
  stockAdj(params) {
    return Api.get('lookup?category=StockAdj', params);
  },
  rawMatCategory() {
    return Api.get('lookup?category=ProdCat');
  },
  stockTxType() {
    return Api.get('lookup?category=TxType');
  },
};
