<template>
  <ValidationObserver ref="observer">
    <div class="d-flex align-center justify-space-between mb-1">
      <div>
        <v-icon class="mr-1">mdi-information</v-icon>
        <span class="text-caption mr-2">{{ $t('fGoods.print.queue.note') }}</span>
      </div>
      <AppBtn :loading="loading" @click="purgeLabel(jobNo)">{{ $t('fGoods.print.act.purgeAllLabel') }}</AppBtn>
    </div>
    <v-row class="form-row">
      <v-col class="form-row-col text-right" cols="12" sm="12">
        <!-- <AppBtn :loading="loading" @click="getGrnPrintQueueList(jobNo)">{{ $t('act.search') }}</AppBtn>
        <AppBtn :loading="loading" @click="purgeLabel(jobNo)">{{ $t('fGoods.print.act.purgeLabelByjobNo') }}</AppBtn> -->
      </v-col>
    </v-row>
    <v-data-table class="mb-5" disable-sort :headers="headers" :items="data" hide-default-footer disable-pagination>
      <template v-slot:item.status="{ item }">
        {{ !isUndefinedNullOrEmpty(item.status) ? $t('map.grnItemStatus')[item.status] : '-' }}
      </template>
      <template v-slot:item.isPrinted="{ item }">
        <v-icon small :color="item.isPrinted ? 'green' : 'red'">{{
          item.isPrinted ? 'mdi-check-bold' : 'mdi-close-thick'
        }}</v-icon>
      </template>
      <template v-slot:item.printOpt="{ item }">
        <SwitchToggle v-model="item.printOpt" :label="$t('map.lblPrintOptBool')[item.printOpt]" @change="printOptChg(item)" />
      </template>
      <template v-slot:item.newUom="{ item }">
        <div class="d-flex justify-end">
          <Select v-model="item.newUom" :options="item.processedUomList" @change="uomChg(item)" />
        </div>
      </template>
    </v-data-table>
  </ValidationObserver>
</template>
<script>
import { fGoodsLabelApi } from '@/api/label';
import { GOODS_LABEL_STATUS } from '@/common/enum';

export default {
  name: 'PrintQueue',
  data() {
    return {
      data: [],
      loading: false,
      jobNo: null,
      headers: [
        { text: '#', width: '30', value: 'no' },
        { text: this.$t('fGoods.print.lbl.serialNo'), width: '150', value: 'sn' },
        { text: this.$t('fGoods.print.lbl.itemDesc'), width: '150', value: 'name' },
        { text: this.$t('fGoods.lbl.jobNo'), width: '120', value: 'jobNo' },
        // { text: this.$t('fGoods.print.lbl.noOfLabels'), width: '100', value: 'cnt' },
        { text: this.$t('fGoods.print.lbl.qty'), width: '100', value: 'qty' },
        { text: this.$t('uom.lbl.uom'), width: '120', value: 'uom' },
      ],
      rules: {
        jobNo: {
          required: true,
        },
      }
    }
  },
  created() {
    this.getPrintQueueList();
  },
  methods: {
    getPrintQueueList(jobNo) {
      let params = {
        page: 1,
        pageSize: 25,
        status: GOODS_LABEL_STATUS.PENDING,
      }
      // if (this.isUndefinedNullOrEmpty(jobNo)) {
      //   params.grnId = 0; // get all print queue list
      //   delete params.jobNo;
      // }
      return fGoodsLabelApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    purgeLabel(jobNo) {
      const params = {
        jobNo,
      }
      this.loading = true;
      return fGoodsLabelApi.purge(params).then(() => {
        this.showSuccess(this.$t('fGoods.print.lbl.purgeSuccess'));
        this.loading = false;
        this.getPrintQueueList();
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 18px;
}
</style>