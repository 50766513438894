<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.supplier') }}
              <span class="font-red">{{ rules.supplierId.required ? '*' : '' }}</span>
            </label>
            <Select
              v-model="form.supplierId"
              :label="$t('productPrice.lbl.supplier')"
              :options="supplierList"
              :rules="rules.supplierId"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="12">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.itemDesc') }}
              <span class="font-red">{{ rules.item.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.item" :rules="rules.item" :label="$t('productPrice.lbl.itemDesc')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.qtyVol') }}
              <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.qty"
              :type="'number'"
              :min="1"
              :rules="rules.qty"
              :label="$t('productPrice.lbl.qtyVol')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.uom') }}
              <span class="font-red">{{ rules.uom.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.uom" :label="$t('uom.lbl.uom')" :options="productUomList" :rules="rules.uom" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.taxCode') }}
              <span class="font-red">{{ rules.taxCode.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.taxCode" :rules="rules.taxCode" :options="$t('taxCodeOpt')" :label="$t('productPrice.lbl.taxCode')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.salesTax%') }}
              <span class="font-red">{{ rules.tax.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.tax" :type="'number'" :rules="rules.tax" :label="$t('productPrice.lbl.salesTax%')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.costExclTax') }}
              <span class="font-red">{{ rules.cost.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.cost" :type="'number'" :rules="rules.cost" :label="$t('productPrice.lbl.costExclTax')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label">{{ $t('productPrice.lbl.salesTaxRm') }}</label>
            <TextField
              v-model="computedTaxRm"
              :type="'number'"
              :rules="rules.taxRm"
              :label="$t('productPrice.lbl.salesTaxRm')"
              disabled
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.costInclTax') }}
              <span class="font-red">{{ rules.price.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="computedPrice"
              :type="'number'"
              :rules="rules.price"
              :label="$t('productPrice.lbl.costInclTax')"
              disabled
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('productPrice.lbl.default') }}
              <span class="font-red">{{ rules.isDef.required ? '*' : '' }}</span>
            </label>
            <SwitchToggle v-model="form.isDef" :label="$t('map.yesNoBool')[form.isDef]" :rules="rules.isDef" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="12">
            <label class="form-row-col__label"
              >{{ $t('lbl.remarks') }}
              <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
          </v-col>
        </v-row>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import Countries from '@/util/countries';
import { productPriceApi, rawMatUomApi } from '@/api/product';
import { supplierApi } from '@/api/supplier';
import { isUndefinedNullOrEmpty, roundToDecimal, extractJsonKey } from '@/util/tools';

export default {
  name: 'ProductPriceDetails',
  props: {
    id: {
      type: Number,
      default: null,
    },
    productId: {
      type: Number,
      default: null,
    },
    uom: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.id)
        ? this.$t('productPrice.title.updateSuppPrice')
        : this.$t('productPrice.title.addSuppPrice'),
      taxRm: 0,
      form: {
        supplierId: null,
        item: '',
        qty: 1,
        uom: '',
        taxCode: 'ZR',
        curr: 'MYR',
        cost: 0,
        tax: 0, // Percent
        price: 0,
        notes: '',
        isDef: false,
      },
      rules: {
        supplierId: { required: true },
        item: { required: true },
        qty: { required: true },
        uom: { required: true },
        taxCode: { required: false },
        curr: { required: false },
        cost: { required: true },
        tax: { required: true },
        price: { required: true },
        notes: { required: false },
        isDef: { required: true },
      },
      productUomList: [],
      supplierList: [],
      loading: false,
    };
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.id) ? true : false;
    },
    computedTaxRm() {
      return roundToDecimal((this.form.cost * this.form.tax) / 100, 2);
    },
    computedPrice() {
      return roundToDecimal(this.form.cost * (1 + this.form.tax / 100), 2);
    },
  },
  created() {
    if (!isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    } else {
      this.form.uom = this.uom.orderUom;
    }
    this.getProductUomList();
    this.getSupplierList();
  },
  methods: {
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
        cost: Number(this.form.cost),
        tax: Number(this.form.tax),
        price: Number(this.computedPrice),
      };
      if (this.isUpdate) {
        data.id = this.id;
        const keys = [
          'id',
          'item',
          'qty',
          'uom',
          'taxCode',
          'curr',
          'cost',
          'tax',
          'price',
          'notes',
          'payTerm',
          'isDef',
          'status',
        ];
        const finalData = extractJsonKey(keys, data);
        this.update(finalData);
      } else {
        data.productId = this.productId;
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return productPriceApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return productPriceApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return productPriceApi.detail({ id }).then(res => {
        this.form = res.data;
      });
    },
    getProductUomList() {
      const params = {
        productId: this.productId,
        orgId: null,
        orderBy: null,
      };
      return rawMatUomApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.uom,
            value: item.uom,
          });
        }
        this.productUomList = list;
      });
    },
    getSupplierList() {
      const params = {
        orgId: null,
        orderBy: null,
      };
      return supplierApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.supplierList = list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
.v-icon {
  font-size: 18px;
}
</style>
