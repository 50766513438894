import Vue from 'vue';
import { isUndefinedNullOrEmpty, dateOnly, setTimeZone } from '@/util/tools.js';

export default {
  data() {
    return {};
  },
  methods: {
    isUndefinedNullOrEmpty,
    dateOnly,
    setTimeZone,
  },
};
