<template>
  <ValidationObserver ref="observer">
    <div>
      <div class="d-flex align-center mb-1">
        <v-icon class="mr-1">mdi-information</v-icon>
        <span class="text-caption mr-2">{{ $t('semiFGoods.print.voidLabel.note') }}</span>
      </div>
      <ul>
        <li class="text-caption">{{ $t('semiFGoods.print.voidLabel.point1') }}</li>
        <li class="text-caption">{{ $t('semiFGoods.print.voidLabel.point2') }}</li>
        <li class="text-caption">{{ $t('semiFGoods.print.voidLabel.point3') }}</li>
      </ul>
      <v-row class="form-row">
        <v-col class="form-row-col" cols="12" sm="3">
          <TextField v-model="serialNo" :rules="rules.serialNo" :label="$t('semiFGoods.print.lbl.serialNo')" @keyup.enter.native="voidLabel(serialNo)" />
        </v-col>
        <v-col class="form-row-col" cols="12" sm="3">
          <AppBtn :loading="loading" @click="voidLabel(serialNo)">{{ $t('semiFGoods.print.act.voidLabel') }}</AppBtn>
        </v-col>
      </v-row>
    </div>
  </ValidationObserver>
</template>
<script>
import { semiFGoodsLabelApi } from '@/api/label';

export default {
  name: 'VoidLabel',
  data() {
    return {
      loading: false,
      serialNo: null,
      rules: {
        serialNo: {
          required: true,
        },
      }
    }
  },
  methods: {
    async voidLabel(serialNo) {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const params = {
        sn: serialNo,
      }
      this.loading = true;
      return semiFGoodsLabelApi.delete(params).then(() => {
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.loading = false;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 18px;
}
</style>