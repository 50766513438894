<template>
  <app-card>
    <v-card-title>
      <TextField v-model="searchVal" class="mr-2" :hide-details="true" :placeholder="$t('lbl.search')" />
      <AppBtn color="primary" @click="getList">{{ $t('act.refresh') }}</AppBtn>
    </v-card-title>
    <v-card-text>
      <v-row class="job-card mx-0 my-2" v-for="(item, index) in data" :key="index">
        <v-col class="job-card__item py-2" cols="6" sm="3" md="2">
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.execDate') }}</div>
            <div class="job-card__content">{{ setTimeZone(item.jobDate) }}</div>
          </div>
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.execBy') }}</div>
            <div class="job-card__content">{{ item.dept }}</div>
          </div>
        </v-col>
        <v-col class="job-card__item py-2" cols="6" sm="3" md="2">
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.jobNo') }}</div>
            <div class="job-card__content">{{ item.jobNo }}</div>
          </div>
          <div class="mb-2">
            <div class="job-card__title">{{ $t('lbl.actionBy') }}</div>
            <div class="job-card__content">{{ item.cby }}</div>
          </div>
        </v-col>
        <v-col class="job-card__item py-2" cols="6" sm="3" md="2">
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.productName') }}</div>
            <div class="job-card__content">{{ item.product }}</div>
          </div>
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.jobType') }}</div>
            <div class="job-card__content">{{ item.typeName }}</div>
          </div>
        </v-col>
        <v-col class="job-card__item py-2" cols="6" sm="3" md="2">
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.qty') }}</div>
            <div class="job-card__content">{{ `${item.qty} ${item.uom}` }}</div>
          </div>
          <div class="mb-2">
            <div class="job-card__title">{{ $t('production.lbl.totalBatch') }}</div>
            <div class="job-card__content">{{ item.totBatch }}</div>
          </div>
        </v-col>
        <v-col class="job-card__item d-flex pa-0" cols="12" sm="4" md="4">
          <div class="job-card__btn-wrap">
            <v-btn class="job-card__btn" color="#2dacd1" @click="showRecipeModal(item)">
              <v-icon class="job-card__btn-icon mb-2" color="#fff">mdi-book-open-variant</v-icon>
              <h3>{{ $t('production.lbl.recipe') }}</h3>
            </v-btn>
          </div>
          <div class="job-card__btn-wrap">
            <v-btn v-if="item.status == JOB_STATUS.IN_PROGRESS" class="job-card__btn" color="#dfb81c" :ripple="false" @click="showCompleteJobModal(item)">
              <v-icon class="job-card__btn-icon mb-2" color="#fff">mdi-progress-clock</v-icon>
              <h3>{{ $t('production.lbl.workInProgress') }}</h3>
            </v-btn>
            <v-btn v-else class="job-card__btn" color="#209e91" :loading="item.loading" @click="start(item)">
              <v-icon class="job-card__btn-icon mb-2" color="#fff">mdi-play</v-icon>
              <h3>{{ $t('act.START') }}</h3>
            </v-btn>
          </div>
          <div class="job-card__btn-wrap">
            <v-btn class="job-card__btn" color="#dfb81c" @click="navigatePush('ProductionStockOut', { jobNo: item.jobNo, recipeId: item.recipeId })">
              <v-icon class="job-card__btn-icon mb-2" color="#fff">mdi-cube-send</v-icon>
              <h3>{{ $t('inventory.act.stockOut') }}</h3>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <CompleteJobModal v-if="completeJobModalShow" :data="completeJobData" :show-modal.sync="completeJobModalShow" @close="closeModal" @getList="getList" />
    <RecipeModal v-if="recipeModalShow" :data="recipeData" :show-modal.sync="recipeModalShow" @close="closeRecipeModal" />
  </app-card>
</template>

<script>
import CompleteJobModal from './components/CompleteJobModal';
import RecipeModal from './components/RecipeModal';
import { JOB_STATUS } from '@/common/enum';
import { jobApi } from '@/api/job';
import { getCurrentDate } from '@/util/tools';
import { recipeItemApi } from '@/api/recipe';
import { mapState } from 'vuex';

export default {
  name: 'ProductionDashboard',
  components: {
    CompleteJobModal,
    RecipeModal,
  },
  data() {
    return {
      pagination: {
        page: 1,
        total: 0,
      },
      completeJobModalShow: false,
      recipeModalShow: false,
      searchVal: '',
      data: [],
      recipeData: [],
      completeJobData: null,
    };
  },
  computed: {
    ...mapState({
      paginationOpts: state => state.app.paginationOpts,
    }),
    JOB_STATUS() {
      return JOB_STATUS;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // getListNTotalRec() {
    //   this.getTotalRec().then(() => {
    //     this.getList();
    //   });
    // },
    // getTotalRec() {
    //   const params = {
    //     orgId: null,
    //     orderBy: '',
    //     page: 0,
    //   };
    //   return jobApi.listOpen(params).then(res => {
    //     this.pagination.total = res.data.total;
    //   });
    // },
    getList() {
      const params = {
        orgId: null,
        orderBy: '',
        page: this.pagination.page,
        pageSize: -1,
      };
      return jobApi.listOpen(params).then(res => {
        this.data = res.data.filter(item => item.status != 0).map(item => {
          return {
            ...item,
            loading: false,
          }
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
    showCompleteJobModal(item) {
      this.completeJobData = item;
      this.$nextTick(() => {
        this.completeJobModalShow = true;
      })
    },
    closeModal() {
      this.completeJobData = null;
      this.completeJobModalShow = false;
    },
    showRecipeModal(item) {
      this.recipeModalShow = true;
      this.getRecipeItemList(item);
    },
    getRecipeItemList(item) {
      return recipeItemApi.ddl({ recipeId: item.recipeId }).then(res => {
        this.recipeData = res.data.map(recipe => {
          return {
            ...recipe,
            totQty: recipe.qty * item.totBatch,
          }
        });
      });
    },
    closeRecipeModal() {
      this.recipeData = [];
      this.recipeModalShow = false;
    },
    start(item) {
      const data = {
        id: item.id,
        startDate: getCurrentDate(),
      };
      item.loading = true;
      return jobApi.start(data).then(res => {
        item.loading = false;
        this.showSuccess(this.$t('production.msg.jobStartSuccess'));
        this.getList();
      }).catch(() => {
        item.loading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.job-card {
  &__item {
    background-color: #eee;
    border-right: 3px solid #fff;
    border-bottom: 2px solid #fff;
  }
  &__title {
    color: #666666;
    font-weight: 400;
  }
  &__content {
    color: #111;
    font-size: 18px;
    font-weight: 500;
  }
  &__btn-wrap {
    border-right: 2px solid #fff;
    width: 100%;
  }
  &__btn-icon {
    font-size: 50px;
  }
  &__btn {
    width: 100%;
    height: 100% !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    ::v-deep .v-btn__content {
      color: #fff;
      flex-direction: column;
    }
  }
}
</style>
