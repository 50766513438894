export default {
  map: {
    approvedRejected: {
      1: 'Approved',
      2: 'Rejected',
    },
    grnItemAct: {
      1: 'Accept',
      2: 'Reject',
    },
    grnItemStatus: {
      1: 'Accepted',
      2: 'Rejected',
    },
    grnLabelStatus: {
      0: 'Pending',
      1: 'Printed',
      4: 'Voided',
      5: 'Purged',
      8: 'Write off',
      9: 'Used',
    },
    jobStatus: {
      0: 'New',
      1: 'Confirmed',
      2: 'In Progress',
      3: 'Deleted',
      4: 'Cancelled',
      5: 'Completed',
      6: 'Completed Variance',
    },
    lblPrintOpt: {
      1: 'Single',
      2: 'Multiple',
    },
    lblPrintOptBool: {
      true: 'Single',
      false: 'Multiple',
    },
    outletType: {
      1: 'HQ',
      2: 'Outlet',
      3: 'Franchisee',
    },
    productType: {
      1: 'Raw Materials',
      2: 'Finished Goods',
      3: 'Semi Finished Goods',
      4: 'Non Food',
    },
    purchaseOrderSts: {
      0: 'New',
      1: 'Confirmed',
      2: 'Approved',
      3: 'Deleted',
      4: 'Cancelled',
      9: 'Completed',
    },
    recipeType: {
      1: 'Raw Materials',
      2: 'Semi Finished Materials',
      3: 'Linked Recipe',
      4: 'Manual/Others',
    },
    salesOrderSts: {
      0: 'New',
      1: 'Confirmed',
      2: 'Approved',
      3: 'Deleted',
      4: 'Cancelled',
      9: 'Completed',
    },
    status: {
      0: 'Pending',
      1: 'Active',
      2: 'Suspended',
      3: 'Terminated',
    },
    stockTxType: {
      1: 'Stock In from GRN',
      2: 'Stock Out for Production',
      3: 'Stock In from Production',
      9: 'Stock Adjustment',
      10: 'Stock Transfer',
      11: 'Sales',
      12: 'Delivery',
      13: 'Withdrawal Request',
    },
    storeStockOrderStatus: {
      0: 'New',
      1: 'Confirmed',
      2: 'Approved',
      3: 'Deleted',
      4: 'Cancelled',
      8: 'Picked Up',
      9: 'Completed',
    },
    storeStockOrderType: {
      1: 'Auto',
      2: 'Manual',
      3: 'Store HQ',
      4: 'Store',
    },
    yesNo: {
      0: 'No',
      1: 'Yes',
    },
    yesNoBool: {
      false: 'No',
      true: 'Yes',
    },
    // gender: {
    //   M: 'Male',
    //   F: 'Female',
    // },
    // genderAbbreviation: {
    //   M: 'M',
    //   F: 'F',
    //   X: 'NA',
    // },
    // kycStatus: {
    //   null: 'Unverified',
    //   0: 'Pending',
    //   1: 'Active',
    //   2: 'Suspended', // Self map here
    //   3: 'Terminated',
    //   4: 'Rejected',
    //   7: 'Blacklisted',
    //   9: 'Expired',
    //   10: 'Submitted',
    //   11: 'Verified',
    //   13: 'Pending Payment',
    //   18: 'In Review',
    //   19: 'Alert',
    //   21: 'Revoked',
    //   22: 'Revoked Verified',
    //   23: 'Revoked Approved',
    //   31: 'Released',
    //   32: 'Release Verified',
    //   33: 'Release Approved',
    //   99: 'Error',
    // },
    // contractStatus: {
    //   0: 'Created',
    //   1: 'Completed',
    //   6: 'Voided', // Self map here
    //   7: 'KIV',
    //   8: 'Expired',
    //   9: 'Terminated',
    //   10: 'Submitted',
    //   11: 'Verified',
    //   12: 'Reviewed',
    //   15: 'Amended',
    //   18: 'Renewal',
    //   21: 'Add Signer',
    //   23: 'Remove Signer',
    //   25: 'Request Amendment',
    //   27: 'Rejected',
    //   28: 'Signed',
    //   29: 'Request Termination',
    //   31: 'Add Viewer',
    //   32: 'Edit Viewer Permission',
    //   33: 'Remove Viewer',
    //   99: 'Others',
    // },
    // kycStatusDesc: {
    //   null: 'Kindly complete your KYC',
    //   2: 'Your Signitory has been suspended due to 1 ID with multiple accounts',
    //   4: 'Your KYC has been rejected, kindly resubmit your KYC',
    //   10: 'Your KYC is in-review',
    //   11: 'Congratulation! Your KYC has been verified!',
    //   18: 'Your KYC is In-Review. Stay tuned!',
    //   21: 'Your KYC has been "Revoked". Your Signitory account has been suspended',
    //   31: 'Your KYC has been released from the “Revoked” status. Kindly re-submit your KYC',
    // },
    // contractCaption: {
    //   0: 'Draft',
    //   1: 'Complete',
    //   3: 'User',
    //   8: 'Expired',
    //   9: 'Cancelled',
    //   27: 'Rejected',
    //   28: 'Signed',
    //   41: 'Need you to sign',
    //   42: 'Pending others',
    //   43: 'Shared with you',
    //   44: 'Template',
    //   45: 'Blockchain recording',
    //   46: 'Error',
    //   47: 'On-Going',
    // },
    // userRole: {
    //   1: 'Owner',
    //   2: 'Admin',
    //   3: 'Maintainer',
    //   4: 'Manager',
    //   // 5: 'Admin',
    //   11: 'User', //user or editor? need advice
    //   12: 'Signer',
    //   13: 'Viewer',
    // },
    // userStatus: {
    //   0: 'Inactive',
    //   1: 'Active',
    //   2: 'Suspended',
    //   3: 'Terminated',
    // },
    // planStatus: {
    //   0: 'Pending',
    //   1: 'Active',
    //   2: 'Failed',
    //   3: 'Timeout',
    // },
    // invitationStatus: {
    //   0: 'Pending',
    //   1: 'Accepted',
    // },
    // orgListStatus: {
    //   0: 'Pending',
    //   1: 'Active',
    //   2: 'Suspended',
    //   3: 'Terminated',
    // },
    // signerStatus: {
    //   0: 'None',
    //   1: 'Authorized',
    //   2: 'Unauthorized',
    // },
    // userAuthStatus: {
    //   0: 'Pending Authorization',
    //   1: 'Authorized User',
    //   2: 'Unauthorized User',
    // },
    // null: null,
  },
};
