<template>
  <div>
    <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
      <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
        {{ tab.label }}
      </v-tab>
    </v-tabs>

    <ValidationObserver ref="observer">
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
            <General :data="data" />
            <!-- <div class="d-flex justify-end">
              <AppBtn class="btn-cancel mr-2" @click="navigatePush('RecipeCategoryList')">{{ $t('act.cancel') }}</AppBtn>
              <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
            </div> -->
          </div>
          <div v-if="tab.id === 1" class="tab-content-wrap px-2 py-3 px-md-4">
            <Category :data="data" />
          </div>
          <div v-if="tab.id === 2" class="tab-content-wrap px-2 py-3 px-md-4">
            <Ingredient :data="data" />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </ValidationObserver>
  </div>
</template>
<script>
import { recipeApi } from '@/api/recipe';
import General from './components/General';
import Category from './components/Category';
import Ingredient from './components/Ingredient';
export default {
  name: 'RecipeDetails',
  components: {
    General,
    Category,
    Ingredient,
  },
  data() {
    return {
      data: {},
      id: null,
      selectedTab: 0,
      tabs: this.$t('recipeLabel'),
      loading: false,
      form: {
        name: '',
        code: '',
        notes: '',
      },
      rules: {
        name: { required: true },
        code: { required: false },
        notes: { required: false },
      },
    };
  },
  computed: {
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'RecipeUpdate';
    },
  },
  created() {
    if (this.isUpdate) {
      this.detail(this.$route.params.id);
    }
  },
  methods: {
    detail(id) {
      return recipeApi.detail({ id }).then(res => {
        this.data = res.data;
      });
    },
    // async confirm() {
    //   const valid = await this.$refs.observer.validate();
    //   if (!valid) return;
    //   let data = {
    //     name: this.form.name,
    //     code: this.form.code,
    //     notes: this.form.notes,
    //   };
    //   if (this.isUpdate) {
    //     data.id = this.form.id;
    //     this.update(data);
    //   } else {
    //     this.create(data);
    //   }
    // },
    // create(data) {
    //   this.loading = true;
    //   return recipeApi
    //     .create(data)
    //     .then(() => {
    //       this.loading = false;
    //       this.showSuccess(this.$t('lbl.createSuccess'));
    //       this.navigatePush('RecipeCategoryList');
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    // update(data) {
    //   this.loading = true;
    //   return recipeApi
    //     .update(data)
    //     .then(() => {
    //       this.loading = false;
    //       this.showSuccess(this.$t('lbl.updateSuccess'));
    //       this.navigatePush('RecipeCategoryList');
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
