var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonModal',{attrs:{"show-modal":_vm.modalState,"header-title":_vm.productName,"modal-size":'modal-md',"with-footer":""},on:{"update:showModal":function($event){_vm.modalState=$event},"update:show-modal":function($event){_vm.modalState=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DataTable',{class:'cursor-pointer',attrs:{"headers":_vm.headers,"items":_vm.data,"mobile-breakpoint":0,"hide-default-header":_vm.hideHeader,"pagination-config":_vm.pagination},on:{"update:paginationConfig":function($event){_vm.pagination=$event},"update:pagination-config":function($event){_vm.pagination=$event},"pageChg":_vm.pageChg,"pageSizeChg":_vm.pageSizeChg},scopedSlots:_vm._u([{key:"item-column-1-xs",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-medium font-15"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.cdate) ? _vm.setTimeZone(item.cdate) : '-'))]),_c('div',[_vm._v(_vm._s(_vm.$t('inventory.lbl.type'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.stockTxTypeMap[item.type]))])]),_c('div',[_vm._v(_vm._s(_vm.$t('inventory.lbl.storage'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.storage))])]),_c('div',[_vm._v(_vm._s(_vm.$t('inventory.lbl.serialNo'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.sn))])]),_c('div',[_vm._v(_vm._s(_vm.$t('inventory.lbl.qty'))+": "),_c('span',{staticClass:"font-weight-regular",class:item.dr ? 'font-red' : 'font-green'},[_vm._v(_vm._s(item.dr ? '-' : '+')+" "+_vm._s(((item.qty) + " " + (item.uom))))])])])]}},{key:"item-cdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.cdate) ? _vm.setTimeZone(item.cdate) : '-')+" ")]}},{key:"item-type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stockTxTypeMap[item.type])+" ")]}},{key:"item-qty",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:item.dr ? 'font-red' : 'font-green'},[_vm._v(_vm._s(item.dr ? '-' : '+')+" "+_vm._s(((item.qty) + " " + (item.uom))))])]}}])})]},proxy:true},{key:"footer",fn:function(){return [_c('AppBtn',{staticClass:"btn-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('act.close')))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }