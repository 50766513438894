import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=1852b96c&scoped=true&"
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"
import style0 from "./Create.vue?vue&type=style&index=0&id=1852b96c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1852b96c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VCardText,VCardTitle,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})
