var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-1"},[_c('div',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-caption mr-2"},[_vm._v(_vm._s(_vm.$t('fGoods.print.queue.note')))])],1),_c('AppBtn',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.purgeLabel(_vm.jobNo)}}},[_vm._v(_vm._s(_vm.$t('fGoods.print.act.purgeAllLabel')))])],1),_c('v-row',{staticClass:"form-row"},[_c('v-col',{staticClass:"form-row-col text-right",attrs:{"cols":"12","sm":"12"}})],1),_c('v-data-table',{staticClass:"mb-5",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.data,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.status) ? _vm.$t('map.grnItemStatus')[item.status] : '-')+" ")]}},{key:"item.isPrinted",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.isPrinted ? 'green' : 'red'}},[_vm._v(_vm._s(item.isPrinted ? 'mdi-check-bold' : 'mdi-close-thick'))])]}},{key:"item.printOpt",fn:function(ref){
var item = ref.item;
return [_c('SwitchToggle',{attrs:{"label":_vm.$t('map.lblPrintOptBool')[item.printOpt]},on:{"change":function($event){return _vm.printOptChg(item)}},model:{value:(item.printOpt),callback:function ($$v) {_vm.$set(item, "printOpt", $$v)},expression:"item.printOpt"}})]}},{key:"item.newUom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('Select',{attrs:{"options":item.processedUomList},on:{"change":function($event){return _vm.uomChg(item)}},model:{value:(item.newUom),callback:function ($$v) {_vm.$set(item, "newUom", $$v)},expression:"item.newUom"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }