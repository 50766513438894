<template>
  <div>
    <ValidationObserver ref="observer">
      <div class="d-flex flex-column flex-sm-row mb-3">
        <Select
          class="mr-0 mr-sm-2 mb-2 mb-sm-0"
          :style="{ 'max-width': inputWidth }"
          v-model="form.customerId"
          :label="$t('replenish.lbl.customer')"
          :placeholder="$t('replenish.lbl.selectCustomer')"
          :options="customerDdl"
          :rules="rules.customerId"
        />
        <Select
          class="mr-0 mr-sm-2 mb-2 mb-sm-0"
          :style="{ 'max-width': inputWidth }"
          v-model="form.storeId"
          :label="$t('replenish.lbl.store')"
          :placeholder="$t('replenish.lbl.selectStore')"
          :options="storeDdl"
          :rules="rules.storeId"
        />
        <div class="d-flex justify-end">
          <AppBtn class="btn-black mb-2 mb-sm-0" :loading="searchLoading" @click="search">{{ $t('act.search') }}</AppBtn>
          <AppBtn v-if="showCreateBtn" class="ml-2 mr-0 mr-sm-2 mb-2 mb-sm-0" color="primary" :loading="loading" @click="showConfirmModal">{{ $t('replenish.act.placeOrder') }}</AppBtn>
        </div>
      </div>
    </ValidationObserver>
    <div v-if="showTable">
      <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        disable-pagination
        disable-sort
        @update:page="pageChg"
        @update:items-per-page="pageSizeChg"
      >
        <template v-slot:item.qty="{ item }">
          <TextField v-model="item.qty" :type="'number'" />
        </template>
      </v-data-table>
      <div class="text-right mt-4">
        <AppBtn v-if="showCreateBtn" class="mr-0 mr-sm-2 mb-2 mb-sm-0" color="primary" :loading="loading" @click="showConfirmModal">{{ $t('replenish.act.placeOrder') }}</AppBtn>
      </div>
    </div>
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { storeApi, storeStockApi, storeStockOrderApi } from '@/api/store';
import { customerApi } from '@/api/customer';
import { STORE_STOCK_ORDER_TYPE, STORE_STOCK_ORDER_STATUS } from '@/common/storeEnum.js';
export default {
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('replenish.lbl.replenishForStore'),
      confirmMsg: this.$t('replenish.lbl.confirm.place'),
      data: [],
      form: {
        customerId: null,
        storeId: null,
      },
      customerDdl: [],
      storeDdl: [],
      rules: {
        customerId: { required: true },
        storeId: { required: true },
      },
      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
      showTable: false,
      searchLoading: false,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    headers() {
      return [
        { text: this.$t('replenish.lbl.store'), width: '120', value: 'store' },
        { text: this.$t('replenish.lbl.category'), width: '70', value: 'grp' },
        { text: this.$t('replenish.lbl.item'), width: '200', value: 'item' },
        { text: this.$t('replenish.lbl.maxLoad'), width: '70', value: 'max' },
        { text: this.$t('replenish.lbl.used'), width: '60', value: 'used' },
        { text: this.$t('replenish.lbl.balance'), width: '70', value: 'bal' },
        { text: this.$t('replenish.lbl.refillQty'), width: '60', value: 'qty' },
        { text: this.$t('replenish.lbl.uom'), width: '70', value: 'uom' },
        { text: this.$t('replenish.lbl.servings/UOM'), width: '90', value: 'serving' },
      ]
    },
    inputWidth() {
      if (this.isMobileView) {
        return '100%';
      } else {
        return '200px';
      }
    },
    showCreateBtn() {
      return this.showTable == true && !this.isUndefinedNullOrEmpty(this.data);
    }
  },
  created() {
    this.getStoreDdl();
    this.getCustomerDdl();
  },
  methods: {
    getListNTotalRec() {
      this.searchLoading = true;
      this.getTotalRec().then(() => {
        this.getList().catch(() => {
          this.searchLoading = false;
        });
      }).catch(() => {
        this.searchLoading = false;
      });
    },
    getTotalRec() {
      const params = {
        page: 0,
        storeId: this.form.storeId,
      };
      return storeStockApi.refillListByStore(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
        storeId: this.form.storeId,
      };
      return storeStockApi.refillListByStore(params).then(res => {
        this.data = res.data;
        this.showTable = true;
        this.searchLoading = false;
      });
    },
    getStoreDdl() {
      return storeApi.ddl({}).then(res => {
        this.storeDdl = res.data.map(item => {
          return { text: item.name, value: item.id }
        });
      });
    },
    getCustomerDdl() {
      return customerApi.ddl({}).then(res => {
        this.customerDdl = res.data.map(item => {
          return { text: item.name, value: item.id }
        });
        this.form.customerId = this.customerDdl[0].value;
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.create(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    create() {
      const items = this.processItems(this.data);
      if (this.isUndefinedNullOrEmpty(items)) return;

      const data = {
        customerId: this.form.customerId,
        storeId: this.form.storeId,
        type: STORE_STOCK_ORDER_TYPE.MANUAL,
        status: STORE_STOCK_ORDER_STATUS.APPROVED,
        items,
      }
      this.confirmActionLoading = true;
      return storeStockOrderApi.storeStockOrderCreate(data).then(res => {
        this.confirmActionLoading = false;
        this.confirmModalShow = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.resetFields();
        }
      }).catch(() => {
        this.confirmActionLoading = false;
        this.confirmModalShow = false;
      });
    },
    processItems(data) {
      return data.filter(item => item.qty > 0).map(item => {
        return {
          itemId: item.stockId,
          qty: item.qty
        }
      });
    },
    resetFields() {
      this.data = [];
      this.form.storeId = null;
      this.showTable = false;
    },
    async search() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.getListNTotalRec();
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  }
}
</script>