import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins';
import store from './store';
// import { sync } from 'vuex-router-sync'
import i18n from '@/translations';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import commonMixin from '@/mixins/common';
import toolMixin from '@/mixins/tool';
import EventBus from './plugins/event-bus';
// import firebaseMessaging from './plugins/firebase';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Vue.prototype.$messaging = firebaseMessaging;
Vue.config.productionTip = false;

Vue.use(EventBus);
Vue.mixin(commonMixin);
Vue.mixin(toolMixin);

// sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
