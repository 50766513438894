import Api from '../api';
import { sha256 } from 'js-sha256';

export const userApi = {
  login(data) {
    return Api.post('auth/user/login', data);
  },
  setPwd(data) {
    data.password = sha256(data.password);
    data.newPassword = sha256(data.newPassword);
    return Api.post('auth/user/login/set', data);
  },
  // addSignature(orgId, label = null, isDefault = false, remarks) {
  //   const data = {
  //     orgId,
  //     label,
  //     default: isDefault ? 1 : 0,
  //     remarks,
  //   };

  //   return Api.post('sig/add', data);
  // },
  // addStamp(orgId, label = null, isDefault = false, remarks) {
  //   const data = {
  //     orgId,
  //     label,
  //     default: isDefault ? 1 : 0,
  //     remarks,
  //   };

  //   return Api.post('stamp/add', data);
  // },
};
