import { isUndefinedNullOrEmpty } from '@/util/tools';
import { rawMatApi, semiFGoodsApi, fGoodsApi } from '@/api/product';

const product = {
  state: {
    rawMatDdl: [],
    semiFGoodsDdl: [],
    fGoodsDdl: [],
    snLengthLimit: 30,
    scanInterval: 2000, // milliseconds
  },
  getters: {
    
  },
  mutations: {
    SET_RAW_MAT_DDL(state, payload) {
      state.rawMatDdl = payload;
    },
    SET_SEMI_F_GOODS_DDL(state, payload) {
      state.semiFGoodsDdl = payload;
    },
    SET_F_GOODS_DDL(state, payload) {
      state.fGoodsDdl = payload;
    },
  },
  actions: {
    fetchRawMatDdl({ commit }, params = {}) {
      return rawMatApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        commit('SET_RAW_MAT_DDL', list);
      });
    },
    fetchSemiFGoodsDdl({ commit }, params = {}) {
      return semiFGoodsApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
            saleUom: item.saleUom,
            recipeQty: item.recipeQty,
            recipeUom: item.recipeUom,
          });
        }
        commit('SET_SEMI_F_GOODS_DDL', list);
      });
    },
    fetchFGoodsDdl({ commit }, params = {}) {
      return fGoodsApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
            saleUom: item.saleUom,
            recipeQty: item.recipeQty,
            recipeUom: item.recipeUom,
          });
        }
        commit('SET_F_GOODS_DDL', list);
      });
    },
  },
};

export default product;
