<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import List from './List';
import Details from './Details';
export default {
  name: 'SalesOrder',
  components: {
    List,
    Details,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'SalesOrderList':
          return 'List';
        case 'SalesOrderCreate':
          return 'Details';
        case 'SalesOrderUpdate':
          return 'Details';
        default:
          return 'List';
      }
    },
  },
};
</script>
