<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('recipe.lbl.type') }}
              <span class="font-red">{{ rules.type.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.type" :label="$t('recipe.lbl.type')" :options="$t('recipeType')" :rules="rules.type" @change="typeChg" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('recipe.lbl.ingredient') }}
              <span class="font-red">{{ rules.itemId.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.itemId" :label="$t('recipe.lbl.ingredient')" :options="ingredientList" :rules="rules.itemId"  />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('recipe.lbl.ingredient.qty') }}
              <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.qty"
              :type="'number'"
              :min="0"
              :step="decimalStep"
              :rules="rules.qty"
              :label="$t('recipe.lbl.ingredient.qty')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.uom') }}
              <span class="font-red">{{ rules.uom.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.uom" :label="$t('uom.lbl.uom')" :options="uomList" :rules="rules.uom"  />
          </v-col>
        </v-row>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.back') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { recipeItemApi } from '@/api/recipe';
import { INGREDIENT_TYPE, RAW_MAT_GRP } from '@/common/enum';
import { isUndefinedNullOrEmpty, extractJsonKey } from '@/util/tools';

export default {
  name: 'RecipeItem',
  props: {
    data: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.data) ? this.$t('recipe.act.updateIngredient') : this.$t('recipe.act.addNewIngredient'),
      decimalStep: '0.01',
      form: {
        type: 1,
        itemId: null,
        uom: null,
        qty: null,
      },
      rules: {
        type: { required: true },
        itemId: { required: true },
        uom: { required: true },
        qty: { required: true },
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      rawMatDdl: state => state.product.rawMatDdl,
      semiFGoodsDdl: state => state.product.semiFGoodsDdl,
      uomList: state => state.lookup.uomList,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.form.id);
    },
    ingredientList() {
      switch(this.form.type) {
        case INGREDIENT_TYPE.RAW_MAT:
          return this.rawMatDdl;
        case INGREDIENT_TYPE.SEMI_F_GOODS:
          return this.semiFGoodsDdl;
        default:
          return [];
      }
    },
    recipeId() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.rawMatDdl)) this.fetchRawMatDdl({ grp: RAW_MAT_GRP.RAW_MAT });
    if (this.isUndefinedNullOrEmpty(this.semiFGoodsDdl)) this.fetchSemiFGoodsDdl();
    if (this.isUndefinedNullOrEmpty(this.uomList)) this.fetchUomList();
    if (!this.isUndefinedNullOrEmpty(this.data)) {
      this.form = this.data;
    }
  },
  methods: {
    ...mapActions(['fetchRawMatDdl', 'fetchSemiFGoodsDdl']),
    typeChg(item) {
      this.form.itemId = null;
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const keys = ['recipeId', 'type', 'itemId', 'qty', 'uom'];
      let data = extractJsonKey(keys, this.form);

      if (this.isUpdate) {
        data.id = this.form.id;
        this.update(data);
      } else {
        data.recipeId = this.recipeId;
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return recipeItemApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('getList');
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return recipeItemApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('getList');
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
