<template>
  <ValidationObserver ref="observer">
    <div>
      <div class="d-flex align-center mb-1">
        <v-icon class="mr-1">mdi-information</v-icon>
        <span class="text-caption mr-2">{{ $t('semiFGoods.print.createLbl.note') }}</span>
      </div>
      <v-row class="form-row py-1">
        <v-col class="form-row-col" cols="12" sm="4">
          <!-- <label class="form-row-col__label"
            >{{ $t('semiFGoods.lbl.jobNo') }}
            <span class="font-red">{{ rules.jobNo.required ? '*' : '' }}</span>
          </label> -->
          <TextField ref="jobNo" v-model="jobNo" :rules="rules.jobNo" :label="$t('semiFGoods.lbl.jobNo')" @keyup.enter.native="searchJobNo(jobNo)" />
        </v-col>
        <v-col class="form-row-col" cols="12" sm="3">
          <AppBtn @click="searchJobNo(jobNo)">{{ $t('act.search') }}</AppBtn>
        </v-col>
      </v-row>
      <v-row v-if="showDetailsSection" class="form-row py-1">
        <v-col class="form-row-col" cols="12" sm="4">
          <label class="form-row-col__label"
            >{{ $t('semiFGoods.lbl.qty') }}
            <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
          </label>
          <TextField v-model="form.qty" :type="'number'" :step="decimalStep" :rules="rules.qty" :label="$t('semiFGoods.lbl.qty')" />
        </v-col>
        <v-col class="form-row-col" cols="12" sm="4">
          <label class="form-row-col__label"
            >{{ $t('uom.lbl.uom') }}
            <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
          </label>
          <Select v-model="form.uom" :rules="rules.uom" :options="uomList" :label="$t('uom.lbl.uom')" />
        </v-col>
        <v-col class="form-row-col" cols="12" sm="4">
          <label class="form-row-col__label"
            >{{ $t('semiFGoods.lbl.noOfLabels') }}
            <span class="font-red">{{ rules.cnt.required ? '*' : '' }}</span>
          </label>
          <TextField v-model="form.cnt" :type="'number'" :rules="rules.cnt" :label="$t('semiFGoods.lbl.noOfLabels')" />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-3">
        <AppBtn @click="createLabel">{{ $t('rawMat.print.act.printLabel') }}</AppBtn>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { semiFGoodsLabelApi } from '@/api/label';
import { semiFGoodsUomApi } from '@/api/product';
import { jobApi } from '@/api/job';

export default {
  name: 'CreateLabel',
  data() {
    return {
      data: [],
      loading: false,
      showDetailsSection: false,
      decimalStep: '0.01',
      jobNo: null,
      uomList: [],
      form: {
        jobId: null,
        qty: null,
        uom: '',
        cnt: null,
      },
      rules: {
        jobNo: {
          required: true,
        },
        jobId: {
          required: true,
        },
        qty: {
          required: true,
        },
        uom: {
          required: true,
        },
        cnt: {
          required: true,
        },
      }
    }
  },
  mounted() {
     // pass from /Production/List.vue
    if (!this.isUndefinedNullOrEmpty(this.$route.params.jobNo)) {
      this.jobNo = this.$route.params.jobNo;
      this.$nextTick(() => {
        this.searchJobNo(this.jobNo);
      })
    }
  },
  methods: {
    async searchJobNo(jobNo) {
      const validObj = await this.$refs.jobNo.validate();
      if (!validObj.valid) return;
      this.getJobByJobNo(jobNo.trim());
      // this.getGrnItemList(jobNo);
    },
    getJobByJobNo(jobNo) {
      const params = {
        page: 1,
        pageSize: -1,
        jobNo,
      };
      return jobApi.list(params).then(res => {
        const data = res.data[0];
        if (!this.isUndefinedNullOrEmpty(data)) {
          this.form.jobId = data.id;
          this.form.qty = 1;
          this.form.uom = data.uom;
          this.form.cnt = data.qty;

          this.getItemUom(data.itemId);
          this.showDetailsSection = true;
        } else {
          this.resetFields();
        }
      });
    },
    getItemUom(itemId) {
      return semiFGoodsUomApi.ddl({ itemId }).then(res => {
        this.uomList = res.data.map(item => {
          return {
            text: item.uom,
            value: item.uom,
          }
        });
      });
    },
    async createLabel() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const data = {
        ...this.form,
        cnt: Number(this.form.cnt),
      }
      this.loading = true;
      return semiFGoodsLabelApi.create(data).then(() => {
        this.showSuccess(this.$t('lbl.createSuccess'));
        this.loading = false;
        this.resetFields();
      }).catch(() => {
        this.loading = false;
      });
    },
    resetFields() {
      this.jobNo = null;
      this.form.jobId = null;
      this.form.qty = null;
      this.form.uom = '';
      this.form.cnt = null;

      this.showDetailsSection = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 18px;
}
</style>