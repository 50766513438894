var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('app-card',[_c('v-card-title',[_c('TextField',{staticClass:"mr-2",attrs:{"hide-details":true,"placeholder":_vm.$t('lbl.search')},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('AppBtn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.createModalShow = true}}},[_vm._v(_vm._s(_vm.$t('fGoods.act.createFGoods')))])],1),_c('DataTable',{class:'cursor-pointer',attrs:{"headers":_vm.headers,"items":_vm.data,"mobile-breakpoint":0,"hide-default-header":_vm.hideHeader,"pagination-config":_vm.pagination},on:{"update:paginationConfig":function($event){_vm.pagination=$event},"update:pagination-config":function($event){_vm.pagination=$event},"click:row":_vm.editItem,"pageChg":_vm.pageChg,"pageSizeChg":_vm.pageSizeChg},scopedSlots:_vm._u([{key:"item-column-1-xs",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-medium font-15"},[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(_vm.$t('fGoods.lbl.code'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.sku))])]),_c('div',[_vm._v(_vm._s(_vm.$t('fGoods.lbl.category'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.category) ? item.category : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('fGoods.lbl.subCategory'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.subCat) ? item.subCat : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('lbl.status'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.$t('map.status')[item.status]))])])])]}},{key:"item-column-act-xs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmModal(item)}}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item-category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.category) ? item.category : '-')+" ")]}},{key:"item-subCat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.subCat) ? item.subCat : '-')+" ")]}},{key:"item-status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('map.status')[item.status])+" ")]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmModal(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)],1),(_vm.createModalShow)?_c('CreateModal',{attrs:{"id":_vm.id,"show-modal":_vm.createModalShow},on:{"update:showModal":function($event){_vm.createModalShow=$event},"update:show-modal":function($event){_vm.createModalShow=$event},"close":_vm.closeModal,"getList":_vm.getListNTotalRec}}):_vm._e(),_c('ConfirmModal',{attrs:{"confirm-action-loading":_vm.confirmActionLoading,"header-title":_vm.confirmModalTitle,"modal-size":'modal-xs',"confirm-msg":_vm.confirmMsg,"show-modal":_vm.confirmModalShow},on:{"update:showModal":function($event){_vm.confirmModalShow=$event},"update:show-modal":function($event){_vm.confirmModalShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }