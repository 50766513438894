import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const outletApi = {
  ddl(params) {
    return Api.get('outlet/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('outlet/list', params);
  },
  detail(params) {
    return Api.get('outlet', params);
  },
  create(data) {
    return Api.post('outlet/create', data);
  },
  update(data) {
    return Api.post('outlet/update', data);
  },
  delete(data) {
    return Api.post('outlet/delete', data);
  },
};
