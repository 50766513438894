<template>
  <div>
    <template v-for="(item, index) in list">
      <div class="noti-box d-flex cursor-pointer" :key="index" @click="showNotiDetailModal(item)">
        <div class="section-1 d-flex justify-center mt-1">
          <div class="blue-dot"></div>
        </div>
        <div class="section-2 flex-grow-1">
          <div class="section-title font-weight-medium mb-2">{{ item.subj }}</div>
          <div class="section-desc-1 font-0875r">{{ timeSince(new Date(item.cdate)).label }}</div>
        </div>
        <div class="section-3 d-flex justify-center align-center"></div>
      </div>
    </template>
    <DetailsModal v-if="modalShow" :id="notiId" :show-modal="modalShow" @close="closeModal" />
  </div>
</template>
<script>
import { timeSince } from '@/util/tools';
import DetailsModal from './components/DetailsModal';
export default {
  props: {
    list: {
      type: Array,
      default: null,
    },
  },
  components: {
    DetailsModal,
  },
  data() {
    return {
      modalShow: false,
      notiId: null,
    };
  },
  methods: {
    showNotiDetailModal(item) {
      this.notiId = item.id;
      this.$nextTick(() => {
        this.modalShow = true;
      });
    },
    closeModal() {
      this.notiId = null;
      this.modalShow = false;
    },
    timeSince: timeSince,
  },
};
</script>
<style lang="scss" scoped>
.noti-box {
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 0;
}
.section-1 {
  width: 10%;
}
.section-3 {
  width: 15%;
}
.section-title {}
.section-desc-1 {
  color: #999999;
}
.blue-dot {
  background-color: #2276ea;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
</style>
