<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import List from './List';
import Details from './Details';
import Dashboard from './Dashboard';
import StockOut from './StockOut';
export default {
  name: 'Production',
  components: {
    List,
    Details,
    Dashboard,
    StockOut,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'ProductionList':
          return 'List';
        case 'ProductionCreate':
          return 'Details';
        case 'ProductionUpdate':
          return 'Details';
        case 'ProductionDashboard':
          return 'Dashboard';
        case 'ProductionStockOut':
          return 'StockOut';
        default:
          return 'List';
      }
    },
  },
};
</script>
