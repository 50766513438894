<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
    <template v-slot:content>
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.code') }}
                    <span class="font-red">{{ rules.shortCode.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.shortCode" :rules="rules.shortCode" :label="$t('supp.lbl.code')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.name') }}
                    <span class="font-red">{{ rules.name.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.name" :rules="rules.name" :label="$t('supp.lbl.name')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.shortName') }}
                    <span class="font-red">{{ rules.shortName.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.shortName" :rules="rules.shortName" :label="$t('supp.lbl.shortName')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.compRegNo') }}
                    <span class="font-red">{{ rules.regNo.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.regNo" :rules="rules.regNo" :label="$t('supp.lbl.compRegNo')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.gstRegNo') }}
                    <span class="font-red">{{ rules.taxNo.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.taxNo" :rules="rules.taxNo" :label="$t('supp.lbl.gstRegNo')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.addr1') }}
                    <span class="font-red">{{ rules.address.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.address" :rules="rules.address" :label="$t('supp.lbl.addr1')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.addr2') }}
                    <span class="font-red">{{ rules.address2.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.address2" :rules="rules.address2" :label="$t('supp.lbl.addr2')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.addr3') }}
                    <span class="font-red">{{ rules.address3.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.address3" :rules="rules.address3" :label="$t('supp.lbl.addr3')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.state') }}
                    <span class="font-red">{{ rules.province.required ? '*' : '' }}</span>
                  </label>
                  <Select v-model="form.province" :label="$t('supp.lbl.state')" :options="provinceList" :rules="rules.province" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.postalCode') }}
                    <span class="font-red">{{ rules.postcode.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.postcode" :rules="rules.postcode" :label="$t('supp.lbl.postalCode')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.city') }}
                    <span class="font-red">{{ rules.city.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.city" :rules="rules.city" :label="$t('supp.lbl.city')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.country') }}
                    <span class="font-red">{{ rules.country.required ? '*' : '' }}</span>
                  </label>
                  <Select v-model="form.country" :label="$t('supp.lbl.country')" :options="countries" :rules="rules.country" />
                </v-col>
              </v-row>
            </div>
            <div v-if="tab.id === 1" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.contactPerson') }}
                    <span class="font-red">{{ rules.contact.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.contact" :rules="rules.contact" :label="$t('supp.lbl.contactPerson')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.mobileNo') }}
                    <span class="font-red">{{ rules.mobile.required ? '*' : '' }}</span>
                  </label>
                  <MobileNo
                    v-model="displayPhone"
                    class="flex-grow-1"
                    :label="$t('supp.lbl.mobileNo')"
                    :placeholder="$t('supp.lbl.mobileNo')"
                    :disabled="false"
                    :dial-code.sync="form.dialCode"
                    :country-code.sync="mobileCountryCode"
                    :rules="rules.mobile"
                    @getPhoneFormat="form.mobile = $event"
                  />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.fax') }}
                    <span class="font-red">{{ rules.fax.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.fax" :rules="rules.fax" :label="$t('supp.lbl.fax')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.email') }}
                    <span class="font-red">{{ rules.email.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.email" :rules="rules.email" :label="$t('supp.lbl.email')" />
                </v-col>
              </v-row>

              <!-- <v-divider class="mt-3 my-6"></v-divider>

              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label">{{ $t('supp.lbl.contactPerson2') }}
                    <span class="font-red">{{ rules.contactPerson2.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.contactPerson2" :rules="rules.contactPerson2" :label="$t('supp.lbl.contactPerson2')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label">{{ $t('supp.lbl.email') }}
                    <span class="font-red">{{ rules.email2.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.email2" :rules="rules.email2" :label="$t('supp.lbl.email')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label">{{ $t('supp.lbl.tel') }}
                    <span class="font-red">{{ rules.tel2.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.tel2" :rules="rules.tel2" :label="$t('supp.lbl.tel')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label">{{ $t('supp.lbl.fax') }}
                    <span class="font-red">{{ rules.fax2.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.fax2" :rules="rules.fax2" :label="$t('supp.lbl.fax')" />
                </v-col>
              </v-row> -->
            </div>
            <div v-if="tab.id === 2" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('supp.lbl.pymtTerm') }}
                    <span class="font-red">{{ rules.payTerm.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.payTerm" :rules="rules.payTerm" :label="$t('supp.lbl.pymtTerm')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('lbl.remarks') }}
                    <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn v-if="allowActivate" :loading="loading" class="btn-orange" @click="setStatus(actEnum.ACTIVATE)">{{ $t('act.activate') }}</AppBtn>
      <AppBtn v-if="allowSuspend" :loading="loading" class="btn-red" @click="setStatus(actEnum.SUSPEND)">{{ $t('act.suspend') }}</AppBtn>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Countries from '@/util/countries';
import { supplierApi } from '@/api/supplier';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import { COMMON_STATUS } from '@/common/enum';

export default {
  name: 'SupplierDetails',
  props: {
    id: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.id) ? this.$t('supp.title.updateSupplier') : this.$t('supp.title.createSupplier'),
      actEnum: {
        ACTIVATE: 1,
        SUSPEND: 2,
      },
      selectedTab: 0,
      tabs: this.$t('supplierTabs'),
      displayPhone: '',
      mobileCountryCode: '',
      currentStatus: null,
      newStatus: null,
      form: {
        address: '',
        address2: '',
        address3: '',
        shortCode: '',
        regNo: '',
        country: 'MY',
        taxNo: '',
        name: '',
        city: '',
        postcode: '',
        shortName: '',
        province: '',

        dialCode: '',
        contact: '',
        mobile: '',
        fax: '',
        email: null,
        // contactPerson2: '',
        // tel2: '',
        // fax2: '',
        // email2: '',
        notes: '',
        payTerm: '',
      },
      rules: {
        shortCode: { required: true },
        name: { required: true },
        shortName: { required: false },
        regNo: { required: false },
        taxNo: { required: false },
        address: { required: false },
        address2: { required: false },
        address3: { required: false },
        province: { required: false },
        city: { required: false },
        postcode: { required: false },
        country: { required: true },

        contact: { required: false },
        mobile: { required: false },
        fax: { required: false },
        email: { required: false, email: true },

        notes: { required: false },
        payTerm: { required: false },
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      provinceList: state => state.lookup.provinceList,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.id) ? true : false;
    },
    allowSuspend() {
      return this.isUpdate && (this.currentStatus == COMMON_STATUS.PENDING || this.currentStatus == COMMON_STATUS.ACTIVE);
    },
    allowActivate() {
      return this.isUpdate && (this.currentStatus == COMMON_STATUS.PENDING || this.currentStatus == COMMON_STATUS.SUSPENDED);
    },
  },
  created() {
    this.fetchProvinceList({ country: this.form.country });
    if (!isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    }
  },
  methods: {
    ...mapActions(['fetchProvinceList']),
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
      };
      data.email = !this.isUndefinedNullOrEmpty(data.email) ? data.email : null;
      if (this.isUpdate) {
        data.id = this.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    async setStatus(val) {
      let data = {}
      data.id = this.id;
      data.status = val;
      this.update(data);
    },
    create(data) {
      this.loading = true;
      return supplierApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return supplierApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return supplierApi.detail({ id }).then(res => {
        this.form = res.data;
        this.currentStatus = this.form.status;
        this.displayPhone = res.data.dialCode + res.data.mobile;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
