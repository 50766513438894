<template>
  <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <div class="mx-2 my-2 font-weight-medium">{{ getUsername }}</div>
      <template v-for="(p, i) in profile">
        <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />

        <app-bar-item v-else class="dropdown-item-wrap" :key="`item-${i}`">
          <!-- to="/" -->
          <v-list-item-title class="dropdown-item" @click="onClick(p.type)" v-text="p.title" />
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'DefaultAccount',
  data() {
    return {
      profile: [
        // { type: '', title: 'Profile' },
        // { type: '', title: 'Settings' },
        { divider: true },
        // { type: 'LOGIN', title: this.$t('login.act.login') },
        { type: 'LOGOUT', title: this.$t('login.act.logout') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getUsername']),
  },
  methods: {
    ...mapActions(['logout_act']),
    onClick(type) {
      if (!type) {
        return;
      }
      switch (type) {
        // case 'LOGIN':
        //   this.login_act({ username: 'pasar', password: 'secret' });
        //   break;
        case 'LOGOUT':
          this.logout_act();
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-item-wrap {
  padding: 0;
}
.dropdown-item {
  padding: 15px 8px;
}
</style>