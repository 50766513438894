var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('app-card',[_c('v-card-title',[_c('div',{staticClass:"grid-wrap"},[_c('TextField',{staticClass:"mb-2 mb-sm-0 mr-0 mr-sm-2",attrs:{"placeholder":_vm.$t('lbl.search')},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('Select',{staticClass:"mb-2 mb-sm-0 mr-0 mr-sm-2",attrs:{"clearable":false,"options":_vm.selectOpts,"placeholder":_vm.$t('lbl.select')},on:{"change":_vm.selectedJobGrpChg},model:{value:(_vm.selectedJobGrp),callback:function ($$v) {_vm.selectedJobGrp=$$v},expression:"selectedJobGrp"}})],1),_c('div',{staticClass:"text-right",class:{ 'w-100': _vm.isMobileView }},[_c('AppBtn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.navigatePush('ProductionCreate')}}},[_vm._v(_vm._s(_vm.$t('production.act.addNewJob')))])],1)]),_c('DataTable',{class:'cursor-pointer',attrs:{"headers":_vm.headers,"items":_vm.data,"mobile-breakpoint":0,"hide-default-header":_vm.hideHeader,"pagination-config":_vm.pagination},on:{"update:paginationConfig":function($event){_vm.pagination=$event},"update:pagination-config":function($event){_vm.pagination=$event},"click:row":_vm.viewDetails,"pageChg":_vm.pageChg,"pageSizeChg":_vm.pageSizeChg},scopedSlots:_vm._u([{key:"item-column-1-xs",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-medium font-15"},[_vm._v(_vm._s(item.jobNo))]),_c('div',[_vm._v(_vm._s(_vm.$t('production.lbl.productName'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.product))])]),_c('div',[_vm._v(_vm._s(_vm.$t('production.lbl.jobType'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.typeName))])]),_c('div',[_vm._v(_vm._s(_vm.$t('production.lbl.qty'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(((item.qty) + " " + (item.uom))))]),_vm._v(" "+_vm._s((", " + (_vm.$t('production.lbl.totalBatch'))))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.totBatch))])]),_c('div',[_vm._v(_vm._s(_vm.$t('production.lbl.execDate'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.jobDate) ? _vm.setTimeZone(item.jobDate) : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('lbl.status'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.$t('map.jobStatus')[item.status]))])])])]}},{key:"item-column-act-xs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.status == _vm.JOB_STATUS.COMPLETED || item.status == _vm.JOB_STATUS.COMPLETED_VARIANCE)?_c('IconWrap',{staticClass:"mr-2 mb-1",attrs:{"icon":'mdi-printer',"disabled":item.printLoading},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.report(item)}}}):_vm._e(),_c('AppBtn',{staticClass:"mr-2",attrs:{"small":"","height":"32"},on:{"click":function($event){$event.stopPropagation();return _vm.goToPrintLabel(item)}}},[_vm._v(_vm._s(_vm.$t('production.act.printLabel')))])],1)]}},{key:"item-qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.qty) + " " + (item.uom)))+" ")]}},{key:"item-soNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.soNo) ? item.soNo : '-')+" ")]}},{key:"item-jobDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.jobDate) ? _vm.setTimeZone(item.jobDate) : '-')+" ")]}},{key:"item-status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('map.jobStatus')[item.status])+" ")]}},{key:"item-notify",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.notify ? 'green' : 'red'}},[_vm._v(_vm._s(item.notify ? 'mdi-check-bold' : 'mdi-close-thick'))])]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.status == _vm.JOB_STATUS.COMPLETED || item.status == _vm.JOB_STATUS.COMPLETED_VARIANCE)?_c('IconWrap',{staticClass:"mr-2",attrs:{"icon":'mdi-printer',"disabled":item.printLoading},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.report(item)}}}):_vm._e(),_c('AppBtn',{staticClass:"mr-2",attrs:{"small":"","height":"32"},on:{"click":function($event){$event.stopPropagation();return _vm.goToPrintLabel(item)}}},[_vm._v(_vm._s(_vm.$t('production.act.printLabel')))])],1)]}}])},[_vm._v(" --> ")])],1)],1),_c('ConfirmModal',{attrs:{"confirm-action-loading":_vm.confirmActionLoading,"header-title":_vm.confirmModalTitle,"modal-size":'modal-xs',"confirm-msg":_vm.confirmMsg,"show-modal":_vm.confirmModalShow},on:{"update:showModal":function($event){_vm.confirmModalShow=$event},"update:show-modal":function($event){_vm.confirmModalShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }