<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-icon class="mr-1">mdi-information</v-icon>
      <span class="text-caption mr-2">{{ $t('item.lbl.manageItemOnList') }}</span>
      <AppBtn color="primary" height="30" small :disabled="!poId" @click="openModal">{{ $t('item.act.addItem') }}</AppBtn>
    </div>
    <DataTable
      :class="'cursor-pointer'"
      :headers="headers"
      :items="data"
      :mobile-breakpoint="0"
      :hide-default-header="hideHeader"
      :hide-default-footer="true"
      :pagination-config.sync="pagination"
      disable-pagination
      @click:row="editItem"
      @pageChg="pageChg"
      @pageSizeChg="pageSizeChg"
    >
      <template #item-column-1-xs="{ item }">
        <div>
          <div class="font-weight-medium font-15">{{ item.product }}</div>
          <div>{{ $t('item.lbl.code') }}: <span class="font-weight-regular">{{ item.sku }}</span></div>
          <div>{{ $t('item.lbl.taxCode') }}: <span class="font-weight-regular">{{ item.taxCode }}</span></div>
          <div>{{ $t('item.lbl.quantity') }}: <span class="font-weight-regular">{{ `${item.qty} ${item.uom}` }}</span></div>
          <div>{{ $t('item.lbl.unitPrice') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.cost) ? priceFormat(item.cost) : '-' }}</span></div>
          <div>{{ $t('item.lbl.subtotal') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.subtotal) ? priceFormat(item.subtotal) : '-' }}</span></div>
          <div>{{ $t('item.lbl.salesTax') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.taxAmt) ? priceFormat(item.taxAmt) : '-' }}</span></div>
          <div>{{ $t('item.lbl.subtotalInclTax') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.subtotalInclTax) ? priceFormat(item.subtotalInclTax) : '-' }}</span></div>
        </div>
      </template>
      <template #item-column-act-xs="{ item }">
        <div class="d-flex justify-end">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click.stop="showConfirmModal(item)">
            mdi-delete
          </v-icon>
        </div>
      </template>

      <template #item-qty="{ item }">
        {{ `${item.qty} ${item.uom}` }}
      </template>
      <template #item-cost="{ item }">
        {{ !isUndefinedNullOrEmpty(item.cost) ? priceFormat(item.cost) : '-' }}
      </template>
      <template #item-subtotal="{ item }">
        {{ !isUndefinedNullOrEmpty(item.subtotal) ? priceFormat(item.subtotal) : '-' }}
      </template>
      <template #item-taxAmt="{ item }">
        {{ !isUndefinedNullOrEmpty(item.taxAmt) ? priceFormat(item.taxAmt) : '-' }}
      </template>
      <template #item-subtotalInclTax="{ item }">
        {{ !isUndefinedNullOrEmpty(item.subtotalInclTax) ? priceFormat(item.subtotalInclTax) : '-' }}
      </template>
      <template #item-actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click.stop="showConfirmModal(item)">
            mdi-delete
          </v-icon>
        </div>
      </template>
    </DataTable>
    <CreateModal
      v-if="createModalShow"
      :po-id="poId"
      :po-item-id="poItemId"
      :item-ddl="itemDdl"
      :uom="uom"
      :show-modal.sync="createModalShow"
      @close="closeModal"
      @getList="getList"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { purchaseOrderItemApi } from '@/api/purchase-order';
import { productPriceApi } from '@/api/product';
import { isUndefinedNullOrEmpty, roundToDecimal, priceFormat } from '@/util/tools';
import CreateModal from './components/CreateModal';

export default {
  components: {
    CreateModal,
  },
  props: {
    poId: {
      type: Number,
      default: null,
    },
    supplierId: {
      type: Number,
      default: null,
    },
    uom: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('item.title.deleteItem'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        page: 1,
        pageSize: 100,
        total: 100,
      },
      searchVal: '',
      createModalShow: false,
      data: [],
      itemDdl: null,
      poItemId: null,
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          { text: '#', width: '30', value: 'no' },
          { text: this.$t('item.lbl.code'), width: '110', value: 'sku' },
          { text: this.$t('item.lbl.itemDesc'), width: '200', value: 'product' },
          { text: this.$t('item.lbl.quantity'), width: '80', value: 'qty' },
          { text: this.$t('item.lbl.taxCode'), width: '80', value: 'taxCode' },
          { text: this.$t('item.lbl.unitPrice'), width: '120', value: 'cost' },
          { text: this.$t('item.lbl.subtotal'), width: '120', value: 'subtotal' },
          { text: this.$t('item.lbl.salesTax'), width: '120', value: 'taxAmt' },
          { text: this.$t('item.lbl.subtotalInclTax'), width: '160', value: 'subtotalInclTax' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
    priceFormat() {
      return priceFormat;
    }
  },
  created() {
    this.pagination = { ...this.pagination };
    if (!isUndefinedNullOrEmpty(this.poId)) {
      this.getList();
    }
    this.getItemsBySupplier();
  },
  methods: {
    editItem(item) {
      this.poItemId = item.id;
      this.createModalShow = true;
    },
    delete(id) {
      this.confirmActionLoading = true;
      const params = {
        id,
        orderId: this.poId,
      };
      return purchaseOrderItemApi.delete(params).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getList() {
      const params = {
        orderId: this.poId,
        orderBy: '',
        page: 1,
        pageSize: -1,
      };
      return purchaseOrderItemApi.list(params).then(res => {
        const data = res.data.map((item, index) => {
          const subtotal = roundToDecimal(item.qty * item.cost);
          const taxAmt = roundToDecimal((item.qty * item.cost * item.tax) / 100);
          return {
            ...item,
            no: index + 1,
            subtotal,
            taxAmt,
            subtotalInclTax: subtotal + taxAmt,
          };
        });
        this.data = data;
      });
    },
    getItemsBySupplier() {
      const params = {
        supplierId: this.supplierId,
        orderBy: '',
        page: 1,
        pageSize: -1,
      };
      return productPriceApi.list(params).then(res => {
        const itemDdl = res.data.map(item => {
          return {
            ...item,
            text: item.item,
            value: item.id,
          };
        });
        this.itemDdl = itemDdl;
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
    openModal() {
      this.createModalShow = true;
    },
    closeModal() {
      this.createModalShow = false;
      this.poItemId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 18px;
}
</style>
