<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title>
          <TextField v-model="searchVal" class="mr-2" :hide-details="true" :placeholder="$t('lbl.search')" />
          <AppBtn color="primary" @click="createModalShow = true">{{ $t('warehouse.act.addWarehouse') }}</AppBtn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
        <v-data-table
          class="cursor-pointer"
          disable-sort
          :headers="headers"
          :items="data"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.pageSize"
          :footer-props="{
            itemsPerPageOptions: pageSizeOpts,
          }"
          :server-items-length="pagination.total"
          @update:page="pageChg"
          @update:items-per-page="pageSizeChg"
          @click:row="editItem"
        >
          <template v-slot:item.type="{ item }">
            {{ $t('map.productType')[item.type] }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ $t('map.status')[item.status] }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-end">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click.stop="showConfirmModal(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </app-card>
    </v-col>
    <CreateModal
      v-if="createModalShow"
      :id="id"
      :show-modal.sync="createModalShow"
      @close="closeModal"
      @getList="getListNTotalRec"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import CreateModal from './components/CreateModal';
import { warehouseApi } from '@/api/warehouse';
import { mapState } from 'vuex';

export default {
  name: 'WarehouseList',
  components: {
    CreateModal,
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('warehouse.title.deleteWarehouse'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        total: 0,
      },
      searchVal: '',
      createModalShow: false,
      headers: [
        { text: '#', width: '50', value: 'no' },
        { text: this.$t('warehouse.lbl.branch'), width: '150', value: 'outlet' },
        { text: this.$t('warehouse.lbl.code'), width: '150', value: 'shortCode' },
        { text: this.$t('warehouse.lbl.desc'), width: '250', value: 'name' },
        { text: this.$t('warehouse.lbl.warehouseType'), width: '150', value: 'type' },
        { text: this.$t('lbl.status'), width: '120', value: 'status' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      data: [],
      id: null,
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getListNTotalRec();
  },
  methods: {
    closeModal() {
      this.createModalShow = false;
      this.id = null;
    },
    editItem(item) {
      this.id = item.id;
      this.createModalShow = true;
    },
    delete(id) {
      this.confirmActionLoading = true;
      return warehouseApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        this.getList();
      });
    },
    getTotalRec() {
      const params = {
        orgId: null,
        orderBy: '',
        page: 0,
      };
      return warehouseApi.list(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        orgId: null,
        orderBy: '',
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return warehouseApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
