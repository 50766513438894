var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-caption mr-2"},[_vm._v(_vm._s(_vm.$t('uom.lbl.addUomForItem')))]),_c('AppBtn',{attrs:{"color":"primary","height":"30","small":"","disabled":!_vm.productId},on:{"click":_vm.openModal}},[_vm._v(_vm._s(_vm.$t('uom.act.addNewUom')))])],1),_c('v-data-table',{staticClass:"bordered",attrs:{"disable-sort":"","headers":_vm.filteredHeaders,"items":_vm.data,"page":_vm.pagination.page,"items-per-page":_vm.pagination.pageSize,"footer-props":{
      itemsPerPageOptions: _vm.pageSizeOpts,
    },"server-items-length":_vm.pagination.total,"hide-default-footer":""},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.pageChg],"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},_vm.pageSizeChg]},scopedSlots:_vm._u([{key:"item.isBase",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.isBase ? 'green' : 'red'}},[_vm._v(_vm._s(item.isBase ? 'mdi-check-bold' : 'mdi-close-thick'))])]}},{key:"item.isStock",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.isStock ? 'green' : 'red'}},[_vm._v(_vm._s(item.isStock ? 'mdi-check-bold' : 'mdi-close-thick'))])]}},{key:"item.isOrder",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.isOrder ? 'green' : 'red'}},[_vm._v(_vm._s(item.isOrder ? 'mdi-check-bold' : 'mdi-close-thick'))])]}},{key:"item.isSale",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.isSale ? 'green' : 'red'}},[_vm._v(_vm._s(item.isSale ? 'mdi-check-bold' : 'mdi-close-thick'))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('map.status')[item.status])+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmModal(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])}),(_vm.createModalShow)?_c('CreateModal',{attrs:{"id":_vm.id,"product-id":_vm.productId,"product-type":_vm.productType,"show-modal":_vm.createModalShow},on:{"update:showModal":function($event){_vm.createModalShow=$event},"update:show-modal":function($event){_vm.createModalShow=$event},"close":_vm.closeModal,"getList":_vm.getList}}):_vm._e(),_c('ConfirmModal',{attrs:{"confirm-action-loading":_vm.confirmActionLoading,"header-title":_vm.confirmModalTitle,"modal-size":'modal-xs',"confirm-msg":_vm.confirmMsg,"show-modal":_vm.confirmModalShow},on:{"update:showModal":function($event){_vm.confirmModalShow=$event},"update:show-modal":function($event){_vm.confirmModalShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }