<template>
  <component :is="getComponent" />
</template>
<script>
import QCList from './List';
import QCCreate from './Create';
export default {
  components: {
    QCList,
    QCCreate
  },
  computed: {
    getComponent() {
      switch(this.$route.name) {
        case 'QCList':
          return QCList;
        case 'QCCreate':
          return QCCreate;
        default:
          return QCList;
      }
    },
  }
}
</script>