import { isUndefinedNullOrEmpty } from '@/util/tools';
import { lookupApi } from '@/api/lookup';

const compareStrings = (a, b) => {
  // Case insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();
  // negative values will be sorted towards left, positive values will be sorted towards right
  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

const lookup = {
  state: {
    selectedProvinceCountry: '',
    provinceList: [],
    rawMatCategoryList: [],
    productCertTypeList: [],
    productCertTypeMap: {},
    timeZoneList: [],
    timeZoneMap: {},
    uomList: [],
    uomMap: {},
    stockTxTypeList: [],
    stockTxTypeMap: {},
  },
  getters: {
    // getTimeZoneList: state => state.timeZoneList,
  },
  mutations: {
    SET_PROVINCE_COUNTRY(state, payload) {
      state.selectedProvinceCountry = payload;
    },
    SET_PROVINCE_LIST(state, payload) {
      state.provinceList = payload;
    },
    SET_RAW_MAT_CATEGORY_LIST(state, payload) {
      state.rawMatCategoryList = payload;
    },
    SET_PRODUCT_CERT_TYPE_LIST(state, payload) {
      state.productCertTypeList = payload;
    },
    SET_PRODUCT_CERT_TYPE_MAP(state, payload) {
      state.productCertTypeMap = payload;
    },
    SET_TIMEZONE_LIST(state, payload) {
      state.timeZoneList = payload;
    },
    SET_TIMEZONE_MAP(state, payload) {
      state.timeZoneMap = payload;
    },
    SET_UOM_LIST(state, payload) {
      state.uomList = payload;
    },
    SET_UOM_MAP(state, payload) {
      state.uomMap = payload;
    },
    SET_STOCK_TX_TYPE_LIST(state, payload) {
      state.stockTxTypeList = payload;
    },
    SET_STOCK_TX_TYPE_MAP(state, payload) {
      state.stockTxTypeMap = payload;
    },
  },
  actions: {
    fetchProvinceList({ commit, state }, params = {}) {
      if (state.selectedProvinceCountry == params.country) {
        if (!isUndefinedNullOrEmpty(state.provinceList)) {
          return state.provinceList;
        }
      }
      return lookupApi.province(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.shortName,
            value: item.province,
          });
        }
        commit('SET_PROVINCE_COUNTRY', params.country);
        commit('SET_PROVINCE_LIST', list);
      });
    },
    fetchRawMatCategoryList({ commit }) {
      return lookupApi.rawMatCategory().then(res => {
        let list = [];
        let data = res.data;
        data.sort((a, b) => {
          return compareStrings(a.name, b.name);
        });
        for (let item of data) {
          list.push({
            text: item.name,
            value: item.name,
          });
        }

        commit('SET_RAW_MAT_CATEGORY_LIST', list);
      });
    },
    fetchProductCertTypeList({ commit }, params = {}) {
      return lookupApi.certType(params).then(res => {
        let list = [];
        let map = {};
        for (let item of res.data) {
          list.push({
            text: item.code,
            value: item.id,
          });
          map[item.id] = item.code;
        }
        commit('SET_PRODUCT_CERT_TYPE_LIST', list);
        commit('SET_PRODUCT_CERT_TYPE_MAP', map);
      });
    },
    fetchTimeZoneList({ commit }, params = {}) {
      return lookupApi.timezone(params).then(res => {
        let list = [];
        let map = {};
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
          map[item.id] = item.name;
        }
        commit('SET_TIMEZONE_LIST', list);
        commit('SET_TIMEZONE_MAP', map);
      });
    },
    fetchUomList({ commit }, params = {}) {
      return lookupApi.uom(params).then(res => {
        let list = [];
        let map = {};
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.name,
          });
          map[item.name] = item.name;
        }
        commit('SET_UOM_LIST', list);
        commit('SET_UOM_MAP', map);
      });
    },
    fetchStockTxType({ commit }, params = {}) {
      return lookupApi.stockTxType(params).then(res => {
        let list = [];
        let map = {}
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
          map[item.id] = item.name;
        }
        commit('SET_STOCK_TX_TYPE_LIST', list);
        commit('SET_STOCK_TX_TYPE_MAP', map);
      });
    }
  },
};

export default lookup;
