import i18n from '@/translations';
import { MENU_TYPE } from '@/common/enum.js';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import AppStorage from '@/plugins/storage/index.js';

const app = {
  state: {
    drawer: null,
    drawerImage: false,
    mini: true,
    items: [
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.dashboard'),
        icon: 'fa-lg fa-pie-chart',
        to: '/dashboard',
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: 'Sales',
        icon: 'mdi-finance',
        to: '/',
        items: [
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.salesOrderList'),
            icon: 'fa-dot-circle-o',
            to: '/sales-order/list',
          },
        ],
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.procurement'),
        icon: 'mdi-cart',
        to: '/',
        items: [
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.purchaseOrder'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.purchaseOrderList'),
            icon: 'fa-dot-circle-o',
            to: '/purchase-order/list',
          },
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.goodsReceive'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.grnList'),
            icon: 'fa-dot-circle-o',
            to: '/grn/list',
          },
        ],
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.production'),
        icon: 'mdi-briefcase-variant-outline',
        to: '/',
        items: [
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.production'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.productionDashboard'),
            icon: 'fa-dot-circle-o',
            to: '/job/dashboard',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.productionListing'), 
            icon: 'fa-dot-circle-o',
            to: '/job/list',
          },
        ],
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.inventory'),
        icon: 'mdi-cube-outline',
        to: '/',
        items: [
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.rawMat'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.rawMatInventory'),
            icon: 'fa-dot-circle-o',
            to: '/inventory/raw-materials/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.semiFGoodsInventory'),
            icon: 'fa-dot-circle-o',
            to: '/inventory/semi-fgs/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.fGoodsInventory'),
            icon: 'fa-dot-circle-o',
            to: '/inventory/goods/list',
          },
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.lblPrinting'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.rawMatLbl'),
            icon: 'fa-dot-circle-o',
            to: '/raw-materials/labels/print',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.semiFGLbl'),
            icon: 'fa-dot-circle-o',
            to: '/semi-fgs/labels/print',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.fGoodsLbl'),
            icon: 'fa-dot-circle-o',
            to: '/goods/labels/print',
          },
        ],
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.knowledge'),
        icon: 'mdi-book-open-blank-variant',
        to: '/',
        items: [
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.recipeMgmt'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.manageCategories'),
            icon: 'fa-dot-circle-o',
            to: '/recipes/categories/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.manageRecipes'),
            icon: 'fa-dot-circle-o',
            to: '/recipes/category-list',
          },
        ],
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.qcAndReplenish'),
        icon: 'mdi-clipboard-text-search',
        to: '/',
        items: [
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.recipeMgmt'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.qc'),
            icon: 'fa-dot-circle-o',
            to: '/qc/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.replenish'),
            icon: 'fa-dot-circle-o',
            to: '/replenish',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.stockOrder'),
            icon: 'fa-dot-circle-o',
            to: '/stock-order',
          },
        ],
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('menu.lbl.settings'),
        icon: 'mdi-cog-outline',
        to: '/',
        items: [
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.maintenance'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.suppliers'),
            icon: 'fa-dot-circle-o',
            to: '/maintenance/suppliers/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.rawMat'),
            icon: 'fa-dot-circle-o',
            to: '/maintenance/raw-materials/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.semiFinishedGoods'),
            icon: 'fa-dot-circle-o',
            to: '/maintenance/semi-fgs/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.fGoods'),
            icon: 'fa-dot-circle-o',
            to: '/maintenance/goods/list',
          },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.warehouses'),
            icon: 'fa-dot-circle-o',
            to: '/maintenance/warehouses/list',
          },
          // {
          //   type: MENU_TYPE.SUB_SECTION,
          //   title: i18n.t('menu.lbl.sysSettings'),
          //   icon: 'fa-circle',
          // },
          {
            type: MENU_TYPE.LOWEST_LAYER,
            title: i18n.t('menu.lbl.branches'),
            icon: 'fa-dot-circle-o',
            to: '/maintenance/branches/list',
          },
        ],
      },
      // {
      //   title: 'User Profile',
      //   icon: 'mdi-account',
      //   to: '/components/profile/',
      // },
      // {
      //   title: 'Regular Tables',
      //   icon: 'mdi-clipboard-outline',
      //   to: '/tables/regular/',
      // },
      // {
      //   title: 'Suppliers',
      //   icon: 'mdi-format-font',
      //   to: '/components/suppliers/',
      // },
      // {
      //   title: 'Branches',
      //   icon: 'mdi-chart-bubble',
      //   to: '/components/icons/',
      // },
      // {
      //   title: 'Raw Materials',
      //   icon: 'mdi-map-marker',
      //   to: '/maps/google/',
      // },
      // {
      //   title: 'Notifications',
      //   icon: 'mdi-bell',
      //   to: '/components/notifications/',
      // },
    ],
    currentLang: {},
    pageSizeOpts: [5, 10, 15, 20, 50],
    paginationOpts: {
      page: 1,
      pageSize: 15,
    },
    dateFormat: 'YYYY-MM-DD',
    defaultPageAfterLogin: 'Dashboard',
    notiBadgeLastId: null,
    notiBadgeShow: null,
    imgCompressTo: 0.5, // in MB
    imgMaxLimit: 512000, // in byte. Limit: 500kB
  },
  getters: {
    getCurrentLang() {
      let lang = AppStorage.local.get('lang');
      if (!isUndefinedNullOrEmpty(lang)) {
        return JSON.parse(lang);
      } else {
        lang = JSON.stringify({ code: 'en_US', name: 'English' });
        AppStorage.local.set('lang', lang);
        return lang;
      }
    },
    getNotiBadgeLastId: state => {
      if (!isUndefinedNullOrEmpty(state.notiBadgeLastId)) {
        return state.notiBadgeLastId;
      } else {
        const notiBadgeLastId = AppStorage.local.get('notiBadgeLastId');
        return !isUndefinedNullOrEmpty(notiBadgeLastId) ? Number(notiBadgeLastId) : null;
      }
    },
    getNotiBadgeShow: state => {
      if (!isUndefinedNullOrEmpty(state.notiBadgeShow)) {
        return state.notiBadgeShow;
      } else {
        const notiBadgeShow = AppStorage.local.get('notiBadgeShow');
        return notiBadgeShow == 'true' ? true : false;
      }
    },
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_DRAWER_IMG(state, payload) {
      state.drawerImage = payload;
    },
    SET_MINI(state, payload) {
      state.mini = payload;
    },
    SET_NOTI_BADGE_LAST_ID(state, payload) {
      state.notiBadgeLastId = payload;
      AppStorage.local.set('notiBadgeLastId', payload);
    },
    SET_NOTI_BADGE_SHOW(state, payload) {
      state.notiBadgeShow = payload;
      AppStorage.local.set('notiBadgeShow', payload);
    },
  },
  actions: {},
};

export default app;
