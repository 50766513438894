import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const deptApi = {
  ddl(params) {
    return Api.get('dept/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('dept/list', params);
  },
  detail(params) {
    return Api.get('dept', params);
  },
  create(data) {
    return Api.post('dept/create', data);
  },
  update(data) {
    return Api.post('dept/update', data);
  },
  delete(data) {
    return Api.post('dept/delete', data);
  },
};
