import { isUndefinedNullOrEmpty } from '@/util/tools';
import { warehouseApi } from '@/api/warehouse';

const warehouse = {
  state: {
    warehouseDdl: [],
  },
  getters: {},
  mutations: {
    SET_WAREHOUSE_DDL(state, payload) {
      state.warehouseDdl = payload;
    },
  },
  actions: {
    fetchWarehouseDdl({ commit }, params = {}) {
      return warehouseApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        commit('SET_WAREHOUSE_DDL', list);
      });
    },
  },
};

export default warehouse;
