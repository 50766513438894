<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title>
          <TextField v-model="searchVal" class="mr-2" :hide-details="true" :placeholder="$t('lbl.search')" />
          <AppBtn color="primary" @click="createModalShow = true">{{ $t('semiFGoods.act.createSemiFGoods') }}</AppBtn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
        <DataTable
          :class="'cursor-pointer'"
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :pagination-config.sync="pagination"
          @click:row="editItem"
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-15">{{ item.name }}</div>
              <div>{{ $t('rawMat.lbl.code') }}: <span class="font-weight-regular">{{ item.sku }}</span></div>
              <div>{{ $t('rawMat.lbl.category') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.category) ? item.category : '-' }}</span></div>
              <div>{{ $t('rawMat.lbl.subCategory') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.subCat) ? item.subCat : '-' }}</span></div>
              <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.status')[item.status] }}</span></div>
            </div>
          </template>
          <template #item-column-act-xs="{ item }">
            <div class="d-flex justify-end">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click.stop="showConfirmModal(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>

          <template #item-category="{ item }">
            {{ !isUndefinedNullOrEmpty(item.category) ? item.category : '-' }}
          </template>
          <template #item-subCat="{ item }">
            {{ !isUndefinedNullOrEmpty(item.subCat) ? item.subCat : '-' }}
          </template>
          <template #item-status="{ item }">
            {{ $t('map.status')[item.status] }}
          </template>
          <template #item-actions="{ item }">
            <div class="d-flex justify-end">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click.stop="showConfirmModal(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </DataTable>
      </app-card>
    </v-col>
    <CreateModal
      v-if="createModalShow"
      :id="id"
      :show-modal.sync="createModalShow"
      @close="closeModal"
      @getList="getListNTotalRec"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import CreateModal from './components/CreateModal';
import { semiFGoodsApi } from '@/api/product';
import { mapState } from 'vuex';

export default {
  name: 'SemiFGoodsList',
  components: {
    CreateModal,
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('semiFGoods.title.deleteSemiFGoods'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        total: 0,
      },
      searchVal: '',
      createModalShow: false,
      data: [],
      id: null,
      timeout: null,
      initialLoad: null,
      initialRecObj: {
        total: 0,
        page: 1,
        pageSize: 10,
        data: [],
      }
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('semiFGoods.lbl.code'), width: '180', value: 'sku' },
          { text: this.$t('semiFGoods.lbl.desc'), width: '300', value: 'name' },
          { text: this.$t('semiFGoods.lbl.category'), width: '200', value: 'category' },
          { text: this.$t('semiFGoods.lbl.subCategory'), width: '150', value: 'subCat' },
          { text: this.$t('lbl.status'), width: '120', value: 'status' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
  },
  watch: {
    searchVal(val) {
      clearTimeout(this.timeout);
      if (val.length >= 3) {
        this.timeout = setTimeout(() => {
          this.pagination.page = 1;
          this.getListNTotalRec();
        }, 500);
      } else if (this.isUndefinedNullOrEmpty(val)) {
        this.pagination.page = this.initialRecObj.page;
        this.pagination.total = this.initialRecObj.total;
        if (this.pagination.pageSize == this.initialRecObj.pageSize) {
          this.data = this.initialRecObj.data;
        } else {
          this.getListNTotalRec();
        }
      }
    }
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.initialLoad = true;
    this.getListNTotalRec();
  },
  methods: {
    closeModal() {
      this.createModalShow = false;
      this.id = null;
    },
    editItem(item) {
      this.id = item.id;
      this.createModalShow = true;
    },
    delete(id) {
      this.confirmActionLoading = true;
      return semiFGoodsApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        if (this.pagination.total > 0) {
          this.getList();
        }
      });
    },
    getTotalRec() {
      const params = {
        orgId: null,
        orderBy: '',
        name: this.searchVal,
        page: 0,
      };
      return semiFGoodsApi.list(params).then(res => {
        this.pagination.total = res.data.total;
        if (this.initialLoad) {
          this.initialRecObj.total = this.pagination.total;
        }
      });
    },
    getList() {
      const params = {
        orgId: null,
        orderBy: '',
        name: this.searchVal,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return semiFGoodsApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
        if (this.initialLoad) {
          this.initialRecObj.page = this.pagination.page;
          this.initialRecObj.pageSize = this.pagination.pageSize;
          this.initialRecObj.data = this.data;
          this.initialLoad = false;
        }
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
