<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
    <template v-slot:content>
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.code') }}
                    <span class="font-red">{{ rules.sku.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.sku" :rules="rules.sku" :label="$t('rawMat.lbl.code')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.desc') }}
                    <span class="font-red">{{ rules.name.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.name" :rules="rules.name" :label="$t('rawMat.lbl.desc')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.shortName') }}
                    <span class="font-red">{{ rules.shortName.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.shortName" :rules="rules.shortName" :label="$t('rawMat.lbl.shortName')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.category') }}
                    <span class="font-red">{{ rules.category.required ? '*' : '' }}</span>
                  </label>
                  <Select v-model="form.category" :rules="rules.category" :options="rawMatCategoryList" :label="$t('rawMat.lbl.category')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.subCategory') }}
                    <span class="font-red">{{ rules.subCat.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.subCat" :rules="rules.subCat" :label="$t('rawMat.lbl.subCategory')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('lbl.type') }}
                    <span class="font-red">{{ rules.grp.required ? '*' : '' }}</span>
                  </label>
                  <Select v-model="form.grp" :rules="rules.grp" :label="$t('lbl.type')" :options="$t('rawMatGrp')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('lbl.remarks') }}
                    <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="2">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.forSale') }}
                    <span class="font-red">{{ rules.forSale.required ? '*' : '' }}</span>
                  </label>
                  <SwitchToggle v-model="form.forSale" :label="$t('map.yesNoBool')[form.forSale]" :rules="rules.forSale" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="2">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.lbl.lblPrintOpt') }}
                    <span class="font-red">{{ rules.lblOpt.required ? '*' : '' }}</span>
                  </label>
                  <SwitchToggle
                    v-model="form.lblOpt"
                    :label="$t('map.lblPrintOpt')[form.lblOpt]"
                    :rules="rules.lblOpt"
                    :true-value="1"
                    :false-value="2"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-if="tab.id === 1" class="tab-content-wrap px-2 py-3 px-md-4">
              <Uom :product-id="id" :product-type="PRODUCT_TYPE.RAW" />
            </div>
            <div v-if="tab.id === 2" class="tab-content-wrap px-2 py-3 px-md-4">
              <ProductLevel :product-id="id" :product-type="PRODUCT_TYPE.RAW" :uom="uom" />
            </div>
            <div v-if="tab.id === 4" class="tab-content-wrap px-2 py-3 px-md-4">
              <ProductCert :product-id="id" :uom="uom" />
            </div>
            <div v-if="tab.id === 5" class="tab-content-wrap px-2 py-3 px-md-4">
              <ProductPrice :product-id="id" :uom="uom" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn v-if="allowApprove" :loading="loading" class="btn-green" @click="setStatus(actEnum.ACTIVATE)">{{ $t('act.approve') }}</AppBtn>
      <AppBtn v-if="allowSuspend" :loading="loading" class="btn-red" @click="setStatus(actEnum.SUSPEND)">{{ $t('act.suspend') }}</AppBtn>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import Countries from '@/util/countries';
import ProductLevel from '@/views/ProductLevel';
import ProductPrice from '@/views/ProductPrice';
import ProductCert from '@/views/ProductCert';
import Uom from '@/views/Uom';
import { rawMatApi } from '@/api/product';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import { PRODUCT_TYPE, RAW_MAT_GRP } from '@/common/enum';
import { mapState, mapActions } from 'vuex';
import { COMMON_STATUS } from '@/common/enum';

export default {
  name: 'RawMatDetails',
  components: {
    ProductLevel,
    ProductPrice,
    ProductCert,
    Uom,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.id) ? this.$t('rawMat.title.updateRawMat') : this.$t('rawMat.title.createRawMat'),
      actEnum: {
        ACTIVATE: 1,
        SUSPEND: 2,
      },
      selectedTab: 0,
      tabs: this.$t('rawMatTabs'),
      currentStatus: null,
      form: {
        sku: '',
        name: '',
        shortName: '',
        category: '',
        subCat: '',

        forSale: false,
        lblOpt: 2,
        yield: 100,
        info: null,
        image: null,
        grp: RAW_MAT_GRP.RAW_MAT,
        type: null,
        notes: '',
      },
      categoryList: [],
      rules: {
        sku: { required: true },
        name: { required: true },
        shortName: { required: false },
        category: { required: true },
        subCat: { required: false },
        notes: { required: false },
        forSale: { required: false },
        lblOpt: { required: false },
        grp: { required: true },
      },
      uom: {},
      loading: false,
    };
  },
  computed: {
    ...mapState({
      rawMatCategoryList: state => state.lookup.rawMatCategoryList,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.id) ? true : false;
    },
    allowSuspend() {
      return this.isUpdate && (this.currentStatus == COMMON_STATUS.PENDING || this.currentStatus == COMMON_STATUS.ACTIVE);
    },
    allowApprove() {
      return this.isUpdate && (this.currentStatus == COMMON_STATUS.PENDING || this.currentStatus == COMMON_STATUS.SUSPENDED);
    },
    PRODUCT_TYPE() {
      return PRODUCT_TYPE;
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.rawMatCategoryList)) {
      this.fetchRawMatCategoryList();
    }
    if (!isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    }
  },
  methods: {
    ...mapActions(['fetchRawMatCategoryList']),
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
      };
      if (this.isUpdate) {
        data.id = this.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    async setStatus(val) {
      let data = {}
      data.id = this.id;
      data.status = val;
      this.update(data);
    },
    create(data) {
      this.loading = true;
      return rawMatApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return rawMatApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return rawMatApi.detail({ id }).then(res => {
        this.form = res.data;
        this.currentStatus = this.form.status;
        this.uom = {
          baseUom: this.form.baseUom,
          stkUom: this.form.stkUom,
          orderUom: this.form.orderUom,
          saleUom: this.form.saleUom,
        };
        this.displayPhone = res.data.dialCode + res.data.mobile;
        this.headerTitle = this.headerTitle + ' - ' + this.form.shortName;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
