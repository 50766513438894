var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"bordered",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.data,"page":_vm.pagination.page,"items-per-page":_vm.pagination.pageSize,"footer-props":{
      itemsPerPageOptions: _vm.pageSizeOpts,
    },"server-items-length":_vm.pagination.total,"hide-default-footer":""},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.pageChg],"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},_vm.pageSizeChg]},scopedSlots:_vm._u([{key:"item.par",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('TextField',{attrs:{"type":'number',"min":0,"rules":{},"label":_vm.$t('inventory.lbl.parLvl')},model:{value:(item.par),callback:function ($$v) {_vm.$set(item, "par", $$v)},expression:"item.par"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.parLvlUom))])],1)]}},{key:"item.reorder",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('TextField',{attrs:{"type":'number',"min":0,"rules":{},"label":_vm.$t('inventory.lbl.reOrderLvl')},model:{value:(item.reorder),callback:function ($$v) {_vm.$set(item, "reorder", $$v)},expression:"item.reorder"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.reorderLvlUom))])],1)]}},{key:"item.reorderQty",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('TextField',{attrs:{"type":'number',"min":0,"rules":{},"label":_vm.$t('inventory.lbl.reOrderQty')},model:{value:(item.reorderQty),callback:function ($$v) {_vm.$set(item, "reorderQty", $$v)},expression:"item.reorderQty"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.reorderQtyUom))])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('AppBtn',{attrs:{"text":""},on:{"click":function($event){return _vm.save(item)}}},[_vm._v(_vm._s(_vm.$t('act.save')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }