import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const customerApi = {
  ddl(params) {
    return Api.get('/customer/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('/customer/list', params);
  },
  detail(params) {
    return Api.get('/customer', params);
  },
  create(data) {
    return Api.post('customer/create', data);
  },
  update(data) {
    return Api.post('customer/update', data);
  },
  delete(data) {
    return Api.post('customer/delete', data);
  },
};