<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-icon class="mr-1">mdi-information</v-icon>
      <span class="text-caption mr-2">{{ $t('productCert.lbl.addCert') }}</span>
      <AppBtn color="primary" height="30" small :disabled="!productId" @click="openModal">{{
        $t('productCert.act.addCert')
      }}</AppBtn>
    </div>
    <v-data-table
      class="bordered"
      disable-sort
      :headers="headers"
      :items="data"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.pageSize"
      :footer-props="{
        itemsPerPageOptions: pageSizeOpts,
      }"
      :server-items-length="pagination.total"
      hide-default-footer
      @update:page="pageChg"
      @update:items-per-page="pageSizeChg"
    >
      <template v-slot:item.expiry="{ item }">
        {{ !isUndefinedNullOrEmpty(item.expiry) ? dateOnly(item.expiry) : '-' }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ $t('map.status')[item.status] }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click.stop="showConfirmModal(item)">
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <CreateModal
      v-if="createModalShow"
      :id.sync="id"
      :product-id="productId"
      :uom="uom"
      :show-modal.sync="createModalShow"
      @close="closeModal"
      @getList="getList"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { productCertApi } from '@/api/product';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import CreateModal from './components/CreateModal';

export default {
  components: {
    CreateModal,
  },
  props: {
    productId: {
      type: Number,
      default: null,
    },
    uom: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('productCert.title.deleteCert'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        page: 1,
        pageSize: 100,
        total: 100,
      },
      searchVal: '',
      createModalShow: false,
      headers: [
        { text: '#', width: '30', value: 'no' },
        { text: this.$t('productPrice.lbl.supplier'), width: '250', value: 'supplier' },
        { text: this.$t('productCert.lbl.expiry'), width: '100', value: 'expiry' },
        { text: this.$t('productCert.lbl.certType'), width: '100', value: 'certType' },
        { text: this.$t('productCert.lbl.refNo'), width: '150', value: 'refNo' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      data: [],
      id: null,
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
    }),
  },
  created() {
    this.pagination = { ...this.pagination };
    if (!isUndefinedNullOrEmpty(this.productId)) {
      this.getList();
    }
  },
  methods: {
    editItem(item) {
      this.id = item.id;
      this.createModalShow = true;
    },
    delete(id) {
      this.confirmActionLoading = true;
      return productCertApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getList() {
      const params = {
        productId: this.productId,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return productCertApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
    openModal() {
      this.createModalShow = true;
    },
    closeModal() {
      this.createModalShow = false;
      this.id = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 18px;
}
</style>
