<template>
  <app-card>
    <v-card-title>
      <ValidationObserver ref="observer" slim>
        <div class="w-100">
          <div class="form-row-col__label"
            >{{ $t('qc.lbl.store') }}
          </div>
          <div class="mr-2" :class="{ 'select-field': !isMobileView }">
            <Select
              v-model="form.storeId"
              :label="$t('qc.lbl.store')"
              :options="storeList"
              :rules="rules.storeId"
              :return-obj="true"
              :clearable="false"
              @change="storeChg"
            />
          </div>
          <!-- <AppBtn color="primary" @click="passes(getStoreQcItemList)">{{ $t('act.search') }}</AppBtn> -->
        </div>
      </ValidationObserver>
    </v-card-title>
    <v-card-text v-if="isReady">
      <ValidationObserver ref="observer2" v-slot="{ passes }">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, index) in qcItemList" :key="index">
            <v-expansion-panel-header>
              <div class="d-flex">
                <div class="mr-4">{{ `#${item.seq}` }}</div>
                <div class="font-weight-medium">{{ item.label }}</div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <!-- <div><span class="font-weight-medium">Last Refill:</span> 2020/01/07</div> -->
                <div class="d-flex mb-2">
                  <!-- <div class="action__card mr-1" @click="items[item.itemId].result = 1">
                    <div class="font-weight-medium">{{ 'Approve' }}</div><br>
                    <div class="action__card-items" v-for="(note, index) in item.notes.approve" :key="index">
                      {{ note }}
                    </div>
                  </div>
                  <div class="action__card" @click="items[item.itemId].result = 1">
                    <div class="font-weight-medium">{{ 'Approve' }}</div><br>
                    <div class="action__card-items" v-for="(note, index) in item.notes.approve" :key="index">
                      {{ note }}
                    </div>
                  </div> -->
                  <!-- {{items[item.itemId]}} -->
                  <div
                    class="action__btn action__btn--green cursor-pointer mr-1"
                    :class="{ 'action__btn--active': items[item.itemId].result == resultEnum.APPROVE }"
                    @click="items[item.itemId].result = resultEnum.APPROVE"
                  >
                    <div class="font-weight-bold mb-4">{{ $t('act.approve') }}</div>
                    <div class="action__btn-items font-weight-regular" v-for="(note, index) in item.notes.approve" :key="index">
                      {{ note }}
                    </div>
                  </div>
                  <div
                    class="action__btn action__btn--red cursor-pointer"
                    :class="{ 'action__btn--active': items[item.itemId].result == resultEnum.REJECT }"
                    @click="items[item.itemId].result = resultEnum.REJECT"
                  >
                    <div class="font-weight-bold mb-4">{{ $t('act.reject') }}</div>
                    <div class="action__btn-items font-weight-regular" v-for="(note, index) in item.notes.reject" :key="index">
                      {{ note }}
                    </div>
                  </div>
                </div>
                <div v-if="items[item.itemId].isStockItem" class="d-flex align-center mb-2">
                  <span class="font-weight-medium mt-1 mr-2">{{ $t('qc.lbl.balance') }}:</span>
                  <TextField
                    class="bal-field"
                    v-model="items[item.itemId].bal"
                    :rules="rules.bal"
                    :type="'number'"
                    :outlined="false"
                    :single-line="true"
                    :label="$t('qc.lbl.balance')"
                  />
                  <div class="mt-1">{{ `serves (${items[item.itemId].serving} per ${items[item.itemId].uom})` }}</div>
                </div>
                <div v-if="items[item.itemId].isStockItem" class="d-flex align-center mb-2">
                  <span class="font-weight-medium mt-1 mr-2">{{ $t('qc.lbl.dispose') }}:</span>
                  <PlusMinusField v-model="items[item.itemId].qty" class="dispose-field mr-2" :minValue="0" filled outlined dense />
                  <BtnGroup v-model="items[item.itemId].disposeUom" class="btn-group-two mt-1 mr-2" :mandatory="true" :options="items[item.itemId].uomOptions" />
                </div>
                <div v-if="items[item.itemId].isStockItem" class="d-flex align-center">
                  <span class="font-weight-medium mt-1 mr-2">{{ $t('qc.lbl.adjType') }}:</span>
                  <Select
                    class="adj-field mr-2"
                    v-model="items[item.itemId].type"
                    :label="$t('qc.lbl.store')"
                    :options="adjTypeList"
                    :rules="rules.adjType"
                  />
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="text-right mt-3">
          <AppBtn color="primary" @click="preSubmitCheck">{{ $t('act.submit') }}</AppBtn>
        </div>
      </ValidationObserver>
    </v-card-text>
    <CommonModal v-if="modalShow" :show-modal.sync="modalShow" :header-title="headerTitle" :modal-size="'modal-sm-2'" with-footer>
      <template v-slot:content>
        <ValidationObserver ref="observer3">
          <div>
            <div class="d-flex mb-2">
              <div class="font-weight-medium mr-2">Cash Sales:</div>
              <div class="mr-2">{{ priceWithCommas(cashSales) }}</div>
            </div>
            <div class="d-flex align-center mb-2">
              <div class="font-weight-medium mt-1 mr-2">Collected:</div>
              <div class="mr-2">
                <TextField
                  class="bal-field"
                  v-model="form.cashAmt"
                  :rules="rules.cashAmt"
                  :type="'number'"
                  :step="'0.01'"
                  :outlined="false"
                  :single-line="true"
                  :label="$t('qc.lbl.cashAmt')"
                />
              </div>
            </div>
            <div class="d-flex align-center mb-2">
              <div class="font-weight-medium mr-2">QC Approved?</div>
              <div class="mr-2">
                <BtnGroup v-model="form.result" class="btn-group-two mt-1 mr-2" :mandatory="true" :options="yesNoOpt" />
              </div>
            </div>
          </div>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <AppBtn :loading="loading" @click="submitQc">{{ $t('act.confirm') }}</AppBtn>
      </template>
    </CommonModal>
  </app-card>
</template>
<script>
import { storeApi, storeQcItemApi, storeStockApi, storeShiftQcApi } from '@/api/store';
import { lookupApi } from '@/api/lookup';
import { priceWithCommas } from '@/util/tools.js';
export default {
  data() {
    return {
      headerTitle: 'Quality Check',
      modalShow: false,
      resultEnum: {
        APPROVE: 1,
        REJECT: 2,
      },
      storeList: [],
      qcItemList: [],
      cashSales: null,
      form: {
        storeId: null,
        cashAmt: null,
        result: 1,
        items: [],
        itemsAdjust: [],
      },
      items: {},
      rules: {
        adjType: { required: true },
        bal: { required: true },
        cashAmt: { required: true },
        storeId: { required: true },
      },
      pagination: {
        page: 1,
        pageSize: 50,
        total: 0,
      },
      adjTypeList: [],
      isReady: false,
      yesNoOpt: [
        { text: this.$t('lbl.yes'), value: '1' },
        { text: this.$t('lbl.no'), value: '2' },
      ],
      loading: false,
    };
  },
  created() {
    this.getStoreDdl();
    this.getStockAdj();
  },
  computed: {
    priceWithCommas() {
      return priceWithCommas;
    },
  },
  methods: {
    async validate() {
      await this.$refs.observer2.validate();
      // await this.$refs.observer.validate();
    },
    async storeChg(data) {
      this.form.storeId = data.value;
      this.cashSales = data.cashSales;
      this.items = {};
      await this.getStoreQcItemList();
      await this.getStockBalList();
      this.isReady = true;
    },
    getStoreDdl() {
      return storeApi.ddlQcRequired({}).then(res => {
        this.storeList = res.data.map(item => {
          return {
            text: item.name,
            value: item.id,
            cashSales: item.cashSales,
          };
        });
      });
    },
    getStockBalList() {
      const params = {
        page: 1,
        pageSize: 100,
        storeId: this.form.storeId,
      }
      return storeStockApi.balList(params).then(res => {
        const data = res.data;
        if (data.length > 0) {
          for (let key in this.items) {
            const balItemObj = data.find(item => item.stockId == key);
            if (!this.isUndefinedNullOrEmpty(balItemObj)) {
              this.$set(this.items, key, {
                ...this.items[key],
                isStockItem: true,
                bal: balItemObj.bal,
                uom: balItemObj.uom,
                serving: balItemObj.serving,
                qty: 0, // dispose qty
                type: 1, // stock adj type
                uomOptions: [
                  { text: 'NOS', value: 1 },
                  { text: balItemObj.uom, value: 2 },
                ],
                disposeUom: 1, // default select NOS
              });
            } else {
              this.$set(this.items, key, {
                ...this.items[key],
                isStockItem: false,
                bal: null,
                uom: null,
                serving: null,
                qty: 0, // dispose qty
                type: null, // stock adj type
                uomOptions: null,
                disposeUom: null, // default select NOS
              });
            }
          }
        }
      });
    },
    // getListNTotalRec() {
    //   return this.getTotalRec().then(() => {
    //     return this.getStoreQcItemList();
    //   });
    // },
    // getTotalRec() {
    //   const params = {
    //     page: 0,
    //   };
    //   return storeQcItemApi.list(params).then(res => {
    //     this.pagination.total = res.data.total;
    //   });
    // },
    getStoreQcItemList() {
      const params = {
        page: 1,
        pageSize: -1,
      };
      return storeQcItemApi.list(params).then(res => {
        this.qcItemList = res.data;
        for (let item of res.data) {
          this.$set(this.items, item.itemId, {
            itemId: item.itemId,
            id: item.id,
            result: 1,
          });
        }
      });
    },
    getStockAdj() {
      return lookupApi.stockAdj({}).then(res => {
        this.adjTypeList = res.data.map(item => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      });
    },
    async preSubmitCheck() {
      const valid = await this.$refs.observer2.validate();
      if (!valid) return;
      const { items, itemsAdjust } = this.processItems(this.items);
      this.form.items = items;
      this.form.itemsAdjust = itemsAdjust;
      // console.log('items', JSON.stringify(items, '', 2));
      // console.log('itemsAdjust', JSON.stringify(itemsAdjust, '', 2));
      this.modalShow = true;
    },
    processItems(data) {
      let items = [];
      let itemsAdjust = [];
      for (let key in data) {
        // if (!this.isUndefinedNullOrEmpty(data[key].itemId)) {
          items.push({
            itemId: data[key].id,
            result: data[key].result,
            bal: data[key].bal,
          });
          if (data[key].isStockItem) {
            if (data[key].qty > 0 || data[key].qty < 0) {
              let itemsData = {
                stkId: data[key].itemId,
                qty: data[key].disposeUom == 2 ? data[key].qty * data[key].serving : data[key].qty,
                type: data[key].type,
              };
              itemsData.qty = data[key].type == 52 /* Add Back */ ? Number(`-${itemsData.qty}`) : itemsData.qty
              itemsAdjust.push(itemsData);
            }
          }
        // }
      }
      return { items, itemsAdjust };
    },
    async submitQc() {
      const valid = await this.$refs.observer3.validate();
      if (!valid) return;

      const data = {
        ...this.form,
      };
      this.loading = true;
      return storeShiftQcApi.create(data).then(res => {
        this.loading = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('qc.success.completed'));
          this.modalShow = false;
          this.storeId = null;
          this.navigatePush('QCList');
          // this.resetFields();
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    resetFields() {
      this.form = {
        cashAmt: null,
        result: 1,
        items: [],
        itemsAdjust: [],
      };
      this.items = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.select-field {
  max-width: 250px;
}
// .action {
//   &__btn-icon {
//     font-size: 50px;
//   }
//   &__card {
//     border-radius: 4px;
//     border: 1px solid #000;
//     width: 150px;
//     min-height: 150px;
//     height: 100%;
//     padding: 10px;

//     &-items {
//       line-height: 1;
//       margin-bottom: 5px;
//     }
//   }
// }
.action {
  &__btn-icon {
    font-size: 50px;
  }
  &__btn {
    border-radius: 4px;
    width: 150px;
    min-height: 150px;
    padding: 10px;
    ::v-deep .v-btn__content {
      color: #000;
      flex-direction: column;
    }
    &--green {
      background-color: var(--green-light-1);
    }
    &--red {
      background-color: var(--red);
      color: #fff;
    }
    &--active {
      border: 3px solid #004fe0;
    }
    &-items {
      line-height: 1;
      margin-bottom: 5px;
    }
  }
}
.bal-field {
  max-width: 100px;
}
.dispose-field {
  max-width: 120px;
}
.adj-field {
  max-width: 250px;
}
.v-expansion-panels {
  .v-expansion-panel {
    &-content {
      ::v-deep &__wrap {
        padding: 0 12px 16px;
      }
    }
  }
}
</style>
