<template>
  <ValidationObserver ref="observer">
    <div>
      <div class="d-flex align-center mb-2">
        <v-icon class="mr-1">mdi-information</v-icon>
        <span class="text-caption mr-2">{{ $t('recipe.lbl.ingredient.note1') }}</span>
        <AppBtn color="primary" height="30" small :disabled="!recipeId" @click="openModal">{{
          $t('recipe.act.addNewIngredient')
        }}</AppBtn>
      </div>
      <DataTable
        :headers="headers"
        :items="list"
        :mobile-breakpoint="0"
        :hide-default-header="hideHeader"
        :hide-default-footer="true"
        :pagination-config.sync="pagination"
        disable-pagination
        @pageChg="pageChg"
        @pageSizeChg="pageSizeChg"
      >
        <template #item-type="{ item }">
          {{ $t('map.recipeType')[item.type] }}
        </template>
        <template #item-actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click.stop="showConfirmModal(item)">
              mdi-delete
            </v-icon>
          </div>
        </template>
      </DataTable>
    </div>
    <!-- <div class="d-flex justify-end">
      <AppBtn class="btn-cancel mr-2" @click="navigatePush('RecipeList', { categoryId })">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ $t('act.save') }}</AppBtn>
    </div> -->
    <CreateModal
      v-if="createModalShow"
      :data="itemData"
      :show-modal.sync="createModalShow"
      @close="closeModal"
      @getList="getList"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </ValidationObserver>
</template>
<script>
import { recipeItemApi } from '@/api/recipe';
import { mapState } from 'vuex';
import CreateModal from './CreateModal';
export default {
  name: 'RecipeIngredient',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    CreateModal,
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('recipe.title.deleteIngredient'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        total: 0,
      },
      loading: false,
      createModalShow: false,
      list: [],
      itemData: {},
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    recipeId() {
      return this.$route.params.id;
    },
    categoryId() {
      return this.$route.params.categoryId || null;
    },
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('recipe.lbl.ingredient'), width: '300', value: 'ingredient' },
          { text: this.$t('recipe.lbl.type'), width: '200', value: 'type' },
          { text: this.$t('recipe.lbl.ingredient.qty'), width: '180', value: 'qty' },
          { text: this.$t('uom.lbl.uom'), width: '180', value: 'uom' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      } else {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('recipe.lbl.ingredient'), width: '300', value: 'ingredient' },
          { text: this.$t('recipe.lbl.type'), width: '200', value: 'type' },
          { text: this.$t('recipe.lbl.ingredient.qty'), width: '180', value: 'qty' },
          { text: this.$t('uom.lbl.uom'), width: '180', value: 'uom' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
  },
  created() {
    if (!this.isUndefinedNullOrEmpty(this.recipeId)) {
      this.pagination = { ...this.paginationOpts, ...this.pagination };
      this.getList();
    }
  },
  methods: {
    // getListNTotalRec() {
    //   this.getTotalRec().then(() => {
    //     this.getList();
    //   });
    // },
    // getTotalRec() {
    //   const params = {
    //     page: 0,
    //     recipeId: this.recipeId,
    //   };
    //   return recipeItemApi.list(params).then(res => {
    //     this.pagination.total = res.data.total;
    //   });
    // },
    getList() {
      const params = {
        page: 1,
        pageSize: -1,
        recipeId: this.recipeId,
      };
      return recipeItemApi.list(params).then(res => {
        this.list = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          };
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
    openModal() {
      this.createModalShow = true;
    },
    closeModal() {
      this.createModalShow = false;
      this.itemData = {};
    },
    editItem(item) {
      this.itemData = item;
      this.$nextTick(() => {
        this.createModalShow = true;
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    delete(id) {
      this.confirmActionLoading = true;
      return recipeItemApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
  },
};
</script>
