import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import lookup from './modules/lookup';
import recipe from './modules/recipe';
import sales from './modules/sales';
import product from './modules/product';
import user from './modules/user';
import warehouse from './modules/warehouse';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    lookup,
    recipe,
    sales,
    product,
    user,
    warehouse,
  },
});
