import Api from '../api';

export const semiFGoodsLabelApi = {
  ddl(params) {
    return Api.get('semi-goods/label/picklist', params);
  },
  list(params) {
    return Api.get('semi-goods/label/list', params);
  },
  create(data) {
    return Api.post('semi-goods/label/create', data);
  },
  update(data) {
    return Api.post('semi-goods/label/update', data);
  },
  purge(data) {
    return Api.post('semi-goods/label/purge', data);
  },
};

export const fGoodsLabelApi = {
  ddl(params) {
    return Api.get('goods/label/picklist', params);
  },
  list(params) {
    return Api.get('goods/label/list', params);
  },
  create(data) {
    return Api.post('goods/label/create', data);
  },
  update(data) {
    return Api.post('goods/label/update', data);
  },
  purge(data) {
    return Api.post('goods/label/purge', data);
  },
};