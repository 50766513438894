<template>
  <v-card
    elevation="2"
  >
    <v-card-text>
      <div class="d-flex align-center mb-3">
        <div class="font-0875r mr-2">{{ $t('recipe.lbl.listOfRecipeCategory') }}</div>
      </div>
      <div class="mb-3">
        <label class="font-1dot25r">{{ $t('recipe.lbl.selectCategory') }}</label>
        <v-divider class="mt-1 mb-1"></v-divider>
      </div>
      <v-row>
        <v-col v-for="(item, index) in data" :key="index" cols="12" sm="6" md="3">
          <v-card @click="goToDetails(item.id)">
            <v-img
              :src="require('@/assets/recipe.jpg')"
              height="200px"
            ></v-img>

            <v-card-title>
              {{ item.name }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { recipeCategoryApi } from '@/api/recipe';
export default {
  name: 'RecipeListByCategory',
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.list();
  },
  methods: {
    goToDetails(categoryId) {
      this.navigatePush('RecipeList', { categoryId });
    },
    list() {
      const params = {
        page: 1,
        pageSize: -1,
      }
      return recipeCategoryApi.list(params).then (res => {
        this.data = res.data;
      });
    },
  }
}
</script>