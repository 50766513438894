import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const supplierApi = {
  ddl(params) {
    return Api.get('supplier/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('supplier/list', params);
  },
  detail(params) {
    return Api.get('supplier', params);
  },
  create(data) {
    return Api.post('supplier/create', data);
  },
  update(data) {
    return Api.post('supplier/update', data);
  },
  delete(data) {
    return Api.post('supplier/delete', data);
  },
};
