<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import List from './List';
import Details from './Details';
export default {
  name: 'PurchaseOrder',
  components: {
    List,
    Details,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'PurchaseOrderList':
          return 'List';
        case 'PurchaseOrderCreate':
          return 'Details';
        case 'PurchaseOrderUpdate':
          return 'Details';
        default:
          return 'List';
      }
    },
  },
};
</script>
