import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const purchaseOrderApi = {
  ddl(params) {
    return Api.get('product/order/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('product/order/list', params);
  },
  detail(params) {
    return Api.get('product/order', params);
  },
  create(data) {
    return Api.post('product/order/create', data);
  },
  update(data) {
    return Api.post('product/order/update', data);
  },
  delete(data) {
    return Api.post('product/order/delete', data);
  },
  cancel(data) {
    return Api.post('product/order/cancel', data);
  },
  confirm(data) {
    return Api.post('product/order/confirm', data);
  },
  approve(data) {
    return Api.post('product/order/approve', data);
  },
  complete(data) {
    return Api.post('product/order/complete', data);
  },
  report(data) {
    return Api.stream('product/order/doc', data);
  },
};

export const purchaseOrderItemApi = {
  ddl(params) {
    return Api.get('product/order/item/picklist', params);
  },
  list(params) {
    return Api.get('product/order/item/list', params);
  },
  detail(params) {
    return Api.get('product/order/item', params);
  },
  create(data) {
    return Api.post('product/order/item/create', data);
  },
  update(data) {
    return Api.post('product/order/item/update', data);
  },
  delete(data) {
    return Api.post('product/order/item/delete', data);
  },
};
