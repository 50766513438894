<template>
  <v-data-table
    :class="className"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    :items-per-page.sync="paginationConfig.pageSize"
    :page.sync="paginationConfig.page"
    :server-items-length="disablePagination ? null : paginationConfig.total"
    :loading="loading"
    :loading-text="loadingText"
    :hide-default-footer="hideDefaultFooter"
    :hide-default-header="hideDefaultHeader"
    :disable-sort="disableSort"
    :disable-pagination="disablePagination"
    :mobile-breakpoint="mobileBreakpoint"
    :show-select="showSelect"
    :selectable-key="selectableKey"
    :height="height"
    :footer-props="{
      itemsPerPageOptions: pageSizeOpts,
    }"
    @toggle-select-all="toggleSelectAll"
    @item-selected="itemSelected"
    @update:page="pageChg"
    @update:items-per-page="pageSizeChg"
    @click:row="clickRow"
  >
    <template v-for="{ value } in headers" v-slot:[`header.${value}`]="{ header }">
      <slot :name="`header-${value}`" v-bind:header="header">
        {{ header.text }}
      </slot>
    </template>

    <template v-for="{ value } in headers" v-slot:[`item.${value}`]="{ item }">
      <slot :name="`item-${value}`" v-bind:item="item">
        {{ item[value] }}
      </slot>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DataTable',
  props: {
    className: {
      type: [String, Array],
      default: null,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: [String, Number],
      default: '',
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    mobileBreakpoint: {
      type: [Number, String],
      default: 600,
    },
    page: {
      type: Number,
      default: 1,
    },
    paginationConfig: {
      type: Object,
      default: () => ({
        page: 1,
        pageSize: 10,
        total: 0,
      }),
      validator: value => {
        return ['page', 'pageSize', 'total'].every(key => key in value);
      },
    },
    selectableKey: {
      type: String,
      default: 'isSelectable',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
    }),
  },
  watch: {
    // Calculate current page records rather than computed
    // Reason: Using computed, calculation not accurate, api haven't return result
    // items: {
    //   handler: 'calculateCurrentPageRecords',
    //   immediate: true,
    // },
  },
  data() {
    return {
      currentPageRecordCounter: null,
    };
  },
  // computed: {
  //   // getTotalPage() {
  //   //   return calTotalPage(this.paginationConfig.total, this.paginationConfig.size);
  //   // },
  //   isItemsExist() {
  //     return this.items.length > 0;
  //   },
  //   // isPaginationFirstPage() {
  //   //   return this.paginationConfig.page === 1;
  //   // },
  //   // isPaginationLastPage() {
  //   //   return this.paginationConfig.page >= this.getTotalPage;
  //   // },
  // },
  methods: {
    toggleSelectAll(e) {
      this.$emit('toggleSelectAll', e);
    },
    itemSelected(e) {
      this.$emit('itemSelected', e);
    },
    pageChg(e) {
      this.$emit('pageChg', e);
    },
    pageSizeChg(e) {
      this.$emit('pageSizeChg', e);
    },
    // calculateCurrentPageRecords() {
    //   const multipleOf = (this.paginationConfig.page - 1) * 10,
    //     firstRecordCounter = multipleOf + 1,
    //     lastRecordCounter = multipleOf + this.items.length;

    //   this.currentPageRecordCounter = `${firstRecordCounter} - ${lastRecordCounter}`;
    // },
    // getPreviousPagination() {
    //   if (!this.isPaginationFirstPage) {
    //     this.paginationConfig.page--;
    //     this.paginationChangeHandler();
    //   }
    // },
    // getNextPagination() {
    //   if (!this.isPaginationLastPage) {
    //     this.paginationConfig.page++;
    //     this.paginationChangeHandler();
    //   }
    // },
    // paginationChangeHandler() {
    //   this.$emit('getList');
    // },
    clickRow(item, otherInfo) {
      this.$emit('click:row', item, otherInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.v-data-table::v-deep {
  @include for-sm {
    .v-data-footer {
      justify-content: flex-start;
      .v-data-footer__select {
        .v-select {
          margin: 13px 0 13px 14px;
        }
      }
      .v-data-footer__pagination {
        margin: 0 16px;
      }
      .v-data-footer__icons-before, .v-data-footer__icons-after {
        .v-btn {
          width: 20px;
        }
      }
    }
  }
//   border: 1px solid var(--border-lighten-gray);
//   border-radius: 4px;

//   .v-data-table-header {
//     background-color: transparent !important;
//     font-weight: 600;

//     tr {
//       th {
//         height: 40px;
//         border-bottom: 1px solid var(--border-lighten-gray) !important;
//         color: var(--text-light-gray) !important;
//         font-size: var(--font-size-sm-2);
//       }
//     }
//   }

//   & > .v-data-table__wrapper {
//     & > table {
//       & > tbody {
//         & > tr {
//           td {
//             padding-top: 15px;
//             padding-bottom: 15px;
//             color: var(--primary);
//             font-weight: 600;
//             font-size: inherit;
//           }
//         }

//         & > tr:not(.v-data-table__empty-wrapper) {
//           &:hover {
//             background-color: var(--dim-lighter-blue) !important;
//             cursor: pointer;
//           }
//         }
//       }
//     }
//   }

  // border-radius: 10px;
  // .v-data-table__wrapper {
  //   border-radius: 10px;
  //   thead.v-data-table-header tr th {
  //     background-color: var(--primary-black-color);
  //     color: var(--primary-color);
  //     font-size: 1rem;
  //     font-weight: 500 !important;
  //     height: 42px;
  //   }
  //   tbody tr {
  //     td {
  //       font-size: 0.9rem;
  //       height: 42px;
  //     }
  //   }
  // }

  // .v-data-custom-footer {
  //   color: var(--text-light-gray);
  //   font-size: var(--font-size-sm-2);
  // }

  // .custom-pagination {
  //   color: inherit;
  //   user-select: none;

  //   &__back,
  //   &__next {
  //     &--hover {
  //       cursor: pointer;

  //       &:hover {
  //         & > span {
  //           text-decoration: underline;
  //         }
  //       }
  //     }
  //   }

  //   &--disabled {
  //     opacity: 0.3;
  //   }
  // }
}
</style>
