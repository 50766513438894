var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-card',[_c('v-card-title',[_c('TextField',{staticClass:"mr-2",attrs:{"placeholder":_vm.$t('lbl.search')},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('AppBtn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.navigatePush('QCCreate')}}},[_vm._v(_vm._s('Perform QC'))])],1),_c('v-data-table',{attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.data,"page":_vm.pagination.page,"items-per-page":_vm.pagination.pageSize,"footer-props":{
          itemsPerPageOptions: _vm.pageSizeOpts,
        },"server-items-length":_vm.pagination.total},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.pageChg],"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},_vm.pageSizeChg]},scopedSlots:_vm._u([{key:"item.cashAmt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.priceWithCommas(item.cashAmt))+" ")]}},{key:"item.result",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('map.approvedRejected')[item.result])+" ")]}},{key:"item.cdate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.cdate) ? _vm.setTimeZone(item.cdate) : item.cdate)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }