<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md-2'" with-footer>
    <template v-slot:content>
      <DataTable
        :class="'cursor-pointer'"
        :headers="headers"
        :items="list"
        :pagination-config.sync="pagination"
        :mobile-breakpoint="0"
        :hide-default-header="hideHeader"
        :hide-default-footer="true"
        @click:row="confirm"
      >
        <template #item-column-1-xs="{ item }">
          <div class="pa-1">
            <div>
              <span class="font-weight-medium font-15">{{ item.poNo }}</span>
            </div>
            <div>{{ $t('purchaseOrder.lbl.poDate') }}: 
              <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.poDate) ? setTimeZone(item.poDate, dateFormat) : '-' }}</span>
            </div>
            <div>{{ $t('purchaseOrder.lbl.deliveryDate') }}: 
              <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate, dateFormat) : '-' }}</span>
            </div>
            <div>{{ $t('purchaseOrder.lbl.supplier') }}: <span class="font-weight-regular">{{ item.supplier }}</span></div>
          </div>
        </template>
        <template #item-column-act-xs="{ item }">
          <div class="d-flex justify-end">
            <AppBtn @click="confirm(item)">{{ $t('act.select') }}</AppBtn>
          </div>
        </template>

        <template #item-poDate="{ item }">
          {{ !isUndefinedNullOrEmpty(item.poDate) ? setTimeZone(item.poDate, dateFormat) : '-' }}
        </template>
        <template #item-doDate="{ item }">
          {{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate, dateFormat) : '-' }}
        </template>
        <template #item-actions="{ item }">
          <div class="d-flex justify-end">
            <AppBtn @click="confirm(item)">{{ $t('act.select') }}</AppBtn>
          </div>
        </template>
      </DataTable>
    </template>
  </CommonModal>
</template>
<script>
import { purchaseOrderApi } from '@/api/purchase-order';
import { PO_STATUS } from '@/common/enum';
import { mapState } from 'vuex';

export default {
  name: 'SelectModal',
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      }
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('purchaseOrder.lbl.poNo'), width: '120', value: 'poNo' },
          { text: this.$t('purchaseOrder.lbl.poDate'), width: '120', value: 'poDate' },
          { text: this.$t('purchaseOrder.lbl.deliveryDate'), width: '120', value: 'doDate' },
          { text: this.$t('purchaseOrder.lbl.supplier'), width: '180', value: 'supplier' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    }
  },
  created() {
    this.getPoList();
  },
  methods: {
    getPoList() {
      const params = {
        status: PO_STATUS.APPROVED,
      }
      return purchaseOrderApi.ddl(params).then(res => {
        this.list = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
        this.pagination = {
          ...this.pagination,
          total: this.list.length,
        }
      });
    },
    confirm(item) {
      this.$emit('confirm', item);
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
