import Api from '../api';

export const notiApi = {
  list(params) {
    return Api.get('noti/list', params);
  },
  listRecent(params) {
    return Api.get('noti/list/recent', params);
  },
  detail(params) {
    return Api.get('noti', params);
  },
};
