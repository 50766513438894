<template>
  <div>
    <ValidationObserver ref="observer">
      <div v-if="showTable">
        <div class="d-flex justify-space-between px-2 py-4 px-sm-4 mb-3">
          <div>
            <div class="font-1dot25r font-weight-bold">{{ $t('dash.lbl.storeSales') }}</div>
          </div>
          <AppBtn :height="30" small :loading="searchLoading" @click="search">
            <IconWrap :icon="'mdi-reload'" :size="16" />
          </AppBtn>
        </div>
        <DataTable
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :hide-default-footer="true"
          :pagination-config.sync="pagination"
          disable-pagination
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-1dot3r">{{ item.bizDate }}</div>
              <div class="font-1dot3r">{{ $t('dash.lbl.store') }}: <span class="font-weight-regular">{{ item.store }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.cashSales') }}: <span class="font-weight-bold">{{ item.cash }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.totOrder') }}: <span class="font-weight-bold">{{ item.totOrder }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.totSales') }}: <span class="font-weight-bold">{{ `${$t('lbl.rm')} ${priceWithCommas(item.totSales)}` }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.salesSlot1') }}: <span class="font-weight-bold">{{ `${$t('lbl.rm')} ${priceWithCommas(item.s1)}` }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.salesSlot2') }}: <span class="font-weight-bold">{{ `${$t('lbl.rm')} ${priceWithCommas(item.s2)}` }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.salesSlot3') }}: <span class="font-weight-bold">{{ `${$t('lbl.rm')} ${priceWithCommas(item.s3)}` }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.salesSlot4') }}: <span class="font-weight-bold">{{ `${$t('lbl.rm')} ${priceWithCommas(item.s4)}` }}</span></div>
              <div class="font-1dot3r">{{ $t('dash.lbl.salesSlot5') }}: <span class="font-weight-bold">{{ `${$t('lbl.rm')} ${priceWithCommas(item.s5)}` }}</span></div>
            </div>
          </template>

          <template #item-cash="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.cash)}` }}
          </template>
          <template #item-totSales="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.totSales)}` }}
          </template>
          <template #item-s1="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.s1)}` }}
          </template>
          <template #item-s2="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.s2)}` }}
          </template>
          <template #item-s3="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.s3)}` }}
          </template>
          <template #item-s4="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.s4)}` }}
          </template>
          <template #item-s5="{ item }">
            {{ `${$t('lbl.rm')} ${priceWithCommas(item.s5)}` }}
          </template>
        </DataTable>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { priceWithCommas } from '@/util/tools';
import { mapState } from 'vuex';
import { storeSalesApi } from '@/api/store';
export default {
  data() {
    return {
      data: [],
      form: {
        customerId: null,
        storeId: null,
      },
      customerDdl: [],
      storeDdl: [],
      rules: {
        customerId: { required: true },
        storeId: { required: true },
      },
      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
      showTable: false,
      searchLoading: false,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '100%', value: 'column-1-xs' },
        ];
      } else {
        return [
          { text: this.$t('dash.lbl.bizDate'), width: '70', value: 'bizDate' },
          { text: this.$t('dash.lbl.store'), width: '90', value: 'store' },
          { text: this.$t('dash.lbl.cashSales'), width: '80', value: 'cash' },
          { text: this.$t('dash.lbl.totOrder'), width: '50', value: 'totOrder' },
          { text: this.$t('dash.lbl.totSales'), width: '80', value: 'totSales' },
          { text: this.$t('dash.lbl.salesSlot1'), width: '80', value: 's1' },
          { text: this.$t('dash.lbl.salesSlot2'), width: '80', value: 's2' },
          { text: this.$t('dash.lbl.salesSlot3'), width: '80', value: 's3' },
          { text: this.$t('dash.lbl.salesSlot4'), width: '80', value: 's4' },
          { text: this.$t('dash.lbl.salesSlot5'), width: '80', value: 's5' },
        ];
      }
    },
    priceWithCommas() {
      return priceWithCommas;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return storeSalesApi.daily(params).then(res => {
        this.data = res.data;
        if (this.data.length > 0) {
          this.showTable = true;
        }
      });
    },

    async search() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      try {
        this.searchLoading = true;
        await this.getList();
        this.searchLoading = false;
      } catch (error) {
        this.searchLoading = false;
      }
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  }
}
</script>
