<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      hide-default-footer
      disable-pagination
      disable-sort
      @update:page="pageChg"
      @update:items-per-page="pageSizeChg"
    >
    </v-data-table>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { storeStockApi } from '@/api/store';
export default {
  data() {
    return {
      data: [],
      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    headers() {
      return [
        { text: this.$t('replenish.lbl.item'), width: '180', value: 'item' },
        { text: this.$t('replenish.lbl.balance'), width: '90', value: 'bal' },
        { text: this.$t('replenish.lbl.used'), width: '70', value: 'used' },
        { text: this.$t('replenish.lbl.maxLoad'), width: '90', value: 'max' },
        { text: this.$t('replenish.lbl.refillQty'), width: '90', value: 'qty' },
        { text: this.$t('replenish.lbl.uom'), width: '90', value: 'uom' },
        { text: this.$t('replenish.lbl.servings/UOM'), width: '120', value: 'serving' },
      ];
    },
    inputWidth() {
      if (this.isMobileView) {
        return '100%';
      } else {
        return '250px';
      }
    },
  },
  created() {
    this.getListNTotalRec();
  },
  methods: {
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        this.getList();
      });
    },
    getTotalRec() {
      const params = {
        page: 0,
      };
      return storeStockApi.refillListByStockItem(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return storeStockApi.refillListByStockItem(params).then(res => {
        this.data = res.data;
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
