import Api from '../api';

export const recipeApi = {
  ddl(params) {
    return Api.get('recipe/picklist', params);
  },
  list(params) {
    return Api.get('recipe/list', params);
  },
  detail(params) {
    return Api.get('recipe', params);
  },
  create(data) {
    return Api.post('recipe/create', data);
  },
  update(data) {
    return Api.post('recipe/update', data);
  },
  delete(data) {
    return Api.post('recipe/delete', data);
  },
  preupload(data) {
    return Api.post('recipe/image/preupload', data);
  },
};

export const recipeItemApi = {
  ddl(params) {
    return Api.get('recipe/item/picklist', params);
  },
  list(params) {
    return Api.get('recipe/item/list', params);
  },
  detail(params) {
    return Api.get('recipe/item', params);
  },
  create(data) {
    return Api.post('recipe/item/create', data);
  },
  update(data) {
    return Api.post('recipe/item/update', data);
  },
  delete(data) {
    return Api.post('recipe/item/delete', data);
  },
  preupload(data) {
    return Api.post('recipe/item/image/preupload', data);
  },
};

export const recipeXCategoryApi = {
  ddl(params) {
    return Api.get('recipe/sub/picklist', params);
  },
  list(params) {
    return Api.get('recipe/sub/list', params);
  },
  detail(params) {
    return Api.get('recipe/sub', params);
  },
  create(data) {
    return Api.post('recipe/sub/create', data);
  },
  update(data) {
    return Api.post('recipe/sub/update', data);
  },
  delete(data) {
    return Api.post('recipe/sub/delete', data);
  },
};

export const recipeCategoryApi = {
  ddl(params) {
    return Api.get('recipe/grp/picklist', params);
  },
  list(params) {
    return Api.get('recipe/grp/list', params);
  },
  detail(params) {
    return Api.get('recipe/grp', params);
  },
  create(data) {
    return Api.post('recipe/grp/create', data);
  },
  update(data) {
    return Api.post('recipe/grp/update', data);
  },
  delete(data) {
    return Api.post('recipe/grp/delete', data);
  },
  preupload(data) {
    return Api.post('recipe/grp/image/preupload', data);
  },
};