<template>
  <div>
      <app-card>
        <v-card-title>
          <TextField v-model="searchVal" class="mr-2" :placeholder="$t('lbl.search')" />
          <AppBtn color="primary" @click="navigatePush('QCCreate')">{{ 'Perform QC' }}</AppBtn>
        </v-card-title>
        <v-data-table
          disable-sort
          :headers="headers"
          :items="data"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.pageSize"
          :footer-props="{
            itemsPerPageOptions: pageSizeOpts,
          }"
          :server-items-length="pagination.total"
          @update:page="pageChg"
          @update:items-per-page="pageSizeChg"
        >
          <template v-slot:item.cashAmt="{ item }">
            {{ priceWithCommas(item.cashAmt) }}
          </template>
          <template v-slot:item.result="{ item }">
            {{ $t('map.approvedRejected')[item.result] }}
          </template>
          <template v-slot:item.cdate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.cdate) ? setTimeZone(item.cdate) : item.cdate }}
          </template>
        </v-data-table>
      </app-card>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { priceWithCommas } from '@/util/tools'
import { storeShiftQcApi } from '@/api/store';
export default {
  data() {
    return {
      searchVal: '',
      data: [],
      pagination: {
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    headers() {
      return [
        { text: this.$t('lbl.no'), width: '5%', value: 'no' },
        { text: this.$t('qc.lbl.store'), width: '30%', value: 'store' },
        { text: this.$t('qc.lbl.cashAmt'), width: '15%', value: 'cashAmt' },
        { text: this.$t('qc.lbl.result'), width: '10%', value: 'result' },
        { text: this.$t('lbl.cdate'), width: '20%', value: 'cdate' },
        { text: this.$t('lbl.cby'), width: '20%', value: 'cby' },
      ];
    },
    priceWithCommas() {
      return priceWithCommas;
    },
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getListNTotalRec();
  },
  methods: {
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        this.getList();
      });
    },
    getTotalRec() {
      const params = {
        page: 0,
      };
      return storeShiftQcApi.list(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return storeShiftQcApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
