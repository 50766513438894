<template>
  <div>
    <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
      <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
        {{ tab.label }}
      </v-tab>
    </v-tabs>

    <ValidationObserver ref="observer">
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
            <template v-if="mode == STOCK_IN_OUT_MODE.QR">
              <v-row>
                <!-- <v-col cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('inventory.lbl.warehouse') }}
                    <span class="font-red">{{ rules.storageId.required ? '*' : '' }}</span>
                  </label>
                  <Select v-model="form.storageId" :options="warehouseDdl" :label="$t('inventory.lbl.warehouse')" :rules="rules.storageId" />
                </v-col> -->
                <v-col cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('inventory.lbl.jobSheetNo') }}
                    <span class="font-red">{{ rules.jobNo.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.jobNo" :rules="rules.jobNo" :vid="'jobNo'" :label="$t('inventory.lbl.jobSheetNo')" />
                </v-col>
              </v-row>

              <v-expansion-panels class="expansion-wrap my-4" v-model="activePanel">
                <v-expansion-panel
                  v-for="(item,i) in 1"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{ $t('production.lbl.ingredients') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <RecipeTable :data="recipeData" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="form-row-col__label my-2">{{ $t('inventory.lbl.scanQr') }}</div>
              <!-- <QrCodeReader @onDecode="qrValueChg" /> -->
              <QrBarcodeReader @onDecode="qrValueChg" :timeout-period="scanInterval" />
              <TextField v-model="form.snDisp" read-only />
              <div class="text-divider my-2">{{ $t('lbl.or') }}</div>
              <div class="font-1r cursor-pointer link text-center my-3" @click="mode = STOCK_IN_OUT_MODE.MANUAL">{{ $t('inventory.lbl.stockOutViaJobSnNo') }}</div>
            </template>
            <template v-else>
              <v-row>
                <!-- <v-col cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('inventory.lbl.warehouse') }}
                    <span class="font-red">{{ rules.storageId.required ? '*' : '' }}</span>
                  </label>
                  <Select v-model="form.storageId" :options="warehouseDdl" :label="$t('inventory.lbl.warehouse')" :rules="rules.storageId" />
                </v-col> -->
                <v-col cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('inventory.lbl.jobSheetNo') }}
                    <span class="font-red">{{ rules.jobNo.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.jobNo" :rules="rules.jobNo" :vid="'jobNo'" :label="$t('inventory.lbl.jobSheetNo')" />
                </v-col>
                <v-col cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('rawMat.print.lbl.serialNo') }}
                    <span class="font-red">{{ rules.sn.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.sn" :rules="rules.sn" :label="$t('rawMat.print.lbl.serialNo')" />
                </v-col>
              </v-row>

              <v-expansion-panels class="expansion-wrap my-4" v-model="activePanel">
                <v-expansion-panel
                  v-for="(item,i) in 1"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{ $t('production.lbl.ingredients') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <RecipeTable :data="recipeData" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="text-divider my-2">{{ $t('lbl.or') }}</div>
              <div class="font-1r cursor-pointer link text-center my-3" @click="mode = STOCK_IN_OUT_MODE.QR">{{ $t('inventory.lbl.stockOutViaQrCode') }}</div>
              <div class="d-flex justify-end">
                <AppBtn class="btn-cancel mr-2" @click="$router.go(-1)">{{ $t('act.cancel') }}</AppBtn>
                <AppBtn :loading="loading" @click="stockOut">{{ $t('act.confirm') }}</AppBtn>
              </div>
            </template>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { rawMatInventoryTxApi, semiGoodsInventoryTxApi } from '@/api/inventory';
import { STOCK_IN_OUT_MODE } from '@/common/enum.js';
// import QrCodeReader from '@/components/QrCodeReader';
import { jobApi } from '@/api/job';
import { recipeItemApi } from '@/api/recipe';
import RecipeTable from './components/RecipeTable';
export default {
  name: 'StockOut',
  components: {
    // QrCodeReader,
    RecipeTable,
  },
  data() {
    return {
      selectedTab: 0,
      tabs: this.$t('inventoryTabs'),
      mode: STOCK_IN_OUT_MODE.QR,
      loading: false,
      form: {
        // storageId: null,
        jobNo: null,
        sn: null,
        snDisp: null,
      },
      rules: {
        // storageId: { required: true },
        jobNo: { required: true },
        sn: { required: true },
      },
      recipeData: [],
      activePanel: null,
      totBatch: 1,
    };
  },
  computed: {
    ...mapState({
      scanInterval: state => state.product.scanInterval,
      snLengthLimit: state => state.product.snLengthLimit,
    }),
    STOCK_IN_OUT_MODE() {
      return STOCK_IN_OUT_MODE;
    },
  },
  async created() {
    this.rules.sn.max = this.snLengthLimit;
    // if (this.isUndefinedNullOrEmpty(this.warehouseDdl)) {
    //   this.fetchWarehouseDdl();
    // }
    const params = this.$route.params;
    if (!this.isUndefinedNullOrEmpty(params.jobNo)) {
      this.form.jobNo = params.jobNo;
    }
    if (!this.isUndefinedNullOrEmpty(params.recipeId)) {
      await this.getJobByJobNo(this.$route.params.jobNo);
      this.getRecipeItemList(params.recipeId);
    }
  },
  methods: {
    // ...mapActions(['fetchWarehouseDdl']),
    async stockOut() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      if (this.isUndefinedNullOrEmpty(this.form.jobNo) || this.isUndefinedNullOrEmpty(this.form.sn)) {
        this.showError(this.$t('production.error.provideJobNoNSn'));
        return;
      }
      const data = {
        // storageId: this.form.storageId,
        jobNo: !this.isUndefinedNullOrEmpty(this.form.jobNo) ? this.form.jobNo.trim() : null,
        sn: !this.isUndefinedNullOrEmpty(this.form.sn) ? this.form.sn.trim() : null,
      }
      try {
        const sn = data.sn.toUpperCase();
        if (sn.length > this.snLengthLimit) {
          this.showError(this.$t('err.lbl.invalidSerialNo'));
          return;
        }
        if (sn.startsWith('RM')) {
          this.loading = true;
          await this.rawMatStockOut(data);
        } else if (sn.startsWith('SFG')) {
          this.loading = true;
          await this.semiFGoodsStockOut(data);
        } else {
          this.showError(this.$t('err.lbl.invalidSerialNo'));
          return;
        }
        this.resetFields();
        this.showSuccess(this.$t('lbl.stockOutSuccess'));
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getJobByJobNo(jobNo) {
      const params = {
        page: 1,
        pageSize: 1,
        jobNo,
      }
      return jobApi.list(params).then(res => {
        if (res.ret == '0') {
          if (res.data.length > 0) {
            this.totBatch = res.data[0]?.totBatch;
          }
        }
      });
    },
    getRecipeItemList(recipeId) {
      return recipeItemApi.ddl({ recipeId }).then(res => {
        this.recipeData = res.data.map(recipe => {
          return {
            ...recipe,
            totQty: recipe.qty * this.totBatch,
          }
        });
      });
    },
    rawMatStockOut(data) {
      return rawMatInventoryTxApi.stockOut(data);
    },
    semiFGoodsStockOut(data) {
      return semiGoodsInventoryTxApi.stockOut(data);
    },
    resetFields() {
      this.form.sn = null;
      this.form.snDisp = null;
    },
    async qrValueChg(qrValue) {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.form.sn = qrValue;
      this.form.snDisp = qrValue;
      this.stockOut();
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
.text-divider {
  display: flex;
  align-items: center;
  &::before, &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    background-color: #d6d6d6;
  }
  &::before {
    margin-right: 1rem;
  }
  &::after {
    margin-left: 1rem;
  }
}
.link {
  color: #0000ee;
  &:hover {
    color: #0202b9;
  }
}
.expansion-wrap {
  border: 1px solid #9a9a9a;
  .v-expansion-panel {
    &::before {
      box-shadow: none;
    }
    &-header {
      min-height: unset;
      padding: 12px 12px;
    }
    .v-expansion-panel-content::v-deep {
      .v-expansion-panel-content__wrap {
        padding: 0 12px 16px;
      }
    }
  }
}
</style>
