import Api from '../api';

export const salesOrderApi = {
  ddl(params) {
    return Api.get('sales/order/picklist', params);
  },
  list(params) {
    return Api.get('sales/order/list', params);
  },
  detail(params) {
    return Api.get('sales/order', params);
  },
  create(params) {
    return Api.post('sales/order/create', params);
  },
  approve(params) {
    return Api.post('sales/order/approve', params);
  },
  update(params) {
    return Api.post('sales/order/update', params);
  },
  delete(params) {
    return Api.post('sales/order/delete', params);
  },
  cancel(params) {
    return Api.post('sales/order/cancel', params);
  },
  report(data) {
    return Api.stream('sales/order/doc', data);
  },
};

export const salesOrderItemApi = {
  ddl(params) {
    return Api.get('sales/order/item/picklist', params);
  },
  list(params) {
    return Api.get('sales/order/item/list', params);
  },
  detail(params) {
    return Api.get('sales/order/item', params);
  },
  create(params) {
    return Api.post('sales/order/item/create', params);
  },
  update(params) {
    return Api.post('sales/order/item/update', params);
  },
  delete(params) {
    return Api.post('sales/order/item/delete', params);
  },
};

export const salesOrderLabelApi = {
  ddl(params) {
    return Api.get('sales/order/label/picklist', params);
  },
  list(params) {
    return Api.get('sales/order/label/list', params);
  },
  detail(params) {
    return Api.get('sales/order/label', params);
  },
  stockOut(params) {
    return Api.post('sales/order/label/import', params);
  },
  report(data) {
    return Api.stream('sales/order/doc', data);
  },
};