<template>
  <v-row>
    <v-col class="img-wrap" v-for="(item, index) in list" :key="index" cols="6" sm="3">
      <img class="img" :src="item.url || item.imgUrl || defaultImg" alt="grn_img">
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ItemImages',
  props: {
    grnId: {
      type: Number,
      default: null,
    },
    list: {
      type: Array,
      default: null,
    }
  },
  components: {},
  data() {
    return {
      defaultImg: require('@/assets/img-not-found.jpg'),
    };
  },
};
</script>
<style lang="scss" scoped>
.img-wrap {
  display: flex;
  justify-content: center;
  height: 125px;
}
.img {
  height: 100%;
  max-width: 100%;
}
</style>