<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title>
          <div class="grid-wrap">
            <TextField v-model="searchVal" class="mb-2 mb-sm-0 mr-0 mr-sm-2" :placeholder="$t('lbl.search')" />
            <Select v-model="selectedJobGrp" class="mb-2 mb-sm-0 mr-0 mr-sm-2" :clearable="false" :options="selectOpts" :placeholder="$t('lbl.select')" @change="selectedJobGrpChg" />
          </div>
          <div class="text-right" :class="{ 'w-100': isMobileView }">
            <AppBtn color="primary" @click="navigatePush('ProductionCreate')">{{ $t('production.act.addNewJob') }}</AppBtn>
          </div>
        </v-card-title>
        <DataTable
          :class="'cursor-pointer'"
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :pagination-config.sync="pagination"
          @click:row="viewDetails"
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-15">{{ item.jobNo }}</div>
              <div>{{ $t('production.lbl.productName') }}: <span class="font-weight-regular">{{ item.product }}</span></div>
              <div>{{ $t('production.lbl.jobType') }}: <span class="font-weight-regular">{{ item.typeName }}</span></div>
              <div>{{ $t('production.lbl.qty') }}: 
                <span class="font-weight-regular">{{ `${item.qty} ${item.uom}` }}</span>
                {{ `, ${$t('production.lbl.totalBatch')}` }}: 
                <span class="font-weight-regular">{{ item.totBatch }}</span>
              </div>
              <div>{{ $t('production.lbl.execDate') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.jobDate) ? setTimeZone(item.jobDate) : '-' }}</span></div>
              <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.jobStatus')[item.status] }}</span></div>
            </div>
          </template>
          <template #item-column-act-xs="{ item }">
            <div class="d-flex flex-column">
              <IconWrap v-if="item.status == JOB_STATUS.COMPLETED || item.status == JOB_STATUS.COMPLETED_VARIANCE" class="mr-2 mb-1" :icon="'mdi-printer'" :disabled="item.printLoading" @click.native.stop="report(item)" />
              <AppBtn class="mr-2" small height="32" @click.stop="goToPrintLabel(item)">{{ $t('production.act.printLabel') }}</AppBtn>
            </div>
          </template>

          <template #item-qty="{ item }">
            {{ `${item.qty} ${item.uom}` }}
          </template>
          <template #item-soNo="{ item }">
            {{ !isUndefinedNullOrEmpty(item.soNo) ? item.soNo : '-' }}
          </template>
          <template #item-jobDate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.jobDate) ? setTimeZone(item.jobDate) : '-' }}
          </template>
          <template #item-status="{ item }">
            {{ $t('map.jobStatus')[item.status] }}
          </template>
          <template #item-notify="{ item }">
            <v-icon small :color="item.notify ? 'green' : 'red'">{{ item.notify ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
          </template> -->
          <template #item-actions="{ item }">
            <div class="d-flex justify-end">
              <IconWrap v-if="item.status == JOB_STATUS.COMPLETED || item.status == JOB_STATUS.COMPLETED_VARIANCE" class="mr-2" :icon="'mdi-printer'" :disabled="item.printLoading" @click.native.stop="report(item)" />
              <AppBtn class="mr-2" small height="32" @click.stop="goToPrintLabel(item)">{{ $t('production.act.printLabel') }}</AppBtn>
            </div>
          </template>
        </DataTable>
      </app-card>
    </v-col>
    <!-- <CreateModal v-if="createModalShow" :id="id" :show-modal.sync="createModalShow" @close="closeModal" @getList="getListNTotalRec" /> -->
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import { jobApi } from '@/api/job';
import { PRODUCT_TYPE, JOB_STATUS } from '@/common/enum';
import { mapState } from 'vuex';

const JOB_GRP = {
  OPEN: 1,
  COMPLETED: 2,
  CANCELLED: 3,
  ALL: 4,
}

export default {
  name: 'ProductionList',
  components: {},
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('outlet.title.deleteBranch'),
      confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        total: 0,
      },
      searchVal: '',
      data: [],
      id: null,
      status: 0,
      selectedJobGrp: 1,
      selectOpts: [
        { text: this.$t('production.selectOpt.open'), value: JOB_GRP.OPEN },
        { text: this.$t('production.selectOpt.completed'), value: JOB_GRP.COMPLETED },
        { text: this.$t('production.selectOpt.cancelled'), value: JOB_GRP.CANCELLED },
        { text: this.$t('production.selectOpt.all'), value: JOB_GRP.ALL },
      ],
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          // { text: '#', width: '50', value: 'no' },
          { text: this.$t('production.lbl.jobNo'), width: '130', value: 'jobNo' },
          { text: this.$t('production.lbl.execDate'), width: '150', value: 'jobDate' },
          // { text: this.$t('production.lbl.soNo'), width: '120', value: 'soNo' },
          { text: this.$t('production.lbl.jobType'), width: '70', value: 'typeName' },
          { text: this.$t('production.lbl.productName'), width: '180', value: 'product' },
          { text: this.$t('production.lbl.qty'), width: '100', value: 'qty' },
          { text: this.$t('production.lbl.totalBatch'), width: '100', value: 'totBatch' },
          { text: this.$t('lbl.status'), width: '130', value: 'status' },
          { text: this.$t('lbl.cby'), width: '120', value: 'cby' },
          // { text: this.$t('lbl.actionBy'), width: '120', value: 'aby' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
    JOB_STATUS() {
      return JOB_STATUS;
    },
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getListHandler();
  },
  methods: {
    selectedJobGrpChg(value) {
      this.getListHandler();
    },
    viewDetails(item) {
      this.navigatePush('ProductionUpdate', { id: item.id });
    },
    delete(id) {
      this.confirmActionLoading = true;
      return jobApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getListHandler();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getListHandler() {
      switch(this.selectedJobGrp) {
        case JOB_GRP.OPEN:
          this.apiFunc = jobApi.listOpen;
          break;
        case JOB_GRP.COMPLETED:
          this.apiFunc = jobApi.listCompleted;
          break;
        case JOB_GRP.CANCELLED:
          this.apiFunc = jobApi.list;
          break;
        case JOB_GRP.ALL:
          this.apiFunc = jobApi.list;
          break;
        default:
          this.apiFunc = jobApi.list;
          break;
      }
      this.getListNTotalRec();
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        if (this.pagination.total > 0) {
          this.getList();
        }
      });
    },
    getTotalRec() {
      const params = {
        orgId: null,
        orderBy: '',
        page: 0,
      };
      return this.apiFunc(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      let params = {
        orgId: null,
        orderBy: '',
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };

      if (this.selectedJobGrp == JOB_GRP.CANCELLED) params.status = JOB_STATUS.CANCELLED;

      return this.apiFunc(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
            printLoading: false,
          }
        });
      });
    },
    report(item) {
      const data = {
        id: item.id,
      }
      item.printLoading = true;
      return jobApi.report(data).then(res => {
        item.printLoading = false;

        const blob = res.data;
        const fileName = `${data.id}`;

        const file = new File([blob], fileName, {
          type: blob.type,  
        });

        const reportUrl = URL.createObjectURL(file);

        window.open(reportUrl, '_blank');
      }).catch(() => {
        item.printLoading = false;
      });
    },
    pageChg(data) {
      this.getListHandler();
    },
    pageSizeChg(data) {
      this.getListHandler();
    },
    goToPrintLabel(item) {
      if (item.type == PRODUCT_TYPE.FGOODS) {
        this.navigatePush('FGoodsLabel', { jobNo: item.jobNo });
      } else if (item.type === PRODUCT_TYPE.SEMIFGOODS) {
        this.navigatePush('SemiFGoodsLabel', { jobNo: item.jobNo });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.grid-wrap {
  display: grid;
  grid-template-columns: 250px 250px;

  @include for-sm {
    grid-template-columns: auto;
    width: 100%;
  }
}
</style>