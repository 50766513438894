<template>
  <v-badge v-model="getNotiBadgeShow" bordered color="red" dot overlap>
    <v-icon class="cursor-pointer" color="#333333" @click="navigatePush('Dashboard')">mdi-bell</v-icon>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DefaultNotifications',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getNotiBadgeShow']),
  },
};
</script>
