<template>
  <div>
    <component :is="getComponent"></component>
  </div>
</template>
<script>
import List from './List';
import Details from './Details';
export default {
  name: 'RecipeCategory',
  components: {
    List,
    Details,
  },
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'RecipeCategoryList':
          return 'List';
        case 'RecipeCategoryCreate':
          return 'Details';
        case 'RecipeCategoryUpdate':
          return 'Details';
        default:
          return 'List';
      }
    },
  }
}
</script>