/*
Naming convention
  Module: Eg. Supplier, Raw Mat, etc.
  Type: act-action, lbl-label, success, error

  {module}.{type}.{actual wording short form}
  {module}.{sub module}.{type}.{actual wording short form}
*/

import labels from './labels/en';
import maps from './maps/en';
export default {
  ...labels,
  ...maps,
  'app.name': 'Food Chain',

  'act.activate': 'Activate',
  'act.approve': 'Approve',
  'act.back': 'Back',
  'act.cancel': 'Cancel',
  'act.close': 'Close',
  'act.confirm': 'Confirm',
  'act.create': 'Create',
  'act.delete': 'Delete',
  'act.done': 'Done',
  'act.new': 'New',
  'act.next': 'Next',
  'act.no': 'No',
  'act.receive': 'Receive',
  'act.refresh': 'Refresh',
  'act.reject': 'Reject',
  'act.save': 'Save',
  'act.search': 'Search',
  'act.select': 'Select',
  'act.START': 'START',
  'act.submit': 'Submit',
  'act.suspend': 'Suspend',
  'act.takePhoto': 'Take Photo',
  'act.update': 'Update',
  'act.view': 'View',
  'act.yes': 'Yes',

  err: {
    lbl: {
      imgUploadFailed: 'Failed to upload image',
      invalidFileType: 'Invalid file type. We only support {value}',
      invalidPhoneFormat: 'Invalid Phone Format',
      invalidSerialNo: 'Invalid Serial No.',
      uploadMaxSize: 'File size exceeds {value}',
    },
  },

  'dash.lbl.storeSales': 'Store Sales',
  'dash.lbl.bizDate': 'Date',
  'dash.lbl.store': 'Store',
  'dash.lbl.cashSales': 'Cash Sales',
  'dash.lbl.totOrder': 'Total Orders',
  'dash.lbl.totSales': 'Total Sales',
  'dash.lbl.salesSlot1': '8AM - 11AM',
  'dash.lbl.salesSlot2': '11AM - 2PM',
  'dash.lbl.salesSlot3': '2PM - 5PM',
  'dash.lbl.salesSlot4': '5PM - 8PM',
  'dash.lbl.salesSlot5': '8PM - 11PM',
  'dash.lbl.firstOrder': 'First Order',
  'dash.lbl.lastOrder': 'Last Order',

  'fGoods.act.createFGoods': 'Create Finished Goods',
  'fGoods.lbl.balance': 'Balance',
  'fGoods.lbl.branch': 'Branch',
  'fGoods.lbl.category': 'Category',
  'fGoods.lbl.code': 'Code / SKU',
  'fGoods.lbl.desc': 'Description',
  'fGoods.lbl.dimension': 'Dimension',
  'fGoods.lbl.forSale': 'For Sale',
  'fGoods.lbl.jobNo': 'Job No.',
  'fGoods.lbl.shelfLife': 'Shelf Life (Day)',
  'fGoods.lbl.shortDesc': 'Short Description',
  'fGoods.lbl.shortName': 'Short Name',
  'fGoods.lbl.subCategory': 'Sub Category',
  'fGoods.lbl.warehouse': 'Warehouse',
  'fGoods.title.createFGoods': 'Create Finished Goods',
  'fGoods.title.deleteFGoods': 'Delete Finished Goods',
  'fGoods.title.updateFGoods': 'Update Finished Goods',

  'fGoods.print.act.printLabel': 'Print Label',
  'fGoods.print.act.purgeLabelByGrnNo': 'Purge Label By Grn No.',
  'fGoods.print.act.purgeAllLabel': 'Purge All Label',
  'fGoods.print.act.voidLabel': 'Void Label',
  'fGoods.print.createLbl.note': 'Make sure the Job Status is Confirmed in order to print the label',
  'fGoods.print.lbl.itemDesc': 'Item Description',
  'fGoods.print.lbl.noOfLabels': 'No. Of Labels',
  'fGoods.print.lbl.serialNo': 'Serial No.',
  'fGoods.print.lbl.qty': 'Qty',
  'fGoods.print.lbl.purgeSuccess': 'Purged Successfully',
  'fGoods.print.queue.note': 'List of Finished Goods Label Print Queue',
  'fGoods.print.voidLabel.note': 'Void product label which fulfill the following conditions:',
  'fGoods.print.voidLabel.point1': 'The label is not voided before.',
  'fGoods.print.voidLabel.point2': 'The label has not stock-in before.',
  'fGoods.print.voidLabel.point3': 'The label does not have any inventory movement record.',

  'grn.act.newGrn': 'New GRN',
  'grn.act.selectPO': 'Select P.O.',
  'grn.act.addGrnItem': 'Add GRN Item',
  'grn.act.updateGrnItem': 'Update GRN Item',
  'grn.error.plsReceiveItem': 'You have not received any items yet',
  'grn.item.lbl.balanceQty': 'Balance Qty',
  'grn.item.lbl.batchNo': 'Batch No.',
  'grn.item.lbl.code': 'Item Code',
  'grn.item.lbl.expDate': 'Expiry Date',
  'grn.item.lbl.name': 'Item Name',
  'grn.item.lbl.orderQty': 'Order Qty',
  'grn.item.lbl.qtyReceived': 'Receive Qty',
  'grn.item.lbl.temperature': 'Temperature (˚C)',
  'grn.item.lbl.uom': 'UOM.',
  'grn.lbl.branch': 'Branch',
  'grn.lbl.grnDate': 'GRN Date',
  'grn.lbl.grnItems': 'GRN Items',
  'grn.lbl.grnNo': 'GRN No.',
  'grn.lbl.labelPrinted': 'Label Printed?',
  'grn.lbl.purchaseOrder': 'Purchase Order',
  'grn.lbl.poNo': 'P.O. No.',
  'grn.lbl.storage': 'Storage',
  'grn.lbl.supplier': 'Supplier',
  'grn.lbl.supplierDoNo': "Supplier's D.O. Number",
  'grn.lbl.step1': 'Step 1: Select Purchase Order',
  'grn.lbl.step2': 'Step 2:',
  'grn.print.act.printLabel': 'Print Label',

  'inventory.act.cancelProduction': 'Cancel Production',
  'inventory.act.stockAdjustment': 'Stock Adjustment',
  'inventory.act.stockIn': 'Stock In',
  'inventory.act.stockOut': 'Stock Out',
  'inventory.lbl.from': 'From',
  'inventory.lbl.jobNo': 'Job No.',
  'inventory.lbl.jobSheetNo': 'Job Sheet No.',
  'inventory.lbl.reOrderLvl': 'ReOrder Level',
  'inventory.lbl.reOrderQty': 'ReOrder Quantity',
  'inventory.lbl.parLvl': 'Par Level',
  'inventory.lbl.qty': 'Qty',
  'inventory.lbl.scanFGoodsQr': 'Scan finished goods label QR code',
  'inventory.lbl.scanRawMatQr': 'Scan raw material label QR code',
  'inventory.lbl.scanQr': 'Scan label QR code',
  'inventory.lbl.scanSemiFGoodsQr': 'Scan semi finished goods label QR code',
  'inventory.lbl.serialNo': 'Serial No.',
  'inventory.lbl.stockInViaGrnSnNo': 'Click here to stock in via GRN No. or Serial No.',
  'inventory.lbl.stockInViaJobSnNo': 'Click here to stock in via Job No. and Serial No.',
  'inventory.lbl.stockInViaQrCode': 'Click here to stock in via QR Code',
  'inventory.lbl.stockOutViaJobSnNo': 'Click here to stock out via Job Sheet No. and Serial No.',
  'inventory.lbl.stockOutViaQrCode': 'Click here to stock out via QR Code',
  'inventory.lbl.storage': 'Storage',
  'inventory.lbl.to': 'To',
  'inventory.lbl.type': 'Type',
  'inventory.lbl.warehouse': 'Warehouse',

  'item.act.addItem': 'Add Item',
  'item.act.updateItem': 'Update Item',
  'item.lbl.code': 'Code',
  'item.lbl.extraQuantity': 'Extra Qty',
  'item.lbl.itemDesc': 'Item Description',
  'item.lbl.manageItemOnList': 'Manage items on the list',
  'item.lbl.quantity': 'Quantity',
  'item.lbl.rawMaterialList': 'Raw Material List',
  'item.lbl.salesTax': 'Sales Tax (RM)',
  'item.lbl.salesTaxPercent': 'Sales Tax (%)',
  'item.lbl.subtotal': 'Subtotal (RM)',
  'item.lbl.taxCode': 'Tax Code',
  'item.lbl.subtotalInclTax': 'Subtotal Inc. Tax (RM)',
  'item.lbl.unitPrice': 'Unit Price (RM)',
  'item.title.addItem': 'Add Item',
  'item.title.deleteItem': 'Delete Item',
  'item.title.updateItem': 'Update Item',

  'lbl.action': 'Action',
  'lbl.actionBy': 'Action By',
  'lbl.addr.addr1': 'Address 1',
  'lbl.addr.addr2': 'Address 2',
  'lbl.addr.addr3': 'Address 3',
  'lbl.addr.country': 'Country',
  'lbl.addr.postalCode': 'Postal Code',
  'lbl.addr.state': 'State',
  'lbl.cancelSuccess': 'Cancelled Successfully',
  'lbl.cancelOrder': 'Cancel Order',
  'lbl.cby': 'Created By',
  'lbl.cdate': 'Date Created',
  'lbl.createSuccess': 'Created Successfully',
  'lbl.confirm.cancel.order': 'Are you sure you want to cancel this order?',
  'lbl.confirm.delete': 'Are you sure you want to delete this item?',
  'lbl.confirm.delete.order': 'Are you sure you want to delete this order?',
  'lbl.date': 'Date',
  'lbl.deleteSuccess': 'Deleted Successfully',
  'lbl.deleteOrder': 'Delete Order',
  'lbl.id': 'ID',
  'lbl.item': 'Item',
  'lbl.no': 'No',
  'lbl.notes': 'Notes',
  'lbl.or': 'Or',
  'lbl.pleaseSelectFile': 'Please select a file for upload',
  'lbl.qty': 'Qty',
  'lbl.rejectReason': 'Reject Reason',
  'lbl.remarks': 'Remarks',
  'lbl.rm': 'RM',
  'lbl.search': 'Search',
  'lbl.select': 'Select',
  'lbl.sessionTimeout': 'Session Timeout',
  'lbl.sku': 'SKU',
  'lbl.status': 'Status',
  'lbl.stockInSuccess': 'Stock In Successfully',
  'lbl.stockOutSuccess': 'Stock Out Successfully',
  'lbl.type': 'Type',
  'lbl.updateSuccess': 'Updated Successfully',
  'lbl.yes': 'Yes',

  'login.act.login': 'Login',
  'login.act.logout': 'Logout',
  'login.act.setPassword': 'Set Password',
  'login.lbl.confirmNewPassword': 'Confirm New Password',
  'login.lbl.newPassword': 'New Password',
  'login.lbl.password': 'Password',
  'login.lbl.setPassword': 'Set Password',
  'login.lbl.username': 'Username',

  'menu.lbl.branches': 'Branch',
  'menu.lbl.dashboard': 'Dashboard',
  'menu.lbl.fGoods': 'Finished Goods',
  'menu.lbl.fGoodsInventory': 'Finished Goods Inventory',
  'menu.lbl.fGoodsLbl': 'Finished Goods Label',
  'menu.lbl.goodsReceive': 'Goods Receive',
  'menu.lbl.grnList': 'GRN List',
  'menu.lbl.knowledge': 'Knowledge',
  'menu.lbl.inventory': 'Inventory',
  'menu.lbl.inventoryView': 'Inventory View',
  'menu.lbl.lblPrinting': 'Label Printing',
  'menu.lbl.maintenance': 'Maintenance',
  'menu.lbl.manageCategories': 'Manage Categories',
  'menu.lbl.manageRecipes': 'Manage Recipes',
  'menu.lbl.production': 'Production',
  'menu.lbl.productionDashboard': 'Production Dashboard',
  'menu.lbl.productionListing': 'Production Listing',
  'menu.lbl.procurement': 'Procurement',
  'menu.lbl.purchaseOrder': 'Purchase Order',
  'menu.lbl.purchaseOrderList': 'Purchase Order List',
  'menu.lbl.qcAndReplenish': 'QC & Replenish',
  'menu.lbl.qc': 'QC',
  'menu.lbl.rawMat': 'Raw Material / Trade Item',
  'menu.lbl.rawMatInventory': 'Raw Material Inventory',
  'menu.lbl.rawMatLbl': 'Raw Material Label',
  'menu.lbl.recipeMgmt': 'Recipe Management',
  'menu.lbl.recipeCategories': 'Recipe Category',
  'menu.lbl.replenish': 'Replenish',
  'menu.lbl.salesOrderList': 'Sales Order List',
  'menu.lbl.semiFGLbl': 'Semi F.G. Label',
  'menu.lbl.semiFinishedGoods': 'Semi Finished Goods',
  'menu.lbl.semiFGoodsInventory': 'Semi F.G. Inventory',
  'menu.lbl.settings': 'Settings',
  'menu.lbl.stockOrder': 'Stock Order',
  'menu.lbl.suppliers': 'Supplier',
  'menu.lbl.sysSettings': 'System Settings',
  'menu.lbl.warehouses': 'Warehouse',

  'noti.lbl.notification': 'Notification',
  'noti.lbl.details': 'Notification Details',

  'outlet.act.addBranch': 'Add New Branch',
  'outlet.lbl.addr1': 'Address 1',
  'outlet.lbl.addr2': 'Address 2',
  'outlet.lbl.addr3': 'Address 3',
  'outlet.lbl.city': 'City',
  'outlet.lbl.country': 'Country',
  'outlet.lbl.code': 'Branch Code',
  'outlet.lbl.contactPerson': 'Contact Person',
  'outlet.lbl.fax': 'Fax',
  'outlet.lbl.isDefault': 'Is Default Branch',
  // 'outlet.lbl.isFranchisee': 'Is Franchisee',
  'outlet.lbl.mobileNo': 'Mobile No.',
  'outlet.lbl.name': 'Branch Name',
  'outlet.lbl.postcode': 'Postal Code',
  'outlet.lbl.shortName': 'Branch Short Name',
  'outlet.lbl.state': 'State',
  'outlet.lbl.timeZone': 'Time Zone',
  'outlet.lbl.type': 'Type',
  'outlet.title.createBranch': 'Create Branch',
  'outlet.title.deleteBranch': 'Delete Branch',
  'outlet.title.updateBranch': 'Update Branch',

  'production.act.addNewJob': 'Add New Job',
  'production.act.printLabel': 'Print Label',
  'production.lbl.actualProdQty': 'Actual Production Quantity',
  'production.lbl.batchNo': 'Batch No.',
  'production.lbl.completeJob': 'Complete Job',
  'production.lbl.execBy': 'Exec. By',
  'production.lbl.execDate': 'Exec. Date',
  'production.lbl.expectedProdQty': 'Expected Production Quantity',
  'production.lbl.fGoods': 'Finished Goods',
  'production.lbl.finalQty': 'Final Quantity',
  'production.lbl.ingredients': 'Ingredients',
  'production.lbl.jobExecDateTime': 'Job Execution Date/Time',
  'production.lbl.jobNo': 'Job No.',
  'production.lbl.jobStatus': 'Job Status',
  'production.lbl.jobType': 'Type',
  'production.lbl.noOfBatch': 'No. of Batch',
  'production.lbl.productName': 'Product Name',
  'production.lbl.qty': 'Qty',
  'production.lbl.recipe': 'Recipe',
  'production.lbl.salesOrderNo': 'Sales Order No.',
  'production.lbl.semiFGoods': 'Semi Finished Goods',
  'production.lbl.soNo': 'S/O No.',
  'production.lbl.totalBatch': 'Total Batch',
  'production.lbl.workInProgress': 'In Progress',
  'production.msg.cancelSuccess': 'Cancelled Successfully',
  'production.msg.jobCompletedSuccess': 'Job Completed Successfully',
  'production.msg.jobStartSuccess': 'Job Started Successfully',
  'production.error.provideJobNoNSn': 'Please provide Job Sheet No and Serial No',

  production: {
    selectOpt: {
      all: 'All Jobs',
      open: 'Open Jobs',
      cancelled: 'Cancelled Jobs',
      completed: 'Completed Jobs',
    }
  },

  'productPrice.act.addSuppPrice': 'Add New Supplier & Pricing',
  'productPrice.lbl.addSuppPrice': 'Add Suppliers & Pricing for the Item',
  'productPrice.lbl.costExclTax': 'Cost (Excl. Tax)',
  'productPrice.lbl.costInclTax': 'Cost (Incl. Tax)',
  'productPrice.lbl.default': 'Default',
  'productPrice.lbl.itemDesc': "Supplier's Item Description",
  'productPrice.lbl.qtyVol': 'Qty/ Volume',
  'productPrice.lbl.salesTax%': 'Sales Tax (%)',
  'productPrice.lbl.salesTaxRm': 'Sales Tax (RM)',
  'productPrice.lbl.supplier': 'Supplier',
  'productPrice.lbl.taxCode': 'Tax Code',
  'productPrice.title.addSuppPrice': 'Add New Supplier & Pricing',
  'productPrice.title.deleteSuppPrice': 'Delete Supplier & Pricing',
  'productPrice.title.updateSuppPrice': 'Update Supplier & Pricing',

  'productCert.act.addCert': 'Add Cert',
  'productCert.lbl.addCert': 'Add Supplier Certificate for the Item',
  'productCert.lbl.certificate': 'Certificate',
  'productCert.lbl.certType': 'Type',
  'productCert.lbl.expiry': 'Expiry Date',
  'productCert.lbl.issue': 'Issue Date',
  'productCert.lbl.notes': 'Notes',
  'productCert.lbl.refNo': 'Ref No',
  'productCert.title.addCert': 'Add Cert',
  'productCert.title.deleteCert': 'Delete Cert',
  'productCert.title.updateCert': 'Update Cert',

  'purchaseOrder.act.newPO': 'New P.O.',
  'purchaseOrder.act.completePO': 'Complete P.O.',
  'purchaseOrder.act.selectBranch': 'Select Branch',
  'purchaseOrder.act.selectSupplier': 'Select Supplier',
  'purchaseOrder.lbl.branch': 'Branch',
  'purchaseOrder.lbl.contactPerson': 'Contact Person',
  'purchaseOrder.lbl.deliveryDate': 'Delivery Date',
  'purchaseOrder.lbl.department': 'Department',
  'purchaseOrder.lbl.email': 'Email',
  'purchaseOrder.lbl.fax': 'Fax',
  'purchaseOrder.lbl.poNo': 'P.O. No.',
  'purchaseOrder.lbl.poDate': 'P.O. Date',
  'purchaseOrder.lbl.poStatus': 'P.O. Status',
  'purchaseOrder.lbl.supplier': 'Supplier',
  'purchaseOrder.lbl.supplierName': 'Supplier Name',
  'purchaseOrder.lbl.tel': 'Telephone',
  'purchaseOrder.subSec.poDetails': 'Purchase Order Details',
  'purchaseOrder.subSec.supplier': 'Supplier',
  'purchaseOrder.subSec.shipTo': 'Ship To (Branch)',
  'purchaseOrder.success.approve': 'Purchase order has been approved',

  purchaseOrder: {
    selectOpt: {
      all: 'All',
      open: 'Open',
      confirmed: 'Confirmed',
      approved: 'Approved',
      cancelled: 'Cancelled',
      completed: 'Completed',
    }
  },

  'qc.lbl.adjType': 'Adjustment Type',
  'qc.lbl.balance': 'Balance',
  'qc.lbl.cashAmt': 'Cash Amount',
  'qc.lbl.dispose': 'Dispose',
  'qc.lbl.result': 'Result',
  'qc.lbl.store': 'Store',
  'qc.success.completed': 'Quality Check completed',

  'rawMat.act.createRawMat': 'Create Raw Material',
  'rawMat.lbl.balance': 'Balance',
  'rawMat.lbl.branch': 'Branch',
  'rawMat.lbl.category': 'Category',
  'rawMat.lbl.code': 'Code',
  'rawMat.lbl.desc': 'Description',
  'rawMat.lbl.forSale': 'For Sale',
  'rawMat.lbl.lblPrintOpt': 'Label Print Option',
  'rawMat.lbl.shortName': 'Short Name',
  'rawMat.lbl.subCategory': 'Sub Category',
  'rawMat.lbl.warehouse': 'Warehouse',

  'rawMat.print.act.printLabel': 'Print Label',
  'rawMat.print.act.purgeLabelByGrnNo': 'Purge Label By Grn No.',
  'rawMat.print.act.purgeAllLabel': 'Purge All Label',
  'rawMat.print.act.voidLabel': 'Void Label',
  'rawMat.print.step1': 'Step 1: Key in GRN Number',
  'rawMat.print.step2': 'Step 2: Check Raw Material Label Settings',
  'rawMat.print.step2.note': "Changing the UOM selection WILL NOT update the Raw Material's label printing option and UOM settings in Maintenance.",
  'rawMat.print.queue.note': 'List of Raw Material Label Print Queue',
  'rawMat.print.voidLabel.note': 'Void raw material label which fulfill the following conditions:',
  'rawMat.print.voidLabel.point1': 'The label is not voided before.',
  'rawMat.print.voidLabel.point2': 'The label has not stock-in before.',
  'rawMat.print.voidLabel.point3': 'The label does not have any inventory movement record.',
  'rawMat.print.lbl.chgUomIfNeeded': 'Change UOM if needed',
  'rawMat.print.lbl.itemDesc': 'Item Description',
  'rawMat.print.lbl.noOfLabels': 'No. Of Labels',
  'rawMat.print.lbl.printOpt': 'Print Option',
  'rawMat.print.lbl.printedBefore': 'Printed Before?',
  'rawMat.print.lbl.printedLabels': 'Printed Labels',
  'rawMat.print.lbl.serialNo': 'Serial No.',
  'rawMat.print.lbl.qty': 'Qty',
  'rawMat.print.lbl.purgeSuccess': 'Purged Successfully',

  'rawMat.title.createRawMat': 'Create Raw Material',
  'rawMat.title.deleteRawMat': 'Delete Raw Material',
  'rawMat.title.updateRawMat': 'Update Raw Material',

  'recipe.act.addNewCategory': 'Add New Category',
  'recipe.act.addNewIngredient': 'Add New Ingredient',
  'recipe.act.addNewRecipe': 'Add New Recipe',
  'recipe.act.updateIngredient': 'Update Ingredient',
  'recipe.lbl.avgActualQty': 'Average Actual Yield Quantity (Per Batch)',
  'recipe.lbl.category': 'Category',
  'recipe.lbl.category.code': 'Category Code',
  'recipe.lbl.category.desc': 'Category Description',
  'recipe.lbl.category.name': 'Category Name',
  'recipe.lbl.category.note1': 'List of Recipe Categories',
  'recipe.lbl.category.note2': 'Click on one of the tiles to edit',
  'recipe.lbl.category.plsFillInNote': 'Please fill in the category information',
  'recipe.lbl.dimension': 'Dimension',
  'recipe.lbl.expectedQty': 'Expected Produced Quantity (Per Batch)',
  'recipe.lbl.ingredient': 'Ingredient',
  'recipe.lbl.ingredient.note1': 'Add the Ingredients for the recipe',
  'recipe.lbl.ingredient.qty': 'Quantity',
  'recipe.lbl.isApproved': 'Is Approved?',
  'recipe.lbl.isPublished': 'Is Published?',
  'recipe.lbl.listOfRecipeCategory': 'List of Recipe Categories',
  'recipe.lbl.listOfRecipes': 'List of Recipes',
  'recipe.lbl.recipe': 'Recipe',
  'recipe.lbl.recipeName': 'Recipe Name',
  'recipe.lbl.recipeList': 'Recipe List',
  'recipe.lbl.selectCategory': 'Select a category',
  'recipe.lbl.type': 'Type',
  'recipe.title.deleteIngredient': 'Delete Ingredient',

  'replenish.act.placeOrder': 'Place Order',
  'replenish.lbl.replenishForStore': 'Replenish for Store',
  'replenish.lbl.confirm.place': 'Are you sure to place this order?',
  'replenish.lbl.balance': 'Balance',
  'replenish.lbl.customer': 'Customer',
  'replenish.lbl.item': 'Item',
  'replenish.lbl.category': 'Category',
  'replenish.lbl.maxLoad': 'Max Load',
  'replenish.lbl.refillQty': 'Refill Qty',
  'replenish.lbl.servings/UOM': 'Servings/UOM',
  'replenish.lbl.selectCustomer': 'Select Customer',
  'replenish.lbl.selectStore': 'Select Store',
  'replenish.lbl.store': 'Store',
  'replenish.lbl.uom': 'UOM',
  'replenish.lbl.used': 'Used',

  'salesOrder.act.newSalesOrder': 'New Sales Order',
  'salesOrder.act.selectBranch': 'Select Branch',
  'salesOrder.act.selectCustomer': 'Select Customer',
  'salesOrder.lbl.branch': 'Branch',
  'salesOrder.lbl.customer': 'Customer',
  'salesOrder.lbl.customerName': 'Customer Name',
  'salesOrder.lbl.contactPerson': 'Contact Person',
  'salesOrder.lbl.deliveryDate': 'Delivery Date',
  'salesOrder.lbl.department': 'Department',
  'salesOrder.lbl.email': 'Email',
  'salesOrder.lbl.fax': 'Fax',
  'salesOrder.lbl.item': 'Item',
  'salesOrder.lbl.soNo': 'Sales Order No.',
  'salesOrder.lbl.soDate': 'Sales Order Date',
  'salesOrder.lbl.tel': 'Telephone',
  'salesOrder.lbl.orderQty': 'Order Qty',
  'salesOrder.subSec.customer': 'Customer',
  'salesOrder.subSec.soDetails': 'Sales Order Details',
  'salesOrder.subSec.from': 'From (Branch)',
  'salesOrder.success.approve': 'Sales order has been approved',
  'salesOrder.success.stockOut': 'Stock out successfully',

  'semiFGoods.act.createSemiFGoods': 'Create Semi Finished Goods',
  'semiFGoods.lbl.balance': 'Balance',
  'semiFGoods.lbl.branch': 'Branch',
  'semiFGoods.lbl.category': 'Category',
  'semiFGoods.lbl.code': 'Code',
  'semiFGoods.lbl.desc': 'Description',
  'semiFGoods.lbl.forSale': 'For Sale',
  'semiFGoods.lbl.jobNo': 'Job No.',
  'semiFGoods.lbl.noOfLabels': 'No. Of Labels',
  'semiFGoods.lbl.qty': 'Qty',
  'semiFGoods.lbl.shelfLife': 'Shelf Life (Day)',
  'semiFGoods.lbl.shortDesc': 'Short Description',
  'semiFGoods.lbl.shortName': 'Short Name',
  'semiFGoods.lbl.subCategory': 'Sub Category',
  'semiFGoods.lbl.warehouse': 'Warehouse',
  'semiFGoods.title.createSemiFGoods': 'Create Semi Finished Goods',
  'semiFGoods.title.deleteSemiFGoods': 'Delete Semi Finished Goods',
  'semiFGoods.title.updateSemiFGoods': 'Update Semi Finished Goods',

  'semiFGoods.print.act.printLabel': 'Print Label',
  'semiFGoods.print.act.purgeLabelByGrnNo': 'Purge Label By Grn No.',
  'semiFGoods.print.act.purgeAllLabel': 'Purge All Label',
  'semiFGoods.print.act.voidLabel': 'Void Label',
  'semiFGoods.print.createLbl.note': 'Make sure the Job Status is Confirmed in order to print the label',
  'semiFGoods.print.lbl.itemDesc': 'Item Description',
  'semiFGoods.print.lbl.noOfLabels': 'No. Of Labels',
  'semiFGoods.print.lbl.serialNo': 'Serial No.',
  'semiFGoods.print.lbl.qty': 'Qty',
  'semiFGoods.print.lbl.purgeSuccess': 'Purged Successfully',
  'semiFGoods.print.queue.note': 'List of Semi Finished Goods Label Print Queue',
  'semiFGoods.print.voidLabel.note': 'Void product label which fulfill the following conditions:',
  'semiFGoods.print.voidLabel.point1': 'The label is not voided before.',
  'semiFGoods.print.voidLabel.point2': 'The label has not stock-in before.',
  'semiFGoods.print.voidLabel.point3': 'The label does not have any inventory movement record.',

  'stockOrder.act.stockOut': 'Stock Out',
  'stockOrder.lbl.doDate': 'D.O. Date',
  'stockOrder.lbl.doQty': 'D.O. Qty',
  'stockOrder.lbl.item': 'Item',
  'stockOrder.lbl.order': 'Order',
  'stockOrder.lbl.orderId': 'Order ID',
  'stockOrder.lbl.orderItemList': 'Order Item List',
  'stockOrder.lbl.orderQty': 'Order Qty',
  'stockOrder.lbl.refNo': 'Ref. No.',
  'stockOrder.lbl.stockOutFailedForItems': 'Stock Out failed for items below',
  'stockOrder.lbl.stockOutItems': 'Stock out items',
  'stockOrder.lbl.store': 'Store',
  'stockOrder.lbl.totQty': 'Total Qty',
  'stockOrder.lbl.type': 'Type',
  'stockOrder.success.approve': 'Stock order has been approved',
  'stockOrder.success.stockOut': 'Stock out successfully',

  'supp.act.addSupp': 'Add Supplier',
  'supp.lbl.addr1': 'Address 1',
  'supp.lbl.addr2': 'Address 2',
  'supp.lbl.addr3': 'Address 3',
  'supp.lbl.city': 'City',
  'supp.lbl.code': 'Supplier Code',
  'supp.lbl.compRegNo': 'Company Reg. No.',
  'supp.lbl.contactPerson': 'Contact Person',
  // 'supp.lbl.contactPerson1': 'Contact Person #1',
  // 'supp.lbl.contactPerson2': 'Contact Person #2',
  'supp.lbl.country': 'Country',
  'supp.lbl.email': 'Email Address',
  'supp.lbl.fax': 'Fax',
  'supp.lbl.gstRegNo': 'GST Reg. No.',
  'supp.lbl.mobileNo': 'Mobile No.',
  'supp.lbl.name': 'Supplier Name',
  'supp.lbl.pymtTerm': 'Payment Term',
  'supp.lbl.postalCode': 'Postal Code',
  'supp.lbl.shortName': 'Supplier Short Name',
  'supp.lbl.state': 'State',
  'supp.lbl.tel': 'Telephone',
  'supp.title.createSupplier': 'Create Supplier',
  'supp.title.deleteSupplier': 'Delete Supplier',
  'supp.title.updateSupplier': 'Update Supplier',

  time: {
    lbl: {
      ago: 'ago',
      day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      min: 'min',
      mins: 'mins',
      month: 'month',
      months: 'months',
      sec: 'sec',
      secs: 'secs',
      year: 'year',
      years: 'years',
    }
  },

  'uom.act.addNewUom': 'Add New UOM',
  'uom.lbl.addUomForItem': 'Add UOM for the item',
  'uom.lbl.baseMultiplier': 'Base Multiplier',
  'uom.lbl.baseUom': 'Base UOM',
  'uom.lbl.baseUom.desc': 'The base unit of this Product',
  'uom.lbl.inventoryUom': 'Inventory UOM',
  'uom.lbl.inventoryUom.desc': 'The unit used for Stock Inventory',
  'uom.lbl.name': 'UOM Name',
  'uom.lbl.poUom': 'P.O. UOM',
  'uom.lbl.poUom.desc': 'The unit used for Purchaser Order',
  'uom.lbl.salesUom': 'Sales UOM',
  'uom.lbl.salesUom.desc': 'The unit used for Sales Order',
  'uom.lbl.unitOfMeasurement': 'Unit of Measurement',
  'uom.lbl.uom': 'UOM',
  'uom.title.addNewUom': 'Add New UOM',
  'uom.title.deleteUom': 'Delete UOM',
  'uom.title.updateUom': 'Update UOM',

  'warehouse.act.addWarehouse': 'Add New Warehouse',
  'warehouse.lbl.branch': 'Branch',
  'warehouse.lbl.code': 'Code',
  'warehouse.lbl.desc': 'Description',
  'warehouse.lbl.isAfterSaleDefault': 'Is AfterSales Default?',
  'warehouse.lbl.isReceivingWarehouse': 'Is Receiving Warehouse?',
  'warehouse.lbl.shortName': 'Short Name',
  'warehouse.lbl.warehouseType': 'Warehouse Type',
  'warehouse.title.createWarehouse': 'Create Warehouse',
  'warehouse.title.deleteWarehouse': 'Delete Warehouse',
  'warehouse.title.updateWarehouse': 'Update Warehouse',

  fGoodsLabel: [
    { id: 0, label: 'Finished Goods Label' },
    // { id: 1, label: 'Re-print Label' },
    // { id: 2, label: 'Void Label' },
    { id: 3, label: 'Label Print Queue' },
    // { id: 4, label: 'OpenBal Label' },
  ],
  fGoodsTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'UOM' },
    { id: 2, label: 'Inventory' },
    { id: 3, label: 'Label (General)' },
  ],
  grnItemAct: [
    { text: 'Accept', value: 1 },
    { text: 'Reject', value: 2 },
  ],
  grnTabs: [{ id: 0, label: 'General' }],
  inventoryTabs: [{ id: 0, label: 'General' }],
  jobStatus: [
    { text: 'New', value: 0 },
    { text: 'Confirmed', value: 1 },
    // { text: 'In Progress', value: 2 },
    // { text: 'Deleted', value: 3 },
    // { text: 'Cancelled', value: 4 },
    // { text: 'Completed', value: 5 },
    // { text: 'Completed Variance', value: 6 },
  ],
  jobType: [
    { text: 'Finished Goods', value: 2 },
    { text: 'Semi Finished Goods', value: 3 },
  ],
  lblPrintOpt: [
    { id: 1, label: 'Single' },
    { id: 2, label: 'Multiple' },
  ],
  outletTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'Contact Details' },
  ],
  outletType: [
    { text: 'HQ', value: 1 },
    { text: 'Outlet', value: 2 },
    { text: 'Franchisee', value: 3 },
  ],
  productionJobType: [
    { text: 'Semi Finished Goods', value: 1 },
    { text: 'Finished Goods', value: 2 },
  ],
  productionTabs: [{ id: 0, label: 'General' }],
  purchaseOrderTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'Item List' },
  ],
  purchaseOrderSts: [
    { text: 'New', value: 0 },
    { text: 'Confirmed', value: 1 },
    { text: 'Approved', value: 2 },
    // { text: 'Deleted', value: 3 },
    // { text: 'Cancelled', value: 4 },
    { text: 'Completed', value: 9 },
  ],
  productType: [
    { text: 'Raw Materials', value: 1 },
    { text: 'Finished Goods', value: 2 },
    { text: 'Semi Finished Goods', value: 3 },
    { text: 'Non Food', value: 4 },
  ],
  rawMatGrp: [
    { text: 'Raw Material', value: 0 },
    { text: 'Trade Item', value: 1 },
  ],
  rawMatLabel: [
    { id: 0, label: 'Raw Material Label' },
    // { id: 1, label: 'Re-print Label' },
    { id: 2, label: 'Void Label' },
    { id: 3, label: 'Label Print Queue' },
    // { id: 4, label: 'OpenBal Label' },
  ],
  rawMatTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'UOM' },
    { id: 2, label: 'Inventory' },
    { id: 3, label: 'Yield' },
    { id: 4, label: 'Certificates' },
    { id: 5, label: 'Suppliers / Pricing' },
  ],
  recipeCategoryLabel: [
    { id: 0, label: 'General' },
    { id: 1, label: 'Branch Access' },
  ],
  recipeLabel: [
    { id: 0, label: 'General' },
    { id: 1, label: 'Categories' },
    { id: 2, label: 'Ingredients' },
    { id: 3, label: 'Steps' },
    { id: 4, label: 'Audit Log' },
  ],
  recipeType: [
    { text: 'Raw Materials', value: 1 },
    { text: 'Semi Finished Materials', value: 2 },
    { text: 'Linked Recipe', value: 3 },
    { text: 'Manual/Others', value: 4 },
  ],
  replenishTabs: [
    { id: 0, label: 'By Stores' },
    { id: 1, label: 'By Items' },
  ],
  salesOrderProductType: [
    { text: 'Finished Goods', value: 2 },
    { text: 'Semi Finished Goods', value: 3 },
  ],
  salesOrderSts: [
    { text: 'New', value: 0 },
    { text: 'Confirmed', value: 1 },
    { text: 'Approved', value: 2 },
    // { text: 'Deleted', value: 3 },
    // { text: 'Cancelled', value: 4 },
    { text: 'Completed', value: 9 },
  ],
  salesOrderTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'Item List' },
  ],
  semiFGoodsLabel: [
    { id: 0, label: 'Semi F.G. Label' },
    // { id: 1, label: 'Re-print Label' },
    // { id: 2, label: 'Void Label' },
    { id: 3, label: 'Label Print Queue' },
    // { id: 4, label: 'OpenBal Label' },
  ],
  semiFGoodsTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'UOM' },
    { id: 2, label: 'Inventory' },
    { id: 3, label: 'Yield' },
    { id: 4, label: 'Label (General)' },
  ],
  stockOrderTabs: [
    { id: 0, label: 'Open' },
    { id: 1, label: 'Completed' },
  ],
  supplierTabs: [
    { id: 0, label: 'General' },
    { id: 1, label: 'Contact Details' },
    { id: 2, label: 'Others' },
  ],
  taxCodeOpt: [
    { text: 'ZR', value: 'ZR' },
    { text: 'SST', value: 'SST' },
  ],
  warehouseTabs: [{ id: 0, label: 'General' }],
  route: {
    BranchList: 'Branch List',
    BranchCreate: 'Create Branch',
    Dashboard: 'Dashboard',
    FGoodsLabel: 'Finished Goods Label',
    FGoodsList: 'Finished Goods List',
    FGoodsCreate: 'Create Finished Goods',
    FGoodsInventoryList: 'Finished Goods Inventory',
    FGoodsStockIn: 'Finished Goods Stock In',
    FGoodsStockOut: 'Finished Goods Stock Out',
    GrnList: 'GRN List',
    GrnCreate: 'New GRN',
    GrnUpdate: 'Update GRN',
    ProductionList: 'Production List',
    ProductionDashboard: 'Production Dashboard',
    ProductionCreate: 'Create Job Production',
    ProductionUpdate: 'Update Job Production',
    ProductionStockOut: 'Job Stock Out',
    PurchaseOrderList: 'Purchase Order List',
    PurchaseOrderCreate: 'Create Purchase Order',
    PurchaseOrderUpdate: 'Update Purchase Order',
    RawMatLabel: 'Print Raw Material Labels',
    RawMatList: 'Raw Material List',
    RawMatCreate: 'Create Raw Material',
    RawMatInventoryList: 'Raw Materials Inventory',
    RawMatStockIn: 'Raw Materials Stock In',
    RawMatStockOut: 'Raw Materials Stock Out',
    QCList: 'Quality Check List',
    QCCreate: 'Quality Check',
    Recipe: 'Recipes',
    RecipeCategory: 'Recipe Categories',
    RecipeCategoryCreate: 'Create Recipe Category',
    RecipeCategoryUpdate: 'Update Recipe Category',
    RecipeCategoryList: 'Recipe Categories',
    RecipeCreate: 'Create Recipe',
    RecipeListByCategory: 'Category List',
    RecipeList: 'Recipe List By Category',
    Replenish: 'Replenish',
    RecipeUpdate: 'Update Recipe',
    SalesOrderList: 'Sales Order List',
    SalesOrderCreate: 'Create Sales Order',
    SalesOrderUpdate: 'Update Sales Order',
    SemiFGoodsLabel: 'Semi Finished Goods Label',
    SemiFGoodsList: 'Semi Finished Goods List',
    SemiFGoodsCreate: 'Create Semi Finished Goods',
    SemiFGoodsInventoryList: 'Semi Finished Goods Inventory',
    SemiFGoodsStockIn: 'Semi Finished Goods Stock In',
    SemiFGoodsStockOut: 'Semi Finished Goods Stock Out',
    StockOrder: 'Stock Order',
    SupplierList: 'Supplier List',
    SupplierCreate: 'Create Supplier',
    WarehouseList: 'Warehouse List',
    WarehouseCreate: 'Create Warehouse',
  },
};
