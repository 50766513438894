import { isUndefinedNullOrEmpty } from '@/util/tools';
import { recipeApi } from '@/api/recipe';

const recipe = {
  state: {
    recipeDdl: [],
  },
  getters: {},
  mutations: {
    SET_RECIPE_DDL(state, payload) {
      state.recipeDdl = payload;
    },
  },
  actions: {
    fetchRecipeDdl({ commit }, params = {}) {
      return recipeApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        commit('SET_RECIPE_DDL', list);
      });
    },
  },
};

export default recipe;
