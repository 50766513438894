<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md-2'" with-footer>
    <template v-slot:content>
      <div class="mb-3">
        <h5 class="font-black-l-1 font-0875r font-weight-regular">{{ sku }}</h5>
        <h3 class="font-black">{{ product }}</h3>
      </div>
      <div class="mb-3">
        <ItemImages class="mb-1" :list="photoList" />
        <AppBtn @click="showCameraModal">{{ $t('act.takePhoto') }}</AppBtn>
      </div>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <v-col v-if="!isUpdate" class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label">{{ $t('grn.item.lbl.orderQty') }} </div>
            <TextField v-model="localDetails.qty" :label="$t('grn.item.lbl.orderQty')" disabled />
          </v-col>
          <v-col v-if="!isUpdate" class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label">{{ $t('grn.item.lbl.balanceQty') }} </div>
            <TextField v-model="balQty" :label="$t('grn.item.lbl.balanceQty')" disabled />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('grn.item.lbl.qtyReceived') }}
            </div>
            <TextField
              v-model="localDetails.qtyToReceive"
              :type="'number'"
              :min="0.01"
              :step="decimalStep"
              :rules="rules.qtyToReceive"
              :label="$t('grn.item.lbl.qtyReceived')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('grn.item.lbl.temperature') }}
              <span class="font-red">{{ rules.temperature.required ? '*' : '' }}</span>
            </div>
            <TextField v-model="localDetails.temperature" :rules="rules.temperature" :label="$t('grn.item.lbl.temperature')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('grn.item.lbl.expDate') }}
              <span class="font-red">{{ rules.expDate.required ? '*' : '' }}</span>
            </div>
            <DatePicker v-model="localDetails.expDate" :rules="rules.expDate" :label="$t('grn.item.lbl.expDate')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('lbl.action') }}
              <span class="font-red">{{ rules.action.required ? '*' : '' }}</span>
            </div>
            <Select v-model="localDetails.action" :label="$t('lbl.action')" :options="$t('grnItemAct')" :rules="rules.action" />
          </v-col>
          <v-col v-if="localDetails.action == GRN_ITEM_ACT.REJECT" class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('lbl.rejectReason') }}
              <span class="font-red">{{ rules.reason.required ? '*' : '' }}</span>
            </div>
            <TextField v-model="localDetails.reason" :rules="rules.reason" :label="$t('lbl.rejectReason')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('grn.item.lbl.batchNo') }}
              <span class="font-red">{{ rules.batchNo.required ? '*' : '' }}</span>
            </div>
            <TextField v-model="localDetails.batchNo" :rules="rules.batchNo" :label="$t('grn.item.lbl.batchNo')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <div class="form-row-col__label"
              >{{ $t('lbl.remarks') }}
              <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
            </div>
            <TextField v-model="localDetails.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
          </v-col>
        </v-row>
      </ValidationObserver>
      <CameraModal v-if="cameraModalShow" :show-modal.sync="cameraModalShow" @confirm="addPhotoToList" @close="cameraModalShow = false" />
    </template>
    <template v-slot:footer>
      <AppBtn :disabled="loading" class="btn-cancel" @click="closeModal">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm(localDetails)">{{ $t('act.confirm') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { mapState } from 'vuex';
import { extractJsonKey, roundToDecimal } from '@/util/tools';
import { upload } from '@/util/upload';
import { GRN_ITEM_ACT } from '@/common/enum';
import { grnItemApi } from '@/api/grn';
import CameraModal from './CameraModal';
import ItemImages from './ItemImages';
import imageCompression from 'browser-image-compression';

export default {
  name: 'GRNItemModal',
  props: {
    // details: {
    //   type: Object,
    //   default: null,
    // },
    grnId: {
      type: Number,
      default: null,
    },
    headerTitle: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CameraModal,
    ItemImages,
  },
  data() {
    return {
      decimalStep: '0.01',
      sku: null,
      product: null,
      loading: false,
      cameraModalShow: false,
      photoList: [{}, {}, {}, {}, {}],
      photoLimit: 5,
      localDetails: {
        id: null,
        uom: null,
        productId: null,
        qty: 0,
        recvQty: 0,
        qtyToReceive: 1,
        temperature: null,
        expDate: null,
        action: GRN_ITEM_ACT.ACCEPT,
        batchNo: null,
        reason: null,
        notes: null,
      },
      rules: {
        expDate: {
          required: false,
        },
        qtyToReceive: {
          required: true,
          min_value: 0.01,
        },
        temperature: {
          required: false,
        },
        action: {
          required: true,
        },
        reason: {
          required: false,
        },
        batchNo: {
          required: false,
        },
        notes: {
          required: false,
        },
      },
      event: null,
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      imgMaxLimit: state => state.app.imgMaxLimit,
      imgCompressTo: state => state.app.imgCompressTo,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.resetFields();
        this.$emit('close');
      },
    },
    balQty() {
      if (!this.isUndefinedNullOrEmpty(this.localDetails.recvQty)) {
        const tempBalQty = roundToDecimal(this.localDetails.qty - this.localDetails.recvQty);
        return tempBalQty > 0 ? tempBalQty : 0;
      }
      return this.localDetails.qty;
    },
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.localDetails.id);
    },
    updateMode() {
      // this.localDetails.id could be not null if during grn creation, user created grn item.
      return !this.isUndefinedNullOrEmpty(this.localDetails.id) && this.$route.name == 'GrnUpdate';
    },
    GRN_ITEM_ACT() {
      return GRN_ITEM_ACT;
    },
  },
  created() {
    this.$eb.$on('show-grn-item-modal', event => {
      this.event = event;
      if (this.isUndefinedNullOrEmpty(this.event.data)) {
        this.$emit('close');
      } else {
        const details = this.event.data;
        const keys = [
          'id',
          'product',
          'productId',
          'uom',
          'sku',
          'qty',
          'recvQty',
          'qtyToReceive',
          'temperature',
          'expDate',
          'action',
          'batchNo',
          'reason',
          'notes',
        ];
        let extractedKeys = extractJsonKey(keys, details);
        if (!this.isUndefinedNullOrEmpty(extractedKeys.id)) {
          this.detail(extractedKeys.id); // to get grn item images
        }
        this.product = extractedKeys.product;
        this.sku = extractedKeys.sku;

        delete extractedKeys.product;
        delete extractedKeys.sku;

        this.localDetails.qtyToReceive = this.isUndefinedNullOrEmpty(extractedKeys.id)
          ? this.isUndefinedNullOrEmpty(extractedKeys.recvQty)
            ? extractedKeys.qty
            : roundToDecimal(extractedKeys.qty - extractedKeys.recvQty)
          : extractedKeys.qty;

        // const qtyToReceive = this.isUndefinedNullOrEmpty(extractedKeys.recvQty) ? extractedKeys.qty : roundToDecimal(extractedKeys.qty - extractedKeys.recvQty);
        // this.localDetails.qtyToReceive = this.isUndefinedNullOrEmpty(extractedKeys.id) ? extractedKeys.qty : qtyToReceive;
        // console.log('qtyToReceive', qtyToReceive);
        this.localDetails = { ...this.localDetails, ...extractedKeys };
      }
    });
  },
  beforeDestroy() {
    this.$eb.$off('show-grn-item-modal');
  },
  methods: {
    showCameraModal() {
      let count = 0;
      for (let item of this.photoList) {
        if (!this.isUndefinedNullOrEmpty(item.url) || !this.isUndefinedNullOrEmpty(item.imgUrl)) {
          count++;
          if (count >= this.photoLimit) {
            alert('Limit exceeded');
            return;
          }
        }
      }
      this.cameraModalShow = true;
    },
    detail(id) {
      return grnItemApi.detail({ id }).then(res => {
        this.photoList = res.data.images;
      })
    },
    closeModal() {
      this.resetFields();
      this.$emit('close');
    },
    async resetFields() {
      this.event = null;
      this.product = null;
      this.sku = null;
      this.localDetails.id = null;
      this.localDetails.uom = null;
      this.localDetails.productId = null;
      this.localDetails.qty = 0;
      this.localDetails.recvQty = 0;
      this.localDetails.qtyToReceive = 0;
      this.localDetails.temperature = null;
      this.localDetails.expDate = null;
      this.localDetails.action = GRN_ITEM_ACT.ACCEPT;
      this.localDetails.batchNo = null;
      this.localDetails.reason = null;
      this.localDetails.notes = null;
      this.photoList = [{}, {}, {}, {}, {}];
      await this.$refs.observer.reset();
    },
    processSingleItem(item) {
      return {
        id: this.isUpdate ? this.localDetails.id : null,
        grnId: this.grnId,
        productId: item.productId,
        qty: parseFloat(item.qtyToReceive),
        uom: item.uom,
        temperature: item.temperature,
        batchNo: item.batchNo,
        expDate: item.expDate,
        // storageId: ,
        reason: item.reason,
        notes: item.notes,
        action: item.action,
      }
    },
    async confirm(item) {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const data = this.processSingleItem(item);
      if (this.isUpdate) {
        this.updateGrnItem(data);
      } else {
        this.createGrnItem(data);
      }
    },
    createGrnItem(data) {
      this.loading = true;
      return grnItemApi.create(data).then(async res => {
        this.localDetails.id = res.data.id;
        await this.bulkUploadPhoto(this.photoList);
        this.loading = false;
        this.event.onSuccess({
          data: {
            ...data,
            id: res.data.id,
            isUpdate: false,
          },
        });
        this.resetFields();
      }).catch(() => {
        this.loading = false;
      });
    },
    updateGrnItem(data) {
      this.loading = true;
      return grnItemApi.update(data).then(async res => {
        await this.bulkUploadPhoto(this.photoList);
        this.loading = false;
        this.event.onSuccess({
          data: {
            ...data,
            isUpdate: true,
          },
        });
        this.resetFields();
      }).catch(() => {
        this.loading = false;
      });
    },
    // confirm(item) {
    //   if (item.action != GRN_ITEM_ACT.REJECT && !this.isUndefinedNullOrEmpty(item.reason)) {
    //     item.reason = null;
    //   }
    //   const event = {
    //     data: {
    //       isUpdate: this.isUpdate,
    //       ...item,
    //     },
    //     onSuccess: () => {
    //       this.loading = false;
    //     },
    //     onError: () => {
    //       this.loading = false;
    //     }
    //   }
    //   this.event.onSuccess(event);
    //   this.resetFields();
    // },
    addPhotoToList(photoObj) {
      for (let [index, item] of Object.entries(this.photoList)) {
        if (this.isUndefinedNullOrEmpty(item.url) && this.isUndefinedNullOrEmpty(item.imgUrl)) {
          this.$set(this.photoList, index, {
            ...this.photoList[index],
            ...photoObj, // { blob, imgUrl }
          });
          break;
        }
      }
      this.cameraModalShow = false;
    },
    async bulkUploadPhoto(photoList) {
      let promises = [];
      for (let [index, item] of Object.entries(photoList)) {
        if (!this.isUndefinedNullOrEmpty(item.imgUrl)) {
          promises.push(this.uploadCompleteSet(item.blob, this.localDetails.id, index));
        }
      }
      if (promises.length > 0) {
        return await Promise.all(promises);
      }
    },
    async uploadCompleteSet(blob, grnItemId, index) {
      let { fileObj, data } = await this.generateFileObj(blob, grnItemId, index);
      await upload(data.token, data.hostUrl, { fileObj });
    },
    async generateFileObj(blob, grnItemId, index) {
      const res = await this.preupload(grnItemId, index);
      let fileObj = new File([blob], res.data.filename, { type: 'image/jpeg' });
      if (fileObj.size > this.imgMaxLimit) {
        try {
          const options = {
            maxSizeMB: this.imgCompressTo,
            useWebWorker: false,
          }
          const compressedBlob = await imageCompression(fileObj, options);
          fileObj = new File([compressedBlob], res.data.filename, { type: 'image/jpeg' });
        } catch (error) {
          this.showError(this.$t('err.lbl.imgUploadFailed'));
          throw error;
        }
      }
      return { fileObj, data: res.data };
    },
    preupload(grnItemId, index) {
      const params = {
        id: grnItemId,
        index,
      }
      return grnItemApi.preupload(params).then(res => {
        return res;
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
