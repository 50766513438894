var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-caption mr-2"},[_vm._v(_vm._s(_vm.$t('item.lbl.manageItemOnList')))]),_c('AppBtn',{attrs:{"color":"primary","height":"30","small":"","disabled":!_vm.soId || _vm.disableEdit},on:{"click":_vm.openModal}},[_vm._v(_vm._s(_vm.$t('item.act.addItem')))])],1),_c('DataTable',{class:'cursor-pointer',attrs:{"headers":_vm.headers,"items":_vm.data,"mobile-breakpoint":0,"hide-default-header":_vm.hideHeader,"hide-default-footer":true,"pagination-config":_vm.pagination,"disable-pagination":""},on:{"update:paginationConfig":function($event){_vm.pagination=$event},"update:pagination-config":function($event){_vm.pagination=$event},"click:row":_vm.editItem,"pageChg":_vm.pageChg,"pageSizeChg":_vm.pageSizeChg},scopedSlots:_vm._u([{key:"item-column-1-xs",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-medium font-15"},[_vm._v(_vm._s(item.product))]),_c('div',[_vm._v(_vm._s(_vm.$t('item.lbl.code'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.sku))])]),_c('div',[_vm._v(_vm._s(_vm.$t('lbl.qty'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.qty) ? item.qty + ' ' + item.uom : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('item.lbl.extraQuantity'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.exQty) ? item.exQty + ' ' + item.exUom : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('item.lbl.unitPrice'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.cost) ? _vm.priceFormat(item.cost) : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('item.lbl.subtotal'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.subtotal) ? _vm.priceFormat(item.subtotal) : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('item.lbl.salesTax'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.taxAmt) ? _vm.priceFormat(item.taxAmt) : '-'))])]),_c('div',[_vm._v(_vm._s(_vm.$t('item.lbl.subtotalInclTax'))+": "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(!_vm.isUndefinedNullOrEmpty(item.subtotalInclTax) ? _vm.priceFormat(item.subtotalInclTax) : '-'))])])])]}},{key:"item-column-act-xs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(!_vm.disableEdit)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmModal(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}},{key:"item-qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.qty) ? item.qty + ' ' + item.uom : '-')+" ")]}},{key:"item-exQty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.exQty) ? item.exQty + ' ' + item.exUom : '-')+" ")]}},{key:"item-cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.cost) ? _vm.priceFormat(item.cost) : '-')+" ")]}},{key:"item-subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.subtotal) ? _vm.priceFormat(item.subtotal) : '-')+" ")]}},{key:"item-taxAmt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.taxAmt) ? _vm.priceFormat(item.taxAmt) : '-')+" ")]}},{key:"item-subtotalInclTax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!_vm.isUndefinedNullOrEmpty(item.subtotalInclTax) ? _vm.priceFormat(item.subtotalInclTax) : '-')+" ")]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(!_vm.disableEdit)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmModal(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}])}),(_vm.createModalShow)?_c('CreateModal',{attrs:{"so-id":_vm.soId,"so-item-id":_vm.soItemId,"uom":_vm.uom,"disable-edit":_vm.disableEdit,"show-modal":_vm.createModalShow},on:{"update:showModal":function($event){_vm.createModalShow=$event},"update:show-modal":function($event){_vm.createModalShow=$event},"close":_vm.closeModal,"getList":_vm.getList}}):_vm._e(),_c('ConfirmModal',{attrs:{"confirm-action-loading":_vm.confirmActionLoading,"header-title":_vm.confirmModalTitle,"modal-size":'modal-xs',"confirm-msg":_vm.confirmMsg,"show-modal":_vm.confirmModalShow},on:{"update:showModal":function($event){_vm.confirmModalShow=$event},"update:show-modal":function($event){_vm.confirmModalShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }