<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title>
          <TextField v-model="searchVal" class="mr-2" :hide-details="true" :placeholder="$t('lbl.search')" />
            <Select v-model="selectedSupplier" class="mb-2 mb-sm-0 mr-0 mr-sm-2" :options="supplierList" :placeholder="$t('purchaseOrder.lbl.supplier')" @change="selectedSupplierChg" />
            <Select v-model="selectedPOStatus" class="mb-2 mb-sm-0 mr-0 mr-sm-2" style="max-width: 170px" :options="selectOpts" :placeholder="$t('purchaseOrder.lbl.poStatus')" @change="selectedPOStatusChg" />
          <AppBtn color="primary" @click="navigatePush('PurchaseOrderCreate')">{{ $t('purchaseOrder.act.newPO') }}</AppBtn>
        </v-card-title>
        <DataTable
          :class="'cursor-pointer'"
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :pagination-config.sync="pagination"
          @click:row="viewDetails"
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-15">{{ !isUndefinedNullOrEmpty(item.poNo) ? item.poNo : '-' }}</div>
              <div>{{ $t('purchaseOrder.lbl.poDate') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.poDate) ? setTimeZone(item.poDate, dateFormat) : '-' }}</span></div>
              <div>{{ $t('purchaseOrder.lbl.supplier') }}: <span class="font-weight-regular">{{ item.supplier }}</span></div>
              <div>{{ $t('purchaseOrder.lbl.branch') }}: <span class="font-weight-regular">{{ item.outlet }}</span></div>
              <div>{{ $t('purchaseOrder.lbl.deliveryDate') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate) : '-' }}</span></div>
              <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.purchaseOrderSts')[item.status] }}</span></div>
            </div>
          </template>
          <template #item-column-act-xs="{ item }">
            <div class="d-flex justify-end align-center">
              <!-- <IconWrap class="mr-2" :icon="'mdi-pencil'" @click="navigatePush('PurchaseOrderUpdate', { id: item.id })" /> -->
              <IconWrap class="mr-2" :icon="'mdi-printer'" @click.native.stop="report(item)" />
              <IconWrap class="mr-2" :icon="'mdi-delete'" @click.native.stop="showConfirmModal(item)" :disabled="item.status != PO_STATUS.NEW && item.status != PO_STATUS.CONFIRMED" />
            </div>
          </template>

          <template #item-poNo="{ item }">
            {{ !isUndefinedNullOrEmpty(item.poNo) ? item.poNo : '-' }}
          </template>
          <template #item-poDate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.poDate) ? setTimeZone(item.poDate, dateFormat) : '-' }}
          </template>
          <template #item-doDate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate, dateFormat) : '-' }}
          </template>
          <template #item-status="{ item }">
            {{ $t('map.purchaseOrderSts')[item.status] }}
          </template>
          <template #item-notify="{ item }">
            <v-icon small :color="item.notify ? 'green' : 'red'">{{ item.notify ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
          </template>
          <template #item-actions="{ item }">
            <div class="d-flex justify-end">
              <!-- <IconWrap class="mr-2" :icon="'mdi-pencil'" @click="navigatePush('PurchaseOrderUpdate', { id: item.id })" /> -->
              <IconWrap class="mr-2" :icon="'mdi-printer'" :disabled="item.printLoading" @click.native.stop="report(item)" />
              <IconWrap class="mb-1 mb-sm-0 mr-2" :icon="'mdi-delete'" @click.native.stop="showConfirmModal(item)" :disabled="item.status != PO_STATUS.NEW && item.status != PO_STATUS.CONFIRMED" />
            </div>
          </template>
        </DataTable>
        <!-- <v-data-table
          class="cursor-pointer"
          disable-sort
          :headers="headers"
          :items="data"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.pageSize"
          :footer-props="{
            itemsPerPageOptions: pageSizeOpts,
          }"
          :server-items-length="pagination.total"
          @click:row="viewDetails"
          @update:page="pageChg"
          @update:items-per-page="pageSizeChg"
        >
        </v-data-table> -->
      </app-card>
    </v-col>
    <!-- <CreateModal v-if="createModalShow" :id="id" :show-modal.sync="createModalShow" @close="closeModal" @getList="getListNTotalRec" /> -->
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import { purchaseOrderApi } from '@/api/purchase-order';
import { supplierApi } from '@/api/supplier';
import { PO_STATUS } from '@/common/enum';
import { mapState } from 'vuex';

export default {
  name: 'PurchaseOrderList',
  components: {},
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('lbl.deleteOrder'),
      confirmMsg: this.$t('lbl.confirm.delete.order'),
      pagination: {
        total: 0,
      },
      searchVal: '',
      data: [],
      selectedSupplier: null,
      selectedPOStatus: 7,
      supplierList: [],
      selectOpts: [
        { text: this.$t('purchaseOrder.selectOpt.open'), value: PO_STATUS.OPEN },
        { text: this.$t('purchaseOrder.selectOpt.confirmed'), value: PO_STATUS.CONFIRMED },
        { text: this.$t('purchaseOrder.selectOpt.approved'), value: PO_STATUS.APPROVED },
        { text: this.$t('purchaseOrder.selectOpt.completed'), value: PO_STATUS.COMPLETED },
        { text: this.$t('purchaseOrder.selectOpt.cancelled'), value: PO_STATUS.CANCELLED },
//        { text: this.$t('purchaseOrder.selectOpt.all'), value: PO_STATUS.ALL },
      ],
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          // { text: '#', width: '50', value: 'no' },
          { text: this.$t('purchaseOrder.lbl.poNo'), width: '120', value: 'poNo' },
          { text: this.$t('purchaseOrder.lbl.poDate'), width: '100', value: 'poDate' },
          { text: this.$t('purchaseOrder.lbl.supplier'), width: '220', value: 'supplier' },
          { text: this.$t('purchaseOrder.lbl.branch'), width: '180', value: 'outlet' },
          { text: this.$t('purchaseOrder.lbl.deliveryDate'), width: '100', value: 'doDate' },
          { text: this.$t('lbl.status'), width: '120', value: 'status' },
          { text: this.$t('purchaseOrder.lbl.email'), width: '120', value: 'notify' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
    PO_STATUS() {
      return PO_STATUS;
    },
  },
  created() {
    this.getSupplierList();
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getListNTotalRec();
  },
  methods: {
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.deleteOrder(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    selectedSupplierChg(value) {
      this.getListNTotalRec();
    },
    selectedPOStatusChg(value) {
      this.getListNTotalRec();
    },
    getSupplierList() {
      return supplierApi.ddl({}).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.supplierList = list;
        return list;
      });
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        if (this.pagination.total > 0) {
          this.getList();
        }
      });
    },
    getTotalRec() {
      const params = {
        orgId: null,
        orderBy: '',
        status: this.selectedPOStatus,
        supplierId: this.selectedSupplier,
        page: 0,
      };
      return purchaseOrderApi.list(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        orgId: null,
        orderBy: '',
        status: this.selectedPOStatus,
        supplierId: this.selectedSupplier,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return purchaseOrderApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
            printLoading: false,
          }
        });
      });
    },
    viewDetails(item) {
      this.navigatePush('PurchaseOrderUpdate', { id: item.id });
    },
    deleteOrder(id) {
      this.confirmActionLoading = true;
      return purchaseOrderApi.delete({ id }).then(res => {
        this.confirmActionLoading = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('lbl.deleteSuccess'));
          this.confirmModalShow = false;
          this.getListNTotalRec();
        }
      }).catch(() => {
        this.confirmActionLoading = false;
      });
    },
    report(item) {
      const data = {
        id: item.id,
      }
      item.printLoading = true;
      return purchaseOrderApi.report(data).then(res => {
        item.printLoading = false;

        const blob = res.data;
        const fileName = `PO_${data.id}`;

        const file = new File([blob], fileName, {
          type: blob.type,
        });

        const reportUrl = URL.createObjectURL(file);

        window.open(reportUrl, '_blank');
      }).catch(() => {
        item.printLoading = false;
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
