<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('lbl.type') }}
              <span class="font-red">{{ rules.type.required ? '*' : '' }}</span>
            </label>
            <Select
              v-model="form.type"
              :label="$t('lbl.type')"
              :options="$t('salesOrderProductType')"
              :rules="rules.type"
              :clearable="false"
              :disabled="isUpdate"
              @change="typeChg"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('lbl.item') }}
              <span class="font-red">{{ rules.itemId.required ? '*' : '' }}</span>
            </label>
            <Autocomplete
              v-model="form.itemId"
              class="font-weight-bold"
              :label="$t('lbl.item')"
              :outlined="true"
              :solo="false"
              :options="itemDdl"
              :rules="rules.itemId"
              :disabled="isUpdate"
              :placeholder="$t('lbl.item')"
              @change="itemChg"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('lbl.qty') }}
              <span class="font-red">{{ rules.qty.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.qty"
              :type="'number'"
              :min="1"
              :step="decimalStep"
              :rules="rules.qty"
              :label="$t('lbl.qty')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.uom') }}
              <span class="font-red">{{ rules.uom.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.uom" :rules="rules.uom" :label="$t('uom.lbl.uom')" disabled />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.extraQuantity') }}
              <span class="font-red">{{ rules.exQty.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.exQty"
              :type="'number'"
              :min="0"
              :step="decimalStep"
              :rules="rules.exQty"
              :label="$t('item.lbl.extraQuantity')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.uom') }}
              <span class="font-red">{{ rules.exUom.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.exUom" :rules="rules.exUom" :label="$t('uom.lbl.uom')" disabled />
          </v-col>
          <!-- <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.unitPrice') }}
              <span class="font-red">{{ rules.cost.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.cost"
              :type="'number'"
              :min="1"
              :step="decimalStep"
              :rules="rules.cost"
              :label="$t('item.lbl.unitPrice')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.taxCode') }}
              <span class="font-red">{{ rules.taxCode.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.taxCode" :rules="rules.taxCode" :label="$t('item.lbl.taxCode')" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label"
              >{{ $t('item.lbl.salesTaxPercent') }}
              <span class="font-red">{{ rules.tax.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.tax" :type="'number'" :min="0" :rules="rules.tax" :label="$t('item.lbl.salesTaxPercent')" />
          </v-col> -->
        </v-row>
        <!-- <v-row>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label">{{ $t('item.lbl.subtotal') }} </label>
            <TextField
              v-model="computedSubtotal"
              :type="'number'"
              :min="1"
              :step="decimalStep"
              :label="$t('item.lbl.subtotal')"
              disabled
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label">{{ $t('item.lbl.salesTax') }} </label>
            <TextField
              v-model="computedTaxRm"
              :type="'number'"
              :min="0"
              :step="decimalStep"
              :label="$t('item.lbl.salesTax')"
              disabled
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="4">
            <label class="form-row-col__label">{{ $t('item.lbl.subtotalInclTax') }} </label>
            <TextField
              v-model="computedSubtotalInclTax"
              :type="'number'"
              :min="0"
              :step="decimalStep"
              :label="$t('item.lbl.subtotalInclTax')"
              disabled
            />
          </v-col>
        </v-row> -->
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm" :disabled="disableEdit">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { salesOrderItemApi } from '@/api/sales-order';
import { PRODUCT_TYPE } from '@/common/enum';
import { isUndefinedNullOrEmpty, roundToDecimal, extractJsonKey } from '@/util/tools';

export default {
  name: 'ProductItemDetails',
  props: {
    disableEdit: {
      type: Boolean,
      default: null,
    },
    soId: {
      type: Number,
      default: null,
    },
    soItemId: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.soItemId) ? this.$t('item.title.updateItem') : this.$t('item.title.addItem'),
      taxRm: 0,
      form: {
        type: 2,
        orderId: null,
        itemId: null,
        qty: 1,
        uom: '',
        exQty: 0,
        exUom: '',
        // taxCode: null,
        // cost: null,
        // tax: null,
        price: 0,
        // notes: null,
      },
      decimalStep: '0.01',
      rules: {
        orderId: { required: true },
        itemId: { required: true },
        qty: { required: true },
        uom: { required: true },
        exQty: { required: false },
        exUom: { required: false },
        // taxCode: { required: true },
        // cost: { required: true },
        // tax: { required: true },
        // price: { required: true },
        // notes: { required: false },
        type: { required: true },
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      semiFGoodsDdl: state => state.product.semiFGoodsDdl,
      fGoodsDdl: state => state.product.fGoodsDdl,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.soItemId) ? true : false;
    },
    computedSubtotal() {
      return roundToDecimal(this.form.cost * this.form.qty, 2);
    },
    computedTaxRm() {
      return roundToDecimal((this.computedSubtotal * this.form.tax) / 100, 2);
    },
    computedSubtotalInclTax() {
      return roundToDecimal(this.computedSubtotal + this.computedTaxRm, 2);
    },
    itemDdl() {
      return this.form.type == PRODUCT_TYPE.SEMIFGOODS ? this.semiFGoodsDdl : this.fGoodsDdl;
    },
  },
  created() {
    if (!isUndefinedNullOrEmpty(this.soItemId)) {
      this.detail(this.soItemId);
    }
    if (isUndefinedNullOrEmpty(this.semiFGoodsDdl)) {
      this.fetchSemiFGoodsDdl();
    }
    if (isUndefinedNullOrEmpty(this.fGoodsDdl)) {
      this.fetchFGoodsDdl();
    }
  },
  methods: {
    ...mapActions(['fetchSemiFGoodsDdl', 'fetchFGoodsDdl']),
    typeChg() {
      this.form.itemId = null;
    },
    itemChg(id) {
      let selectedItem = this.itemDdl.find(item => {
        return (item.value == id);
      });
      this.form.uom = selectedItem.saleUom;
      this.form.exUom = selectedItem.saleUom;
      // this.form.qty = selectedItem.qty;
      // this.form.cost = selectedItem.cost;
      // this.form.price = selectedItem.price;
      // this.form.taxCode = selectedItem.taxCode;
      // this.form.tax = selectedItem.tax;
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
        qty: Number(this.form.qty),
        exQty: Number(this.form.exQty),
        // cost: Number(this.form.cost),
        // tax: Number(this.form.tax),
        // price: Number(this.form.price),
        orderId: this.soId,
      };

      if (this.isUpdate) {
        data.id = this.soItemId;
        const keys = ['id', 'orderId', 'itemId', 'qty', 'uom', 'exQty', 'exUom', 'taxCode', 'cost', 'tax', 'price', 'notes'];
        const finalData = extractJsonKey(keys, data);
        this.update(finalData);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return salesOrderItemApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return salesOrderItemApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return salesOrderItemApi.detail({ id }).then(res => {
        this.form = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
.v-icon {
  font-size: 18px;
}
</style>
