<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
    <template v-slot:content>
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.code') }}
                    <span class="font-red">{{ rules.sku.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.sku" :rules="rules.sku" :label="$t('fGoods.lbl.code')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.desc') }}
                    <span class="font-red">{{ rules.name.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.name" :rules="rules.name" :label="$t('fGoods.lbl.desc')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.shortDesc') }}
                    <span class="font-red">{{ rules.shortDesc.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.shortDesc" :rules="rules.shortDesc" :label="$t('fGoods.lbl.shortDesc')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.shortName') }}
                    <span class="font-red">{{ rules.shortName.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.shortName" :rules="rules.shortName" :label="$t('fGoods.lbl.shortName')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.dimension') }}
                    <span class="font-red">{{ rules.dimension.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.dimension" :rules="rules.dimension" :label="$t('fGoods.lbl.dimension')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.category') }}
                    <span class="font-red">{{ rules.category.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.category" :rules="rules.category" :label="$t('fGoods.lbl.category')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.subCategory') }}
                    <span class="font-red">{{ rules.subCat.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.subCat" :rules="rules.subCat" :label="$t('fGoods.lbl.subCategory')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.shelfLife') }}
                    <span class="font-red">{{ rules.life.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.life" :type="'number'" :rules="rules.life" :label="$t('fGoods.lbl.shelfLife')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('recipe.lbl.recipe') }}
                    <span class="font-red">{{ rules.recipeId.required ? '*' : '' }}</span>
                  </div>
                  <Select v-model="form.recipeId" :options="recipeDdl" :rules="rules.recipeId" :label="$t('recipe.lbl.recipe')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <div class="form-row-col__label"
                    >{{ $t('lbl.remarks') }}
                    <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                  </div>
                  <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="2">
                  <div class="form-row-col__label"
                    >{{ $t('fGoods.lbl.forSale') }}
                    <span class="font-red">{{ rules.forSale.required ? '*' : '' }}</span>
                  </div>
                  <SwitchToggle v-model="form.forSale" :label="$t('map.yesNoBool')[form.forSale]" :rules="rules.forSale" />
                </v-col>
              </v-row>
            </div>
            <div v-if="tab.id === 1" class="tab-content-wrap px-2 py-3 px-md-4">
              <Uom :product-id="id" :product-type="PRODUCT_TYPE.FGOODS" />
            </div>
            <div v-if="tab.id === 2" class="tab-content-wrap px-2 py-3 px-md-4">
              <ProductLevel :product-id="id" :product-type="PRODUCT_TYPE.FGOODS" :uom="uom" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import Countries from '@/util/countries';
import ProductLevel from '@/views/ProductLevel';
import Uom from '@/views/Uom';
import { fGoodsApi } from '@/api/product';
import { mapState, mapActions } from 'vuex';
import { PRODUCT_TYPE } from '@/common/enum';

export default {
  name: 'FGoodsDetails',
  components: {
    ProductLevel,
    Uom,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !this.isUndefinedNullOrEmpty(this.id) ? this.$t('fGoods.title.updateFGoods') : this.$t('fGoods.title.createFGoods'),
      selectedTab: 0,
      tabs: this.$t('fGoodsTabs'),
      form: {
        sku: '',
        name: '',
        shortDesc: '',
        shortName: '',
        dimension: '',
        category: '',
        subCat: '',
        recipeId: null,
        life: 0,

        forSale: false,
        label: null,
        yield: 100,
        info: null,
        image: null,
        grp: null,
        type: null,
        notes: '',
      },
      rules: {
        sku: { required: true },
        name: { required: true },
        shortDesc: { required: false },
        shortName: { required: false },
        dimension: { required: false },
        category: { required: true },
        subCat: { required: false },
        recipeId: { required: true },
        life: { required: true },
        forSale: { required: false },
        notes: { required: false },
      },
      uom: {},
      loading: false,
    };
  },
  computed: {
    ...mapState({
      recipeDdl: state => state.recipe.recipeDdl,
    }),    
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.id) ? true : false;
    },
    PRODUCT_TYPE() {
      return PRODUCT_TYPE;
    },
  },
  created() {
    if (!this.isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    }
    if (this.isUndefinedNullOrEmpty(this.recipeDdl)) {
      this.fetchRecipeDdl();
    }
  },
  methods: {
    ...mapActions(['fetchRecipeDdl']),
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
        life: Number(this.form.life),
      };
      if (this.isUpdate) {
        data.id = this.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return fGoodsApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return fGoodsApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return fGoodsApi.detail({ id }).then(res => {
        this.form = res.data;
        this.uom = {
          baseUom: this.form.baseUom,
          stkUom: this.form.stkUom,
          orderUom: this.form.orderUom,
          saleUom: this.form.saleUom,
        };
        this.headerTitle = this.headerTitle + ' - ' + this.form.shortName;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
