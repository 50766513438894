<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title>
          <TextField v-model="searchVal" class="mr-2" :hide-details="true" :placeholder="$t('lbl.search')" />
          <AppBtn color="primary" @click="navigatePush('GrnCreate')">{{ $t('grn.act.newGrn') }}</AppBtn>
        </v-card-title>
        <DataTable
          :class="'cursor-pointer'"
          :headers="headers"
          :items="data"
          :pagination-config.sync="pagination"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
          @click:row="viewDetails"
        >
          <template #item-column-1-xs="{ item }">
            <div class="pa-1">
              <div>
                <span class="font-weight-medium font-15">{{ item.grnNo }}</span>
                <span v-if="!isUndefinedNullOrEmpty(item.grnDate)"> ({{ setTimeZone(item.grnDate, dateFormat) }})</span>
              </div>
              <div>Supplier: <span class="font-weight-regular">{{ item.supplier }}</span></div>
              <div><span class="font-weight-regular">{{ item.poNo }}</span></div>
            </div>
          </template>
          <template #item-column-act-xs="{ item }">
            <div class="d-flex justify-end">
              <AppBtn class="btn-green" :height="'32'" @click.stop="navigatePush('RawMatStockIn', { grnNo: item.grnNo })">{{ $t('inventory.act.stockIn') }}</AppBtn>
              <v-icon class="mr-2" size="30" @click="navigatePush('GrnUpdate', { id: item.id })">
                mdi-chevron-right
              </v-icon>
            </div>
          </template>

          <template #item-grnDate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.grnDate) ? setTimeZone(item.grnDate, dateFormat) : '-' }}
          </template>
          <template #item-storage="{ item }">
            {{ !isUndefinedNullOrEmpty(item.storage) ? item.storage : '-' }}
          </template>
          <template #item-print="{ item }">
            {{ item.print }}
          </template>
          <template #item-isPrinted="{ item }">
            <v-icon small :color="item.isPrinted ? 'green' : 'red'">{{ item.isPrinted ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
          </template>
          <template #item-actions="{ item }">
            <div class="d-flex justify-end">
              <!-- <v-icon small class="mr-2" @click="navigatePush('GrnUpdate', { id: item.id })">
                mdi-pencil
              </v-icon> -->
              <AppBtn class="btn-green" :height="'32'" @click.stop="navigatePush('RawMatStockIn', { grnNo: item.grnNo })">{{ $t('inventory.act.stockIn') }}</AppBtn>
            </div>
          </template>
        </DataTable>
      </app-card>
    </v-col>
    <!-- <CreateModal v-if="createModalShow" :id="id" :show-modal.sync="createModalShow" @close="closeModal" @getList="getListNTotalRec" /> -->
    <!-- <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal> -->
  </v-row>
</template>

<script>
import { grnApi } from '@/api/grn';
import { mapState } from 'vuex';

export default {
  name: 'GrnList',
  components: {},
  data() {
    return {
      // confirmModalShow: false,
      // confirmActionLoading: false,
      // confirmModalTitle: this.$t('outlet.title.deleteBranch'),
      // confirmMsg: this.$t('lbl.confirm.delete'),
      pagination: {
        total: 0,
      },
      searchVal: '',
      data: [],
      id: null,
      timeout: null,
      initialLoad: null,
      initialRecObj: {
        total: 0,
        page: 1,
        pageSize: 10,
        data: [],
      }
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          // { text: '#', width: '50', value: 'no' },
          { text: this.$t('grn.lbl.grnNo'), width: '130', value: 'grnNo' },
          { text: this.$t('grn.lbl.grnDate'), width: '100', value: 'grnDate' },
          { text: this.$t('grn.lbl.supplier'), width: '200', value: 'supplier' },
          { text: this.$t('grn.lbl.supplierDoNo'), width: '160', value: 'doNo' },
          { text: this.$t('grn.lbl.poNo'), width: '120', value: 'poNo' },
          { text: this.$t('grn.lbl.storage'), width: '180', value: 'storage' },
          { text: this.$t('grn.lbl.labelPrinted'), width: '120', value: 'isPrinted' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    }
  },
  watch: {
    searchVal(val) {
      clearTimeout(this.timeout);
      if (val.length >= 3) {
        this.timeout = setTimeout(() => {
          this.pagination.page = 1;
          this.getListNTotalRec();
        }, 500);
      } else if (this.isUndefinedNullOrEmpty(val)) {
        this.pagination.page = this.initialRecObj.page;
        this.pagination.total = this.initialRecObj.total;
        if (this.pagination.pageSize == this.initialRecObj.pageSize) {
          this.data = this.initialRecObj.data;
        } else {
          this.getListNTotalRec();
        }
      }
    }
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.initialLoad = true;
    this.getListNTotalRec();
  },
  methods: {
    delete(id) {
      this.confirmActionLoading = true;
      return grnApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.delete(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        this.getList();
      });
    },
    getTotalRec() {
      const params = {
        keyword: this.searchVal,
        page: 0,
      };
      return grnApi.list(params).then(res => {
        this.pagination.total = res.data.total;
        if (this.initialLoad) {
          this.initialRecObj.total = this.pagination.total;
        }
      });
    },
    getList() {
      const params = {
        keyword: this.searchVal,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return grnApi.list(params).then(res => {
        this.data = res.data;
        if (this.initialLoad) {
          this.initialRecObj.page = this.pagination.page;
          this.initialRecObj.pageSize = this.pagination.pageSize;
          this.initialRecObj.data = this.data;
          this.initialLoad = false;
        }
      });
    },
    viewDetails(item) {
      this.navigatePush('GrnUpdate', { id: item.id, poId: item.orderId });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
