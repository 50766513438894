<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-xs'" with-footer>
    <template v-slot:content>
      <Select v-model="localId" :label="fieldLbl" :options="list" />
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn @click="confirm(localId)">{{ $t('act.confirm') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
export default {
  name: 'SelectModal',
  props: {
    id: {
      type: Number,
      default: null,
    },
    fieldLbl: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localId: null,
    };
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.id) ? true : false;
    },
  },
  created() {
    this.localId = this.id;
  },
  methods: {
    confirm(id) {
      this.$emit('confirm', id);
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
