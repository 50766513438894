<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import List from './List';
import Details from './Details';
export default {
  name: 'Grn',
  components: {
    List,
    Details,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'GrnList':
          return 'List';
        case 'GrnCreate':
          return 'Details';
        case 'GrnUpdate':
          return 'Details';
        default:
          return 'List';
      }
    },
  },
};
</script>
