<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import List from './List';
export default {
  name: 'Branch',
  components: {
    List,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'BranchList':
          return 'List';
        default:
          return 'List';
      }
    },
  },
};
</script>
