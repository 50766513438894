<template>
  <div>
    <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
      <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
        {{ tab.label }}
      </v-tab>
    </v-tabs>

    <ValidationObserver ref="observer">
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
            <label class="font-0875r">{{ $t('recipe.lbl.category.plsFillInNote') }}</label>
            <v-row>
              <v-col cols="12" sm="4">
                <v-img :src="require('@/assets/recipe.jpg')" height="215px"></v-img>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="mb-3">
                  <div class="form-row-col__label"
                    >{{ $t('recipe.lbl.category.name') }}
                    <span class="font-red">{{ rules.name.required ? '*' : '' }}</span>
                  </div >
                  <TextField v-model="form.name" :rules="rules.name" :label="$t('recipe.lbl.category.name')"></TextField>
                </div>
                <div class="mb-3">
                  <label class="form-row-col__label"
                    >{{ $t('recipe.lbl.category.code') }}
                    <span class="font-red">{{ rules.code.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.code" :rules="rules.code" :label="$t('recipe.lbl.category.code')"></TextField>
                </div>
                <div class="mb-3">
                  <label class="form-row-col__label"
                    >{{ $t('recipe.lbl.category.desc') }}
                    <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                  </label>
                  <TextArea v-model="form.notes" :rules="rules.notes" :label="$t('recipe.lbl.category.desc')"></TextArea>
                </div>
              </v-col>
            </v-row>
            <div class="d-flex justify-end">
              <AppBtn class="btn-cancel mr-2" @click="navigatePush('RecipeCategoryList')">{{ $t('act.cancel') }}</AppBtn>
              <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </ValidationObserver>
  </div>
</template>
<script>
import { recipeCategoryApi } from '@/api/recipe';
export default {
  name: 'RecipeCategoryDetails',
  data() {
    return {
      data: [],
      selectedTab: 0,
      tabs: this.$t('recipeCategoryLabel'),
      loading: false,
      form: {
        name: '',
        code: '',
        notes: '',
      },
      rules: {
        name: { required: true },
        code: { required: false },
        notes: { required: false },
      },
    };
  },
  computed: {
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'RecipeCategoryUpdate';
    },
  },
  created() {
    if (this.isUpdate) {
      this.detail(this.$route.params.id);
    }
  },
  methods: {
    detail(id) {
      return recipeCategoryApi.detail({ id }).then(res => {
        this.form = res.data;
      });
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        name: this.form.name,
        code: this.form.code,
        notes: this.form.notes,
      };
      if (this.isUpdate) {
        data.id = this.form.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return recipeCategoryApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.navigatePush('RecipeCategoryList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return recipeCategoryApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.navigatePush('RecipeCategoryList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
