<template>
  <div>
    <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
      <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
        {{ tab.label }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
          <CreateLabel />
        </div>
        <div v-if="tab.id === 2" class="tab-content-wrap px-2 py-3 px-md-4">
          <VoidLabel />
        </div>
        <div v-if="tab.id === 3" class="tab-content-wrap px-2 py-3 px-md-4">
          <PrintQueue />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CreateLabel from './components/CreateLabel';
import VoidLabel from './components/VoidLabel';
import PrintQueue from './components/PrintQueue';

export default {
  name: 'FGoodsLabel',
  components: {
    CreateLabel,
    VoidLabel,
    PrintQueue,
  },
  props: ['grnNo'], // pass from /Grn/Details.vue
  data() {
    return {
      selectedTab: 0,
      tabs: this.$t('fGoodsLabel'),
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
