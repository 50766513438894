<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
    <template v-slot:content>
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.branch') }}
                    <span class="font-red">{{ rules.outletId.required ? '*' : '' }}</span>
                  </label>
                  <Select
                    v-model="form.outletId"
                    :label="$t('warehouse.lbl.branch')"
                    :options="outletDDL"
                    :rules="rules.outletId"
                  />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.code') }}
                    <span class="font-red">{{ rules.shortCode.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.shortCode" :rules="rules.shortCode" :label="$t('warehouse.lbl.code')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.desc') }}
                    <span class="font-red">{{ rules.name.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.name" :rules="rules.name" :label="$t('warehouse.lbl.desc')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.shortName') }}
                    <span class="font-red">{{ rules.shortName.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.shortName" :rules="rules.shortName" :label="$t('warehouse.lbl.shortName')" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="6">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.warehouseType') }}
                    <span class="font-red">{{ rules.type.required ? '*' : '' }}</span>
                  </label>
                  <Select
                    v-model="form.type"
                    :label="$t('warehouse.lbl.warehouseType')"
                    :options="$t('productType')"
                    :rules="rules.type"
                  />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="3">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.isReceivingWarehouse') }}
                    <span class="font-red">{{ rules.isReceive.required ? '*' : '' }}</span>
                  </label>
                  <SwitchToggle v-model="form.isReceive" :label="$t('map.yesNoBool')[form.isReceive]" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="3">
                  <label class="form-row-col__label"
                    >{{ $t('warehouse.lbl.isAfterSaleDefault') }}
                    <span class="font-red">{{ rules.isAfterSaleDef.required ? '*' : '' }}</span>
                  </label>
                  <SwitchToggle v-model="form.isAfterSaleDef" :label="$t('map.yesNoBool')[form.isAfterSaleDef]" />
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { outletApi } from '@/api/outlet';
import { warehouseApi } from '@/api/warehouse';
import { isUndefinedNullOrEmpty } from '@/util/tools';

export default {
  name: 'WarehouseDetails',
  props: {
    id: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.id)
        ? this.$t('warehouse.title.updateWarehouse')
        : this.$t('warehouse.title.createWarehouse'),
      selectedTab: 0,
      tabs: this.$t('warehouseTabs'),
      form: {
        outletId: null,
        shortCode: '',
        name: '',
        shortName: '',
        isReceive: false,
        isAfterSaleDef: false,
        type: null,
        notes: '',
      },
      rules: {
        outletId: { required: true },
        shortCode: { required: true },
        name: { required: true },
        shortName: { required: false },
        isReceive: { required: false },
        isAfterSaleDef: { required: false },
        type: { required: true },
        notes: { required: false },
      },
      outletDDL: [],
      loading: false,
    };
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.id) ? true : false;
    },
  },
  created() {
    if (!isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    }
    this.getOutletDDL();
  },
  methods: {
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
      };
      if (this.isUpdate) {
        data.id = this.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return warehouseApi
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return warehouseApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return warehouseApi.detail({ id }).then(res => {
        this.form = res.data;
      });
    },
    getOutletDDL() {
      const params = {
        orgId: null,
        orderBy: null,
      };
      return outletApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.shortName,
            value: item.id,
          });
        }
        this.outletDDL = list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
