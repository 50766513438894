<template>
  <!-- Dialog will be modal-xs if not specified. Please specify only either modalSize OR modalCustWidth in parent component -->
  <v-dialog v-model="showModal" persistent :content-class="'confirm-modal ' + modalSize" :width="modalCustWidth">
    <v-card>
      <v-card-title class="modal-title" :style="{ padding: headerPadding }">
        <div class="modal-title__headline">{{ headerTitle }}</div>
        <v-btn v-if="showCloseBtn" class="modal-title__close-btn" text icon @click="hideModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="modal-content">
        <slot name="content">
          <div class="modal-msg" :class="[...contentClasses]" :style="{ 'max-width': bodyMaxWidth }">
            {{ confirmMsg ? confirmMsg : $t('modal.confirmMsg') }}
          </div>
        </slot>
      </v-card-text>
      <v-card-actions>
        <slot name="footer">
          <div class="btns-wrap">
            <AppBtn class="btn-cancel mr-2" @click="hideModal">{{ declineBtnText ? declineBtnText : $t('act.cancel') }}</AppBtn>
            <AppBtn :loading="confirmActionLoading" @click="confirmAction">{{
              confirmBtnText ? confirmBtnText : $t('act.confirm')
            }}</AppBtn>
          </div>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    confirmBtnText: {
      type: String,
      default: '',
    },
    confirmActionLoading: {
      type: Boolean,
      default: false,
    },
    confirmMsg: {
      type: String,
      default: '',
    },
    contentClasses: {
      type: Array,
      default: null,
    },
    declineBtnText: {
      type: String,
      default: '',
    },
    headerPadding: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: 'Modal Title',
    },
    bodyMaxWidth: {
      type: String,
      default: '',
    },
    modalCustWidth: {
      type: String,
      default: '',
    },
    modalSize: {
      // Options: modal-lg, modal-md, modal-sm, modal-xs
      type: String,
      default: 'modal-xs',
    },
    rowData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      event: null,
      id: null,
    };
  },
  created() {
    this.$eb.$on('show-confirm-modal', event => {
      this.event = event;
      this.id = event.id;
    });
  },
  methods: {
    confirmAction() {
      this.event.onSuccess(this.id);
    },
    hideModal() {
      this.$emit('update:showModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .confirm-modal {
    .v-dialog {
      margin: 10px;
    }

    .modal-title {
      display: flex;
      justify-content: space-between;
      padding: 10px 24px 5px;

      &__close-btn {
        margin-right: -10px;
      }

      &__headline {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    .v-card__text.modal-content {
      display: flex;
      font-size: 1rem;
      padding: 5px 24px 8px;

      .modal-msg {
        font-size: 14px;
        padding: 5px 0px;
        text-align: center;
      }
    }

    .v-card__actions {
      display: flex;
      justify-content: flex-end;
      padding: 8px 24px 20px;
      .btns-wrap {
        .theme--light.v-btn {
          min-width: 100px;
        }
      }
    }

    &.modal-lg {
      max-width: 1200px;
    }

    &.modal-md {
      max-width: 1000px;
    }

    &.modal-sm {
      max-width: 600px;
    }

    &.modal-sm-2 {
      max-width: 500px;
    }

    &.modal-xs {
      max-width: 400px;
    }
  }
}
@media only screen and (max-width: 599px) {
  .confirm-modal {
    .modal-title {
      &__headline {
        font-size: var(--font-size-large);
      }
    }
  }
}

@media only screen and (max-width: 399px) {
  .confirm-modal {
    .modal-title {
      &__headline {
        font-size: var(--font-size-medium);
      }
    }
  }
}
</style>
