<template>
  <CommonModal :show-modal.sync="modalState" :header-title="$t('act.takePhoto')" fullscreen>
    <template v-slot:content>
      <Camera @confirm="$emit('confirm', $event)" />
    </template>
  </CommonModal>
</template>
<script>
import Camera from '@/components/Camera';
export default {
  name: "CameraModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Camera,
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
  },
  data() {
    return {

    }
  }
}
</script>