<template>
  <div>
    <v-data-table
      class="bordered"
      disable-sort
      :headers="headers"
      :items="data"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.pageSize"
      :footer-props="{
        itemsPerPageOptions: pageSizeOpts,
      }"
      :server-items-length="pagination.total"
      hide-default-footer
      @update:page="pageChg"
      @update:items-per-page="pageSizeChg"
    >
      <template v-slot:item.par="{ item }">
        <div class="d-flex align-center">
          <TextField v-model="item.par" :type="'number'" :min="0" :rules="{}" :label="$t('inventory.lbl.parLvl')" />
          <span class="ml-2">{{ parLvlUom }}</span>
        </div>
      </template>
      <template v-slot:item.reorder="{ item }">
        <div class="d-flex align-center">
          <TextField v-model="item.reorder" :type="'number'" :min="0" :rules="{}" :label="$t('inventory.lbl.reOrderLvl')" />
          <span class="ml-2">{{ reorderLvlUom }}</span>
        </div>
      </template>
      <template v-slot:item.reorderQty="{ item }">
        <div class="d-flex align-center">
          <TextField
            v-model="item.reorderQty"
            :type="'number'"
            :min="0"
            :rules="{}"
            :label="$t('inventory.lbl.reOrderQty')"
          />
          <span class="ml-2">{{ reorderQtyUom }}</span>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <AppBtn text @click="save(item)">{{ $t('act.save') }}</AppBtn>
      </template>
    </v-data-table>
    <!-- <CreateModal v-if="createModalShow" :id="id" :product-id="productId" :show-modal.sync="createModalShow" @close="createModalShow = false" @getList="getList" /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { outletApi } from '@/api/outlet';
import { rawMatLvlApi, semiFGoodsLvlApi, fGoodsLvlApi } from '@/api/product';
import { PRODUCT_TYPE } from '@/common/enum';
import { isUndefinedNullOrEmpty } from '@/util/tools';

export default {
  props: {
    productId: {
      // Can be productId or itemId. productId - raw mat id, itemId - semi finished goods & goods id
      type: Number,
      default: null,
    },
    productType: {
      type: Number,
      default: null,
    },
    uom: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 100,
        total: 100,
      },
      searchVal: '',
      createModalShow: false,
      headers: [
        { text: this.$t('outlet.lbl.name'), width: '150', value: 'name' },
        { text: this.$t('inventory.lbl.parLvl'), width: '200', value: 'par' },
        { text: this.$t('inventory.lbl.reOrderLvl'), width: '200', value: 'reorder' },
        { text: this.$t('inventory.lbl.reOrderQty'), width: '200', value: 'reorderQty' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      outletData: [],
      productLvlData: [],
      data: [],
      id: null,
      api: null,
      idKey: null,
    };
  },
  computed: {
    ...mapState({
      pageSizeOpts: state => state.app.pageSizeOpts,
    }),
    parLvlUom() {
      return !isUndefinedNullOrEmpty(this.uom.stkUom) ? this.uom.stkUom : this.uom.baseUom;
    },
    reorderLvlUom() {
      return !isUndefinedNullOrEmpty(this.uom.stkUom) ? this.uom.stkUom : this.uom.baseUom;
    },
    reorderQtyUom() {
      if (this.productType == PRODUCT_TYPE.RAW) {
        return !isUndefinedNullOrEmpty(this.uom.orderUom) ? this.uom.orderUom : this.uom.baseUom;
      } else {
        return !isUndefinedNullOrEmpty(this.uom.saleUom) ? this.uom.saleUom : this.uom.baseUom;
      }
    },
  },
  created() {
    switch (this.productType) {
      case PRODUCT_TYPE.RAW:
        this.api = rawMatLvlApi;
        this.idKey = 'productId';
        break;
      case PRODUCT_TYPE.SEMIFGOODS:
        this.api = semiFGoodsLvlApi;
        this.idKey = 'itemId';
        break;
      case PRODUCT_TYPE.FGOODS:
        this.api = fGoodsLvlApi;
        this.idKey = 'itemId';
        break;
      default:
        alert('Something went wrong');
        break;
    }
    this.pagination = { ...this.pagination };
    if (!isUndefinedNullOrEmpty(this.productId)) {
      const promise1 = this.getOutletList();
      const promise2 = this.getProductLvlList();
      Promise.all([promise1, promise2]).then(() => {
        this.data = this.processData(this.outletData, this.productLvlData);
      });
    }
  },
  methods: {
    editItem(item) {
      this.id = item.id;
      this.createModalShow = true;
    },
    deleteItem(item) {},
    getOutletList() {
      const params = {
        orgId: null,
        orderBy: '',
        page: this.pagination.page,
        pageSize: -1, // Get all records
      };
      return outletApi.list(params).then(res => {
        this.outletData = res.data;
      });
    },
    getProductLvlList() {
      const params = {
        [this.idKey]: this.productId,
        outletId: null,
        orgId: null,
        orderBy: '',
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return this.api.list(params).then(res => {
        this.productLvlData = res.data;
      });
    },
    pageChg(data) {
      this.getProductLvlList();
    },
    pageSizeChg(data) {
      this.getProductLvlList();
    },
    async save(item) {
      let data = {
        par: Number(item.par),
        reorder: Number(item.reorder),
        reorderQty: Number(item.reorderQty),
      };
      if (!isUndefinedNullOrEmpty(item.id)) {
        data.id = item.id;
        this.update(data);
      } else {
        data[this.idKey] = item.productId;
        data.outletId = item.outletId;
        item.id = await this.create(data);
      }
    },
    create(data) {
      return this.api.create(data).then(res => {
        this.showSuccess(this.$t('lbl.createSuccess'));
        return res.data.id;
      });
    },
    update(data) {
      return this.api.update(data).then(res => {
        this.showSuccess(this.$t('lbl.updateSuccess'));
      });
    },
    processData(outletData, productLvlData) {
      return outletData.map(outlet => {
        const productLvl = productLvlData.find(productLvl => {
          return outlet.id == productLvl.outletId;
        });
        return {
          id: isUndefinedNullOrEmpty(productLvl) ? null : productLvl.id,
          productId: this.productId,
          outletId: outlet.id,
          name: outlet.name,
          par: isUndefinedNullOrEmpty(productLvl) ? 0 : productLvl.par,
          reorder: isUndefinedNullOrEmpty(productLvl) ? 0 : productLvl.reorder,
          reorderQty: isUndefinedNullOrEmpty(productLvl) ? 0 : productLvl.reorderQty,
        };
      });
    },
  },
};
</script>
