<template>
  <div class="text-center">
    <!-- <div>Value: {{ text || 'Nothing' }}</div> -->
    <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)" @loaded="() => onLoaded()"></StreamBarcodeReader>
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
  name: 'QrBarcodeReader',
  components: {
    StreamBarcodeReader,
  },
  props: {
    msg: String,
    timeoutPeriod: {
      type: Number,
      default: 1500,
    },
  },
  data() {
    return {
      text: '',
      id: null,
      timer: null,
    };
  },
  methods: {
    onDecode(a, b, c) {
      // console.log(a, b, c);
      if (this.isUndefinedNullOrEmpty(this.timer)) {
        if (!this.isUndefinedNullOrEmpty(a)) {
          this.$emit('onDecode', a);
          this.timer = setTimeout(() => {
            this.timer = null;
          }, this.timeoutPeriod);
        }
      }
      // this.text = a;
      // if (this.id) clearTimeout(this.id);
      // this.id = setTimeout(() => {
      //   this.$emit('onDecode', a);
      //   if (this.text === a) {
      //     console.log('if', a);
      //     this.text = '';
      //   } else {
      //     console.log('else', a);
      //   }
      // }, 5000);
    },
    onLoaded() {},
  },
};
</script>
