<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
    <template v-slot:content>
      <div v-if="data.content" class="mb-3">
        {{ data.content }}
      </div>
      <div>
        <div v-if="isMobileView">
          <div v-for="(row, rowIndex) in data.data" :key="rowIndex">
            <div v-for="(item, index) in row" :key="index" class="content-wrap">
              <span class="font-0875r">{{ `${data.header[index]}: ` }}</span>
              <span class="font-0875r font-weight-regular">{{ item }}</span>
            </div>
            <v-divider class="my-1"></v-divider>
          </div>
        </div>
        <v-simple-table v-else class="table-wrap">
          <template>
            <thead>
              <tr>
                <th v-for="(item, index) in data.header" :key="index" class="table-header">
                  {{ item }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, rowIndex) in data.data" :key="rowIndex">
                <td v-for="(item, index) in row" :key="index" class="table-cell">
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-grey" @click="$emit('close')">{{ $t('act.close') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { notiApi } from '@/api/notification';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  props: {
    id: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      headerTitle: '',
    };
  },
  computed: {
    ...mapGetters(['getNotiBadgeLastId']),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
  },
  created() {
    this.getNotiDetail(this.id);
  },
  methods: {
    ...mapMutations(['SET_NOTI_BADGE_LAST_ID', 'SET_NOTI_BADGE_SHOW']),
    getNotiDetail(id) {
      if (this.isUndefinedNullOrEmpty(this.id)) return;
      return notiApi.detail({ id }).then(res => {
        if (res.ret == '0') {
          this.data = res.data;
          this.headerTitle = res.data.subj;
          if (id > this.getNotiBadgeLastId) {
            this.SET_NOTI_BADGE_LAST_ID(id);
          }
          this.SET_NOTI_BADGE_SHOW(false);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrap {
  .table-header {
    font-size: 1rem;
  }
  .table-cell {
    font-size: 0.875rem;
  }
}
.content-wrap {
  color: #333333;
}
</style>
