<template>
  <DataTable
    :headers="headers"
    :items="data"
    :mobile-breakpoint="0"
    :hide-default-header="hideHeader"
    :hide-default-footer="true"
    disable-pagination
  >
    <template #item-column-1-xs="{ item }">
      <div>
        <div class="font-weight-medium font-15">{{ item.ingredient }}</div>
        <div>
          {{ $t('recipe.lbl.type') }}: <span class="font-weight-regular">{{ $t('map.recipeType')[item.type] }}</span>
        </div>
        <div>
          {{ $t('recipe.lbl.ingredient.qty') }}: <span class="font-weight-regular">{{ `${item.totQty} ${item.uom}` }}</span>
        </div>
      </div>
    </template>

    <template #item-type="{ item }">
      {{ $t('map.recipeType')[item.type] }}
    </template>
    <template #item-qty="{ item }">
      {{ `${item.totQty} ${item.uom}` }}
    </template>
  </DataTable>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  computed: {
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '100%', value: 'column-1-xs' },
        ];
      } else {
        return [
          { text: this.$t('recipe.lbl.type'), width: '120', value: 'type' },
          { text: this.$t('lbl.item'), width: '200', value: 'ingredient' },
          { text: this.$t('recipe.lbl.ingredient.qty'), width: '100', value: 'qty' },
        ];
      }
    },
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>