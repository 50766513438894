<template>
  <v-container id="alerts" fluid tag="section">
    <component :is="getComponent"></component>
  </v-container>
</template>

<script>
import List from './List';
import StockIn from './StockIn';
// import StockOut from './StockOut';
export default {
  name: 'FGoodsInventory',
  components: {
    List,
    StockIn,
    // StockOut,
  },
  data: () => ({}),
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'FGoodsInventoryList':
          return 'List';
        case 'FGoodsStockIn':
          return 'StockIn';
        // case 'FGoodsStockOut':
        //   return 'StockOut';
        default:
          return 'List';
      }
    },
  },
};
</script>
