<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-md-2'" with-footer>
    <template v-slot:content>
      <RecipeTable :data="data" />
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-grey" @click="$emit('close')">{{ $t('act.close') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import RecipeTable from './RecipeTable';
export default {
  name: 'RecipeModal',
  props: {
    data: {
      type: Array,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RecipeTable,
  },
  data() {
    return {
      headerTitle: this.$t('recipe.lbl.recipe'),
    };
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
  },
};
</script>