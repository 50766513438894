import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const jobApi = {
  ddl(params) {
    return Api.get('job/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('job/list', params);
  },
  listOpen(params) {
    params = sanitizeParams(params);
    return Api.get('job/list/open', params);
  },
  listCompleted(params) {
    params = sanitizeParams(params);
    return Api.get('job/list/completed', params);
  },
  detail(params) {
    return Api.get('job', params);
  },
  create(data) {
    return Api.post('job/create', data);
  },
  update(data) {
    return Api.post('job/update', data);
  },
  start(data) {
    return Api.post('job/start', data);
  },
  stop(data) {
    return Api.post('job/stop', data);
  },
  cancel(data) {
    return Api.post('job/cancel', data);
  },
  report(data) {
    return Api.stream('job/doc', data);
  },
};