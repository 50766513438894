<template>
  <v-row>
    <v-col cols="12" md="12">
      <app-card>
        <v-card-title class="d-flex flex-column flex-sm-row justify-sm-space-between align-sm-start">
          <div class="d-flex" :class="{ 'w-100': isMobileView }">
            <TextField v-model="searchVal" class="flex-grow-1 mr-2 mb-2 mr-mb-0" :class="{ 'w-100': isMobileView }" :hide-details="true" :placeholder="$t('lbl.search')" />
            <AppBtn color="primary" @click="navigatePush('SalesOrderCreate')">{{ isMobileView ? $t('act.new') : $t('salesOrder.act.newSalesOrder') }}</AppBtn>
          </div>
          <div class="d-flex justify-end" :class="{ 'w-100': isMobileView }">
            <AppBtn class="btn-green" @click="showStockOutModal">{{ $t('inventory.act.stockOut') }}</AppBtn>
          </div>
        </v-card-title>
        <DataTable
          :class="'cursor-pointer'"
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :pagination-config.sync="pagination"
          @click:row="viewDetails"
          @pageChg="pageChg"
          @pageSizeChg="pageSizeChg"
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-15">{{ !isUndefinedNullOrEmpty(item.soNo) ? item.soNo : '-' }}</div>
              <div>{{ $t('salesOrder.lbl.soDate') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.soDate) ? setTimeZone(item.soDate, dateFormat) : '-' }}</span></div>
              <div>{{ $t('salesOrder.lbl.customer') }}: <span class="font-weight-regular">{{ item.customer }}</span></div>
              <div>{{ $t('salesOrder.lbl.branch') }}: <span class="font-weight-regular">{{ item.outlet }}</span></div>
              <div>{{ $t('salesOrder.lbl.deliveryDate') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate) : '-' }}</span></div>
              <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.salesOrderSts')[item.status] }}</span></div>
            </div>
          </template>
          <template #item-column-act-xs="{ item }">
            <div class="d-flex flex-column flex-sm-row justify-sm-end">
              <div class="d-flex justify-end">
                <IconWrap class="mb-1 mb-sm-0 mr-2" :icon="'mdi-printer'" @click.native.stop="report(item)" :disabled="item.printLoading" />
                <IconWrap class="mb-1 mb-sm-0 mr-2" :icon="'mdi-delete'" @click.native.stop="showConfirmModal(item)" :disabled="item.status != SALES_ORDER_STATUS.NEW && item.status != SALES_ORDER_STATUS.CONFIRMED" />
              </div>
              <AppBtn class="mb-1 mb-sm-0 mr-2 w-100" small height="32" :loading="item.printDoLoading" @click.stop="doReport(item)">{{ 'Print DO' }}</AppBtn>
              <AppBtn class="mb-1 mb-sm-0" small height="32" @click.stop="detail(item)" :disabled="item.status != SALES_ORDER_STATUS.APPROVED">{{ 'View Items' }}</AppBtn>
            </div>
          </template>

          <template #item-soNo="{ item }">
            {{ !isUndefinedNullOrEmpty(item.soNo) ? item.soNo : '-' }}
          </template>
          <template #item-outlet="{ item }">
            {{ !isUndefinedNullOrEmpty(item.outlet) ? item.outlet : '-' }}
          </template>
          <template #item-soDate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.soDate) ? setTimeZone(item.soDate, dateFormat) : '-' }}
          </template>
          <template #item-doDate="{ item }">
            {{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate, dateFormat) : '-' }}
          </template>
          <template #item-status="{ item }">
            {{ $t('map.salesOrderSts')[item.status] }}
          </template>
          <!-- <template #item-notify="{ item }">
            <v-icon small :color="item.notify ? 'green' : 'red'">{{ item.notify ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
          </template> -->
          <template #item-actions="{ item }">
            <div class="d-flex justify-end">
              <IconWrap class="mr-2" :icon="'mdi-printer'" @click.native.stop="report(item)" :disabled="item.printLoading" />
              <IconWrap class="mb-1 mb-sm-0 mr-2" :icon="'mdi-delete'" @click.native.stop="showConfirmModal(item)" :disabled="item.status != SALES_ORDER_STATUS.NEW && item.status != SALES_ORDER_STATUS.CONFIRMED" />
              <AppBtn class="mr-2" small height="32" :loading="item.printDoLoading" @click.stop="doReport(item)">{{ 'Print DO' }}</AppBtn>
              <AppBtn class="mr-2" small height="32" @click.stop="detail(item)" :disabled="item.status != SALES_ORDER_STATUS.APPROVED">{{ 'View Items' }}</AppBtn>
            </div>
          </template>
        </DataTable>
      </app-card>
    </v-col>
    <CommonModal v-if="orderItemModalShow" :show-modal.sync="orderItemModalShow" :header-title="headerTitle" :modal-size="'modal-md'" with-footer>
      <template v-slot:content>
        <!-- <div class="text-label mb-2">{{ $t('stockOrder.lbl.store') }}: <b>{{ modalData.store }}</b></div> -->
        <div class="d-flex justify-center mb-3">
          <div>
            <div class="text-center">
              <QrCode class="mb-2" :value="`${orderPrefix}${modalData.id}`" />
              <div class="text-label mb-3">{{ $t('stockOrder.lbl.order') }} <b>#{{ modalData.id }}</b></div>
            </div>
          </div>
        </div>

        <DataTable
          :headers="orderItemHeaders"
          :items="orderItems"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :hide-default-footer="true"
          :pagination-config.sync="pagination"
          disable-pagination
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <div class="font-weight-medium font-15">{{ !isUndefinedNullOrEmpty(item.item) ? item.item : '-' }}</div>
              <div>{{ $t('lbl.sku') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.sku) ? item.sku : '-' }}</span></div>
              <div>{{ $t('salesOrder.lbl.orderQty') }}: <span class="font-weight-regular">{{ item.qty + ' ' + item.uom }}</span></div>
              <div>{{ $t('item.lbl.extraQuantity') }}: <span class="font-weight-regular">{{ !isUndefinedNullOrEmpty(item.exQty) ? item.exQty + ' ' + item.exUom : '-' }}</span></div>
            </div>
          </template>
          <template #item-qty="{ item }">
            {{ item.qty + ' ' + item.uom }}
          </template>
          <template #item-exQty="{ item }">
            {{ !isUndefinedNullOrEmpty(item.exQty) ? item.exQty + ' ' + item.exUom : '-' }}
          </template>
        </DataTable>
      </template>
      <template v-slot:footer>
        <AppBtn class="btn-cancel" @click="orderItemModalShow = false">{{ $t('act.close') }}</AppBtn>
      </template>
    </CommonModal>
    <CommonModal v-if="stockOutModalShow" :show-modal.sync="stockOutModalShow" :header-title="stockOutHeaderTitle" :modal-size="'modal-md'" with-footer @close="resetFields">
      <template v-slot:content>
        <ValidationObserver ref="observer">
          <TextArea ref="textArea" v-model="form.stockOutData" :rules="rules.stockOutData" :rows="18" :label="$t('stockOrder.lbl.stockOutItems')" :placeholder="$t('stockOrder.lbl.stockOutItems')"></TextArea>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <AppBtn :loading="loading" @click="stockOut">{{ $t('act.confirm') }}</AppBtn>
      </template>
    </CommonModal>
    <CommonModal v-if="stockOutErrModalShow" :show-modal.sync="stockOutErrModalShow" :header-title="stockOutErrHeaderTitle" :modal-size="'modal-sm'" with-footer @close="resetErrFields">
      <template v-slot:content>
        <ValidationObserver ref="observer">
          <div v-for="(item, index) in stockOutErrData" :key="index" class="mb-3">
            <div class="font-weight-bold font-black">{{ `Order #${item.orderId}` }}</div>
            <ul>
              <li v-for="(cause, causeIndex) in item.cause" :key="causeIndex" class="font-weight-bold font-red">
                {{ cause.notes }} <span v-if="cause.sku" class="font-black font-weight-medium">{{ `(${$t('lbl.sku')}: ${cause.sku})` }}</span>
              </li>
            </ul>
          </div>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <AppBtn @click="resetErrFields">{{ $t('act.close') }}</AppBtn>
      </template>
    </CommonModal>
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :show-modal.sync="confirmModalShow"
    ></ConfirmModal>
  </v-row>
</template>

<script>
import { salesOrderApi, salesOrderItemApi, salesOrderLabelApi } from '@/api/sales-order';
import { SALES_ORDER_STATUS } from '@/common/enum';
import { mapState } from 'vuex';

export default {
  name: 'SalesOrderList',
  components: {},
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('lbl.deleteOrder'),
      confirmMsg: this.$t('lbl.confirm.delete.order'),
      stockOutModalShow: false,
      stockOutErrModalShow: false,
      stockOutHeaderTitle: this.$t('stockOrder.act.stockOut'),
      stockOutErrHeaderTitle: this.$t('stockOrder.lbl.stockOutFailedForItems'),
      stockOutErrData: [],
      headerTitle: this.$t('stockOrder.lbl.orderItemList'),
      orderItems: [],
      orderItemModalShow: false,
      modalData: {},
      pagination: {
        total: 0,
      },
      form: {
        stockOutData: '',
      },
      searchVal: '',
      data: [],
      id: null,
      loading: false,
      orderPrefix: 'OD:',
      rules: {
        stockOutData: { required: true },
      },
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      pageSizeOpts: state => state.app.pageSizeOpts,
      paginationOpts: state => state.app.paginationOpts,
    }),
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          // { text: '#', width: '50', value: 'no' },
          { text: this.$t('salesOrder.lbl.soNo'), width: '120', value: 'soNo' },
          { text: this.$t('salesOrder.lbl.soDate'), width: '130', value: 'soDate' },
          { text: this.$t('salesOrder.lbl.customer'), width: '220', value: 'customer' },
          { text: this.$t('salesOrder.lbl.branch'), width: '180', value: 'outlet' },
          { text: this.$t('salesOrder.lbl.deliveryDate'), width: '120', value: 'doDate' },
          { text: this.$t('lbl.status'), width: '120', value: 'status' },
          // { text: this.$t('salesOrder.lbl.email'), width: '120', value: 'notify' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
    SALES_ORDER_STATUS() {
      return SALES_ORDER_STATUS;
    },
    orderItemHeaders() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '100%', value: 'column-1-xs' },
        ];
      } else {
        return [
          { text: this.$t('lbl.no'), width: '50', value: 'no' },
          { text: this.$t('lbl.sku'), width: '150', value: 'sku' },
          { text: this.$t('salesOrder.lbl.item'), width: '350', value: 'item' },
          { text: this.$t('salesOrder.lbl.orderQty'), width: '120', value: 'qty' },
          { text: this.$t('item.lbl.extraQuantity'), width: '120', value: 'exQty' },
        ];
      }
    },
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getListNTotalRec();
  },
  methods: {
    showStockOutModal() {
      this.stockOutModalShow = true;
      setTimeout(() => {
        this.$refs.textArea.focus();
      }, 300);
    },
    delete(id) {
      this.confirmActionLoading = true;
      return salesOrderApi.delete({ id }).then(() => {
        this.confirmActionLoading = false;
        this.showSuccess(this.$t('lbl.deleteSuccess'));
        this.confirmModalShow = false;
        this.getList();
      });
    },
    showConfirmModal(item) {
      const event = {
        id: item.id,
        onSuccess: id => {
          this.deleteOrder(id);
        },
        onError: () => {},
      };
      this.confirmModalShow = true;
      this.$eb.$emit('show-confirm-modal', event);
    },
    getListNTotalRec() {
      this.getTotalRec().then(() => {
        this.getList();
      });
    },
    getTotalRec() {
      const params = {
        orgId: null,
        orderBy: '',
        page: 0,
      };
      return salesOrderApi.list(params).then(res => {
        this.pagination.total = res.data.total;
      });
    },
    getList() {
      const params = {
        orgId: null,
        orderBy: '',
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return salesOrderApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
            printLoading: false,
            printDoLoading: false,
          }
        });
      });
    },
    async detail(item) {
      await this.getOrderItemList(item.id);
      this.modalData.store = item.store;
      this.modalData.id = item.id;
      this.orderItemModalShow = true;
    },
    getOrderItemList(orderId) {
      const data = {
        page: 1,
        pageSize: 100,
        orderId
      }
      return salesOrderItemApi.list(data).then(res => {
        this.orderItems = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    viewDetails(item) {
      this.navigatePush('SalesOrderUpdate', { id: item.id });
    },
    async stockOut() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.loading = true;
      const obj = {
        data: this.form.stockOutData,
      }
      return salesOrderLabelApi.stockOut(obj).then(res => {
        setTimeout(() => {
          this.loading = false;
          if (res.ret == '0') {
            this.showSuccess(this.$t('salesOrder.success.stockOut'));
            this.stockOutModalShow = false;
            this.form.stockOutData = '';
            this.getListNTotalRec();
          } else {
            if (!this.isUndefinedNullOrEmpty(res.data)) {
              this.stockOutModalShow = false;
              this.form.stockOutData = '';
              if (res.data.length > 0) {
                console.log(res.data);
                setTimeout(() => {
                  this.stockOutErrModalShow = true;
                  this.stockOutErrData = res.data;
                }, 150);
              }
            }
          }
        }, 500);
      }).catch((error) => {
        this.loading = false;
      });
    },
    deleteOrder(id) {
      this.confirmActionLoading = true;
      return salesOrderApi.delete({ id }).then(res => {
        this.confirmActionLoading = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('lbl.deleteSuccess'));
          this.confirmModalShow = false;
          this.getListNTotalRec();
        }
      }).catch(() => {
        this.confirmActionLoading = false;
      });
    },
    resetFields() {
      this.form.stockOutData = '';
    },
    resetErrFields() {
      this.stockOutErrModalShow = false;
      this.stockOutErrData = [];
    },
    report(item) {
      const data = {
        id: item.id,
      }
      item.printLoading = true;
      return salesOrderApi.report(data).then(res => {
        item.printLoading = false;

        const blob = res.data;
        const fileName = `PO_${data.id}`;

        const file = new File([blob], fileName, {
          type: blob.type,
        });

        const reportUrl = URL.createObjectURL(file);

        window.open(reportUrl, '_blank');
      }).catch(() => {
        item.printLoading = false;
      });
    },
    doReport(item) {
      const data = {
        id: item.id,
      }
      item.printDoLoading = true;
      return salesOrderLabelApi.report(data).then(res => {
        item.printDoLoading = false;

        const blob = res.data;
        const fileName = `DO_${data.id}`;

        const file = new File([blob], fileName, {
          type: blob.type,
        });

        const reportUrl = URL.createObjectURL(file);

        window.open(reportUrl, '_blank');
      }).catch(() => {
        item.printDoLoading = false;
      });
    },
    pageChg(data) {
      this.getList();
    },
    pageSizeChg(data) {
      this.getList();
    },
  },
};
</script>
