import Api from '../api';
import { sanitizeParams } from '@/util/tools';

export const rawMatApi = {
  ddl(params) {
    return Api.get('product/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('product/list', params);
  },
  detail(params) {
    return Api.get('product', params);
  },
  create(data) {
    return Api.post('product/create', data);
  },
  update(data) {
    return Api.post('product/update', data);
  },
  delete(data) {
    return Api.post('product/delete', data);
  },
};

export const semiFGoodsApi = {
  ddl(params) {
    return Api.get('semi-goods/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('semi-goods/list', params);
  },
  detail(params) {
    return Api.get('semi-goods', params);
  },
  create(data) {
    return Api.post('semi-goods/create', data);
  },
  update(data) {
    return Api.post('semi-goods/update', data);
  },
  delete(data) {
    return Api.post('semi-goods/delete', data);
  },
};

export const fGoodsApi = {
  ddl(params) {
    return Api.get('goods/picklist', params);
  },
  list(params) {
    params = sanitizeParams(params);
    return Api.get('goods/list', params);
  },
  detail(params) {
    return Api.get('goods', params);
  },
  create(data) {
    return Api.post('goods/create', data);
  },
  update(data) {
    return Api.post('goods/update', data);
  },
  delete(data) {
    return Api.post('goods/delete', data);
  },
};

export const rawMatUomApi = {
  ddl(params) {
    return Api.get('product/uom/picklist', params);
  },
  list(params) {
    return Api.get('product/uom/list', params);
  },
  detail(params) {
    return Api.get('product/uom', params);
  },
  create(data) {
    return Api.post('product/uom/create', data);
  },
  update(data) {
    return Api.post('product/uom/update', data);
  },
  delete(data) {
    return Api.post('product/uom/delete', data);
  },
};

export const semiFGoodsUomApi = {
  ddl(params) {
    return Api.get('semi-goods/uom/picklist', params);
  },
  list(params) {
    return Api.get('semi-goods/uom/list', params);
  },
  detail(params) {
    return Api.get('semi-goods/uom', params);
  },
  create(data) {
    return Api.post('semi-goods/uom/create', data);
  },
  update(data) {
    return Api.post('semi-goods/uom/update', data);
  },
  delete(data) {
    return Api.post('semi-goods/uom/delete', data);
  },
};

export const fGoodsUomApi = {
  ddl(params) {
    return Api.get('goods/uom/picklist', params);
  },
  list(params) {
    return Api.get('goods/uom/list', params);
  },
  detail(params) {
    return Api.get('goods/uom', params);
  },
  create(data) {
    return Api.post('goods/uom/create', data);
  },
  update(data) {
    return Api.post('goods/uom/update', data);
  },
  delete(data) {
    return Api.post('goods/uom/delete', data);
  },
};

export const rawMatLvlApi = {
  ddl(params) {
    return Api.get('product/level/picklist', params);
  },
  list(params) {
    return Api.get('product/level/list', params);
  },
  detail(params) {
    return Api.get('product/level', params);
  },
  create(data) {
    return Api.post('product/level/create', data);
  },
  update(data) {
    return Api.post('product/level/update', data);
  },
  delete(data) {
    return Api.post('product/level/delete', data);
  },
};

export const semiFGoodsLvlApi = {
  ddl(params) {
    return Api.get('semi-goods/level/picklist', params);
  },
  list(params) {
    return Api.get('semi-goods/level/list', params);
  },
  detail(params) {
    return Api.get('semi-goods/level', params);
  },
  create(data) {
    return Api.post('semi-goods/level/create', data);
  },
  update(data) {
    return Api.post('semi-goods/level/update', data);
  },
  delete(data) {
    return Api.post('semi-goods/level/delete', data);
  },
};

export const fGoodsLvlApi = {
  ddl(params) {
    return Api.get('goods/level/picklist', params);
  },
  list(params) {
    return Api.get('goods/level/list', params);
  },
  detail(params) {
    return Api.get('goods/level', params);
  },
  create(data) {
    return Api.post('goods/level/create', data);
  },
  update(data) {
    return Api.post('goods/level/update', data);
  },
  delete(data) {
    return Api.post('goods/level/delete', data);
  },
};

export const productPriceApi = {
  ddl(params) {
    return Api.get('product/price/picklist', params);
  },
  list(params) {
    return Api.get('product/price/list', params);
  },
  detail(params) {
    return Api.get('product/price', params);
  },
  create(data) {
    return Api.post('product/price/create', data);
  },
  update(data) {
    return Api.post('product/price/update', data);
  },
  delete(data) {
    return Api.post('product/price/delete', data);
  },
};

export const productCertApi = {
  ddl(params) {
    return Api.get('product/cert/picklist', params);
  },
  list(params) {
    return Api.get('product/cert/list', params);
  },
  detail(params) {
    return Api.get('product/cert', params);
  },
  create(data) {
    return Api.post('product/cert/create', data);
  },
  update(data) {
    return Api.post('product/cert/update', data);
  },
  delete(data) {
    return Api.post('product/cert/delete', data);
  },
  preupload(data) {
    return Api.post('product/cert/image/preupload', data);
  },
};
