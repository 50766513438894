<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <template v-if="step === stepEnum.GRN_DETAILS">
                <div v-if="!isUpdate" class="mb-4">
                  <label class="font-1dot25r">{{ $t('grn.lbl.step1') }}</label>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <v-row class="form-row">
                    <v-col class="form-row-col" cols="12" sm="3">
                      <div class="form-row-col__label"
                        >{{ $t('grn.lbl.purchaseOrder') }}
                        <span class="font-red">{{ rules.poNo.required ? '*' : '' }}</span>
                      </div>
                      <TextField v-model="poNo" :rules="rules.poNo" :label="$t('grn.lbl.purchaseOrder')" disabled />
                    </v-col>
                    <v-col class="form-row-col align-self-end" cols="12" sm="3">
                      <AppBtn @click="poModalShow = true">{{ $t('act.select') }}</AppBtn>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="!isUndefinedNullOrEmpty(poNo)">
                  <label class="font-1dot25r">{{ isUpdate ? form.grnNo : $t('grn.lbl.step2') }}</label>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <v-row class="form-row">
                    <v-col class="form-row-col" cols="12" sm="4">
                      <div class="form-row-col__label"
                        >{{ $t('grn.lbl.supplier') }}
                      </div>
                      <TextField v-model="supplier" :label="$t('grn.lbl.supplier')" disabled />
                    </v-col>
                    <v-col class="form-row-col" cols="12" sm="4">
                      <div class="form-row-col__label"
                        >{{ $t('grn.lbl.branch') }}
                        <span class="font-red">{{ rules.outletId.required ? '*' : '' }}</span>
                      </div>
                      <Select
                        v-model="form.outletId"
                        :label="$t('grn.lbl.branch')"
                        :options="outletList"
                        :rules="rules.outletId"
                        disabled
                      />
                    </v-col>
                    <v-col class="form-row-col" cols="12" sm="4">
                      <div class="form-row-col__label"
                        >{{ $t('grn.lbl.storage') }}
                        <span class="font-red">{{ rules.storageId.required ? '*' : '' }}</span>
                      </div>
                      <Select
                        v-model="form.storageId"
                        :label="$t('grn.lbl.storage')"
                        :options="warehouseList"
                        :rules="rules.storageId"
                      />
                    </v-col>
                    <v-col class="form-row-col" cols="12" sm="4">
                      <div class="form-row-col__label"
                        >{{ $t('grn.lbl.supplierDoNo') }}
                        <span class="font-red">{{ rules.doNo.required ? '*' : '' }}</span>
                      </div>
                      <TextField v-model="form.doNo" :rules="rules.doNo" :label="$t('grn.lbl.supplierDoNo')" />
                    </v-col>
                    <v-col class="form-row-col" cols="12" sm="4">
                      <div class="form-row-col__label"
                        >{{ $t('lbl.remarks') }}
                        <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                      </div>
                      <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" />
                    </v-col>
                  </v-row>
                </div>
                <div class="d-flex justify-end mt-4">
                  <AppBtn class="btn-cancel mr-2" @click="navigateReplace('GrnList')">{{ $t('act.back') }}</AppBtn>
                  <AppBtn :loading="loading" :disabled="isUndefinedNullOrEmpty(poNo) && $route.name == 'GrnCreate'" @click="confirm">{{ isUpdate ? $t('act.next') : $t('act.next') }}</AppBtn>
                </div>
              </template>
              <template v-else-if="step === stepEnum.GRN_ITEM_DETAILS">
                <div class="d-flex justify-space-between mb-3">
                  <label class="font-1dot25r">{{ $t('grn.lbl.grnItems') }}</label>
                  <AppBtn :height="'30'" :classes="['btn-orange']" :loading="loading" @click="navigateToPrint">{{ $t('grn.print.act.printLabel') }}</AppBtn>
                </div>
                <v-divider class="mt-1 mb-3"></v-divider>
                <DataTable
                  class="table-bordered"
                  :class="'cursor-pointer'"
                  :headers="updateHeaders"
                  :items="form.items"
                  :mobile-breakpoint="0"
                  :hide-default-header="hideHeader"
                  :hide-default-footer="true"
                  :pagination-config.sync="pagination"
                  disable-pagination
                  @click:row="showGrnItemModal"
                >
                  <template #item-column-1-xs="{ item }">
                    <div>
                      <div class="font-weight-medium font-15">{{ item.product }}</div>
                      <div>{{ $t('grn.item.lbl.code') }}: <span class="font-weight-regular">{{ item.sku }}</span></div>
                      <div>{{ $t('grn.item.lbl.qtyReceived') }}: <span class="font-weight-regular">{{ `${item.qty} ${item.uom}` }}</span></div>
                    </div>
                  </template>
                  <template #item-column-act-xs="{ item }">
                    <div class="d-flex justify-end">
                      <AppBtn @click="showGrnItemModal(item)">{{ isUndefinedNullOrEmpty(item.id) ? $t('act.receive') : $t('act.update') }}</AppBtn>
                    </div>
                  </template>

                  <template #item-qtyToReceive="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.qtyToReceive) ? `${item.qtyToReceive} ${item.uom}` : '-' }}
                  </template>
                  <template #item-batchNo="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.batchNo) ? item.batchNo : '-' }}
                  </template>
                  <template #item-temperature="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.temperature) ? item.temperature : '-' }}
                  </template>
                  <template #item-expDate="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.expDate) ? setTimeZone(item.expDate, dateFormat) : '-' }}
                  </template>
                  <template #item-notes="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.notes) ? item.notes : '-' }}
                  </template>
                  <template #item-action="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.action) ? $t('map.grnItemAct')[item.action] : '-' }}
                  </template>
                  <template #item-actions="{ item }">
                    <div class="d-flex justify-end">
                      <AppBtn :height="'30'" @click="showGrnItemModal(item)">{{ isUndefinedNullOrEmpty(item.id) ? $t('act.receive') : $t('act.update') }}</AppBtn>
                    </div>
                  </template>
                </DataTable>

                <!-- <DataTable
                  v-else
                  class="table-bordered"
                  :class="'cursor-pointer'"
                  :headers="headers"
                  :items="form.items"
                  :mobile-breakpoint="0"
                  :hide-default-header="hideHeader"
                  :hide-default-footer="true"
                  :pagination-config.sync="pagination"
                  disable-pagination
                  @click:row="showGrnItemModal"
                >
                  <template #item-column-1-xs="{ item }">
                    <div>
                      <div class="font-weight-medium font-15">{{ item.product }}</div>
                      <div>{{ $t('grn.item.lbl.code') }}: <span class="font-weight-regular">{{ item.sku }}</span></div>
                      <div>{{ $t('grn.item.lbl.balanceQty') }}: <span class="font-weight-regular">{{ `${item.balanceQty} ${item.uom}` }}</span></div>
                      <div v-if="!isUndefinedNullOrEmpty(item.qtyReceived)">{{ $t('grn.item.lbl.qtyReceived') }}: <span class="font-weight-regular">{{ `${item.qtyReceived} ${item.uom}` }}</span></div>
                    </div>
                  </template>
                  <template #item-column-act-xs="{ item }">
                    <div class="d-flex justify-end">
                      <AppBtn @click="showGrnItemModal(item)">{{ $t('act.receive') }}</AppBtn>
                    </div>
                  </template>

                  <template #item-balanceQty="{ item }">
                    {{ item.balanceQty }}
                  </template>
                  <template #item-qtyToReceive="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.qtyToReceive) ? item.qtyToReceive : '-' }}
                  </template>
                  <template #item-temperature="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.temperature) ? item.temperature : '-' }}
                  </template>
                  <template #item-action="{ item }">
                    {{ !isUndefinedNullOrEmpty(item.action) ? $t('map.grnItemAct')[item.action] : '-' }}
                  </template>
                  <template #item-actions="{ item }">
                    <div class="d-flex justify-end">
                      <AppBtn @click="showGrnItemModal(item)">{{ $t('act.receive') }}</AppBtn>
                    </div>
                  </template>
                </DataTable> -->

                <v-expansion-panels class="expansion-wrap mt-6" v-model="activePanel">
                  <v-expansion-panel
                    v-for="(item,i) in 1"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      {{ `${poNo} Items` }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="d-flex justify-space-between align-center mb-3">
                        <span class="caption">*Items below are yet to be received</span>
                        <AppBtn :height="'34'" :disabled="isUndefinedNullOrEmpty(poItemData)" @click="addAllItemToGrn">{{ 'Add all items' }}</AppBtn>
                      </div>
                      <DataTable
                        class="table-bordered"
                        :headers="poItemHeaders"
                        :items="poItemData"
                        :mobile-breakpoint="0"
                        :hide-default-header="hideHeader"
                        :hide-default-footer="true"
                        :pagination-config.sync="pagination"
                        disable-pagination
                      >
                        <template #item-column-1-xs="{ item }">
                          <div>
                            <div class="font-weight-medium font-15">{{ item.product }}</div>
                            <div>{{ $t('grn.item.lbl.code') }}: <span class="font-weight-regular">{{ item.sku }}</span></div>
                            <div>{{ $t('grn.item.lbl.balanceQty') }}: <span class="font-weight-regular">{{ `${item.qty} ${item.uom}` }}</span></div>
                          </div>
                        </template>
                        <template #item-column-act-xs="{ item }">
                          <div class="d-flex justify-end">
                            <AppBtn @click="addItemToGrn(item)">{{ 'Add' }}</AppBtn>
                          </div>
                        </template>

                        <template #item-qty="{ item }">
                          {{ `${item.qty} ${item.uom}` }}
                        </template>
                        <template #item-actions="{ item }">
                          <div class="d-flex justify-end">
                            <AppBtn :height="'30'" @click="addItemToGrn(item)">{{ 'Add' }}</AppBtn>
                          </div>
                        </template>
                      </DataTable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <div class="d-flex justify-end mt-4">
                  <AppBtn class="btn-cancel mr-2" @click="step = stepEnum.GRN_DETAILS">{{ $t('act.back') }}</AppBtn>
                  <AppBtn @click="showConfirmModal">{{ $t('act.submit') }}</AppBtn>
                </div>
              </template>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </v-col>
    <ItemModal
      v-if="step == stepEnum.GRN_ITEM_DETAILS"
      :grn-id="form.id"
      :header-title="isUpdate ? $t('grn.act.updateGrnItem') : $t('grn.act.addGrnItem')"
      :show-modal.sync="itemModalShow"
      @close="itemModalShow = false"
    />
    <SelectModal
      v-if="poModalShow"
      :header-title="$t('grn.act.selectPO')"
      :show-modal.sync="poModalShow"
      @close="poModalShow = false"
      @confirm="selectPO"
    />
    <ConfirmModal
      :confirm-action-loading="confirmActionLoading"
      :header-title="confirmModalTitle"
      :modal-size="'modal-xs'"
      :confirm-msg="confirmMsg"
      :content-classes="['text-left']"
      :show-modal.sync="confirmModalShow"
    >
      <template #footer>
        <div class="btns-wrap">
          <AppBtn class="btn-cancel mr-2" @click="backToList">{{ isMobileView ? $t('act.no') : 'No, back to GRN list' }}</AppBtn>
          <AppBtn :loading="confirmActionLoading" @click="completePo($route.params.poId)">{{
            isMobileView ? $t('act.yes') : 'Yes, complete P.O.'
          }}</AppBtn>
        </div>
      </template>
    </ConfirmModal>
  </v-row>
</template>

<script>
import { purchaseOrderApi, purchaseOrderItemApi } from '@/api/purchase-order';
import { grnApi, grnItemApi } from '@/api/grn';
import { outletApi } from '@/api/outlet';
import { warehouseApi } from '@/api/warehouse';
import { mapState } from 'vuex';
import { roundToDecimal } from '@/util/tools';
import { PO_STATUS } from '@/common/enum';
import Countries from '@/util/countries';
import ItemModal from './components/ItemModal';
import SelectModal from './components/SelectModal';

export default {
  name: 'GrnDetails',
  components: {
    ItemModal,
    SelectModal,
  },
  data() {
    return {
      confirmModalShow: false,
      confirmActionLoading: false,
      confirmModalTitle: this.$t('purchaseOrder.act.completePO'),
      confirmMsg: 'Have you completed receiving all items from this P.O.? Click yes to complete the P.O.',

      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
      selectedTab: 0,
      tabs: this.$t('grnTabs'),
      step: 1,
      stepEnum: {
        GRN_DETAILS: 1,
        GRN_ITEM_DETAILS: 2,
      },
      poItemModalShow: false,
      itemModalShow: false,
      poModalShow: false,
      outletList: [],
      warehouseList: [],
      poItemData: [],
      // supplierList: [],
      loading: false,
      supplier: '',
      poNo: '',
      activePanel: 0,
      poStatus: null,
      grnItemReceived: false, // Use to check if any grn item received by user on this page
      form: {
        id: null,
        orderId: null,
        outletId: null,
        doNo: '',
        doDate: null,
        storageId: null,
        notes: '',
        print: null,
        type: null,
        items: [
          // {
          //   grnId: number;
          //   productId: number;
          //   qty: number;
          //   uom: string;
          //   temperature?: string;
          //   batchNo?: string;
          //   expDate?: Date;
          //   storageId?: number;
          //   image?: string;
          //   reason?: string;
          //   notes?: string;
          //   action?: GRN_ITEM_ACTION;
          //   flag?: number;
          // }
        ],
      },
      rules: {
        orderId: {
          required: true,
        },
        poNo: {
          required: true,
        },
        outletId: {
          required: true,
        },
        storageId: {
          required: false,
        },
        doNo: {
          required: true,
        },
        notes: {
          required: false,
        },
      },
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
    }),
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) && !this.isUndefinedNullOrEmpty(this.$route.params.poId) && (this.$route.name == 'GrnUpdate' || this.$route.name == 'GrnCreate');
    },
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    // headers() {
    //   if (this.$vuetify.breakpoint.xs) {
    //     return [
    //       { text: '', width: '80%', value: 'column-1-xs' },
    //       { text: '', width: '20%', value: 'column-act-xs' },
    //     ];
    //   } else {
    //     return [
    //       { text: '#', width: '50', value: 'no' },
    //       { text: this.$t('grn.item.lbl.code'), width: '120', value: 'sku' },
    //       { text: this.$t('grn.item.lbl.name'), width: '200', value: 'product' },
    //       { text: this.$t('grn.item.lbl.balanceQty'), width: '120', value: 'balanceQty' },
    //       { text: this.$t('grn.item.lbl.uom'), width: '100', value: 'uom' },
    //       { text: this.$t('grn.item.lbl.qtyReceived'), width: '120', value: 'qtyToReceive' },
    //       { text: this.$t('grn.item.lbl.uom'), width: '100', value: 'uom' },
    //       { text: this.$t('grn.item.lbl.temperature'), width: '130', value: 'temperature' },
    //       // { text: this.$t('grn.item.lbl.expDate'), width: '120', value: 'expiry' },
    //       // { text: this.$t('lbl.remarks'), width: '120', value: 'notes' },
    //       { text: this.$t('lbl.action'), width: '120', value: 'action' },
    //       { text: '', value: 'actions', sortable: false, align: 'end' },
    //     ];
    //   }
    // },
    updateHeaders() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('grn.item.lbl.code'), width: '120', value: 'sku' },
          { text: this.$t('grn.item.lbl.name'), width: '200', value: 'product' },
          { text: this.$t('grn.item.lbl.qtyReceived'), width: '100', value: 'qtyToReceive' },
          // { text: this.$t('grn.item.lbl.uom'), width: '100', value: 'uom' },
          { text: this.$t('grn.item.lbl.batchNo'), width: '120', value: 'batchNo' },
          { text: this.$t('grn.item.lbl.temperature'), width: '130', value: 'temperature' },
          { text: this.$t('grn.item.lbl.expDate'), width: '120', value: 'expDate' },
          { text: this.$t('lbl.remarks'), width: '120', value: 'notes' },
          { text: this.$t('lbl.action'), width: '80', value: 'action' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
    poItemHeaders() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '80%', value: 'column-1-xs' },
          { text: '', width: '20%', value: 'column-act-xs' },
        ];
      } else {
        return [
          { text: this.$t('grn.item.lbl.code'), width: '120', value: 'sku' },
          { text: this.$t('grn.item.lbl.name'), width: '400', value: 'product' },
          { text: this.$t('lbl.qty'), width: '120', value: 'qty' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ];
      }
    },
    allowCompletePo() {
      return this.poStatus == PO_STATUS.APPROVED;
    },
  },
  async created() {
    this.getOutletList();

    // if (!this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'GrnCreate') {
    //   this.navigateReplace('GrnUpdate');
    // }
    if (this.isUpdate) {
      if (this.$route.name == 'GrnCreate') {
        this.step = this.stepEnum.GRN_ITEM_DETAILS;
      }
      this.getPOStatus(this.$route.params.poId);
      await this.detail(this.$route.params.id);
      await this.getPOItemList(this.$route.params.poId);
    }
  },
  methods: {
    showConfirmModal() {
      if (!this.grnItemReceived) {
        this.showError(this.$t('grn.error.plsReceiveItem'));
        return;
      }
      if (this.allowCompletePo) {
        this.confirmModalShow = true;
        this.$eb.$emit('show-confirm-modal', event);
      } else {
        this.navigateReplace('GrnList');
      }
    },
    showGrnItemModal(item) {
      const event = {
        data: item,
        onSuccess: async(event) => {
          let data = event.data;
          if (data.isUpdate) {
            // const grnItemData = {
            //   ...this.processSingleItem(data),
            //   id: data.id,
            // }
            // await this.updateGrnItem(grnItemData);
            item.qty = (this.isUndefinedNullOrEmpty(data.qty) || data.qty == 0) ? 0 : data.qty;
          }
          item.id = data.id;
          item.qtyToReceive = (this.isUndefinedNullOrEmpty(data.qty) || data.qty == 0) ? 0 : data.qty;
          item.temperature = data.temperature;
          item.expDate = data.expDate;
          item.action = data.action;
          item.batchNo = data.batchNo;
          item.reason = data.reason;
          item.notes = data.notes;
          this.itemModalShow = false;

          this.grnItemReceived = true;
        },
        onError: () => {}
      }
      this.itemModalShow = true;
      this.$eb.$emit('show-grn-item-modal', event);
    },
    completePo(id) {
      this.confirmActionLoading = true;
      return purchaseOrderApi.complete({ id }).then(res => {
        this.confirmActionLoading = false;
        if (res.ret == '0') {
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.navigateReplace('GrnList');
        }
      }).catch(() => {
        this.confirmActionLoading = false;
      });
    },
    addAllItemToGrn() {
      const poItemData = JSON.parse(JSON.stringify(this.poItemData));
      for (let item of poItemData) {
        this.addItemToGrn(item);
      }
    },
    addItemToGrn(item) {
      let data = JSON.parse(JSON.stringify(item));
      data.no = this.form.items.length + 1;
      data.balanceQty = !this.isUndefinedNullOrEmpty(item.recvQty) ? roundToDecimal(item.qty - item.recvQty) : item.qty;
      this.form.items.push(data);
      const poItemIndex = this.poItemData.indexOf(item);
      this.poItemData.splice(poItemIndex, 1);
    },
    selectPO(item) {
      this.getPODetail(item.id);
      this.form.orderId = item.id;
      this.poNo = item.poNo;
      this.form.outletId = item.outletId;
      this.getWarehouseList(this.form.outletId);
      this.supplier = item.supplier;
    },
    // processSingleItem(item) {
    //   return {
    //     grnId: this.isUpdate ? this.$route.params.id : 0,
    //     productId: item.productId,
    //     qty: parseFloat(item.qtyToReceive),
    //     uom: item.uom,
    //     temperature: item.temperature,
    //     batchNo: item.batchNo,
    //     expDate: item.expDate,
    //     // storageId: ,
    //     // image: ,
    //     reason: item.reason,
    //     notes: item.notes,
    //     action: item.action,
    //   }
    // },
    // processItems(items) {
    //   let result = [];
    //   for (let item of items) {
    //     if (!this.isUndefinedNullOrEmpty(item.qtyToReceive) && item.qtyToReceive > 0) {
    //       result.push({
    //         ...this.processSingleItem(item),
    //       });
    //     }
    //   }
    //   return result;
    // },
    getOutletList() {
      return outletApi.ddl({}).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.outletList = list;
      });
    },
    getWarehouseList(outletId) {
      const params = {
        outletId,
      }
      return warehouseApi.ddl(params).then(res => {
        let list = [];
        for (let item of res.data) {
          list.push({
            text: item.name,
            value: item.id,
          });
        }
        this.warehouseList = list;
      });
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
      };
      delete data.status;

      delete data.items;
      if (this.isUpdate) {
        this.update(data);
      } else {
        delete data.id;
        // data.items = this.processItems(data.items);
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return grnApi
        .create(data)
        .then(res => {
          this.loading = false;
          this.form.id = res.data.id;
          this.form.grnNo = res.data.grnNo;
          this.step = this.stepEnum.GRN_ITEM_DETAILS;
          this.navigateReplace('GrnCreate', { id: res.data.id, poId: data.orderId });
          // this.showSuccess(this.$t('lbl.createSuccess'));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return grnApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.step = this.stepEnum.GRN_ITEM_DETAILS;
          // this.showSuccess(this.$t('lbl.updateSuccess'));
          // this.navigatePush('GrnList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateGrnItem(data) {
      return grnItemApi
        .update(data)
        .then(() => {
          this.showSuccess(this.$t('lbl.updateSuccess'));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getPODetail(id) {
      return purchaseOrderApi.detail({ id }).then(res => {
        this.form.items = res.data?.items.map((item, index) => {
          delete item.id; // To avoid confusion between po item id & grn item id. ItemModal.vue will use this id to query grn item details.
          return {
            ...item,
            no: index + 1,
            balanceQty: !this.isUndefinedNullOrEmpty(item.recvQty) ? roundToDecimal(item.qty - item.recvQty) : item.qty,
          }
        });
        this.pagination = {
          ...this.pagination,
          total: this.form.items.length,
        };
      });
    },
    getPOStatus(id) {
      return purchaseOrderApi.detail({ id }).then(res => {
        if (!this.isUndefinedNullOrEmpty(res.data)) {
          this.poStatus = res.data.status;
        }
      });
    },
    getPOItemList(id) {
      return purchaseOrderItemApi.list({ orderId: id }).then(res => {
        this.poItemData = res.data.filter(poItem => {
          const matched = this.form.items.find(item => item.productId == poItem.productId);
          return matched ? false : true;
        }).map(item => {
          delete item.id; // To avoid confusion between po item id & grn item id. ItemModal.vue will use this id to query grn item details.
          return {
            ...item,
            balanceQty: !this.isUndefinedNullOrEmpty(item.recvQty) ? roundToDecimal(item.qty - item.recvQty) : item.qty,
          }
        });
        if (this.isUndefinedNullOrEmpty(this.poItemData)) this.activePanel = null;
      });
    },
    detail(id) {
      return grnApi.detail({ id }).then(res => {
        const data = res.data;
        this.form.id = data.id;
        this.form.grnNo = data.grnNo;
        this.poNo = data.poNo;
        this.form.doNo = data.doNo;
        // this.form.doDate = data.doDate;
        this.form.orderId = data.orderId;
        this.form.outletId = data.outletId;
        this.form.storageId = data.storageId;
        this.supplier = data.supplier;
        this.form.notes = data.notes;
        this.form.status = data.status;

        this.getWarehouseList(this.form.outletId);
        this.form.items = data.items.map((item, index) => {
          return {
            ...item,
            no: index + 1,
            qtyToReceive: this.isUndefinedNullOrEmpty(item.qty) ? 0 : item.qty,
            expDate: !this.isUndefinedNullOrEmpty(item.expDate) ? this.setTimeZone(item.expDate, this.dateFormat) : null,
          };
        });
        this.grnItemReceived = this.form.items.length > 0;
        this.pagination = {
          ...this.pagination,
          total: this.form.items.length,
        };
      });
    },
    navigateToPrint() {
      this.navigatePush('RawMatLabel', { grnNo: this.form.grnNo });
    },
    backToList() {
      this.confirmModalShow = false;
      this.navigateReplace('GrnList');
    }
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
.table-bordered {
  border: 1px solid #ccc;
  border-radius: 0px;
}
.expansion-wrap {
  border: 1px solid #ccc;
  border-radius: 0px;
  .v-expansion-panel::before {
    box-shadow: none;
  }
}
</style>
