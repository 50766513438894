<template>
  <v-card
    elevation="2"
  >
    <v-card-text>
      <!-- <div class="mb-3">
        <label class="font-1dot25r">{{ $t('recipe.lbl.category') }}: <b>{{ categoryName }}</b></label>
        <v-divider class="mt-1 mb-1"></v-divider>
      </div> -->
      <!-- <div class="d-flex align-center mb-3">
        <div class="font-0875r mr-2">{{ $t('recipe.lbl.listOfRecipes') }}</div>
        <AppBtn @click="goToCreate">{{ $t('recipe.act.addNewRecipe') }}</AppBtn>
      </div> -->
      <div class="mb-3">
        <div class="d-flex justify-space-between align-center">
          <label class="font-1dot25r">{{ $t('recipe.lbl.recipeList') }}: <b>{{ categoryName }}</b></label>
          <AppBtn @click="goToCreate">{{ $t('recipe.act.addNewRecipe') }}</AppBtn>
        </div>
        <v-divider class="mt-1 mb-1"></v-divider>
      </div>
      <div class="d-flex align-center mb-3">
        <v-icon class="mr-1">mdi-information</v-icon>
        <span class="text-caption">{{ $t('recipe.lbl.category.note2') }}</span>
      </div>
      <v-row>
        <v-col v-for="(item, index) in data" :key="index" cols="12" sm="6" md="3">
          <v-card @click="goToDetails(item.recipeId)">
            <v-img
              :src="require('@/assets/recipe.jpg')"
              height="200px"
            ></v-img>

            <v-card-title>
              {{ item.recipe }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { recipeApi, recipeXCategoryApi } from '@/api/recipe';
export default {
  name: 'RecipeList',
  data() {
    return {
      categoryName: '',
      data: [],
    }
  },
  computed: {
    categoryId() {
      return this.$route.params.categoryId || null;
    },
  },
  created() {
    if (!this.isUndefinedNullOrEmpty(this.categoryId)) {
      this.list(this.categoryId);
    } else {
      alert('Category id not found');
    }
  },
  methods: {
    goToCreate() {
      this.navigatePush('RecipeCreate');
    },
    goToDetails(recipeId) {
      this.navigatePush('RecipeUpdate', { categoryId: this.categoryId, id: recipeId });
    },
    list(categoryId) {
      const params = {
        page: 1,
        pageSize: -1,
        grpId: categoryId,
      }
      return recipeXCategoryApi.list(params).then (res => {
        this.data = res.data;
        if (res.data.length > 0) {
          this.categoryName = res.data[0].grp;
        }
      });
    },
  }
}
</script>