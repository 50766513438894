<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <ValidationObserver ref="observer">
        <v-row class="form-row">
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.unitOfMeasurement') }}
              <span class="font-red">{{ rules.uom.required ? '*' : '' }}</span>
            </label>
            <Select v-model="form.uom" :label="$t('uom.lbl.unitOfMeasurement')" :options="uomList" :rules="rules.uom" />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <label class="form-row-col__label"
              >{{ $t('uom.lbl.baseMultiplier') }}
              <span class="font-red">{{ rules.mul.required ? '*' : '' }}</span>
            </label>
            <TextField
              v-model="form.mul"
              :type="'number'"
              :rules="rules.mul"
              :label="$t('uom.lbl.baseMultiplier')"
            />
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <div class="d-flex align-center">
              <Checkbox v-model="form.isBase" class="mr-2" :label="$t('uom.lbl.baseUom')" :rules="rules.isBase" />
              <Tooltip :text="$t('uom.lbl.baseUom.desc')" />
            </div>
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <div class="d-flex align-center">
              <Checkbox v-model="form.isStock" class="mr-2" :label="$t('uom.lbl.inventoryUom')" :rules="rules.isStock" />
              <Tooltip :text="$t('uom.lbl.inventoryUom.desc')" />
            </div>
          </v-col>
          <v-col v-if="productType == PRODUCT_TYPE.RAW" class="form-row-col" cols="12" sm="6">
            <div class="d-flex align-center">
              <Checkbox v-model="form.isOrder" class="mr-2" :label="$t('uom.lbl.poUom')" :rules="rules.isOrder" />
              <Tooltip :text="$t('uom.lbl.poUom.desc')" />
            </div>
          </v-col>
          <v-col class="form-row-col" cols="12" sm="6">
            <div class="d-flex align-center">
              <Checkbox v-model="form.isSale" class="mr-2" :label="$t('uom.lbl.salesUom')" :rules="rules.isSale" />
              <Tooltip :text="$t('uom.lbl.salesUom.desc')" />
            </div>
          </v-col>
          <v-col class="form-row-col" cols="12">
            <label class="form-row-col__label"
              >{{ $t('lbl.remarks') }}
              <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
            </label>
            <TextArea v-model="form.notes" :type="'number'" :rules="rules.notes" :label="$t('lbl.remarks')" />
          </v-col>
        </v-row>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Countries from '@/util/countries';
import { rawMatUomApi, semiFGoodsUomApi, fGoodsUomApi } from '@/api/product';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import { PRODUCT_TYPE } from '@/common/enum';

export default {
  name: 'UomDetails',
  props: {
    id: {
      type: Number,
      default: null,
    },
    productId: {
      type: Number,
      default: null,
    },
    productType: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerTitle: !isUndefinedNullOrEmpty(this.id) ? this.$t('uom.title.updateUom') : this.$t('uom.title.addNewUom'),
      form: {
        uom: '',
        mul: 1,
        isBase: false,
        isStock: false,
        isOrder: false,
        isSale: false,
        notes: '',
      },
      rules: {
        uom: { required: true },
        mul: { required: true },
        isBase: { required: false },
        isStock: { required: false },
        isOrder: { required: false },
        isSale: { required: false },
        notes: { required: false },
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      uomList: state => state.lookup.uomList,
    }),
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    countries() {
      const response = new Countries(this.$i18n.locale);
      return response.get();
    },
    isUpdate() {
      return !isUndefinedNullOrEmpty(this.id) ? true : false;
    },
    PRODUCT_TYPE() {
      return PRODUCT_TYPE;
    },
  },
  created() {
    switch (this.productType) {
      case PRODUCT_TYPE.RAW:
        this.api = rawMatUomApi;
        this.idKey = 'productId';
        break;
      case PRODUCT_TYPE.SEMIFGOODS:
        this.api = semiFGoodsUomApi;
        this.idKey = 'itemId';
        break;
      case PRODUCT_TYPE.FGOODS:
        this.api = fGoodsUomApi;
        this.idKey = 'itemId';
        break;
      default:
        alert('Something went wrong');
        break;
    }
    if (isUndefinedNullOrEmpty(this.uomList)) {
      this.fetchUomList();
    }
    if (!isUndefinedNullOrEmpty(this.id)) {
      this.detail(this.id);
    }
  },
  methods: {
    ...mapActions(['fetchUomList']),
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      let data = {
        ...this.form,
        [this.idKey]: this.productId,
      };
      data.mul = Number(data.mul);
      if (this.productType != PRODUCT_TYPE.RAW) {
        // Only raw material has this field
        delete data.isOrder;
      }
      if (this.isUpdate) {
        data.id = this.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return this.api
        .create(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return this.api
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.$emit('close');
          this.$emit('getList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    detail(id) {
      return this.api.detail({ id }).then(res => {
        this.form = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
