<template>
  <CommonModal :show-modal.sync="modalState" :header-title="headerTitle" :modal-size="'modal-sm'" with-footer>
    <template v-slot:content>
      <v-row>
        <v-col class="py-2" cols="12">
          <label class="form-row-col__label">{{ $t('production.lbl.productName') }}</label>
          <TextField v-model="data.product" :label="$t('production.lbl.productName')" readOnly />
        </v-col>
        <v-col class="py-2" cols="12" sm="6">
          <label class="form-row-col__label">{{ $t('production.lbl.expectedProdQty') }}</label>
          <TextField :value="`${data.qty} ${data.uom}`" :label="$t('production.lbl.expectedProdQty')" readOnly />
        </v-col>
        <v-col class="py-2" cols="12" sm="6">
          <label class="form-row-col__label">{{ $t('production.lbl.totalBatch') }}</label>
          <TextField v-model="data.totBatch" :label="$t('production.lbl.totalBatch')" readOnly />
        </v-col>
        <v-col class="py-2" cols="12" sm="6">
          <label class="form-row-col__label">{{ $t('production.lbl.actualProdQty') }}</label>
          <TextField v-model="form.finalQty" :type="'number'" :step="decimalStep" :suffix="data.uom" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer>
      <AppBtn class="btn-cancel" @click="$emit('close')">{{ $t('act.cancel') }}</AppBtn>
      <AppBtn @click="stop" :loading="loading">{{ $t('act.confirm') }}</AppBtn>
    </template>
  </CommonModal>
</template>
<script>
import { jobApi } from '@/api/job';
import { getCurrentDate } from '@/util/tools';
export default {
  name: 'CompleteJobModal',
  props: {
    data: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      decimalStep: '0.01',
      form: {
        endDate: null,
        finalQty: 1,
      }
    };
  },
  computed: {
    modalState: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('close');
      },
    },
    headerTitle() {
      return `${this.$t('production.lbl.completeJob')}: ${this.data.jobNo}`;
    },
  },
  created() {
    if (!this.isUndefinedNullOrEmpty(this.data)) {
      this.form.finalQty = this.data.qty;
    }
  },
  methods: {
    stop() {
      const data = {
        id: this.data.id,
        endDate: getCurrentDate(),
        finalQty: this.form.finalQty,
      };
      this.loading = true;
      return jobApi.stop(data).then(res => {
        this.loading = false;
        this.showSuccess(this.$t('production.msg.jobCompletedSuccess'));
        this.$emit('close');
        this.$emit('getList');
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>