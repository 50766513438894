var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.className,attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":_vm.itemKey,"items-per-page":_vm.paginationConfig.pageSize,"page":_vm.paginationConfig.page,"server-items-length":_vm.disablePagination ? null : _vm.paginationConfig.total,"loading":_vm.loading,"loading-text":_vm.loadingText,"hide-default-footer":_vm.hideDefaultFooter,"hide-default-header":_vm.hideDefaultHeader,"disable-sort":_vm.disableSort,"disable-pagination":_vm.disablePagination,"mobile-breakpoint":_vm.mobileBreakpoint,"show-select":_vm.showSelect,"selectable-key":_vm.selectableKey,"height":_vm.height,"footer-props":{
    itemsPerPageOptions: _vm.pageSizeOpts,
  }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.paginationConfig, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.paginationConfig, "pageSize", $event)},_vm.pageSizeChg],"update:page":[function($event){return _vm.$set(_vm.paginationConfig, "page", $event)},_vm.pageChg],"toggle-select-all":_vm.toggleSelectAll,"item-selected":_vm.itemSelected,"click:row":_vm.clickRow},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
  var value = ref.value;
return {key:("header." + value),fn:function(ref){
  var header = ref.header;
return [_vm._t(("header-" + value),[_vm._v(" "+_vm._s(header.text)+" ")],{"header":header})]}}}),_vm._l((_vm.headers),function(ref){
  var value = ref.value;
return {key:("item." + value),fn:function(ref){
  var item = ref.item;
return [_vm._t(("item-" + value),[_vm._v(" "+_vm._s(item[value])+" ")],{"item":item})]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }