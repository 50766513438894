<template>
  <ValidationObserver ref="observer">
    <div>
      <label class="font-1dot25r">{{ $t('rawMat.print.step1') }}</label>
      <v-divider class="mt-1 mb-2"></v-divider>
      <v-row class="form-row">
        <v-col class="form-row-col" cols="12" sm="3">
          <TextField v-model="grnNo" :rules="rules.grnNo" :label="$t('grn.lbl.grnNo')" @keyup.enter.native="searchGrn(grnNo)" />
        </v-col>
        <v-col class="form-row-col" cols="12" sm="3">
          <AppBtn :loading="loading" @click="searchGrn(grnNo)">{{ $t('act.search') }}</AppBtn>
        </v-col>
      </v-row>
    </div>
    <div v-if="showCheckSettSection" class="mt-3">
      <label class="font-1dot25r">{{ $t('rawMat.print.step2') }}</label>
      <v-divider class="mt-1 mb-1"></v-divider>
      <div class="d-flex align-center my-3">
        <v-icon class="mr-1">mdi-information</v-icon>
        <span class="text-caption mr-2">{{ $t('rawMat.print.step2.note') }}</span>
      </div>

      <DataTable
        class="mb-3"
        :headers="headers"
        :items="data"
        :mobile-breakpoint="0"
        :hide-default-header="hideHeader"
        :hide-default-footer="true"
        disable-pagination
      >
        <template #item-column-1-xs="{ item }">
          <div>
            <span class="font-weight-medium font-15">{{ item.product }}</span>
            <div>{{ $t('rawMat.print.lbl.itemDesc') }}: <span class="font-weight-regular">{{ item.product }}</span></div>
            <div>
              {{ $t('rawMat.print.lbl.noOfLabels') }}: <span class="font-weight-regular">{{ `${item.cnt},` }}</span>
              {{ $t('rawMat.print.lbl.qty') }}: <span class="font-weight-regular">{{ item.qty }}</span>
            </div>
            <div>{{ $t('rawMat.print.lbl.printedBefore') }}:
              <v-icon small :color="item.isPrinted ? 'green' : 'red'">{{
                item.isPrinted ? 'mdi-check-bold' : 'mdi-close-thick'
              }}</v-icon>
            </div>
            <div class="d-flex align-center">
              <div class="mr-2">{{ $t('rawMat.print.lbl.printOpt') }}:</div>
              <SwitchToggle v-model="item.printOpt" :label="$t('map.lblPrintOptBool')[item.printOpt]" @change="printOptChg(item)" />
            </div>
            <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.grnLabelStatus')[item.status] }}</span></div>
            <div class="d-flex align-center">
              <div class="mr-2">{{ $t('rawMat.print.lbl.chgUomIfNeeded') }}:</div>
              <Select v-model="item.newUom" :options="item.processedUomList" @change="uomChg(item)" />
            </div>
          </div>
        </template>

        <template #item-status="{ item }">
          {{ !isUndefinedNullOrEmpty(item.status) ? $t('map.grnLabelStatus')[item.status] : '-' }}
        </template>
        <template #item-isPrinted="{ item }">
          <v-icon small :color="item.isPrinted ? 'green' : 'red'">{{
            item.isPrinted ? 'mdi-check-bold' : 'mdi-close-thick'
          }}</v-icon>
        </template>
        <template #item-printOpt="{ item }">
          <SwitchToggle v-model="item.printOpt" :label="$t('map.lblPrintOptBool')[item.printOpt]" @change="printOptChg(item)" />
        </template>
        <template #item-newUom="{ item }">
          <div class="d-flex justify-end">
            <Select v-model="item.newUom" :options="item.processedUomList" @change="uomChg(item)" />
          </div>
        </template>
      </DataTable>

      <div class="d-flex justify-end">
        <AppBtn @click="printLabel">{{ $t('rawMat.print.act.printLabel') }}</AppBtn>
      </div>

      <div class="mt-6 pa-2 printed-lbl-wrap">
        <h3 class="font-1dot25r font-weight-regular mb-3">{{ $t('rawMat.print.lbl.printedLabels') }}</h3>
        <DataTable
          :headers="printedLabelHeaders"
          :items="grnLabelList"
          :mobile-breakpoint="0"
          :hide-default-header="hideHeader"
          :hide-default-footer="true"
          disable-pagination
        >
          <template #item-column-1-xs="{ item }">
            <div>
              <span class="font-weight-medium font-15">{{ item.product }}</span>
              <div>{{ $t('rawMat.print.lbl.itemDesc') }}: <span class="font-weight-regular">{{ item.product }}</span></div>
              <div>{{ $t('rawMat.print.lbl.serialNo') }}: <span class="font-weight-regular">{{ item.sn }}</span></div>
              <div>
                {{ $t('rawMat.print.lbl.noOfLabels') }}: <span class="font-weight-regular">{{ `${item.cnt},` }}</span>
                {{ $t('rawMat.print.lbl.qty') }}: <span class="font-weight-regular">{{ item.qty }}</span>
              </div>
              <div>{{ $t('uom.lbl.uom') }}: <span class="font-weight-regular">{{ item.uom }}</span></div>
              <div>{{ $t('lbl.status') }}: <span class="font-weight-regular">{{ $t('map.grnLabelStatus')[item.status] }}</span></div>
            </div>
          </template>
          <template #item-status="{ item }">
            {{ !isUndefinedNullOrEmpty(item.status) ? $t('map.grnLabelStatus')[item.status] : '-' }}
          </template>
        </DataTable>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { grnItemApi, grnLabelApi } from '@/api/grn';

export default {
  name: 'CreateLabel',
  data() {
    return {
      data: [],
      grnLabelList: [],
      grnNo: null,
      loading: false,
      showCheckSettSection: false,
      rules: {
        grnNo: {
          required: true,
        },
      }
    }
  },
  computed: {
    hideHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '100%', value: 'column-1-xs' },
        ];
      } else {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('lbl.status'), width: '100', value: 'status' },
          { text: this.$t('rawMat.print.lbl.printedBefore'), width: '120', value: 'isPrinted' },
          { text: this.$t('rawMat.print.lbl.itemDesc'), width: '150', value: 'product' },
          { text: this.$t('rawMat.print.lbl.printOpt'), width: '120', value: 'printOpt' },
          { text: this.$t('rawMat.print.lbl.noOfLabels'), width: '120', value: 'cnt' },
          { text: this.$t('rawMat.print.lbl.qty'), width: '80', value: 'qty' },
          { text: this.$t('uom.lbl.uom'), width: '80', value: 'uom' },
          { text: this.$t('rawMat.print.lbl.chgUomIfNeeded'), width: '120', value: 'newUom' },
          // { text: '', value: 'actions', sortable: false, align: 'end' },
        ]
      }
    },
    printedLabelHeaders() {
      if (this.$vuetify.breakpoint.xs) {
        return [
          { text: '', width: '100%', value: 'column-1-xs' },
        ];
      } else {
        return [
          { text: '#', width: '50', value: 'no' },
          { text: this.$t('rawMat.print.lbl.itemDesc'), width: '150', value: 'product' },
          { text: this.$t('rawMat.print.lbl.serialNo'), width: '150', value: 'sn' },
          { text: this.$t('rawMat.print.lbl.noOfLabels'), width: '120', value: 'cnt' },
          { text: this.$t('rawMat.print.lbl.qty'), width: '80', value: 'qty' },
          { text: this.$t('uom.lbl.uom'), width: '80', value: 'uom' },
          { text: this.$t('lbl.status'), width: '100', value: 'status' },
        ];
      }
    },
  },
  mounted() {
     // pass from /Grn/Details.vue
    if (!this.isUndefinedNullOrEmpty(this.$route.params.grnNo)) {
      this.grnNo = this.$route.params.grnNo;
      this.$nextTick(() => {
        this.searchGrn(this.grnNo);
      })
    }
  },
  methods: {
    async searchGrn(grnNo) {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.data = [];
      this.getGrnItemList(grnNo);
      this.getGrnLabelList(grnNo);
    },
    getGrnItemList(grnNo) {
      const params = {
        page: 1,
        pageSize: -1,
        grnNo,
      }

      return grnItemApi.list(params).then(res => {
        this.data = res.data.map((item, index) => {
          let data = {
            ...item,
            no: index + 1,
            cnt: 1, // No. of labels
            oriQty: item.qty,
            oriUom: item.uom,
            qty: (item.uom == item.stkUom) ? item.qty : item.qty * item.stkMul,
            uom: (item.uom == item.stkUom) ? item.uom : item.stkUom,
            printOpt: true,
          }
          data.processedUomList = data.uomList.map(uom => {
            let qty;
            if (uom == item.stkUom) {
              if (item.uom == item.stkUom) {
                qty = item.qty;
              } else {
                qty = item.qty * item.stkMul;
              }
            } else {
              qty = item.qty;
            }
            return {
              text: uom,
              value: uom,
              qty,
            }
          });
          return data;
        });
        this.showCheckSettSection = true;
      })
    },
    getGrnLabelList(grnNo) {
      const params = {
        page: 1,
        pageSize: 100,
        grnNo,
      }
      return grnLabelApi.list(params).then(res => {
        this.grnLabelList = res.data.map((item, index) => {
          return {
            ...item,
            no: index + 1,
          }
        });
      });
    },
    uomChg(item) {
      if (item.newUom == item.uom) return;
      for (let data of item.processedUomList) {
        if (data.value == item.newUom) {
          item.uom = item.newUom;
          if (item.printOpt == true) {
            // Print option: Single
            item.qty = data.qty;
          } else {
            // Print option: Multiple
            item.cnt = data.qty;
          }
          break;
        }
      }
    },
    printOptChg(item) {
      const tempCnt = item.cnt;
      item.cnt = item.qty;
      item.qty = tempCnt;
    },
    printLabel() {
      const labels = this.data.map(item => {
        return {
          itemId: item.id,
          grnId: item.grnId,
          qty: item.qty,
          uom: item.uom,
          cnt: item.cnt,
        }
      });
      const data = {
        labels,
      }
      this.loading = true;
      return grnLabelApi.create(data).then(() => {
        this.showSuccess(this.$t('lbl.createSuccess'));
        this.showCheckSettSection = false;
        this.loading = false;
        this.data = [];
        this.grnNo = null;
      }).catch(() => {
        this.loading = false;
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.printed-lbl-wrap {
  border: 1px solid #c1c1c1;
}
.v-icon {
  font-size: 18px;
}
</style>