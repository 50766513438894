<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-tabs v-model="selectedTab" background-color="var(--tbl-header-color)" active-class="tab-active" height="40">
        <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ValidationObserver ref="observer">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div v-if="tab.id === 0" class="tab-content-wrap px-2 py-3 px-md-4">
              <v-row class="form-row">
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.jobNo') }}
                    <span class="font-red">{{ rules.jobNo.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.jobNo" :rules="rules.jobNo" :label="$t('production.lbl.jobNo')" disabled />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.batchNo') }}
                    <span class="font-red">{{ rules.batchNo.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.batchNo" :rules="rules.batchNo" :label="$t('production.lbl.batchNo')" disabled />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.jobStatus') }}
                    <span class="font-red">{{ rules.status.required ? '*' : '' }}</span>
                  </label>
                  <Select
                    v-if="form.status == JOB_STATUS.NEW || form.status == JOB_STATUS.CONFIRMED"
                    v-model="form.status"
                    :label="$t('production.lbl.jobStatus')"
                    :options="$t('jobStatus')"
                    :rules="rules.status"
                    :disabled="!enableEdit"
                  />
                  <TextField v-else :value="$t('map.jobStatus')[form.status]" :label="$t('production.lbl.jobStatus')" disabled />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.jobExecDateTime') }}
                    <span class="font-red">{{ rules.jobDate.required ? '*' : '' }}</span>
                  </label>
                  <DatePicker v-model="form.jobDate" :rules="rules.jobDate" :label="$t('production.lbl.jobExecDateTime')" :disabled="!enableEdit" />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.execBy') }}
                    <span class="font-red">{{ rules.deptId.required ? '*' : '' }}</span>
                  </label>
                  <Select
                    v-model="form.deptId"
                    :label="$t('production.lbl.execBy')"
                    :options="departmentList"
                    :rules="rules.deptId"
                    :disabled="!enableEdit"
                  />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('lbl.remarks') }}
                    <span class="font-red">{{ rules.notes.required ? '*' : '' }}</span>
                  </label>
                  <TextField v-model="form.notes" :rules="rules.notes" :label="$t('lbl.remarks')" :disabled="!enableEdit" />
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.jobType') }}
                    <span class="font-red">{{ rules.type.required ? '*' : '' }}</span>
                  </label>
                  <Select
                    v-model="form.type"
                    :label="$t('production.lbl.jobType')"
                    :options="$t('jobType')"
                    :rules="rules.type"
                    :clearable="false"
                    :disabled="!enableEdit"
                    @change="jobTypeChg"
                  />
                </v-col>
                <v-col v-if="form.type == PRODUCT_TYPE.SEMIFGOODS" class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.semiFGoods') }}
                    <span class="font-red">{{ rules.itemId.required ? '*' : '' }}</span>
                  </label>
                  <Autocomplete
                    v-model="form.itemId"
                    class="font-weight-bold"
                    :label="$t('production.lbl.semiFGoods')"
                    :outlined="true"
                    :solo="false"
                    :options="semiFGoodsDdl"
                    :rules="rules.itemId"
                    :placeholder="$t('production.lbl.semiFGoods')"
                    :disabled="!enableEdit"
                    @change="semiFGoodsChg"
                  />
                </v-col>
                <v-col v-else-if="form.type == PRODUCT_TYPE.FGOODS" class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.fGoods') }}
                    <span class="font-red">{{ rules.itemId.required ? '*' : '' }}</span>
                  </label>
                  <Autocomplete
                    v-model="form.itemId"
                    class="font-weight-bold"
                    :label="$t('production.lbl.fGoods')"
                    :outlined="true"
                    :solo="false"
                    :options="fGoodsDdl"
                    :rules="rules.itemId"
                    :placeholder="$t('production.lbl.fGoods')"
                    :disabled="!enableEdit"
                    @change="fGoodsChg"
                  />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label"
                    >{{ $t('production.lbl.noOfBatch') }}
                    <span class="font-red">{{ rules.totBatch.required ? '*' : '' }}</span>
                  </label>
                  <TextField
                    v-model="form.totBatch"
                    :type="'number'"
                    :rules="rules.totBatch"
                    :label="$t('production.lbl.noOfBatch')"
                    :disabled="!enableEdit"
                  />
                </v-col>
                <v-col class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label">{{ $t('production.lbl.expectedProdQty') }} </label>
                  <TextField v-model="expectedQty" :label="$t('production.lbl.expectedProdQty')" disabled />
                </v-col>
                <v-col v-if="showFinalQty" class="form-row-col" cols="12" sm="4">
                  <label class="form-row-col__label">{{ $t('production.lbl.finalQty') }} </label>
                  <TextField :value="`${form.finalQty} ${recipeUom}`" :label="$t('production.lbl.finalQty')" disabled />
                </v-col>
              </v-row>
              <div v-if="isReady" class="d-flex justify-end mt-3">
                <AppBtn class="btn-cancel" :class="{ 'mr-2': enableUpdate }" @click="navigateReplace('ProductionList')">{{ $t('act.back') }}</AppBtn>
                <AppBtn v-if="isUpdate && enableUpdate" class="btn-red mr-2" :loading="cancelLoading" @click="cancel(jobId)">{{
                  $t('inventory.act.cancelProduction')
                }}</AppBtn>
                <AppBtn v-if="enableUpdate" :loading="loading" @click="confirm">{{ isUpdate ? $t('act.update') : $t('act.create') }}</AppBtn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import { deptApi } from '@/api/department';
import { jobApi } from '@/api/job';
// import { recipeApi } from '@/api/recipe';
import { semiFGoodsApi, fGoodsApi } from '@/api/product';
import { mapState, mapActions } from 'vuex';
import { extractJsonKey } from '@/util/tools';
import { PRODUCT_TYPE, JOB_STATUS } from '@/common/enum';

export default {
  name: 'ProductionDetails',
  data() {
    return {
      selectedTab: 0,
      tabs: this.$t('productionTabs'),
      cancelLoading: false,
      loading: false,
      departmentList: [],
      isReady: false,
      form: {
        orderId: null,
        outletId: null,
        jobNo: '',
        jobDate: null,
        batchNo: '',
        itemId: null,
        totBatch: 1,
        deptId: null,
        notes: '',
        type: 2,
        status: 0,
        qty: null,
      },
      recipeUom: null,
      rules: {
        orderId: { required: false },
        outletId: { required: false },
        jobNo: { required: false },
        jobDate: { required: true },
        batchNo: { required: false },
        itemId: { required: true },
        totBatch: { required: true, min_value: 1 },
        deptId: { required: true },
        notes: { required: false },
        type: { required: true },
        status: { required: true },
      },
    };
  },
  computed: {
    ...mapState({
      dateFormat: state => state.app.dateFormat,
      semiFGoodsDdl: state => state.product.semiFGoodsDdl,
      fGoodsDdl: state => state.product.fGoodsDdl,
    }),
    isUpdate() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) && this.$route.name == 'ProductionUpdate';
    },
    jobId() {
      return !this.isUndefinedNullOrEmpty(this.$route.params.id) ? Number(this.$route.params.id) : null;
    },
    expectedQty() {
      let qty = (this.form.totBatch * this.form.qty)?.toString();
      let uom = !this.isUndefinedNullOrEmpty(this.recipeUom) ? this.recipeUom : '';
      const finalString = qty + ' ' + uom;
      return finalString;
    },
    PRODUCT_TYPE() {
      return PRODUCT_TYPE;
    },
    JOB_STATUS() {
      return JOB_STATUS;
    },
    enableEdit() {
      return this.form.status == JOB_STATUS.NEW || this.form.status == JOB_STATUS.CONFIRMED || this.form.status == JOB_STATUS.IN_PROGRESS;
    },
    enableUpdate() {
      return this.form.status == JOB_STATUS.NEW || this.form.status == JOB_STATUS.CONFIRMED || this.form.status == JOB_STATUS.IN_PROGRESS;
    },
    showFinalQty() {
      return this.form.status == JOB_STATUS.COMPLETED || this.form.status == JOB_STATUS.COMPLETED_VARIANCE;
    }
  },
  async created() {
    this.getDepartmentDdl();
    if (this.isUndefinedNullOrEmpty(this.semiFGoodsDdl)) {
      await this.fetchSemiFGoodsDdl();
    }
    if (this.isUndefinedNullOrEmpty(this.fGoodsDdl)) {
      await this.fetchFGoodsDdl();
    }
    if (this.isUpdate) {
      this.detail(this.jobId);
    } else {
      this.isReady = true;
    }
  },
  methods: {
    ...mapActions(['fetchSemiFGoodsDdl', 'fetchFGoodsDdl']),
    getDepartmentDdl() {
      return deptApi.ddl({}).then(res => {
        this.departmentList = res.data.map(item => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      });
    },
    getSemiFGoodsDetail(id) {
      return semiFGoodsApi.detail({ id }).then(res => {
        const data = res.data;
        if (this.isUndefinedNullOrEmpty(data) || this.isUndefinedNullOrEmpty(data.recipeId)) {
          this.form.qty = null;
        } else {
          // this.getRecipeDetail(data.recipeId);
        }
      });
    },
    getFGoodsDetail(id) {
      return fGoodsApi.detail({ id }).then(res => {
        const data = res.data;
        if (this.isUndefinedNullOrEmpty(data) || this.isUndefinedNullOrEmpty(data.recipeId)) {
          this.form.qty = null;
        } else {
          // this.getRecipeDetail(data.recipeId);
        }
      });
    },
    // getRecipeDetail(id) {
    //   return recipeApi.detail({ id }).then(res => {
    //     this.form.qty = res.data.qty;
    //   });
    // },
    semiFGoodsChg() {
      if (this.isUndefinedNullOrEmpty(this.form.itemId)) {
        this.form.qty = null;
      } else {
        // this.getSemiFGoodsDetail(this.form.itemId);
        const { recipeQty, recipeUom } = this.semiFGoodsDdl.find(item => item.value == this.form.itemId);
        this.form.qty = !this.isUndefinedNullOrEmpty(recipeQty) ? recipeQty : null;
        this.recipeUom = !this.isUndefinedNullOrEmpty(recipeUom) ? recipeUom : null;
      }
    },
    fGoodsChg() {
      if (this.isUndefinedNullOrEmpty(this.form.itemId)) {
        this.form.qty = null;
      } else {
        // this.getFGoodsDetail(this.form.itemId);
        const { recipeQty, recipeUom } = this.fGoodsDdl.find(item => item.value == this.form.itemId);
        this.form.qty = !this.isUndefinedNullOrEmpty(recipeQty) ? recipeQty : null;
        this.recipeUom = !this.isUndefinedNullOrEmpty(recipeUom) ? recipeUom : null;
      }
    },
    jobTypeChg() {
      this.form.itemId = null;
      this.form.qty = null;
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const keys = ['orderId', 'outletId', 'jobDate', 'batchNo', 'itemId', 'totBatch', 'deptId', 'notes', 'type', 'status'];
      let data = extractJsonKey(keys, this.form);
      data.totBatch = Number(data.totBatch);

      if (this.isUpdate) {
        data.id = this.form.id;
        this.update(data);
      } else {
        this.create(data);
      }
    },
    create(data) {
      this.loading = true;
      return jobApi
        .create(data)
        .then(res => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.createSuccess'));
          this.navigatePush('ProductionList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      return jobApi
        .update(data)
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('lbl.updateSuccess'));
          this.navigatePush('ProductionList');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancel(id) {
      this.cancelLoading = true;
      return jobApi
        .cancel({ id })
        .then(() => {
          this.cancelLoading = false;
          this.showSuccess(this.$t('production.msg.cancelSuccess'));
          this.navigatePush('ProductionList');
        })
        .catch(() => {
          this.cancelLoading = false;
        });
    },
    detail(id) {
      return jobApi.detail({ id }).then(res => {
        this.form = {
          ...this.form,
          ...res.data,
        };

        this.form.jobDate = !this.isUndefinedNullOrEmpty(this.form.jobDate)
          ? this.setTimeZone(this.form.jobDate, this.dateFormat)
          : null;

        if (this.form.type == PRODUCT_TYPE.SEMIFGOODS) {
          const { recipeUom, recipeQty } = this.semiFGoodsDdl.find(item => item.value == this.form.itemId);
          this.recipeUom = !this.isUndefinedNullOrEmpty(recipeUom) ? recipeUom : null;
          this.form.qty = recipeQty;
        } else if (this.form.type == PRODUCT_TYPE.FGOODS) {
          const { recipeUom, recipeQty } = this.fGoodsDdl.find(item => item.value == this.form.itemId);
          this.recipeUom = !this.isUndefinedNullOrEmpty(recipeUom) ? recipeUom : null;
          this.form.qty = recipeQty;
        }
        this.isReady = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  ::v-deep .v-slide-group__prev {
    display: none !important;
  }
  .v-tabs-bar {
    .v-tab {
      // background-color: var(--tbl-header-color);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: normal;
      text-transform: none;

      &.tab-active {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }
    .v-tab:not(.v-tab--active) {
      color: #ffffff;
    }
  }
}
.v-tabs-items::v-deep {
  border: 1px solid #d2d2d2;
  .v-card {
    border-radius: 0px;
  }
}
</style>
