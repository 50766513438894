import { render, staticRenderFns } from "./OpenList.vue?vue&type=template&id=4bc9609c&scoped=true&"
import script from "./OpenList.vue?vue&type=script&lang=js&"
export * from "./OpenList.vue?vue&type=script&lang=js&"
import style0 from "./OpenList.vue?vue&type=style&index=0&id=4bc9609c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc9609c",
  null
  
)

export default component.exports